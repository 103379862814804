import * as cmsActions from '@actions/cms.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {LoadStatuses} from '@types/load.types';

const initialState = {
  content: {},
  children: {
    isFetching: false,
    data: {},
    error: null,
    status: LoadStatuses.NOT_LOADED,
  },
  selectedParentPage: {data: null},
};

const cms = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case cmsActions.CMS_GET_CONTENT_REQUEST: {
    if (!action.payload.contentId) return state;

    return {
      ...state,
      content: {
        ...state.content,
        [action.payload.contentId]: {
          ...state.content[action.payload.contentId],
          status: LoadStatuses.IS_LOADING,
          error: false,
        },
      },
    };
  }
  case cmsActions.CMS_GET_CONTENT_SUCCESS: {
    return {
      ...state,
      content: {
        ...state.content,
        [action.payload.contentId]: {
          ...action.payload.content,
          status: LoadStatuses.LOADED,
          error: false,
        },
      },
    };
  }
  case cmsActions.CMS_GET_CONTENT_FAILURE: {
    return {
      ...state,
      content: {
        ...state.content,
        [action.payload.contentId]: {
          ...state.content[action.payload.contentId],
          status: LoadStatuses.FAILED,
          error: true,
        },
      },
    };
  }
  case cmsActions.CMS_GET_CHILDREN_REQUEST: {
    return {
      ...state,
      children: {
        ...initialState.children,
        isFetching: true,
        status: LoadStatuses.IS_LOADING,
      },
    };
  }
  case cmsActions.CMS_GET_CHILDREN_SUCCESS: {
    return {
      ...state,
      children: {
        ...initialState.children,
        data: action.payload.children,
        isFetching: false,
        status: LoadStatuses.LOADED,
      },
    };
  }
  case cmsActions.CMS_GET_CHILDREN_FAILURE: {
    return {
      ...state,
      children: {
        ...initialState.children,
        error: action.payload.error,
        isFetching: false,
        status: LoadStatuses.FAILED,
      },
    };
  }
  case cmsActions.CMS_SET_SELECTED_PARENT_PAGE: {
    return {
      ...state,
      selectedParentPage: {
        ...initialState.selectedParentPage,
        isFetching: true,
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default cms;
