import {AccessLevels, NOT_NULL, OPTIONAL} from './constants/access-levels';
import {PayloadTypes} from './constants/payload-types';
import {createReducer} from './store/create-reducer';
import {createModalsRegistry} from './util/create-modals-registry';
import {AdminModals, CommonModals} from './components';
import {ModalIds} from './modal-ids';

const registry = createModalsRegistry({
  /**
   * Modals available to all users (access level: All)
   */
  [ModalIds.SELF_SIGN_READ]: {
    component: CommonModals.LoadableSelfSignReadModal,
    access: AccessLevels.All,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.personId,
        PayloadTypes.competenceId,
        PayloadTypes.phceId,
      ],
      [OPTIONAL]: [
        PayloadTypes.competence,
        PayloadTypes.props,
      ],
    },
  },

  [ModalIds.SELF_SIGN_SELECT]: {
    component: CommonModals.LoadableSelfSignSelectModal,
    access: AccessLevels.All,
    payload: {
      [OPTIONAL]: [
        PayloadTypes.competence,
        PayloadTypes.trackId,
        PayloadTypes.roleId,
        PayloadTypes.defaultData,
        PayloadTypes.personId,
        PayloadTypes.props,
      ],
    },
  },

  [ModalIds.COMPETENCE]: {
    component: CommonModals.LoadableCompetenceModal,
    access: AccessLevels.All,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.orgId,
        PayloadTypes.personId,
        PayloadTypes.competence,
      ],
      [OPTIONAL]: [
        PayloadTypes.trackId,
        PayloadTypes.roleId,
        PayloadTypes.props,
      ],
    },
  },

  [ModalIds.CHECKLIST]: {
    component: CommonModals.LoadableChecklistModal,
    access: AccessLevels.All,
    payload: {
      [OPTIONAL]: [
        PayloadTypes.trackId,
        PayloadTypes.personId,
        PayloadTypes.orgId,
        PayloadTypes.competenceId,
        PayloadTypes.competence,
        PayloadTypes.roleId,
        PayloadTypes.mapId,
        {
          key: 'refetchOnClose',
          type: 'boolean',
        },
        {
          key: 'loadChildrenLocally',
          type: 'boolean',
        },
        PayloadTypes.props,
      ],
    },
  },

  [ModalIds.SIGNATURE]: {
    component: CommonModals.LoadableSignatureModal,
    access: AccessLevels.All,
    payload: {
      [NOT_NULL]: [PayloadTypes.competence],
      [OPTIONAL]: [
        PayloadTypes.trackId,
        PayloadTypes.mapId,
        PayloadTypes.props,
      ],
    },
  },

  /**
   * Modals available to admins
   */
  [ModalIds.BART]: {
    component: AdminModals.LoadableBartModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [PayloadTypes.person],
      [OPTIONAL]: [PayloadTypes.props],
    },
  },

  [ModalIds.COURSE_EVENT]: {
    component: AdminModals.LoadableCourseEventModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.eventId,
        PayloadTypes.competenceId,
      ],
      [OPTIONAL]: [PayloadTypes.props],
    },
  },

  [ModalIds.EMPLOYEE]: {
    component: AdminModals.LoadableEmployeeModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.orgId,
        PayloadTypes.personId,
        PayloadTypes.userName,
      ],
      [OPTIONAL]: [PayloadTypes.props],
    },
    options: {renderAllways: true},
  },

  [ModalIds.EMPLOYMENT]: {
    component: AdminModals.LoadableEmploymentModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.personId,
        PayloadTypes.userName,
      ],
      [OPTIONAL]: [PayloadTypes.props],
    },
  },

  [ModalIds.COURSE_BUILDER]: {
    component: AdminModals.LoadableCourseBuilderModal,
    access: AccessLevels.Admin,
    payload: {
      [OPTIONAL]: [{
        key: 'initialSelectedTool',
        type: 'string',
      },
      {
        key: 'initialStep',
        type: 'number',
      },
      PayloadTypes.props],
    },
  },

  [ModalIds.COURSE_EVENT_ATTENDANCE]: {
    component: AdminModals.LoadableCourseEventModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.orgId,
        PayloadTypes.eventId,
      ],
      [OPTIONAL]: [PayloadTypes.props],
    },
  },

  [ModalIds.PERSON_COMPETENCES]: {
    component: AdminModals.LoadablePersonCompetencesModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.personId,
        PayloadTypes.orgId,
        PayloadTypes.userName,
      ],
    },
  },

  [ModalIds.PERSON_ROLES]: {
    component: AdminModals.LoadablePersonRolesModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.personId,
        PayloadTypes.orgId,
        PayloadTypes.userName,
      ],
    },
  },

  [ModalIds.SEND_MESSAGE]: {
    component: AdminModals.LoadableSendMessageModal,
    access: AccessLevels.Admin,
    payload: {
      [NOT_NULL]: [
        PayloadTypes.userName,
        PayloadTypes.fullname,
      ],
      [OPTIONAL]: [PayloadTypes.props],
    },
  },

  // [ModalIds.EMPLOYEES_LIST]: {
  //   component: null,
  //   access: AccessLevel.Admin,
  //   payload: {
  //     [OPTIONAL]: [
  //       PayloadTypes.orgId,
  //       PayloadTypes.props,
  //     ],
  //   },
  // },

  // [ModalIds.COURSE]: {
  //   component: null,
  //   access: AccessLevel.All,
  //   payload: {
  //     [NOT_NULL]: [
  //       PayloadTypes.orgId,
  //       PayloadTypes.personId,
  //       PayloadTypes.competenceId,
  //     ],
  //     [OPTIONAL]: [PayloadTypes.props],
  //   },
  // },
});

const {
  initialState: modalsInitialState,
  actions: modalActions,
  components: modalComponents,
  options: modalOptions,
  idsByAccessLevel: modalIdsByAccessLevel,
} = registry;

const modalsReducer = createReducer(modalsInitialState);

export {
  modalActions,
  modalComponents,
  modalIdsByAccessLevel,
  modalOptions,
  modalsInitialState,
  modalsReducer,
};
