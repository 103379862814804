import dayjs from 'dayjs';
import {daysDifference} from '@utils/misc.utils';

export const isDatesEqual = (a, b) => dayjs(a).isSame(dayjs(b), 'day');

export const isToday = date => isDatesEqual(date, dayjs());

export const sortByDateDesc = (startDateA, startDateB) => startDateA - startDateB;

export const verifyDeadlineDates = (signOnDeadlineDate, signOffDeadlineDate)  => {
  const now = dayjs();

  const enableSignOn = dayjs(signOnDeadlineDate).isAfter(now);
  const enableSignOff = dayjs(signOffDeadlineDate).isAfter(now);

  const daysToSignOn = enableSignOn ? daysDifference(signOnDeadlineDate, now) : 0;
  const daysToSignOff =  enableSignOff ? daysDifference(signOffDeadlineDate, now) : 0;

  return {
    signOnDeadline: signOnDeadlineDate,
    signOffDeadline: signOffDeadlineDate,
    enableSignOn,
    enableSignOff,
    daysToSignOn,
    daysToSignOff,
  };
};

export const getPersonDetailsFromEventObject = event => {
  if (!event?.person?.id) return {};

  const {
    person: {
      id,
      fullname,
      user_name,
    },
  } = event;

  return {
    id,
    fullname,
    user_name,
  };
};

export const partitionWaitlistConfirmed = ({
  eventId,
  profileId,
  employeesEvents,
  selfSignedOn,
}) => {
  const waitlist = [];
  const confirmed = [];

  const eventObjects = selfSignedOn
    ? employeesEvents?.filter?.(event => event?.id === eventId && !!event?.person?.id && event.person.id !== profileId)
    : employeesEvents?.filter?.(event => event?.id === eventId);

  eventObjects?.forEach?.(event => {
    if (!event?.hasOwnProperty?.('waitlist')) return;

    const personDetails = getPersonDetailsFromEventObject(event);

    (event.waitlist ? waitlist : confirmed).push(personDetails);
  });

  return {
    waitlist,
    confirmed,
  };
};

export const getNormalizedParticipantsData = ({
  eventId,
  myEvents,
  employeesEvents,
  profileId,
}) => {
  let selfSignedOn, selfWaitlist, selfConfirmed, selfParticipantObj;

  const selfSignedOnEvent = myEvents?.filter(event => event.id === eventId)?.[0];

  if (selfSignedOnEvent) {
    selfSignedOn = true;
    selfWaitlist = selfSignedOnEvent.waitlist;
    selfConfirmed = !selfWaitlist;

    const {fullname, user_name} = selfSignedOnEvent.person;

    selfParticipantObj = {
      id: profileId,
      fullname,
      user_name,
    };
  } else {
    [selfSignedOn, selfWaitlist, selfConfirmed, selfParticipantObj] = [false, false, false, null];
  }

  const {waitlist, confirmed} = partitionWaitlistConfirmed({
    eventId,
    profileId,
    employeesEvents,
    selfSignedOn,
  });

  // const confirmedCount = (selfConfirmed ? 1 : 0) + confirmed?.length ?? 0;
  const waitlistCount = (selfWaitlist ? 1 : 0) + waitlist?.length ?? 0;

  const normalized = {
    selfSignedOn,
    selfWaitlist,
    selfConfirmed,
    selfParticipantObj,
    employeesConfirmed: confirmed,
    employeesWaitlist: waitlist,
    // confirmedCount,
    waitlistCount,
  };

  return normalized;
};

export const parseEvent = (event, type) => {
  const {
    id,
    title,
    startdate,
    enddate,
    competence_id,
    duration,
    durations,
    location: {title: locationStr},
    max_participants,
    participants_count,
    sign_on_deadline,
    sign_off_deadline,
    short_description,
    user_file_upload_allowed,
    user_file_upload_date_limit,
    userFiles,
    // certificates_url,
    // competence_groups,
    // joinable,
    files,
  } = event;

  const startDate = dayjs(startdate || event?.startDate);
  const endDate = dayjs(enddate || event?.endDate);

  if (!startDate || startDate.isBefore(dayjs())) return null;

  const startsToday = startDate.isSame(dayjs());

  const durationUnits = {};

  durations?.forEach?.(({duration, type}) => {
    durationUnits[type] = duration;
  });

  const {
    signOnDeadline,
    signOffDeadline,
    daysToSignOn,
    daysToSignOff,
    enableSignOn,
    enableSignOff,
  } = verifyDeadlineDates(dayjs(sign_on_deadline), dayjs(sign_off_deadline));

  return {
    id,
    title,
    startDate,
    endDate,
    startdate,
    enddate,
    sign_on_deadline,
    sign_off_deadline,
    isToday: startsToday,
    location: locationStr,
    locationTitle: locationStr,
    locationObj: {...event.location},
    participants_count,
    confirmedCount: participants_count,
    maxParticipants: max_participants,
    shortDescription: short_description,
    duration: {
      formatted: duration,
      units: durationUnits,
    },
    deadlines: {
      signOnDeadline,
      signOffDeadline,
      daysToSignOn,
      daysToSignOff,
      signOnPassed: !enableSignOn,
      signOffPassed: !enableSignOff,
    },
    files,
    userFiles,
    fileUploadEnabled: user_file_upload_allowed,
    fileUploadDeadline: user_file_upload_date_limit,
    // certificateUrl: certificates_url,
    courseId: competence_id,
    competence_id,
    // courseTypeTitle,
    // joinable,
    __isParsedEvent: true,
    __isFullView: participants_count != null && max_participants != null,
  };
};
