export const isObject = obj => !!obj && typeof obj === 'object' && !Array.isArray(obj);
export const isObjectWithKeys = obj => isObject(obj) && Object.keys(obj).length > 0;

export const _snake2PascalOrCamel = pascal => str => {
  str += '';
  str = str.toLowerCase().split('_');
  for (var i = 0; i < str.length; i++) {
    const first = str[i].charAt(0).toUpperCase();
    const rest = str[i].slice(1);

    if (pascal) {
      str[i] = first + rest;
    } else {
      str[i] = i === 0 ? first.toLowerCase() + rest : first + rest;
    }
  }

  return str.join('');
};

export const snake2Pascal = _snake2PascalOrCamel(true);
export const snake2Camel = _snake2PascalOrCamel(false);

export const camel2Snake = str => str.replaceAll(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);

export function camelCaseToNormalCase(str) {
  return str.replaceAll(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
}

export const normalCaseToCamelCase = str => str.toLowerCase()
  .replaceAll(
    /^\w|[A-Z]|\b\w/g,
    (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase(),
  )
  .replaceAll(/\s+/g, '');

export const joinPrefixes = args => args.join('/');
export const isUpperCase = str => str === str.toUpperCase();
export const isCamelCase = str => !/[\s_-]/g.test(str);

export const mergeCompetencesNonNullish = (...objects) => objects
  .reduce((acc, obj) => {
    if (!isObjectWithKeys(obj)) return acc;

    Object.keys(obj).forEach(key => {
      if (!obj[key]) return;

      if (!acc[key]) {
        acc[key] = obj[key];
      } else if (Array.isArray(obj[key])) {
        acc[key] = [...acc[key] || [], ...obj[key] || []].filter((item, index, arr) => {
          if (isObjectWithKeys(item)) {
            const foundIndex = arr.findIndex(i => {
              if (i.competence_id && item.competence_id) {
                return i.competence_id === item.competence_id;
              }

              return i.id === item.id;
            });

            return foundIndex === index;
          }

          return arr.indexOf(item) === index;
        });
      } else if (isObjectWithKeys(obj[key])) {
        acc[key] = mergeCompetencesNonNullish(acc[key], obj[key]);
      } else if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
        acc[key] = obj[key];
      }
    });

    return acc;
  }, {});
