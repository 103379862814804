import {baseTheme, baseThemeColors} from '../base';
import {createButtonsTheme} from './create-buttons-theme';
import {createCourseCatalogTheme} from './create-course-catalog-theme';

export function createCustomTheme({
  primary = baseThemeColors.primary,
  surface = baseThemeColors.surface,
  scheme = {}, // ,baseTheme.scheme.primary,
  courseCatalog = {}, // baseTheme.courseCatalog,
  buttons = {}, // baseTheme.buttons,
  dropdown = {}, // baseTheme.colors.dropdown,
  employeeModal = {}, // baseTheme.employeeModal,
  progressBarColor = null,
  clapIllustrationColor = null,
  mainMenuBackgroundColor = null,
  mainMenuColor = null,
  menuIconColor = null,
  menuIconBackgroundColor = null,
  checkContrast = false,
  ...rest
}) {
  return {
    mainMenuBackgroundColor,
    mainMenuColor,
    menuIconColor,
    menuIconBackgroundColor,
    chartPrimaryColor: primary,
    primaryColor: primary,
    chartSecondaryColor: '#EDEDED',
    borderHoverColor: primary,
    chartBadge: primary,
    clapIllustrationColor,
    progress: {
      ...baseTheme.progress,
      'default': {
        ...baseTheme.progress.default,
        backgroundColor: progressBarColor || primary,
      },
    },
    scheme: {
      ...baseTheme.scheme,
      primary: {
        ...baseTheme.scheme.primary,
        ...scheme,
      },
    },
    colors: {
      ...baseTheme.colors,
      primary,
      surface,
      dropdown: {
        ...baseTheme.colors.dropdown,
        ...dropdown,
      },
      header: '#D1EB2E',
      menu: {header: primary},
      icon: {
        color1: '#EDFCF2',
        color2: '#D3F8DF',
        ico: '#099250',
      },
      reports: {
        ...baseTheme.colors.reports,
        selectReport: {
          ...baseTheme.colors.reports.selectReport,
          colorSelected: primary,
          color: '#000',
        },
      },
    },
    tiles: {
      color2: '#026149',
      color1: '#008B69',
    },
    buttons: createButtonsTheme({
      primary,
      surface,
      scheme,
      buttons,
      checkContrast,
    }),
    courseCatalog: createCourseCatalogTheme({
      scheme,
      courseCatalog,
      checkContrast,
    }),
    employeeModal: {
      ...baseTheme.employeeModal,
      ...employeeModal,
      roleCard: {
        ...baseTheme.employeeModal?.roleCard,
        backgroundColorExpanded: scheme.color50,
        borderColorExpanded: scheme.color400,
        colorExpanded: scheme.color800,
        iconBorderColor: scheme.color50,
        iconBackgroundColor: scheme.color100,
        iconColor: scheme.color600,
        childBackgroundColorExpanded: scheme.color50,
        childBorderColorExpanded: scheme.color200,
        ...employeeModal?.roleCard,
      },
    },
  };
}
