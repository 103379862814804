import memoize from 'micro-memoize';
import {useSelector} from 'react-redux';
import {createSelector} from 'reselect';

export const selectModals = ({modals}) => modals;
export const getModalSelector = memoize(modalId => createSelector(
  selectModals,
  modals => modals[modalId] || {},
));
export const useModalSelector = modalId => useSelector(getModalSelector(modalId));
