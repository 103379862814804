import dayjs from 'dayjs';
import {getCompetenceCoverImageSrc, getDurationText} from '@routes/course-catalog/util';
import {parseEvent} from '@src/hooks/store/course/util';

export const sortEventsByDate = (a, b) => (a, b) => dayjs(a.startdate).diff(dayjs(b.startdate));

export const getYearMonthString = date => dayjs(date).format('YYYY-MM');

export const insertSingleEvent = (acc, parsedEvent) => {
  const {
    allEventIds,
    eventById,
    eventIdsByCourseId,
    eventIdsByYearMonth,
  } = acc;

  const {competence_id: courseId, id: eventId} = parsedEvent;
  const [year, month]  = getYearMonthString(parsedEvent.startDate).split('-');

  // no other events in prev state, we can return early
  if (!allEventIds.length) {
    return {
      allEventIds: [eventId],
      eventById: {[eventId]: parsedEvent},
      eventIdsByCourseId: {[courseId]: [eventId]},
      eventIdsByYearMonth: {[year]: {[month]: [eventId]}},
    };
  }

  allEventIds.push(eventId);
  eventById[eventId] = parsedEvent;

  if (!eventIdsByCourseId[courseId]) {
    eventIdsByCourseId[courseId] = [eventId];
  } else if (!eventIdsByCourseId[courseId].includes(eventId)) {
    eventIdsByCourseId[courseId].push(eventId);
  }

  const prevByYearMonth = eventIdsByYearMonth[year]?.[month] || [];

  if (prevByYearMonth.includes(eventId)) {
    return acc;
  }

  if (!eventIdsByYearMonth[year]) {
    eventIdsByYearMonth[year] = {[month]: [eventId]};
  } else if (eventIdsByYearMonth[year][month]) {
    eventIdsByYearMonth[year][month].push(eventId);
  } else {
    eventIdsByYearMonth[year][month] = [eventId];
  }

  return acc;
};

export const reduceEventsFullUpdate = (acc, event) => {
  const {id: eventId, competence_id: courseId} = event;
  const {allEventIds} = acc;

  if (!eventId || !courseId || allEventIds.includes(eventId)) return acc;

  const parsedEvent = event?.__isParsedEvent ? event : parseEvent(event);

  if (!parsedEvent) return acc;

  return insertSingleEvent(acc, parsedEvent);
};

export const getCompentenceMetaData = ({competence, passedIdsSet, passedCompetences}) => {
  const {id, competence_type, durations} = competence || {};

  if (!id || !competence_type || !durations) return null;

  const durationText = getDurationText(durations);
  const metaData = {
    durationText,
    ...competence_type === 'Nano Speed Course' && passedIdsSet?.has(id)
      ? {
        passed: true,
        points: passedCompetences && passedCompetences?.data ? passedCompetences.data.find(obj => obj.competence_id === id)?.point : 0,
      }
      : {},
  };

  return metaData;
};

export const getCompetenceCoverImage = (competence, defaultImage) => {
  const {files, content} = competence || {};

  if (!files && !content) return defaultImage;

  return getCompetenceCoverImageSrc({
    files,
    content,
  }, defaultImage);
};
