import {snake2Camel} from '@src/store-normalized/util/misc';
import {createModalActionCreators} from './create-modal-action-creators';
import {createModalInitialState} from './create-modal-initial-state';

export function createModalsRegistry(configs) {
  return Object.keys(configs)
    .reduce((acc, modalId) => {
      const config = configs[modalId];

      if (!config) return acc;

      acc.initialState[modalId] = createModalInitialState(modalId, config.payload, config.initialState);
      acc.actions[modalId] = createActions(modalId, config);
      acc.components[modalId] = config.component;
      acc.options[modalId] = config.options;

      if (!acc.idsByAccessLevel[config.access]) acc.idsByAccessLevel[config.access] = [];
      acc.idsByAccessLevel[config.access].push(modalId);

      return acc;
    }, {
      initialState: {},
      actions: {},
      components: {},
      options: {},
      idsByAccessLevel: {},
    });
}

const toArray = value => value == null
  ? []
  : Array.isArray(value)
    ? value
    : [value];

function asActionCreator(value) {
  return value
    ? typeof value === 'function'
      ? value
      : typeof value === 'string'
        ? payload => ({
          type: value,
          payload,
        })
        : null
    : null;
}

function createActions(modalId, config) {
  const {open, close} = createModalActionCreators(modalId);

  return {
    open: [
      open,
      payload => ({
        type: `modals/${snake2Camel(modalId)}/opened`,
        payload,
      }),
      ...toArray(config.actionsOnOpen).map(asActionCreator)
        .filter(Boolean),
    ],
    close: [
      close,
      payload => ({
        type: `modals/${snake2Camel(modalId)}/closed`,
        payload,
      }),
      ...toArray(config.actionsOnClose).map(asActionCreator)
        .filter(Boolean),
    ],
  };
};
