import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import {CourseTypes} from '@types/competence';
import {isStringWithLength} from '@utils/string.utils';

const courseTypeKeys = new Set(Object.keys(CourseTypes));

export const getCompetenceType = competenceOrType => {
  if (!competenceOrType) return null;

  const competenceType = typeof competenceOrType === 'string'
    ? competenceOrType
    : competenceOrType?.competence_type?.competence_type
      || competenceOrType?.competence_type
      || competenceOrType?.competence?.competence_type?.competence_type
      || competenceOrType?.competence?.competence_type
      || competenceOrType?.competenceType
      || competenceOrType?.competence?.competenceType
      || competenceOrType?.type;

  if (isObjectWithKeys(competenceType)) return getCompetenceType(competenceType);

  return isStringWithLength(competenceType)
    ? competenceType
    : null;
};

const getCompetenceTypes = typesOrTypeKeys => {
  const types = typesOrTypeKeys.flatMap(competenceType => {
    if (courseTypeKeys.has(competenceType)) {
      return [competenceType, ...CourseTypes[competenceType]];
    }

    return [competenceType];
  });

  if (!types.length) return [];

  for (const competenceType of types) {
    if (courseTypeKeys.has(competenceType) && !types.includes(competenceType)) {
      types.push(competenceType);
    }
  }

  return types;
};

const createTypeCheckFunction = typeKeyOrKeys => {
  const types = Array.isArray(typeKeyOrKeys)
    ? typeKeyOrKeys
    : [typeKeyOrKeys];

  const typesToCheck = new Set(getCompetenceTypes(types));

  return typeOrCompetence => {
    const type = getCompetenceType(typeOrCompetence);

    return !!type && typesToCheck.has(type);
  };
};

export const isCertificateCompetence = createTypeCheckFunction('certificate');
export const isFormalCompetence = createTypeCheckFunction('formalcompetence');
export const isComplexCompetence = createTypeCheckFunction('complex');
export const isECourseCompetence = createTypeCheckFunction('ecourse');
export const isEquivalentsCompetence = createTypeCheckFunction('equivalents');
export const isTrackCompetence = createTypeCheckFunction('track');
export const isDigitalSignatureCompetence = createTypeCheckFunction('digitalsignature');
export const isChecklistItemCompetence = createTypeCheckFunction('checklist_item');
export const isVerificationCompetence = createTypeCheckFunction('verification');
export const isNanoCompetence = createTypeCheckFunction('nano');
export const isClassroomCourseCompetence = createTypeCheckFunction('course');

export const isSelfSign = createTypeCheckFunction('selfsign');
export const isChecklistCompetence = createTypeCheckFunction('checklist');
export const isChecklistOrChecklistItem = createTypeCheckFunction(['checklist', 'checklist_item']);
export const isNanoOrEcourse = createTypeCheckFunction(['nano', 'ecourse']);
export const isSelfSignOrChecklistItem = createTypeCheckFunction(['selfsign', 'checklist_item']);
export const isSelfSignOrChecklist = createTypeCheckFunction(['selfsign', 'checklist', 'checklist_item']);
export const isSelfSignOrSignature = createTypeCheckFunction(['selfsign', 'digitalsignature']);
export const isChecklistItemSelfSignOrSignature = createTypeCheckFunction(['selfsign', /* 'checklist', */'checklist_item', 'digitalsignature']);

export const canHaveChildrenCompetences = createTypeCheckFunction([
  'complex',
  'equivalents',
  'checklist',
  'track',
]);
export const canBeStatusPending = createTypeCheckFunction([
  'formalcompetence',
  'verification',
  'certificate',
  'checklist_item',
]);

export const getCompetenceId = idOrCompetence => {
  if (!idOrCompetence) return null;

  const id = typeof idOrCompetence === 'number' || typeof idOrCompetence === 'string'
    ? idOrCompetence
    : idOrCompetence?.competence_id
      || idOrCompetence?.competence?.competence_id
      || idOrCompetence?.course_id
      || idOrCompetence?.competence?.course_id
      || idOrCompetence?.courseId
      || idOrCompetence?.competence?.courseId
      || idOrCompetence?.competenceId
      || idOrCompetence?.competence?.competenceId
      || idOrCompetence?.course?.competence_id
      || idOrCompetence?.course?.cid
      || idOrCompetence?.course?.course_id
      || idOrCompetence?.course?.courseId
      || idOrCompetence?.competence?.id
      || idOrCompetence?.competence?.cid
      || idOrCompetence?.course?.id
      || idOrCompetence?.pending_checklists?.competence_id
      || idOrCompetence?.pending_checklists?.id
      || idOrCompetence?.cid
      || idOrCompetence?.id
      || null;

  if (!id && isObjectWithKeys(idOrCompetence?.data)) return getCompetenceId(idOrCompetence.data);

  const idNum = Number(id);

  return Number.isNaN(idNum) ? null : idNum;
};

export const getCompetencePassed = item => {
  if (!isObjectWithKeys(item)) return null;

  const passed = item?.competence?.passed
    ?? item?.course?.passed
    ?? item?.passed
    ?? item?.data?.passed
    ?? item?.passedInfo?.passed
    ?? null;

  return Number.isNaN(passed)
    ? null
    : passed;
};

export const getCompetenceCheckedBy = item => {
  if (!isObjectWithKeys(item)) return null;

  // const passed = getCompetencePassed(item) || 0;

  const checked_by = item.competence?.checked_by
    || item.checked_by;
    // || item.checklist?.check_raw
    // || (isSelfSignOrChecklistItem(item) && passed > 0 && passed < 100 ? 'both' : null);

  return checked_by;
};

export const getPassedTasksInfo = ({
  competence,
  passedCompetenceIds = [],
  pendingSelfSignIds = [],
}) => {
  const {
    id,
    checked_by,
    passed: competencePassed,
    children_ids,
    children,
  } = competence;

  const childrenIds = children_ids || children?.map(child => getCompetenceId(child));

  const tasksCount = childrenIds?.length || 1;
  const childrenPassedCount = childrenIds?.length
    ? childrenIds.filter(id => passedCompetenceIds.includes(id)).length
    : +passedCompetenceIds.includes(id);

  const isPassed = competencePassed == null
    ? childrenPassedCount === tasksCount
    : competencePassed === 100;

  const passedPercent = competencePassed == null
    ? Math.round(childrenPassedCount / tasksCount * 100)
    : competencePassed;

  const completedTasksCount = isPassed
    ? tasksCount
    : childrenPassedCount
      ? childrenPassedCount
      : passedPercent
        ? Math.round(passedPercent / 100 * tasksCount)
        : 0;

  const checkedBy = getCompetenceCheckedBy(competence);
  const waitingForApproval = !!checked_by && passedPercent === 50
    && ['both', 'manager'].includes(checkedBy)
    || !!pendingSelfSignIds?.includes?.(id);

  return {
    total: tasksCount,
    completed: completedTasksCount,
    isPassed,
    passedPercent,
    waitingForApproval,
    checkedBy,
    status: isPassed && !waitingForApproval
      ? 'passed'
      : waitingForApproval
        ? 'waiting_for_approval'
        : completedTasksCount > 0
          ? 'in_progress'
          : 'not_started',
  };
};

export const getPhceId = item => {
  if (!isObjectWithKeys(item)) return null;

  return item?.phceId
    || item?.phce_id
    || item?.competence?.phce_id
    || item?.competence?.phceId
    || item?.person_competence_id
    || null;
};

export const getIsPassedOrWaitingForApproval = (competence, passedCompetenceIds) => {
  if (!isObjectWithKeys(competence)) return false;

  const passed = getCompetencePassed(competence);

  if (passed === 100) return true;

  const phceId = getPhceId(competence);

  if (phceId) return true;

  const passedInfo = competence?.passedInfo
    || !!passedCompetenceIds?.length && getPassedTasksInfo({
      competence,
      passedCompetenceIds,
    });

  return !!passedInfo?.isPassed || !!passedInfo?.waitingForApproval;
};

export const getIsCompletedCompetence = (competence, passedCompetenceIds) => {
  if (!isObjectWithKeys(competence)) return false;

  const {passed = 0, passedInfo = {}} = competence;

  return passed === 100
    || !!passedInfo.isPassed
    || !!competence?.isPassed
    || !!passedCompetenceIds?.length && !!getPassedTasksInfo({
      competence,
      passedCompetenceIds,
    })?.isPassed;
};
