import {withProduce} from '@src/store/util/with-produce';
import {BASE} from '../util/constants';
import {isObjectWithKeys, isUpperCase, snake2Camel} from '../util/misc';
import {createAction, createReduxActions as createReduxActionCreators} from './createActions';

const isProd = process?.env?.NODE_ENV === 'production';

const allPropertiesAreNull = obj => {
  for (const key of Object.keys(obj)) {
    if (obj[key] != null) return false;
  }

  return true;
};

export function createReducerAndActions({
  prefix,
  actions = {},
  initialState = {},
  keepActionNames = false,
}) {
  const _actionCreators = {};
  const _reducers = {};

  if (!isProd) {
    if (!prefix) throw new Error('prefix is required');
    if (!isObjectWithKeys(actions)) throw new Error('No actions provided');
  }

  for (const baseActionName of Object.keys(actions)) {
    const actionMatcher = actions[baseActionName];

    if (actionMatcher == null) {
      _actionCreators[baseActionName] = createAction(keepActionNames
        ? baseActionName
        : `${prefix}/${baseActionName}`);

      continue;
    }

    const actionMatcherIsObject = isObjectWithKeys(actionMatcher);

    if (actionMatcherIsObject && allPropertiesAreNull(actionMatcher)) {
      const actionCreators = createReduxActionCreators({
        prefix,
        actionName: baseActionName,
        keepNames: keepActionNames,
        subTypes: Object.keys(actionMatcher),
      });

      _actionCreators[baseActionName] = actionCreators.BASE;

      for (const actionSubType of Object.keys(actionCreators)) {
        const actionCreator = actionCreators[actionSubType];

        if (actionSubType !== BASE) {
          const subType = isUpperCase(actionSubType)
            ? snake2Camel(actionSubType)
            : actionSubType;

          _actionCreators[baseActionName][subType] = actionCreator;
        }
      };

      continue;
    }

    const actionMatcherIsFunction = !actionMatcherIsObject && typeof actionMatcher === 'function';
    const _actionTypes = actionMatcherIsFunction
      ? [BASE]
      : [];

    if (actionMatcherIsObject) {
      for (const actionType of Object.keys(actionMatcher)) {
        if (!_actionTypes.includes(actionType)) {
          _actionTypes.push(actionType);
        }
      }
    }
    const actionCreators = createReduxActionCreators({
      prefix,
      actionName: baseActionName,
      subTypes: _actionTypes,
      keepNames: keepActionNames,
    });

    _actionCreators[baseActionName] = actionCreators.BASE;

    for (const actionSubType of Object.keys(actionCreators)) {
      const actionCreator = actionCreators[actionSubType];

      if (actionSubType !== BASE) {
        const subType = isUpperCase(actionSubType)
          ? snake2Camel(actionSubType)
          : actionSubType;

        _actionCreators[baseActionName][subType] = actionCreator;
      }

      const updateFn = actionMatcherIsFunction
        ? actionMatcher
        : actionMatcher[actionSubType];

      if (updateFn) _reducers[actionCreator.type] = updateFn;
    };
  }

  return {
    reducer: withProduce(initialState, _reducers),
    actions: _actionCreators,
  };
};
