import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {faHourglass} from '@fortawesome/pro-regular-svg-icons';
import {faClock, faStopwatch} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';
import './course-duration.scss';

export const getDurationName = (metric, plural) => plural
  ? i18n('date-and-time.' + metric, {lowerCase: true}, metric)
  : i18n('date-and-time.' + metric.slice(0, - 1), {lowerCase: true}, metric);

const CourseDuration = ({
  duration,
  metric,
  iconType,
  className,
  format, // allows you to pass a function on how to present the duration and duration name
  ...props
}) => {
  const icon = {
    stopwatch: faStopwatch,
    hourglass: faHourglass,
  }[iconType] || faClock;

  return (
    <div
      className={classNames('course-duration', className)}
      {...props}
    >
      <FontAwesomeIcon
        icon={icon}
        className="course-duration__icon"
      />
      {format ? format(duration, getDurationName(metric, duration !== 1)) : `${duration} ${getDurationName(metric, duration !== 1)}`}
    </div>
  );
};

CourseDuration.defaultProps = {
  className: null,
  iconType: 'clock',
};

CourseDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number.isRequired,
  metric: PropTypes.oneOf(['hours', 'days', 'minutes']).isRequired,
  iconType: PropTypes.oneOf(['stopwatch', 'clock']),
};

export default CourseDuration;
