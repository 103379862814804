import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';
import {selectEmployeesList} from './employees.selectors';

export const selectManage = ({manage}) => manage;
export const selectManageRoles = ({manage: {roles}}) => roles;
export const _selectManageCompetences = ({manage: {competences}}) => competences;
export const selectManageCompetenceDetails = ({manage: {competenceDetails}}) => competenceDetails;

export const selectManageCompetences = createSelector(
  _selectManageCompetences,
  competences => {
    const {
      byId = {},
      allIds = [],
      status = LoadStatuses.NOT_LOADED,
      hasMore = false,
      count = 0,
      filteredCount = null,
      filteredHasMore = null,
    } = competences || {};

    return {
      byId,
      allIds,
      data: allIds.map(id => byId[id]),
      status,
      hasMore,
      count,
      filteredCount,
      filteredHasMore,
    };
  },
);

export const selectManageCompetencesWithParams = createSelector(
  selectManageCompetences,
  (_, {limit, courseTypes, searchFor}) => ({
    limit,
    courseTypes,
    searchFor,
  }),
  (competences, {limit, courseTypes, searchFor}) => {
    const anyFiltersActive = !!(courseTypes?.length || searchFor?.trim?.()?.length);

    if (!anyFiltersActive) return competences;

    const {
      byId,
      allIds,
      status,
      count,
      hasMore,
      filteredCount,
      filteredHasMore,
    } = competences;

    const filteredIds = allIds.filter(id => {
      const competence = byId[id];

      if (!competence) return false;
      const {title = '', course_type = ''} = competence;

      if (searchFor && !title.toLowerCase().includes(searchFor.toLowerCase())) {
        return false;
      }

      if (courseTypes && !courseTypes.includes(course_type)) {
        return false;
      }

      return true;
    });

    const data = filteredIds.map(id => byId[id]);

    return {
      byId,
      allIds: filteredIds,
      data,
      status,
      hasMore,
      count,
      filteredCount,
      filteredHasMore: filteredIds.length < filteredCount,
    };
  },
);

// not working wip/placeholder (copied function from roles.selectors.js)
export const selectCompetenceDetailsById = createSelector(
  selectManageCompetenceDetails,
  selectEmployeesList,
  (_, competenceId) => competenceId,
  (competenceDetails, employeesState, competenceId) => {
    if (!competenceId) return {};

    const {byId: competenceById} = competenceDetails;

    const {
      data = null,
      status = LoadStatuses.NOT_LOADED,
    } = competenceById[competenceId] || {};

    if (status !== LoadStatuses.LOADED) return {
      data,
      employees: [],
      status,
    };

    const idNum = Number(competenceId);
    const {byId: employeeById = {}} = employeesState || {};
    const competenceEmployees = [];

    Object.values(employeeById).forEach(employee => {
      const {competenceIds = []} = employee;

      if (competenceIds.includes(idNum)) {
        competenceEmployees.push(employee);
      }
    });

    return {
      data,
      employees: competenceEmployees,
      status,
    };
  },
);
