import React from 'react';
import {useSelector} from 'react-redux';
import {selectReduxModuleLoadStatuses} from '@actions/app.actions';
import {LoadingLine} from '@components/loading/loading-line';
import {
  getIsSuperuser,
  selectIsManager,
  selectProfileId,
} from '@selectors/profile.selectors';
import {DefaultRedirectRoute} from '@src/default-redirect';
import {LoadStatuses} from '@types/load.types';
import {ReduxModuleIds} from '../constants';
import {ReduxModuleInjector} from './module-injector';

export const WithEmployeesModule = React.memo(({
  redirect = true,
  renderAllways = false,
  children,
}) => {
  const profileId = useSelector(selectProfileId);
  const isManager = useSelector(selectIsManager);
  const isSuperUser = useSelector(getIsSuperuser);

  const {employees} = useSelector(selectReduxModuleLoadStatuses);

  if (employees === LoadStatuses.LOADED) return children;

  const isLoading = !profileId || employees === LoadStatuses.IS_LOADING;

  if (isLoading) return <LoadingLine />;
  const isDisabled = !!profileId && !(isManager || isSuperUser);

  if (isDisabled && !renderAllways && redirect) return <DefaultRedirectRoute />;
  const shouldLoadModule = employees === LoadStatuses.NOT_LOADED && !isDisabled;

  if (shouldLoadModule) return (
    <ReduxModuleInjector
      moduleId={ReduxModuleIds.employees}
      renderAllways={renderAllways}
    >
      {children}
    </ReduxModuleInjector>
  );

  if (isDisabled && !renderAllways) return null;

  return children;
}, (prev, next) => prev.redirect === next.redirect
  && prev.renderAllways === next.renderAllways);
