import {css} from '@emotion/react';
import styled from '@emotion/styled';

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 1.5em;
`;

export const FileinfoWrapper = styled.div`
  display: flex;
  line-height: 1.3;
`;

export const Wrapper = styled.div`
  margin-bottom: 0.4rem;


`;
export const Download = styled.span`
  color: #0B5CD7;
  margin-left: auto;
  font-size: 12.5px;
  padding: .25rem .5rem;
  border: 1px solid transparent;
`;

export const Read = styled.span`
  font-weight: 400;
  color: #444444;
  .separator{
    margin: 0 0.3rem;
  }
`;

export const File = styled.a`
  display: block;
  padding: 12px 16px;
  background-color: white;
  color: $color-black;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  font-weight: 500;
  margin-bottom: 0.7rem;
  justify-content: space-between;


  &:last-child {
    margin-bottom: 0;
  }

  &:hover{
    cursor: pointer;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }

  ${({read}) => read && css`
    border: 1px solid #B3DAC7;
    background: #EBF5F0;

  `}


`;
