import axios from 'axios';
import {
  all,
  call,
} from 'redux-saga/effects';
import {backendUrl} from '@config';
import {waitForOrgId, waitForProfileId, waitForUsername} from '@sagas/app.sagas';
import {isObjectWithKeys} from '@snapper/core';
import {retry} from '@utils/sagas.utils';
import {getCompetenceCheckedBy, getCompetenceId, getCompetencePassed, getCompetenceType} from '../util/competence-identity';
import {fetchChildrenCompetencesAPI} from './api';

export function* fetchPersonCompetenceAPI({cid, username}) {
  if (!cid) return null;

  const _username = username || (yield call(waitForUsername));

  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences/${cid}`,
      params: {
        fields: 'title,date,description,valid_until,requirement_type,certificate_url,description,competence_id,competence_type,'
        + 'competence(files,competence_title,checked_by,short_description,title,competence_type,competence_type_id,competence_id)'
        + 'passed,manager_check_user_id,checklist',
        limit: 101,
        user_name: _username,
      },
      withCredentials: true,
    }));

    const phce = data?.personcompetences?.[0];

    return phce
      ? {
        ...phce,
        id: null,
        phce_id: phce?.id,
        competence_id: getCompetenceId(phce),
        passed: getCompetencePassed(phce),
        checked_by: getCompetenceCheckedBy(phce),
        passed_date: phce?.date,
      }
      : null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchCompetenceChildrenProgressAPI(action) {
  const {cid, personId, orgId} = action?.payload || {};

  if (!cid) return null;

  const _orgId = orgId || (yield call(waitForOrgId));
  const _personId = personId || (yield call(waitForProfileId));

  try {
    const childrenData = yield call(fetchChildrenCompetencesAPI, {
      cid,
      personId,
      orgId,
    });

    if (!childrenData?.length) return null;

    let progress = 0;
    let childrenCount = 0;
    let childrenCompletedCount = 0;

    const childById = {};
    const childrenIds = [];
    const progressById = {};

    for (const competence of childrenData) {
      const childId = getCompetenceId(competence);

      childrenCount += 1;
      progress += competence?.passed || 0;
      if (competence?.passed === 100) childrenCompletedCount += 1;

      if (childId) {
        progressById[childId] = competence?.passed;
        childrenIds.push(childId);
      }
    }

    return {
      competence_id: cid,
      passed: Math.round(progress / childrenCount),
      children_ids: childrenIds,
      children_count: childrenCount,
      children_completed_count: childrenCompletedCount,
      children_progress_by_id: progressById,
      child_by_id: childById,
    };
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchParentAndChildrenPersonCompetencesAPI({cid, personId, orgId, username}) {
  if (!cid) return null;

  const _orgId = orgId || (yield call(waitForOrgId, true));
  const _pid = personId || (yield call(waitForProfileId));

  try {
    const {parentData, childrenData} = yield all({
      parentData: call(fetchPersonCompetenceAPI, {
        cid,
        username,
      }),
      childrenData: call(fetchChildrenCompetencesAPI, {
        cid,
        personId: _pid,
        orgId: _orgId,
      }),
    });

    const childrenPassedPercent = childrenData?.reduce?.((acc, item) => acc + (item?.passed || 0), 0) / childrenData?.length || 0;

    const parent = isObjectWithKeys(parentData)
      ? [{
        ...parentData,
        children_ids: childrenData?.map?.(item => getCompetenceId(item)) || null,
        passed: getCompetenceType(parentData) === 'checklist' && childrenPassedPercent || parentData?.passed || 0,
      }]
      : [];

    return [...parent, ...childrenData || []];
  } catch (error) {
    console.error(error);

    return null;
  }
}
