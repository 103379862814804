import * as componentActions from '@actions/components.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {LoadStatuses} from '@types/load.types';

const initialState = {
  courseCatalog: {
    state: {
      activeView: 'catalog',
      activeSorting: 'title-asc',
      selectedTopLevelCompetencegroup: null,
    },
    layout: {
      status: LoadStatuses.NOT_LOADED,
      config: {},
    },
  },
  map: {
    id: null,
    animationIndex: -1,
    animationTargetIndex: -1,
    initialCompletedLength: 0,
    isInitialLoad: true,
    skipAnimation: true,
  },
};

const components = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case componentActions.COMPONENTS_SET_MAP_ANIMATION_INDEX: {
    if (action.payload == null || state.map.animationIndex === action.payload) return state;

    const animationIndex = action.payload === '-1'
      ? Math.max(state.map.animationIndex - 1, 0)
      : action.payload === '+1'
        ? state.map.animationIndex + 1
        : action.payload;

    return {
      ...state,
      map: {
        ...state.map,
        animationIndex,
      },
    };
  }
  case componentActions.COMPONENTS_SET_MAP_ANIMATION_TARGET_INDEX: {
    if (action.payload == null || state.map.animationTargetIndex === action.payload) return state;

    const animationTargetIndex = action.payload === '-1'
      ? Math.max(state.map.animationTargetIndex - 1, 0)
      : action.payload === '+1'
        ? state.map.animationTargetIndex + 1
        : action.payload;

    return {
      ...state,
      map: {
        ...state.map,
        animationTargetIndex,
      },
    };
  }
  case componentActions.COMPONENTS_SET_MAP: {
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      map: {
        ...state.map,
        ...action.payload,
      },
    };
  }
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_STATE: {
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        state: {
          ...state.courseCatalog.state,
          ...action.payload,
        },
      },
    };
  }
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT_REQUEST: {
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        layout: {
          ...state.courseCatalog.layout,
          status: LoadStatuses.IS_LOADING,
        },
      },
    };
  }
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_LAYOUT_SUCCESS: {
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        layout: {
          ...state.courseCatalog.layout,
          status: LoadStatuses.LOADED,
          config: action.payload.config,
        },
      },
    };
  }
  case componentActions.COMPONENTS_SET_COURSE_CATALOG_TOPLEVEL_COMPETENCEGROUP: {
    if (!action.payload || typeof action.payload !== 'object') return state;

    return {
      ...state,
      courseCatalog: {
        ...state.courseCatalog,
        state: {
          ...state.courseCatalog.state,
          selectedTopLevelCompetencegroup: action?.payload?.competencegroupId || null,
        },
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default components;
