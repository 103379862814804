import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as manageActions from '@actions/manage.actions';
import * as T from '@types/load.types';

const initialState = {
  roles: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    stats: null,
    error: null,
  },
  competences: {
    status: T.LoadStatuses.NOT_LOADED,
    byId: {},
    allIds: [],
    count: 0,
    error: null,
    hasMore: false,
    filteredCount: null,
    filteredHasMore: null,
  },
  competenceDetails: {byId: {}},
};

const manage = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case manageActions.MANAGE_ROLES_GET_REQUEST: {
    return {
      ...state,
      roles: {
        ...state.roles,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  }
  case manageActions.MANAGE_ROLES_GET_SUCCESS: {
    return {
      ...state,
      roles: {
        ...state.roles,
        data: action.payload.roles,
        stats: {competences: 3},
        status: T.LoadStatuses.LOADED,
        error: null,
      },
    };
  }
  case manageActions.MANAGE_COMPETENCES_GET_REQUEST: {
    return {
      ...state,
      competences: {
        ...state.competences,
        status: action.payload.fetchMore
          ? T.LoadStatuses.IS_LOADING_MORE
          : T.LoadStatuses.IS_LOADING,
      },
    };
  }
  case manageActions.MANAGE_COMPETENCES_GET_SUCCESS: {
    const {
      // allIds = [],
      // byId = {},

      // count,
      // hasMore,

      searchFor,
      courseTypes,

      filteredCount = null,
      filteredHasMore = null,
    } = action.payload;

    const anyFiltersActive = !!(courseTypes?.length || searchFor?.trim()?.length);

    if (anyFiltersActive && !filteredCount) {
      return {
        ...state,
        competences: {
          ...state.competences,
          status: T.LoadStatuses.LOADED,
          error: null,
          filteredCount: 0,
          filteredHasMore: false,
        },
      };
    }

    if (action.payload.fetchMore || filteredCount) {
      const newAllIds = [
        ...state.competences.allIds,
        ...action.payload.allIds?.filter?.(id => !state.competences.allIds.includes(id)) || [],
      ];

      return {
        ...state,
        competences: {
          ...state.competences,
          byId: {
            ...state.competences.byId,
            ...action.payload.byId,
          },
          allIds: newAllIds,
          status: T.LoadStatuses.LOADED,
          error: null,
          hasMore: newAllIds.length < state.competences.count,
          filteredCount,
          filteredHasMore,
        },
      };
    }

    return {
      ...state,
      competences: {
        ...state.competences,
        byId: action.payload.byId,
        allIds: action.payload.allIds,
        count: action.payload.count,
        status: T.LoadStatuses.LOADED,
        error: null,
        hasMore: action.payload.allIds.length < action.payload.count,
        filteredCount: null,
        filteredHasMore: null,
      },
    };
  }
  case manageActions.MANAGE_COMPETENCES_GET_FAILURE: {
    return {
      ...state,
      competences: {
        ...state.competences,
        status: T.LoadStatuses.FAILED,
        error: action.payload.error,
        hasMore: false,
      },
    };
  }
  case manageActions.MANAGE_COMPETENCE_DETAILS_GET_REQUEST: {
    return {
      ...state,
      competenceDetails: {
        ...state.competenceDetails,
        byId: {
          ...state.competenceDetails.byId,
          [action.payload.id]: {
            status: T.LoadStatuses.IS_LOADING,
            error: null,
            data: null,
          },
        },
      },
    };
  }
  case manageActions.MANAGE_COMPETENCE_DETAILS_GET_SUCCESS: {
    return {
      ...state,
      competenceDetails: {
        ...state.competenceDetails,
        byId: {
          ...state.competenceDetails.byId,
          [action.payload.id]: {
            status: T.LoadStatuses.LOADED,
            error: null,
            data: action.payload.data,
          },
        },
      },
    };
  }
  case manageActions.MANAGE_COMPETENCE_DETAILS_GET_FAILURE: {
    return {
      ...state,
      competenceDetails: {
        ...state.competenceDetails,
        byId: {
          ...state.competenceDetails.byId,
          [action.payload.id]: {
            status: T.LoadStatuses.FAILED,
            error: action.payload.error,
            data: null,
          },
        },
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default manage;
