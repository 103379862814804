import {LoadingLine} from '@components/loading/loading-line';
import {loadableRetry} from '@utils/loadable-retry';

const LoadableEmployeesContainer = loadableRetry(() => {
  console.log('load employees.container');

  return import(/* webpackChunkName: "employees.container" */ './containers/employees-container');
}, {
  fallback: <LoadingLine />,
  name: 'employees',
});

export default LoadableEmployeesContainer;
