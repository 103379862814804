import {validateModalId} from '../util/validate';
import {MODALS_PUT_MODAL} from './actions';

export function createReducer(initialState) {
  return function reducer(state = initialState, action) {
    switch (action.type) {
    case MODALS_PUT_MODAL: {
      const {
        modalId = null,
        isOpen = false,
        closeAll = false,
        payload: modalPayload = {},
      } = action.payload || {};

      if (closeAll) return initialState;

      validateModalId(modalId); // This does nothing in prod, but will throw an error in dev

      if (!isOpen) {
        return {
          ...state,
          [modalId]: initialState[modalId],
        };
      }

      // validateModalPayload(modalId, modalPayload); // This does nothing in prod, but will throw an error in dev

      return {
        ...state,
        [modalId]: {
          ...initialState[modalId],
          ...modalPayload,
          isOpen: true,
        },
      };
    }
    default: {
      return state;
    }
    }
  };
}
