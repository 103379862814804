import axios from 'axios';
import {call} from 'redux-saga/effects';
import {backendUrl, backendUrlV2} from '@config';
import {waitForOrgId, waitForProfileId} from '@sagas/app.sagas';
import {retry} from '@utils/sagas.utils';
import {getCompetenceCheckedBy, getCompetenceId, getCompetencePassed} from '../util/competence-identity';

export function* fetchCompetenceAPI({cid}) {
  if (!cid) return null;

  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/competences/${cid}`,
      withCredentials: true,
    }));

    return data
      ? {
        ...data,
        competence_id: getCompetenceId(data),
        passed: getCompetencePassed(data),
        checked_by: getCompetenceCheckedBy(data),
      }
      : null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchChildrenCompetencesAPI({cid, personId, orgId}) {
  if (!cid) return null;

  const _orgId = orgId || (yield call(waitForOrgId, true));
  const _pid = personId || (yield call(waitForProfileId));

  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/persons/get_children_competences_json/${_pid}/${cid}/${_orgId}/0`,
      params: {
        state: 'all',
        limit: 100,
        fields:
        'certificate_url,passed,date,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
      },
      withCredentials: true,
    }));

    return data?.map?.(item => ({
      ...item,
      competence_id: getCompetenceId(item),
      passed: getCompetencePassed(item),
      checked_by: getCompetenceCheckedBy(item),
      parent_competence_id: cid,
    })) || null;
  } catch (error) {
    console.error(error);

    return null;
  }
}
