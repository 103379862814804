import {isObjectWithKeys} from './misc';

const SECONDS = 'SECONDS';
const MINUTES = 'MINUTES';
const HOURS = 'HOURS';
const DAYS = 'DAYS';

export const TimeUnits = {
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
};

export const TimeUnitToMs = {
  [SECONDS]: 1000,
  [MINUTES]: 1000 * 60,
  [HOURS]: 1000 * 60 * 60,
  [DAYS]: 1000 * 60 * 60 * 24,
};

export const isValidTimeunit = unit => !!unit && Object.keys(TimeUnits).includes(unit);

export const getMilliseconds = (num, timeunit) => {
  if (isObjectWithKeys(num)) {
    let total = 0;

    Object.keys(num).forEach(key => {
      const upperCaseKey = key.toUpperCase();

      if (isValidTimeunit(upperCaseKey)) {
        total += num[key] * TimeUnitToMs[upperCaseKey];
      }
    });

    return total;
  }
  if (typeof num === 'number') {
    if (isValidTimeunit(timeunit)) return num * TimeUnitToMs[timeunit];

    return num;
  }

  return 0;
};
