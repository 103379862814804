import React from 'react';
import {useSelector} from 'react-redux';
import {ThemeProvider as EmotionThemeProvider} from '@emotion/react';
import {baseTheme} from '@styles/theme-objects/base';
import './index.scss';

const selectCustomTheme = ({theme: {customTheme}}) => customTheme;

const CustomThemeProvider = ({children}) => {
  const customTheme = useSelector(selectCustomTheme);

  return (
    <EmotionThemeProvider theme={customTheme}>
      {children}
    </EmotionThemeProvider>
  );
};

export const ThemeProvider = ({children}) => (
  <EmotionThemeProvider theme={baseTheme}>
    <CustomThemeProvider>
      {children}
    </CustomThemeProvider>
  </EmotionThemeProvider>
);
