import axios from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {configGetConfigSuccess} from '@actions/config.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {profileFetchPerson} from '@actions/profile.actions';
import {themeSetCSSVars, themeSetCustomTheme, themeSetThemeByKey} from '@actions/theme.actions';
import {backendUrlV2} from '@config';
import {getBrandId} from '@selectors/profile.selectors';
import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import {createCustomTheme} from '@styles/theme-objects/util/create-custom-theme';
import {retry} from '@utils/sagas.utils';
import {defaultThemeKey, getThemeCSSVarsByThemeKey, getThemeKeyByBrandId, getValidThemeKey, styleInjectThemeCSSVars} from '@utils/themes.utils';
import {waitForOrgId} from './app.sagas';
import {waitForConfigObject} from './config.sagas';

const themeObjectMap = {
  red: () => import(/* webpackChunkName: "theme.red" */ '@styles/theme-objects/red'),
  gray: () => import(/* webpackChunkName: "theme.gray" */ '@styles/theme-objects/gray'),
  green: () => import(/* webpackChunkName: "theme.green" */ '@styles/theme-objects/green'),
  orange: () => import(/* webpackChunkName: "theme.orange" */ '@styles/theme-objects/orange'),
  blue: () => import(/* webpackChunkName: "theme.blue" */ '@styles/theme-objects/blue'),
};

function* fetchOrganisationCustomTheme(action) {
  const orgId = action?.payload?.orgId || (yield call(waitForOrgId, true));

  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/organisation/${orgId}/theme`,
      withCredentials: true,
    }));

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function* setOrganisationCustomTheme(action) {
  const theme = action?.payload?.theme;

  if (!isObjectWithKeys(theme)) return;

  const orgId = action?.payload?.orgId || (yield call(waitForOrgId, true));

  try {
    const {data} = yield retry(() => axios.request({
      method: 'POST',
      url: `${backendUrlV2}/organisation/${orgId}/theme`,
      data: theme,
      withCredentials: true,
    }));

    if (data?.success) {
      yield put(themeSetCustomTheme({
        themeKey: 'custom',
        customTheme: theme,
      }));

      yield put(notificationsAdd({
        notification: {
          text: 'Temainnstillinger lagret for organisasjonen',
          color: 'red',
        },
      }));
    } else {
      throw new Error('Failed to set custom theme');
    }
  } catch (error) {
    console.error(error);

    yield put(notificationsAdd({
      notification: {
        text: 'En feil oppstod ved lagring av tema',
        color: 'red',
      },
    }));
  }
}

async function lazyLoadThemeObject(themeKey) {
  if (!themeObjectMap[themeKey]) return {};

  try {
    const themeObject = await themeObjectMap[themeKey]()
      .then(module => module.default);

    return themeObject;
  } catch (error) {
    console.error(error);

    return {};
  }
}

function setLocalStorageThemeKey(themeKey) {
  localStorage.setItem('theme', themeKey);
}

function* injectCSSVarsByThemeKey(themeKey) {
  try {
    const key = getValidThemeKey(themeKey);
    const cssVars = getThemeCSSVarsByThemeKey(themeKey);

    setLocalStorageThemeKey(key);
    yield put(themeSetCSSVars({cssVars}));
    styleInjectThemeCSSVars(cssVars);
  } catch (error) {
    console.error(error);
  }
}

function* setCSSVarsByActiveBrandId() {
  try {
    const brandId = yield select(getBrandId);

    localStorage.setItem('brandId', brandId);

    const cssVars = getThemeKeyByBrandId(brandId);

    yield call(injectCSSVarsByThemeKey, cssVars);
  } catch (error) {
    console.error(error);
  }
}

function* asyncSetThemeFromConfig(action) {
  const themeKeyArg = action.payload?.key
    ? getValidThemeKey(action.payload.key)
    : null;

  // if (themeKeyArg) yield call(injectCSSVarsByThemeKey, themeKeyArg);

  try {
    const configObject = yield call(waitForConfigObject, true);
    const configThemeKey = configObject.getProperty('params.theme');
    const themeKey = themeKeyArg ?? (configThemeKey ? getValidThemeKey(configThemeKey) : defaultThemeKey);

    // if (themeKey === defaultThemeKey) return;

    const configThemeObject = configObject.getProperty('params.custom-theme'); // TODO: change to 'custom-theme' instead of 'params.custom-theme' in config

    const themeObject = yield call(lazyLoadThemeObject, themeKey);

    const customTheme = isObjectWithKeys(configThemeObject)
      ? createCustomTheme(configThemeObject)
      : {};

    yield put(themeSetCustomTheme({
      themeKey,
      customTheme: {
        ...themeObject,
        ...customTheme,
      },
    }));
  } catch (error) {
    console.error('error loading theme', error);
  }
}

const exportObj = [
  takeLatest(`${profileFetchPerson.success}`, setCSSVarsByActiveBrandId),
  takeLatest([
    configGetConfigSuccess().type,
    themeSetThemeByKey().type,
  ], asyncSetThemeFromConfig),
];

export default exportObj;
