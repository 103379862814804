import dayjs from 'dayjs';
import {createSelector} from 'reselect';
import {sortRoles} from '@routes/my-education/utils/utils';
import {i18ndayjs} from '@src/i18n';
import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import {LoadStatuses} from '@types/load.types';
import {eventHasStarted, getImageUrl, verifyPassed} from '@utils/misc.utils';
import {getConfigObject} from './config.selectors';
import {getSelectedOrganisation} from './employees.selectors';
import {_selectRolesData, selectRolesOrganisations} from './roles.selectors';
import {selectUserMeData} from './user.selectors';

const emptyArr = [];
const emptyObj = {};

export const selectProfile = ({profile}) => profile || emptyObj;
export const selectActiveOrgId = ({profile: {activeOrg}}) => activeOrg?.orgId;

export const getProfile = ({profile: {person}}) => person;
export const getRoles = ({profile: {roles}}) => roles;

export const _selectProfileRolesData = ({profile: {person: {data: {roles} = {}} = {}} = {}}) => roles;
export const _selectProfileCompetences = ({profile: {competences}}) => competences;

export const selectProfilePersonRoles = createSelector(
  selectProfile,
  profile => profile?.person?.data?.roles || emptyArr,
);

export const selectProfileRoles = createSelector(
  _selectProfileRolesData,
  _selectRolesData,
  (profileRoles, rolesData) => {
    if (!Array.isArray(rolesData) || !Array.isArray(profileRoles) || !rolesData.length || !profileRoles.length) return emptyArr;

    return rolesData.filter(role => profileRoles
      .some(profileRole => profileRole.id === role.id));
  },
);

export const getSelfSign = ({profile: {selfSign}}) => selfSign;
const _selectSelfSignData = ({profile: {selfSign: {data} = {}} = {}}) => data;

export const selectCourseEventsById = ({courses: {normalizedData: {courseEvents: {eventById}}}}) => eventById;
export const _selectProfileEvents = ({profile: {events}}) => events;
export const selectProfileEvents = createSelector(
  _selectProfileEvents,
  selectCourseEventsById,
  (profileEvents, eventById) => {
    if (!profileEvents?.data?.length || !isObjectWithKeys(eventById)) return profileEvents;

    const data = profileEvents.data.map(event => {
      const {id} = event;
      const eventObject = eventById[id];

      if (!eventObject) return event;

      return {
        ...event,
        files: event.files || eventObject.files,
        userFiles: event.userFiles || eventObject.userFiles,
        fileUploadEnabled: event.user_file_upload_allowed ?? event.fileUploadEnabled ?? eventObject.user_file_upload_allowed ?? eventObject.fileUploadEnabled,
        fileUploadDeadline: event.user_file_upload_date_limit ?? event.fileUploadDeadline ?? eventObject.user_file_upload_date_limit ?? eventObject.fileUploadDeadline,
      };
    });

    return {
      ...profileEvents,
      data,
    };
  },
);

export const selectSelfSignIds = createSelector(
  _selectSelfSignData,
  selfSignData => selfSignData?.map(ss => ss.course_id) || emptyArr,
);

export const selectIsKioskMode = createSelector(
  getConfigObject,
  configObject => Number(configObject?.getProperty?.('params.kiosk') || 0) > 0,
);

export const selectKioskModeStartUrl = createSelector(
  selectIsKioskMode,
  getConfigObject,
  (isKioskMode, configObject) => isKioskMode
    ? configObject?.getProperty?.('params.start-route')
    : null,
);

export const selectIsMyPageDisabled = createSelector(
  selectIsKioskMode,
  selectProfilePersonRoles,
  (isKioskMode, personRoles) => isKioskMode && personRoles?.length === 1,
);

export const getExpiring = ({profile: {expiring}}) => expiring;
export const getShowRoles = ({profile: {show_role}}) => show_role;

export const getProfileCompetenceLevel = ({profile: {person: {competencelevel}}}) => competencelevel;

export const getIsFetchingProfile = createSelector(
  getProfile,
  ({status}) => status === LoadStatuses.IS_LOADING,
);

const _selectProfileIsManager = ({profile: {person: {storeManager}}}) => storeManager;
const _selectUserGroupNames = ({user: {data: {group_names}}}) => group_names;

export const selectIsManager = createSelector(
  _selectProfileIsManager,
  _selectUserGroupNames,
  (isManager, groupNames) => isManager || groupNames?.includes?.('Manager') || groupNames?.includes?.('Superuser'),
);

export const getSpecialroles = ({profile: {specialroles: {data}}}) => data;
export const getIsSuperuser = ({profile: {specialroles: {superuser}}}) => superuser;
export const getIsDriftsjef = ({profile: {specialroles: {driftsjef}}}) => driftsjef;

export const _getPassed = ({profile: {passed}}) => passed;

export const getPassed = createSelector(
  _getPassed,
  passed => {
    const {
      allIds = [],
      byId = {},
    } = passed?.data?.reduce?.((acc, item) => {
      acc.byId[item.competence_id] = item;
      acc.allIds.push(item.competence_id);

      return acc;
    }, {
      allIds: [],
      byId: {},
    }) || {};

    return {
      ...passed,
      allIds,
      byId,
    };
  },
);

export const getSection = ({profile: {section}}) => section;
export const getTab = ({profile: {tab}}) => tab;
export const getCompetences = ({profile: {competences}}) => competences.data;
export const shouldUpdate = ({profile: {updated}}) => updated === null;

export const getProfileData = ({profile: {person: {data}}}) => data;

export const getPendingChecklists = ({profile: {pendingChecklists}}) => pendingChecklists;

export const selectProfileId = createSelector(
  getProfileData,
  selectUserMeData,
  (profileData, userMeData) => {
    const bartId = localStorage.getItem('bart');

    if (bartId) return Number(bartId);

    return profileData?.id || userMeData?.person_id;
  },
);

export const selectProfileUserName = createSelector(
  getProfileData,
  selectUserMeData,
  (profileData, userMeData) => {
    if (localStorage.getItem('bart')) return profileData?.user_name;

    return profileData?.user_name || userMeData?.user_name;
  },
);

export const selectProfileFullname = createSelector(
  getProfileData,
  data => data?.fullname,
);

export const selectProfileRolesSummary = ({profile: {summary}}) => summary;

const _selectSummaryRequirementData = ({profile: {summary = {}} = {}}) => summary?.data?.requirement;

export const selectMyPageRoles = createSelector(
  _selectSummaryRequirementData,
  rolesData => {
    if (!rolesData) return {status: LoadStatuses.IS_LOADING};

    if (!rolesData?.length) return {
      status: LoadStatuses.LOADED,
      complete: [],
      hidden: [],
      inProgress: [],
    };

    const {roles, hidden, complete} = sortRoles(rolesData);

    return {
      inProgress: roles,
      hidden,
      complete,
      status: LoadStatuses.LOADED,
    };
  },
);

export const isOnboardingRole = role => role?.roletype_name === 'Onboarding';
export const isMandatoryRequirement = competence => competence?.requirement_type === 'Mandatory';

const graceKeys = [-7, 7, 30, 90, 120]; // 0 is also an option, but maps to 120 days

const getNearestGraceKeyDown = days => {
  if (!days) return 120;

  const nearestKey = graceKeys.reduce((acc, key) => {
    if (days >= key) return key;

    return acc;
  }, -7);

  return nearestKey;
};

export const selectOnboardingRole = createSelector(
  getShowRoles,
  getPassed,
  // getSelfSign,
  (role, passedCompetences /* pendingSelfSign*/) => {
    const {status, data: roleData} = role || {};

    // const {data: passedData = []} = passedCompetences || {};
    // const {byId: selfSignData = {}} = pendingSelfSign || {};

    if (!roleData?.id || !isOnboardingRole(roleData)) return {
      role: {},
      stages: [],
      nonEmptyStages: [],
      competenceById: {},
      isEmpty: true,
      status: status || LoadStatuses.NOT_LOADED,
    };

    const passedDataById = passedCompetences?.data?.reduce?.((acc, item) => {
      const {id: phceId, ...rest} = item || {};

      acc[item.competence_id] = {
        ...rest,
        phceId,
      };

      return acc;
    }, {});

    const {
      id,
      title,
      description,
      startdate,
      files,
      requirements = [],
      optional_competences = [],
      required_competences = [],
    } = roleData || {};

    const competenceSortKeys = [...required_competences, ...optional_competences]
      .reduce((acc, {sortkey, lock_period, id}) => {
        acc[id] = sortkey + (lock_period ? 1000 : 0);

        return acc;
      }, {});

    const lockableCompetenceIds = new Set(required_competences
      .filter(({lock_period}) => !!lock_period)
      .map(({id}) => id));

    const competenceById = {};

    const stages = graceKeys.reduce((acc, key) => {
      acc[key] = {
        dateEnd: dayjs(startdate).add(key, 'day')
          .format('YYYY-MM-DD'),
        competences: {
          mandatory: [],
          optional: [],
          allIds: [],
        },
        completedCompetences: {
          mandatory: [],
          optional: [],
          allIds: [],
        },
        allCompleted: false,
        key,
      };

      return acc;
    }, {});

    let hasNoDefinedPeriods = true;

    requirements.forEach((req, idx) => {
      const {
        competence = {},
        grace_period,
      } = req || {};

      if (grace_period) hasNoDefinedPeriods = false;

      const {
        competence_id,
        competence_type,
      } = competence || {};

      if (!competence_id) return;

      const graceKey = getNearestGraceKeyDown(grace_period);

      stages[graceKey].competences.allIds.push(competence_id);
      const isMandatory = isMandatoryRequirement(req);

      if (isMandatory) {
        stages[graceKey].competences.mandatory.push(competence_id);
      } else {
        stages[graceKey].competences.optional.push(competence_id);
      }

      competenceById[competence_id] = {
        ...passedDataById[competence_id],
        ...req,
        ...competence,
        type: competence_type?.competence_type,
        key: graceKey,
        isMandatory,
        isLockable: isMandatory && lockableCompetenceIds.has(competence_id),
      };

      const isPassed = verifyPassed(competenceById[competence_id]);

      competenceById[competence_id].isPassed = isPassed;

      if (isPassed) {
        stages[graceKey].completedCompetences.allIds.push(competence_id);

        if (isMandatory) {
          stages[graceKey].completedCompetences.mandatory.push(competence_id);
        } else {
          stages[graceKey].completedCompetences.optional.push(competence_id);
        }
      }
    });

    const nonEmptyStageIds = [];
    const sortedIds = {};

    const stagesWithInfo = Object.values(stages)
      .filter(stage => !!stage?.competences?.allIds?.length)
      .sort((a, b) => Number(a.key) - Number(b.key))
      .map((stage, idx, arr) => ({
        ...stage,
        prevStage: idx > 0 ? arr[idx - 1] : null,
      }))
      .reduce((acc, stage, idx) => {
        if (!stage?.competences?.allIds) return acc;

        const {
          competences: {
            allIds,
            mandatory: mandatoryCompetenceIds,
          },
          completedCompetences: {
            allIds: completedIds,
            mandatory: completedMandatoryCompetenceIds,
          },
          key,
          prevStage,
        } = stage;

        const competencesLength = allIds.length;
        const isNotEmpty = competencesLength > 0;

        if (isNotEmpty) nonEmptyStageIds.push(key);

        const passedLength = completedIds.length;
        const allCompleted = competencesLength === passedLength;
        const allMandatoryCompleted = mandatoryCompetenceIds.length === completedMandatoryCompetenceIds.length;

        sortedIds[key] = {
          mandatory: {
            completed: [],
            incomplete: [],
            all: [],
          },
          optional: {
            completed: [],
            incomplete: [],
            all: [],
          },
          all: {
            completed: [],
            incomplete: [],
            all: [],
          },
        };

        const mandatorySorted = mandatoryCompetenceIds
          .sort((a, b) => {
            const aKey = competenceSortKeys[a];
            const bKey = competenceSortKeys[b];

            return aKey - bKey;
          });

        const stageLockIndex = allMandatoryCompleted
          ? -1
          : mandatorySorted.findIndex(id => lockableCompetenceIds.has(id) && !completedMandatoryCompetenceIds.includes(id));

        allIds.sort((a, b) => {
          const aKey = competenceSortKeys[a];
          const bKey = competenceSortKeys[b];

          return aKey - bKey;
        }).forEach((id, idx) => {
          const competence = competenceById[id];
          const {
            isMandatory,
            isLockable,
            isPassed: competencePassed,
          } = competence;

          const isLocked = stageLockIndex !== -1 && isMandatory && isLockable && mandatorySorted.indexOf(id) > Math.max(stageLockIndex, 0);

          const isPassed = !isLocked && competencePassed;
          const status = isLocked
            ? 'LOCKED'
            : isPassed
              ? 'PASSED'
              : 'OPEN';

          let openedAfterId = null;

          if (isMandatory) {
            if (isLocked && idx > 0) {
              openedAfterId = sortedIds[key].mandatory.incomplete.slice(-1)?.[0];
            }
            sortedIds[key].mandatory.all.push(id);
            if (isPassed) {
              sortedIds[key].mandatory.completed.push(id);
            } else {
              sortedIds[key].mandatory.incomplete.push(id);
            }
          } else {
            sortedIds[key].optional.all.push(id);
            if (isPassed) {
              sortedIds[key].optional.completed.push(id);
            } else {
              sortedIds[key].optional.incomplete.push(id);
            }
          }
          sortedIds[key].all.all.push(id);
          if (isPassed) {
            sortedIds[key].all.completed.push(id);
          } else {
            sortedIds[key].all.incomplete.push(id);
          }

          competenceById[id].isPassed = isPassed;
          competenceById[id].isLocked = isLocked;
          competenceById[id].isOpened = !isLocked;
          competenceById[id].status = status;
          competenceById[id].openedAfterId = openedAfterId;
        });

        const prevStageDateEnd = prevStage?.dateEnd;
        const openedDate = prevStageDateEnd || null;
        const today = dayjs();

        acc[key] = stage;
        acc[key].allCompleted = allCompleted;
        acc[key].allMandatoryCompleted = allMandatoryCompleted;
        acc[key].dateOpen = i18ndayjs(openedDate).format('DD. MMM YYYY');
        acc[key].isOpen = openedDate
          ? dayjs(openedDate).isSame(today, 'day') || dayjs(openedDate).isBefore(today, 'day')
          : true;

        return acc;
      }, {});

    const cover = files?.find(file => file.title === 'cover');
    const header = files?.find(file => file.title === 'header');

    const nonEmptyStages = nonEmptyStageIds.map(key => stagesWithInfo[key]);

    return {
      role: {
        description,
        id,
        title,
        startdate,
        coverImage: cover?.url ? getImageUrl(cover.url) : null,
        headerImage: header?.url ? getImageUrl(header.url) : null,
      },
      competenceById,
      stages: stagesWithInfo,
      nonEmptyStages,
      firstIncompleteStage: nonEmptyStages.find(({isOpen, allMandatoryCompleted}) => isOpen && !allMandatoryCompleted),
      hasOnlyOneStage: nonEmptyStages.length === 1,
      status: LoadStatuses.LOADED,
      sortedIds,
      hasNoDefinedPeriods,
    };
  },
);

const selectRolesData = createSelector(
  selectProfileRolesSummary,
  summary => summary?.data?.requirement || emptyArr,
);

export const selectOnboardingData = createSelector(
  selectRolesData,
  rolesData => rolesData?.find(req => req.type === 'onboarding'),
);

export const selectOnboardingDataNotCompleted = createSelector(
  selectOnboardingData,
  onboardingData => onboardingData?.missing_count > 0 ? onboardingData : null,
);

export const getIsFullPersonFetched = createSelector(
  getProfile,
  person => !!person.data,
);

export const getPersonOrganisations = createSelector(
  getProfile,
  person => person?.data?.organisations || emptyArr,
);

export const getMainOrganisations = createSelector(
  getProfile,
  selectActiveOrgId,
  (person, activeOrgId) => {
    if (person.data) {
      const orgId = activeOrgId || localStorage.getItem('orgId');

      if (orgId && person?.organisations && Array.isArray(person.organisations)) {
        const orgMatch = person.organisations.find(o => o.id === Number.parseInt(orgId, 0));

        if (orgMatch) {
          return {
            title: orgMatch.title,
            brand_id: person?.data?.organisations?.[0]?.brand_id,
            id: orgMatch.id,
          };
        }
      }

      return person?.data?.organisations?.[0];
    }

    return null;
  },
);

export const getOrganisation = createSelector(
  getPersonOrganisations,
  selectActiveOrgId,
  getSelectedOrganisation,
  (organisations, activeOrgId, selectedOrg) => {
    if (!organisations) return null;

    let orgId = activeOrgId || localStorage.getItem('orgId');

    if (!orgId) return organisations?.[0];
    if (typeof orgId === 'string') {
      orgId = Number.parseInt(orgId, 0);
    }
    const orgMatch = organisations.find(o => o.id === orgId);

    if (orgMatch) return orgMatch;
    if (selectedOrg?.data?.organisation_id === orgId) {
      return {
        ...selectedOrg.data,
        id: orgId,
      };
    }

    return organisations?.[0] || null;
  },
);

export const getOrganisationId = createSelector(
  selectIsManager,
  getMainOrganisations,
  selectActiveOrgId,
  (manager, mainOrg, activeOrgId) => {
    if (activeOrgId) {
      return activeOrgId;
    }

    if (mainOrg) {
      return mainOrg.organisation_id;
    }

    return mainOrg;
  },
);

export const getBrandId = createSelector(
  getOrganisation,
  getSelectedOrganisation,
  getMainOrganisations,
  (org, selectedOrg, mainOrg) => org?.brand_id || selectedOrg?.brand_id || mainOrg?.brand_id,
);

export const getSelectedRoleId = createSelector(
  selectProfile,
  ({show_role}) => {
    if (show_role?.data) {
      return show_role.data.id;
    }

    return null;
  },
);

export const getHelptexts = ({profile: {helptexts}}) => helptexts;

export const getProfileOrganisations = ({profile: {person: {data: {organisations}}}}) => organisations;

export const getPassedData = createSelector(
  getPassed,
  passed => passed?.data || emptyArr,
);

export const getPassedIds = createSelector(
  getPassedData,
  passed => {
    if (!passed?.length) {
      return emptyArr;
    }

    const ids = [];

    for (const element of passed) {
      if (element.passed === 100) {
        if (element.valid_until && dayjs(element.valid_until).isBefore(dayjs())) {
          ids.push(element.competence_id);
        } else {
          ids.push(element.competence_id);
        }
      }
    }

    return ids;
  },
);

export const getPassedIdsNew = createSelector(
  getPassedData,
  passed => {
    if (!passed?.length) return emptyArr;

    const ids = [];

    for (const competence of passed) {
      if (competence.passed === 100) {
        ids.push(competence.competence_id);
      }
    }

    return ids;
  },
);

export const getPassedCompetencesIds = competences => {
  if (!competences.data?.length) {
    return emptyArr;
  }

  const ids = [];

  for (let i = 0; i < competences.data.length; i += 1) {
    if (competences.data[i].passed === 100) {
      ids.push(competences.data[i].competence_id);
    }
  }

  return ids;
};

const getPassedCompetencesCheck = competences => {
  if (!Array.isArray(competences?.data) || !competences?.data?.length) return emptyArr;

  return competences.data.filter(competence => competence.passed === 100);
};

export const getMessageUnreadCount = ({messages: {unread: {cnt}}}) => cnt;

export const getPassedCompetences = createSelector(
  _selectProfileCompetences,
  competences => ({
    passed: getPassedCompetencesCheck(competences),
    keys: getPassedCompetencesIds(competences),
  }),
);

export const getPassedCompetencesGroupedByType = ({profile: {passed_full}}) => {
  if (!passed_full.data) {
    return passed_full;
  }

  return {
    ...passed_full,
    data: Object.entries(passed_full.data.reduce((groupedCompetences, personcompetence) => {
      const {competence_type: competenceType} = personcompetence;

      return {
        ...groupedCompetences,
        [competenceType.id]: {
          ...competenceType,
          personcompetences: [
            ...groupedCompetences[competenceType.id]
              ? groupedCompetences[competenceType.id].personcompetences
              : [],
            personcompetence,
          ],
        },
      };
    }, {})).map(([, competenceTypeGroup]) => competenceTypeGroup),
  };
};

export const getRecommendedCompetences = ({profile: {recommendedCompetences}}) => recommendedCompetences;

export const getCVs = ({profile: {cvs}}) => cvs;
export const getCVsFull = ({profile: {cvs_full}}) => cvs_full;

export const getReport = ({profile: {report}}) => report;

export const getNormalizedProfileEvents = ({profile: {normalizedData: {events}}}) => events;

export const getProfileEventsLoadStatus = ({profile: {normalizedData: {events: {status}}}}) => status;

export const selectProfileOrganisations = createSelector(
  getProfile,
  selectRolesOrganisations,
  (profile, organisations) => {
    const orgsData = organisations?.data;
    const profileOrgs = profile?.data?.organisations;

    if (!Array.isArray(orgsData) || !Array.isArray(profileOrgs)) return [];

    return orgsData.filter(organisation => profileOrgs
      .some(profileOrg => profileOrg.id === organisation.id));
  },
);

export const selectProfileCompetences = createSelector(
  _selectProfileCompetences,
  competences => {
    const {
      allIds = [],
      byId = {},
    } = competences?.data?.reduce?.((acc, item) => {
      acc.byId[item.id] = item;
      acc.allIds.push(item.id);

      return acc;
    }, {
      allIds: [],
      byId: {},
    }) || {};

    return {
      ...competences,
      byId,
      allIds,
    };
  },
);

export const selectUpcomingProfileEvents = createSelector(
  selectProfileEvents,
  selectIsManager,
  (events, normalizedEvents, isManager) => {
    if (isManager || !events?.data?.length) return events;

    const filtered = events?.data?.length
      ? events.data.filter(event => !eventHasStarted(event))
      : [];

    return {
      ...events,
      data: filtered,
    };
  },
);

export const selectIsProfileTasksEmpty = createSelector(
  selectUpcomingProfileEvents,
  getExpiring,
  getSelfSign,
  (events, expiring, selfSign) => !events?.data?.length && !expiring?.data?.length && !selfSign?.data?.length,
);
