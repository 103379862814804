import React, {useCallback, useState} from 'react';
import {EyeSlash, File as FileIcon, MinusCircle} from 'phosphor-react';
import {useDispatch} from 'react-redux';
import {coursesDeleteFileForPerson} from '@actions/courses.actions';
import {Action, ActionName} from '@components/course/course-details/course-event-partipicant-list/course-event-partipicant-list-styles';
import {ActionsWrapper, Download, File, FileinfoWrapper, Read, Wrapper} from '@components/files-list/files-list-styles';
import Modal from '@components/modal/modal';
import {css, useTheme} from '@emotion/react';
import {i18n} from '@src/i18n';
import {isStringWithLength} from '@utils/string.utils';

const skipRenderByTitle = new Set(['cover', 'track', 'map']);
const getShouldRender = title => !skipRenderByTitle.has(title);

const ConfirmTitle = () => <Modal.Title>{i18n('course.confirm-delete-file-for-person')}</Modal.Title>;

const getFileTitle = (title, filenameMaxLength) => {
  if (!isStringWithLength(title)) return '';

  if (!filenameMaxLength || title.length <= filenameMaxLength) return title;

  const [namePart = '', extentionPart = ''] = title.split('.');

  const nameSlice = namePart.slice(0, filenameMaxLength - (extentionPart?.length || 0));

  return `${nameSlice}...${extentionPart || ''}`;
};

/**
 * Displays files in a list.
 * actions: list of either "download","delete" or both
 */
export const FilesList = ({
  className,
  files,
  personId,
  onClickFile,
  fileLineStyle,
  wrapperStyle,
  actions = ['download'],
  filenameMaxLength,
  onDelete,
  onDownload,
  deleteText = 'Delete',
  downloadText = 'Download',
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [confirmedFileToDelete, setConfirmedFileToDelete] = useState(null);

  const handleDeleteFileForPerson = useCallback((fileId, personId) => {
    dispatch(coursesDeleteFileForPerson({
      fileId,
      personId,
      onSuccess: () => {
        setConfirmedFileToDelete(null);
      },
    }));
  }, [dispatch]);

  return (
    <Wrapper
      css={wrapperStyle || ''}
      className={className || ''}
    >
      {confirmedFileToDelete && (
        <Modal
          wrapperStyle={css`margin-top: 1.6em;`}
          variant="warning"
          onClose={() => setConfirmedFileToDelete(null)}
          onConfirm={() => {
            if (onDelete) {
              onDelete(confirmedFileToDelete);
              setConfirmedFileToDelete(null);
            } else {
              handleDeleteFileForPerson(confirmedFileToDelete?.id, personId);
            }
          }}
          title={ConfirmTitle}
          submitButtonText={i18n('course.delete-file')}
        >
          {i18n('course.delete-file-for-person-question')}
        </Modal>
      )}

      {files.map(file => {
        const {title, url, id, state, path} = file;
        const shouldRender = getShouldRender(title);

        if (!shouldRender) return null;

        const renderedTitle = getFileTitle(title || path, filenameMaxLength);

        return (
          <File
            key={url}
            css={fileLineStyle || ''}
            onClick={() => {
              if (onClickFile) {
                onClickFile({
                  title,
                  url,
                  state,
                });
              }
            }}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            read={state === 'read'}
          >
            {state === 'unread' && (
              <EyeSlash
                weight="fill"
                color="#0B5CD7"
                size="16"
                css={css`margin-right: 0.8rem;`}
              />
            )}
            <FileinfoWrapper title="Åpne/last ned fil">
              {(!state || state === 'default') && (
                <FileIcon
                  weight="fill"
                  color="#B9B9B9"
                  size="17"
                  css={css`margin-right: 0.8rem;`}
                />
              )}
              {renderedTitle}
              {' '}
              {state === 'read' && (
                <Read>
                  <span className="separator">-</span>
                  {i18n('globals.has-read')}
                </Read>
              )}
            </FileinfoWrapper>
            <ActionsWrapper>
              {actions.includes('download') && (
                <Download>
                  {i18n('globals.download')}
                </Download>
              )}
              {actions.includes('delete') && (
                <Action
                  css={css`margin-left: auto;`}
                  role="button"
                  tabIndex="0"
                  theme={theme}
                  warnStyleOnHover
                  onClick={e => {
                    e.preventDefault(); // prevent parent link from triggering download
                    setConfirmedFileToDelete(file);
                  }}
                >
                  <MinusCircle
                    weight="fill"
                    aria-hidden="true"
                  />
                  <ActionName css={css`font-size: 12.5px;`}>
                    {i18n('person.add-competences.delete-file')}
                  </ActionName>
                </Action>
              )}
            </ActionsWrapper>
          </File>
        );
      })}
    </Wrapper>
  );
};
