import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import {
  call,
  delay,
  fork,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {appInjectReduxModule} from '@actions/app.actions';
import {
  AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN,
  authBartUser,
  authCheckIfLoggedIn,
  authGetAuthStatusAndStartLoading,
  authGotoNext,
  authGotoOn,
  authLoginFailure,
  authLoginNewPasswordFailure,
  authLoginNewPasswordRequest,
  authLoginNewPasswordSuccess,
  authLoginRequest,
  authLoginSuccess,
  authLogoutRequest,
  authLogoutSuccess,
  authStartLoginCheckOnInterval,
  authTransferUser,
  authTransferUserSuccess,
  authUnauthorized,
  authUserIsConfirmedLoggedIn,
  authUserIsConfirmedNotLoggedIn,
  authVerifyUser,
  authVerifyUserFailure,
  authVerifyUserSuccess,
} from '@actions/auth.actions';
import {
  employeesFetchOrganisation,
  employeesGetWorklist,
} from '@actions/employees.actions';
import {RESET_STORE} from '@actions/global.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {profileSetManager} from '@actions/profile.actions';
import {userSetPartial, userSuccess} from '@actions/user.actions';
import {
  backendUrl,
  backendUrlFull,
  backendUrlV2,
  nanoLearningLoginUrl,
} from '@config';
import {fetchConfigObject, waitForConfigObject} from '@sagas/config.sagas';
import {selectIsKioskMode} from '@selectors/profile.selectors';
import * as Sentry from '@sentry/react';
import {i18n} from '@src/i18n';
import {retry} from '@utils/sagas.utils';
import {isStringWithLength} from '@utils/string.utils';
import {getAuthStatus} from '../selectors/auth.selectors';
import {waitForOrgId} from './app.sagas';
import {fetchFullPerson, updateActiveOrgIdSaga} from './profile.sagas';
import {fetchRoleSaga} from './roles.sagas';

export const AdminNames = new Set([
  'employeemanager',
  'admin',
  'manager',
  'superadmin',
  'superuser',
]);

const watchConfirmedNotLoggedIn = takeLatest([
  AUTH_LOGIN_FAILURE,
  AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN,
  // AUTH_CHECK_LOGIN_METHOD_FAILURE,
], function* userNotLoggedInSaga(action) {
  console.log('not logged in');

  if (action.type === AUTH_CHECK_LOGIN_METHOD_SUCCESS && action.payload?.message !== 'no-user') {
    return;
  }

  if (action.type === AUTH_LOGIN_FAILURE) {
    console.log('login failure');

    return;
  }

  localStorage.removeItem('track');
  localStorage.removeItem('config');

  const bartId = localStorage.getItem('bart');

  if (bartId) {
    localStorage.removeItem('learningportalConfig');
    localStorage.removeItem('config-pid');
    localStorage.removeItem('bart');
  }

  localStorage.removeItem('orgId');
  // localStorage.removeItem('learningportalConfig');
  sessionStorage.removeItem('working-pids');
  Cookies.remove('tg-visit');
  Sentry.setUser(null);
  yield put({type: RESET_STORE});
});

function* gotoNext(action) {
  yield put(authGotoOn());
  window.location.reload();
}

function* watchInjectedReduxModule(action) {
  const moduleName = action?.payload;

  if (moduleName === 'employees') {
    const orgId = yield call(waitForOrgId, true);

    if (orgId != null) yield put(employeesFetchOrganisation({orgId}));
    try {
      yield put(employeesGetWorklist({pids: JSON.parse(sessionStorage.getItem('working-pids'))}));
    } catch (error) {
      console.log(error);
    }
  }
}

function* watchConfirmedLogin(action) {
  const {authProfile, start_url} = action?.payload || {};

  try {
    if (authProfile?.user_name) {
      yield put(userSetPartial({username: authProfile.user_name}));
      yield spawn(setCurrentUser);
    } else {
      throw new Error('no user_name');
    }

    yield call(fetchConfigObject);

    const configObject = yield call(waitForConfigObject, true);

    const isKioskMode = yield select(selectIsKioskMode);
    const configStartUrl = configObject?.getProperty?.('params.start-route') || '';

    const startUrl = start_url || configStartUrl || '/my-education';

    yield put(authLoginSuccess({
      authProfile,
      start_url: startUrl,
    }));

    yield fork(fetchFullPerson, {payload: {userName: authProfile.user_name}});

    const shouldFetchKioskRole = isKioskMode && configStartUrl;

    if (shouldFetchKioskRole) {
    // if kiosk mode is enabled, we fetch the role from the start url
      const roleId = configStartUrl.match(/\/role\/(\d+)$/)?.[1];

      if (roleId != null) {
        yield call(fetchRoleSaga, {
          payload: {
            roleId: Number(roleId),
            userName: authProfile.user_name,
          },
        });
      }
    }
  } catch (error) {
    console.error(error);
    yield put(authUserIsConfirmedNotLoggedIn());
  }
}

const watchSetUserSuccess = takeLatest(
  `${userSuccess().type}`,
  function* onSuccess(action) {
    const {payload: userMeData} = action;

    try {
      if (!localStorage.getItem('bart')) {
        if (userMeData.orgs?.length === 1) yield spawn(updateActiveOrgIdSaga, {payload: userMeData.orgs[0]});
        if (userMeData.group_names?.length && userMeData.group_names.some(name => isStringWithLength(name) && AdminNames.has(name.toLowerCase()))) {
          yield put(profileSetManager());
        }
      }
      Sentry.setUser(userMeData);
    } catch (error) {
      Sentry.setUser(null);
      console.error(error);
    }
  },
);

function* fetchUserMe() {
  try {
    const {data} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/user/me`,
      withCredentials: true,
    }));

    if (!data?.person_id) throw new Error('Invalid user');

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function* setCurrentUser() {
  try {
    const user = yield call(fetchUserMe);

    if (!user?.person_id) throw new Error('Invalid user');
    yield put(userSuccess(user));
  } catch (error) {
    yield put(authUserIsConfirmedNotLoggedIn({error}));
  }
}

function* login(action) {
  const {username, password} = action.payload;

  try {
    const {login: loginData, valid} = yield retry(() => axios.request({
      method: 'POST',
      url: `${backendUrl}/api/login`,
      data: qs.stringify({
        password,
        user_name: username,
        login: 1,
        full: 1,
      }),
      withCredentials: true,
    })
      .then(({data}) => data));

    if (!valid) {
      Cookies.remove('tg-visit');
      // yield put({type: 'RESET_STORE'});
      throw new Error('Invalid user');
    }

    const {session_id, start_url} = loginData;

    Cookies.set('tg-visit', session_id);
    Cookies.set('identity_login_attempted', 0);

    const redirectUrl = new URLSearchParams(window.location.search).get('redirect') || start_url;

    yield put(authUserIsConfirmedLoggedIn({
      authProfile: loginData,
      start_url: redirectUrl,
    }));
  } catch (error) {
    console.error(error);
    yield put(authLoginFailure({error}));
  }
}

function* checkLogin(action) {
  try {
    const {username} = action.payload;
    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          user_name: username,
        },
        url: `${backendUrl}/api/verify_user`,
        withCredentials: true,
      }));

    // since this endpoint returns a cookie, we remove it since the cookie is invalid before logon
    Cookies.remove('tg-visit');

    if (data.valid) {
      if (data.auth_url) {
        window.location = data.auth_url;
      }
      yield put(authVerifyUserSuccess({message: 'valid'}));
    } else {
      // yield put({type: 'RESET_STORE'});
      yield put(authVerifyUserSuccess({message: 'no-user'}));
    }
  } catch (error) {
    console.error(error);
    yield put(authVerifyUserFailure({}));
  }
}

function* newPassword(action) {
  try {
    const {username} = action.payload;
    const {data} = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          email_or_user_name: username,
        },
        url: `${backendUrl}/sendreminder`,
        withCredentials: true,
      }));

    if (data.statuscode === 0) {
      yield put(authLoginNewPasswordSuccess({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-success'),
          color: 'green',
        },
      }));
    } else {
      yield put(authLoginNewPasswordFailure({message: data.status}));
      yield put(notificationsAdd({
        notification: {
          text: i18n('login.send-new-password-failure'),
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(authLoginNewPasswordFailure({}));
  }
}

function* logout() {
  try {
    localStorage.removeItem('track');
    localStorage.removeItem('config');
    localStorage.removeItem('bart');
    localStorage.removeItem('orgId');
    // localStorage.removeItem('theme');
    // localStorage.removeItem('learningportalConfig');
    sessionStorage.removeItem('working-pids');

    if (nanoLearningLoginUrl) {
      window.location.replace(backendUrl + '/logout?redirect=/auth/logout');
    } else {
      yield call(() =>
        axios.request({
          method: 'POST',
          url: `${backendUrl}/api/logout`,
          withCredentials: true,
        }));
      Cookies.remove('identity_login_attempted');
      Cookies.remove('tg-visit');
    }

    yield put(authLogoutSuccess());
  } catch (error) {
    console.error(error);
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    yield put(authLogoutSuccess());
  }

  //  yield put({type: 'RESET_STORE'});
}

function* transferUser() {
  const {data} = yield call(() => axios.request({
    method: 'POST',
    url: `${backendUrlFull}/transfer`,
    data: {token: Cookies.get('tg-visit')},
    withCredentials: true,
    credentials: 'same-origin',
  }));

  if (data.valid) {
    yield put(authTransferUserSuccess());
  }

  return true;
}

function* bartUser(action) {
  const {person_id} = action.payload.person.data;

  localStorage.setItem('bart', person_id);
  localStorage.removeItem('orgId');

  yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/persons/impersonate/${person_id}?json=1`,
      withCredentials: true,
    }));

  window.location = '/';

  /* DEBUG!
  const { data } = yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    })
  ); */

  // return data.valid;
  return true;
}

function* checkIfLoggedIn() {
  const {data} = yield retry(() => axios.request({
    method: 'GET',
    url: `${backendUrl}/api/login`,
    withCredentials: false,
  }));

  return data;
}

function* getAuthStatusAndStartLoading() {
  const loginData = yield call(checkIfLoggedIn);
  const isValid = loginData?.valid;

  yield isValid
    ? put(authUserIsConfirmedLoggedIn({authProfile: loginData?.login}))
    : put(authUserIsConfirmedNotLoggedIn());
  // when config is loaded, the rest of the loading will continie
}

function* pollCheckUser(action) {
  const {intervalMs} = action.payload;

  const authStatus = yield select(getAuthStatus);

  if (authStatus.isLoggedIn) {
    const user = yield call(fetchUserMe);

    if (!user) yield put(authUserIsConfirmedNotLoggedIn());
    // const loginData = yield call(checkIfLoggedIn);
    //
    // if (!loginData?.valid) {
    //  yield put(authUserIsConfirmedNotLoggedIn());
    // }
  }

  // try to emulate set interval
  yield delay(intervalMs);
  yield call(pollCheckUser, {payload: {intervalMs}});
}

const exportObj = [
  watchSetUserSuccess,
  watchConfirmedNotLoggedIn,
  takeLatest(authLoginRequest().type, login),
  takeLatest(authLogoutRequest().type, logout),
  takeLatest(authVerifyUser().type, checkLogin),
  takeLatest(authLoginNewPasswordRequest().type, newPassword),
  takeLatest(authGotoNext().type, gotoNext),
  takeEvery(authUnauthorized().type, logout),
  takeEvery(authBartUser().type, bartUser),
  takeLatest(authTransferUser().type, transferUser),
  takeLatest(authCheckIfLoggedIn().type, checkIfLoggedIn),
  takeLatest(
    authGetAuthStatusAndStartLoading().type,
    getAuthStatusAndStartLoading,
  ),
  takeLatest(authStartLoginCheckOnInterval().type, pollCheckUser),
  takeLatest(authUserIsConfirmedLoggedIn().type, watchConfirmedLogin),
  takeLatest(`${appInjectReduxModule.success}`, watchInjectedReduxModule),
];

export default exportObj;
