import React from 'react';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import {env} from '../../../../env';
import {Base, box, Button, ButtonLink, Input, Label, stack} from './styles';

const LoginForm = ({
  stage,
  handleSubmit,
  handleForgotPassword,
  invalidCredentials = false,
  submitting = false,
}) => {
  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(usernameRef.current.value, passwordRef.current.value);
      }}
      css={[
        box,
        stack,
        css`width: 100%;`,
      ]}
    >
      <div css={css`width: 100%; input { font-size: 16px; }`}>
        <Label htmlFor="username-field">{i18n('login.username')}</Label>
        <Base sizeVariant="fullwidth">
          <Input
            id="username-field"
            autoComplete="username"
            name="username"
            type="text"
            placeholder={i18n('login.username-placeholder')}
            isRequired={true}
            ref={usernameRef}
          />
        </Base>

        <Label htmlFor="password-field">{i18n('login.password')}</Label>
        <Base sizeVariant="fullwidth">
          <Input
            id="password-field"
            autoComplete="password"
            placeholder={i18n('login.password-placeholder')}
            sizeVariant="fullwidth"
            isRequired={true}
            name="password"
            type="password"
            ref={passwordRef}
          />
        </Base>

        {invalidCredentials && !submitting && (
          <p
            role="alert"
            data-testid="invalid-credentials"
            css={css`color: #ee0c00;`}
          >
            {i18n('login.invalid-user')}
          </p>
        )}

        <Button
          sizeVariant="fullwidth"
          css={css`margin-top: 0.7em;`}
          type="submit"
          data-testid="submit-login"
        >
          {i18n('login.login')}
        </Button>
        {!env.REACT_APP_LOGIN_DISABLE_CHANGE && (
          <ButtonLink
            type="button"
            sizeVariant="fullwidth"
            onClick={handleForgotPassword}
            data-testid="submit-forgot"
            css={css`
            width: 100%;
            text-align: center;
            margin-top: 1.48em;
            margin-bottom: 3.2em;
            padding-bottom: 0.11em;
          `}
          >
            {i18n('login.forgot')}
          </ButtonLink>
        )}
      </div>
    </form>
  );
};

export default LoginForm;
