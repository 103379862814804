import dayjs from 'dayjs';
import {produce} from 'immer';
import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {faRoute} from '@fortawesome/pro-light-svg-icons/faRoute';
import {faTachometerAltFast} from '@fortawesome/pro-light-svg-icons/faTachometerAltFast';
import {faUserTag} from '@fortawesome/pro-light-svg-icons/faUserTag';
import {i18n} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {getImageUrl} from '@utils/misc.utils';
import {cleanPhoneNumber} from '@utils/string.utils';
import {initialState} from '../initial-state/employees.initial-state';

const emptyArr = [];

export const selectEmployeesRoot = ({employees}) => employees || initialState;

export const selectEmployeesEvents = createSelector(selectEmployeesRoot, ({events}) => events);
export const selectEmployeeModalState = createSelector(selectEmployeesRoot, ({employeeModal}) => employeeModal);

export const selectWorkList = createSelector(
  selectEmployeesRoot,
  ({worklist}) => worklist,
);

export const getIsSaving = createSelector(
  selectEmployeesRoot,
  ({saving}) => saving.isSaving,
);

export const getEmployeesEventsData = createSelector(
  selectEmployeesRoot,
  ({events}) => events?.data,
);

export const getEmployeesExpiring = createSelector(
  selectEmployeesRoot,
  ({expiring}) => expiring,
);

export const getSelectedOrganisation = createSelector(
  selectEmployeesRoot,
  ({organisation}) => organisation,
);

export const getSelectedOrganisationStatus = createSelector(
  selectEmployeesRoot,
  ({organisation: {status}}) => status,
);

export const getTree = createSelector(
  selectEmployeesRoot,
  ({tree}) => tree,
);

export const selectOrganisationProgressByOrgId = createSelector(
  selectEmployeesRoot,
  ({organisationProgress: {byId}}) => byId,
);

export const getNormalizedEmployeesEvents = createSelector(
  selectEmployeesRoot,
  ({normalizedData: {events}}) => events,
);

export const selectEmployeesList = createSelector(
  selectEmployeesRoot,
  ({list}) => list,
);

export const getEmployeesChecklists = createSelector(
  selectEmployeesRoot,
  ({checklists}) => checklists,
);

export const _getSelectedPerson = createSelector(
  selectEmployeesRoot,
  ({selectedPerson}) => selectedPerson,
);

const getSelectedPersonData = createSelector(
  _getSelectedPerson,
  ({data} = {}) => data || {},
);

export const getSelectedPersonEvents = createSelector(getSelectedPersonData, ({events}) => events);

export const getSelectedPersonUsername = createSelector(
  getSelectedPersonData,
  ({user_name}) => user_name,
);

export const getSelectedPersonId = createSelector(
  getSelectedPersonData,
  ({person_id}) => person_id,
);

export const getIsEmployeesLoaded = createSelector(
  selectEmployeesRoot,
  ({list: {status}}) => status === LoadStatuses.LOADED,
);

export const getEmployeesStatistics = createSelector(
  selectEmployeesRoot,
  ({statistics}) => statistics,
);

export const getFilteredEmployees = createSelector(
  selectEmployeesRoot,
  ({filteredList}) => filteredList,
);

export const getReport = createSelector(
  selectEmployeesRoot,
  ({report}) => report,
);

export const getSelectedPersonReport = createSelector(
  _getSelectedPerson,
  ({report}) => report,
);

export const getIsFetchingFunctions = createSelector(
  selectEmployeesRoot,
  ({functions: {isFetching} = {}}) => isFetching,
);

export const selectEmployeesPendingSelfSignById = createSelector(
  getEmployeesChecklists,
  checklists => {
    const {data: tasksData} = checklists || {};

    if (!tasksData?.length) return {};
    const data = tasksData.reduce((acc, task) => {
      if (!task.person_id) return acc;

      if (!acc[task.person_id]) acc[task.person_id] = [];

      acc[task.person_id].push(task);

      return acc;
    }, {});

    return data;
  },
);

export const selectEmployeesEventsStatus = createSelector(
  selectEmployeesEvents,
  events => {
    if (!events) return {status: LoadStatuses.NOT_LOADED};
    const {status, lastFetched, refetchAfter} = events || {};

    return {
      status,
      lastFetched,
      refetchAfter,
    };
  },
);

export const selectEmployeesEventsById = createSelector(
  getEmployeesEventsData,
  events => {
    if (!events?.length) return {};

    const data = events.reduce((acc, event) => {
      if (
        !event?.person?.person_id
        || !event?.startdate && !event.startDate
        || dayjs().isAfter(dayjs(event?.startdate || event?.startDate))
      ) return acc;

      if (!acc[event.person.person_id]) acc[event.person.person_id] = {
        enrolled: [],
        waitlist: [],
      };
      if (event.waitlist) {
        acc[event.person.person_id].waitlist.push(event);
      } else {
        acc[event.person.person_id].enrolled.push(event);
      }

      return acc;
    }, {});

    return data;
  },
);

export const selectEmployeesExpiringComptetencesById = createSelector(
  getEmployeesExpiring,
  expiring => {
    if (!expiring?.data?.length) return {};

    const data = expiring.data.reduce((acc, item) => {
      if (!item?.person?.person_id) return acc;

      if (!acc[item.person.person_id]) acc[item.person.person_id] = [];

      acc[item.person.person_id].push(item);

      return acc;
    }, {});

    return data;
  },
);

export const memoizedSelectEmployeePendingItemsById = memoize(personId =>
  personId
    ? createSelector(
      selectEmployeesEventsById,
      selectEmployeesPendingSelfSignById,
      selectEmployeesExpiringComptetencesById,
      (events, selfSign, expiring) => ({
        eventsEnrolled: events?.[personId]?.enrolled || [],
        eventsWaitlist: events?.[personId]?.waitlist || [],
        expiring: expiring?.[personId] || [],
        selfSign: selfSign?.[personId] || [],
      }),
    )
    : () => ({
      eventsEnrolled: [],
      eventsWaitlist: [],
      expiring: [],
      selfSign: [],
    }));

export const getEmployees = createSelector(
  selectEmployeesList,
  selectEmployeesEventsById,
  selectEmployeesPendingSelfSignById,
  selectEmployeesExpiringComptetencesById,
  (employees, events, checklists, expiring) => {
    const {allIds} = employees;

    if (!allIds?.length) return employees;

    const result = produce(employees, draft => {
      draft.data.forEach(employee => {
        if (!employee.person_id) return;

        if (employee.mobile) employee.mobile = cleanPhoneNumber(employee.mobile);

        const pendingSelfSignLength = (checklists?.[employee.person_id] || []).length;

        employee.pending_checklists = pendingSelfSignLength;
        draft.byId[employee.person_id].pending_checklists = pendingSelfSignLength;
        draft.byId[employee.person_id].eventsEnrolled = events?.[employee.person_id]?.enrolled || [];
        draft.byId[employee.person_id].eventsWaitlist = events?.[employee.person_id]?.waitlist || [];
        draft.byId[employee.person_id].expiringCompetences = expiring?.[employee.person_id] || [];
        draft.byId[employee.person_id].pendingSelfSign = checklists?.[employee.person_id] || [];
      });
    });

    return result;
  },
);

export const getSelectedPerson = createSelector(
  _getSelectedPerson,
  selectedPerson => {
    if (!selectedPerson?.data?.roles?.length) return selectedPerson;

    return {
      ...selectedPerson,
      data: selectedPerson.data && {
        ...selectedPerson.data,
        roles: Array.isArray(selectedPerson.data.roles) && selectedPerson.data.roles.map(role => ({
          ...role,
          taskdone: Array.isArray(role.competences)
            && role.competences.length
            && role.competences.reduce(
              (taskdone, {passed}) => taskdone + (passed === 100 && 1 || 0),
              0,
            )
          || 0,
          tasks: role.competences.length,
          progress: Array.isArray(role.competences)
          && role.competences.length
          && role.competences.reduce(
            (progress, {passed}) => progress + (passed || 0),
            0,
          ) / role.competences.length / 100
          || 0,
          competences: Array.isArray(role.competences)
          && role.competences.map(competence => ({
            id: competence.id,
            passed: competence.passed,
            date: competence.date ? dayjs(competence.date) : null,
            title: competence.competence.title,
            competenceType: competence.competence.competence_type,
          })),
        })),
      },
    };
  },
);

const selectProfileData = ({profile: {person: {data}}}) => data;

// if selected person is not yet fully loaded,
// this selector checks if we can use partial employee data from employees list,
// while awaiting full fetch (e.g. when we open person details from employees list)
export const memoizedSelectedPersonWithFallback = memoize(personId => createSelector(
  selectProfileData,
  getSelectedPersonData,
  getEmployees,
  (profileData, selectedPerson, employees) => {
    const isMe = !!personId && profileData?.person_id === personId;
    const employee = employees.byId[personId] || isMe && !!profileData && {
      ...profileData,
      profile_image: {url: getImageUrl(profileData.profile_image?.url)},
    };

    if (!employee) return selectedPerson;

    const positions = employee.active_personroles?.filter?.(role => role?.role_meta_type_name === 'position') || isMe && !!profileData && profileData.positions || [];

    return {
      ...selectedPerson,
      data: {
        ...employee,
        mobile: cleanPhoneNumber(employee.mobile),
        positions,
        position: positions[0],
        pending_checklists: employee.pending_checklists || 0,
      },
      status: LoadStatuses.LOADED_PARTIAL,
    };
  },
));

export const getStatisticsKindsForView = createSelector(
  getEmployeesStatistics,
  getIsFetchingFunctions,
  (statistics, isFetching) => {
    if (!statistics?.data || isFetching) {
      return emptyArr;
    }

    const stats = [
      {
        id: 'dashboard',
        data: {},
        icon: faTachometerAltFast,
        name: i18n('employees.report-search'),
        gotoName: '/dashboard/search',
      },
    ];

    stats.push({
      id: 'totalt',
      data: statistics.data.progress,
      name: i18n('employees.total'),
      gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.total')}}),
    });

    statistics.data.functions.forEach(item => {
      stats.push({
        id: item.role_id,
        data: item.progress,
        name: item.title,
        gotoName: i18n('globals.goto-x', {functionArgs: {x: item.title}}),
      });
    });

    stats.push(
      {
        id: 'role',
        data: {},
        name: i18n('employees.report-roles'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.report-roles')}}),
        icon: faUserTag,
      },
      {
        id: 'competence',
        data: {},
        name: i18n('employees.report-competences'),
        gotoName: i18n('globals.goto-x', {functionArgs: {x: i18n('employees.report-competences')}}),
        icon: faRoute,
      },
    );

    return stats;
  },
);

const emptyFunctions = {
  position: [],
  role: [],
};

export const getFunctions = createSelector(
  selectEmployeesRoot,
  ({functions}) => functions?.data?.length
    ? functions.data.reduce(
      (acc, cur) => {
        const {rolemetatype} = cur;
        const types = ['position', 'role'];

        types.forEach(type => {
          if (rolemetatype === type) {
            acc[type].push(cur);
          }
        });

        return acc;
      },
      {
        position: [],
        role: [],
      },
    )
    : emptyFunctions,
);
