export const MODALS_PUT_MODAL = 'modals/putModal';

export const modalsPutModal = payload => ({
  type: MODALS_PUT_MODAL,
  payload,
});

export const modalsCloseModal = modalId => ({
  type: MODALS_PUT_MODAL,
  payload: {
    modalId,
    isOpen: false,
  },
});
