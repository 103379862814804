import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {mqMax} from '@facepaint';

export const Wrapper = styled.div`
    background: #FAFBFC;
    border: 1px solid #E1E4E8;
    border-radius: 6px;
    margin-top: 15px;
    padding: 0.4em 1.1em 1.3em 1.1em;
`;

export const  Header = styled.h3`
    font-size: 1.5rem;
    color: black;
    font-weight: 500;
`;
export const  PartipicantsWrapper = styled.div`
    background: #FFFFFF;
    border: 2px solid ${({theme}) => theme.chartPrimaryColor};
    border-radius: 4px;
    padding: 1rem;
`;

export const ParticipantsLabel = styled.div`
    color: #757575;
    font-size: 0.9em;
    margin: 10px 0;
`;

export const CapWidthElipsis = styled.span`
    max-width: ${({maxWidth}) => maxWidth};
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({hoverEffect}) => hoverEffect && css`
        &:hover{
            cursor: pointer;
            text-decoration: underline;
        }
    `}
`;

export const  Partipicant = styled.div`
     display: flex;
    align-items: center;
    margin-bottom: 0.4rem;
`;
export const StatusDot = styled.div``;

export const dotStyle = css`
    margin-right: 0.35rem;
    min-width: 16px;
`;

export const signedUpDotStyle = css`
    ${dotStyle};
    color: #008243;
`;

export const onWaitlistDotStyle = css`
    ${dotStyle};
    color: #EA780E;
`;

export const signOffDotStyle = css`
   ${dotStyle}
    color: #E00000;
`;

export const approveDotStyle = css`
   ${dotStyle}
   color: #008243;
`;

export const grayDotStyle = css`
   ${dotStyle}
   color: #616161;
`;

export const Name = styled.div`
    font-size: 1.05rem;
    display: flex;
    align-items: baseline;
    flex: 1;

    em {
      color: #666;
      font-size: 0.8rem;
      margin-left: 1em;
    }

    ${mqMax.medium}{
        width: 70%;
    }


`;

export const ActionsWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

export const ActionName = styled.div`
    padding: 0.25em 0.5em;
`;

export const Action = styled.div`
    display: flex;
    align-items: center;
    &:hover{
        cursor: pointer;
        ${({warnStyleOnHover, theme}) => warnStyleOnHover && css`
        ${ActionName}{
            color: ${theme.buttons.danger.color};
            background: ${theme.buttons.danger.backgroundColor};
            border-radius: 4px;
            box-shadow: 0px 1px 2px rgb(0 0 0 / 12%);
        }
        `}
    }

    &:not(:last-child){
        margin-right: 0.6rem;
    }
`;
