import dayjs from 'dayjs';
import {produce} from 'immer';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {
  MESSAGE_SET_READ,
  MESSAGE_SET_READ_SUCCESS,
  MESSAGES_CHECK_MESSAGES_REQUEST,
  MESSAGES_CHECK_MESSAGES_SUCCESS,
  MESSAGES_GET_FAILURE,
  MESSAGES_GET_REQUEST,
  MESSAGES_GET_SUCCESS,
} from '@actions/messages.actions';
import * as T from '@types/load.types';

const initialState = {
  all: {
    status: T.LoadStatuses.NOT_LOADED,
    data: [],
    offset: 0,
    limit: 20,
    hasMore: undefined,
    error: null,
  },
  unread: {
    data: [],
    status: T.LoadStatuses.NOT_LOADED,
    notification: 0,
    cnt: 0,
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case MESSAGES_GET_REQUEST: {
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  }
  case MESSAGES_GET_SUCCESS: {
    const {
      offset = 0,
      hasMore = false,
      messages = [],
      limit = 20,
    } = action.payload || {};

    if (offset === 0) {
      // if offset is 0, we are fetching the first page (on mount)
      // check if the fetched messages already exist in state
      // if not, add them to the beginning of the array and update the offset
      const existingIds = new Set(state.all.data.map(({message_id: id}) => id));
      const newMessages = messages.filter(({message_id: id}) => !existingIds.has(id));
      const updatedData = [...newMessages, ...state.all.data || []];

      return {
        ...state,
        all: {
          isFetching: false,
          status: T.LoadStatuses.LOADED,
          error: null,
          data: updatedData,
          offset: updatedData.length,
          hasMore: state.all.data?.length > 0
            ? state.all.hasMore
            : hasMore,
          limit: limit ?? state.all.limit,
        },
      };
    }

    if (offset === state.all?.data?.length) {
      return {
        ...state,
        all: {
          isFetching: false,
          status: T.LoadStatuses.LOADED,
          error: null,
          offset: (state.all?.data?.length || 0) + messages.length,
          data: [...state.all?.data || [], ...messages],
          hasMore,
          limit: limit ?? state.all.limit,
        },
      };
    }

    return {
      ...state,
      all: {
        ...state.all,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        error: null,
        hasMore,
      },
    };
  }
  case MESSAGE_SET_READ_SUCCESS: {
    const messageId = action.payload.message_id;

    const newData = produce(state.all.data, draft => {
      const index = draft.findIndex(({message_id: id}) => id === messageId);

      if (index !== -1) {
        draft[index].isread = dayjs(new Date()).format('YYYY-MM-DD HH:MM:SS');
      }

      return draft;
    });

    const newUnread = produce(state.unread.data, draft => {
      const index = draft.findIndex(({message_id: id}) => id === messageId);

      if (index !== -1) {
        draft.splice(index, 1);
      }

      return draft;
    });

    return {
      ...state,
      all: {
        ...state.all,
        data: newData,
      },
      unread: {
        ...state.unread,
        data: newUnread,
        notification: state.unread.notification - 1,
      },
    };
  }

  case MESSAGES_GET_FAILURE: {
    return {
      ...state,
      all: {
        ...state.all,
        status: T.LoadStatuses.FAILED,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  case MESSAGE_SET_READ: {
    return {
      ...state,
      all: {
        ...state.all,
        data: state.all.data.map(m => m.message_id === action.payload.message.message_id
          ? {
            ...m,
            is_read: true,
          }
          : m),
      },

    };
  }
  case MESSAGES_CHECK_MESSAGES_REQUEST: {
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  }

  case MESSAGES_CHECK_MESSAGES_SUCCESS: {
    return {
      ...state,
      unread: {
        ...state.unread,
        status: T.LoadStatuses.LOADED,
        cnt: action.payload.unreadCnt,
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default messages;
