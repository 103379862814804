import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createSelector} from 'reselect';
import {
  atlas as AtlasRoutes,
  courseCatalog as courseCatalogRoutes,
} from '@routes/routes.manifest';
import {getStartUrl} from '@selectors/auth.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {getIsMapCompleted} from '@selectors/map.selectors.new';
import {selectKioskModeStartUrl} from '@selectors/profile.selectors';

const selectDefaultRedirectRoute = createSelector(
  getConfigObject,
  getStartUrl,
  getIsMapCompleted,
  selectKioskModeStartUrl,
  (configObject, startUrl, isMapCompleted, kioskStartUrl) => {
    if (kioskStartUrl) return kioskStartUrl;

    const defaultRoute = startUrl !== '/'
      && startUrl
      || configObject?.getProperty('params.default-route');

    if (configObject?.isMapActivated) {
      if (!isMapCompleted) return defaultRoute || AtlasRoutes.main.path;

      return configObject?.isModuleEnabled?.('course-catalog')
        ? courseCatalogRoutes.main.path
        : defaultRoute || AtlasRoutes.main.path;
    }

    return defaultRoute || courseCatalogRoutes.main.path;
  },
);

export const DefaultRedirectRoute = React.memo(() => {
  const route = useSelector(selectDefaultRedirectRoute);

  return <Redirect to={route} />;
});
