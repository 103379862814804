const prefix = 'auth';

export const AUTH_LOGIN_REQUEST = `${prefix} LOGIN_REQUEST`;
export const AUTH_LOGIN_SUCCESS = `${prefix} LOGIN_SUCCESS`;
export const AUTH_LOGIN_SUCCESS_CHOICE = `${prefix} LOGIN_SUCCESS/BUT/SELECT`;
export const AUTH_LOGIN_FAILURE = `${prefix} LOGIN_FAILURE`;
export const AUTH_LOGOUT_REQUEST = `${prefix} LOGOUT_REQUEST`;
export const AUTH_LOGOUT_SUCCESS = `${prefix} LOGOUT_SUCCESS`;
export const AUTH_UNAUTHORIZED = `${prefix} AUTH_UNAUTHORIZED`;

export const AUTH_LOGIN_ATTEMTPS_REQUEST = `${prefix} AUTH_LOGIN_ATTEMTPS_REQUEST`;

export const AUTH_CHECK_LOGIN_METHOD = `${prefix}/AUTH/CHECK-LOGIN-METHOD`;
export const AUTH_CHECK_LOGIN_METHOD_REQUEST = `${prefix}/AUTH/CHECK-LOGIN-METHOD/REQUEST`;
export const AUTH_CHECK_LOGIN_METHOD_SUCCESS = `${prefix}/AUTH/CHECK-LOGIN-METHOD/SUCCESS`;
export const AUTH_CHECK_LOGIN_METHOD_FAILURE = `${prefix}/AUTH/CHECK-LOGIN-METHOD/FAILURE`;
export const AUTH_USER_IS_CONFIRMED_LOGGED_IN = `${prefix}/AUTH_USER_IS_CONFIRMED_LOGGED_IN`;
export const AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN = `${prefix}/AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN`;

export const authVerifyUser = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD,
  payload,
});

export const AUTH_BART_USER = 'AUTH/AUTH_BART_USER';
export const AUTH_BART_USER_SUCCESS = 'AUTH/AUTH_BART_USER_SUCCESS';
export const authBartUser = payload => ({
  type: AUTH_BART_USER,
  payload,
});

export const authBartUserSuccess = () => ({type: AUTH_BART_USER_SUCCESS});

export const AUTH_TRANSFER_USER = 'AUTH/AUTH_TRANSFER_USER';
export const AUTH_TRANSFER_USER_SUCCESS = 'AUTH/AUTH_TRANSFER_USER_SUCCESS';
export const authTransferUser = payload => ({
  type: AUTH_TRANSFER_USER,
  payload,
});

export const authTransferUserSuccess = () => ({type: AUTH_TRANSFER_USER_SUCCESS});

export const authVerifyUserRequest = () => ({type: AUTH_CHECK_LOGIN_METHOD_REQUEST});

export const authVerifyUserSuccess = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  payload,
});

export const authVerifyUserFailure = payload => ({
  type: AUTH_CHECK_LOGIN_METHOD_FAILURE,
  payload,
});

export const AUTH_GOTO_NEXT = `${prefix} AUTH_GOTO_NEXT`;
export const AUTH_SET_SESSION_ID = `${prefix} AUTH_SET_SESSION_ID`;

export const AUTH_LOGIN_GOTO_ON = `${prefix}/AUTH_LOGIN_GOTO_ON`;

export const AUTH_LOGIN_NEW_PASSWORD_REQUEST = `${prefix}/NEWPASSWORD/REQUEST`;
export const AUTH_LOGIN_NEW_PASSWORD_SUCCESS = `${prefix}/NEWPASSWORD/SUCCESS`;
export const AUTH_LOGIN_NEW_PASSWORD_FAILURE = `${prefix}/NEWPASSWORD/FAILURE`;

export const AUTH_CLOSE_RESET_PASSWORD_FORM = `${prefix}/CLOSE_RESET_PASSWORD_FORM`;
export const AUTH_CHECK_IF_LOGGED_IN = `${prefix}/CHECK_IF_LOGGED_IN`;
export const AUTH_WAIT_FOR_AUTH_STATUS_AND_START_LOADING = `${prefix}/AUTH_WAIT_FOR_AUTH_STATUS_AND_START_LOADING`;
export const AUTH_START_LOGIN_CHECK_ON_INTERVAL = `${prefix}/AUTH_START_LOGIN_CHECK_ON_INTERVAL`;

export const authGotoOn = () => ({type: AUTH_LOGIN_GOTO_ON});

export const authAttempt = () => ({type: AUTH_LOGIN_ATTEMTPS_REQUEST});

export const authLoginRequest = payload => ({
  type: AUTH_LOGIN_REQUEST,
  payload,
});

export const authLoginNewPasswordSuccess = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_SUCCESS,
  payload,
});

export const authLoginNewPasswordFailure = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_FAILURE,
  payload,
});

export const authLoginNewPasswordRequest = payload => ({
  type: AUTH_LOGIN_NEW_PASSWORD_REQUEST,
  payload,
});

export const authGotoNext = payload => ({
  type: AUTH_GOTO_NEXT,
  payload,
});

export const authSetSessionId = payload => ({
  type: AUTH_SET_SESSION_ID,
  payload,
});

export const authLoginChoice = payload => ({
  type: AUTH_LOGIN_SUCCESS_CHOICE,
  payload,
});

export const authLoginSuccess = payload => ({
  type: AUTH_LOGIN_SUCCESS,
  payload,
});

export const authLoginFailure = payload => ({
  type: AUTH_LOGIN_FAILURE,
  payload,
});

export const authLogoutRequest = () => ({type: AUTH_LOGOUT_REQUEST});

export const authLogoutSuccess = () => ({type: AUTH_LOGOUT_SUCCESS});

export const authUnauthorized = payload => ({
  type: AUTH_UNAUTHORIZED,
  payload,
});

export const authCloseResetPassowrdForm = () => ({type: AUTH_CLOSE_RESET_PASSWORD_FORM});

export const authCheckIfLoggedIn = () => ({type: AUTH_CHECK_IF_LOGGED_IN});

export const authUserIsConfirmedLoggedIn = payload => ({
  type: AUTH_USER_IS_CONFIRMED_LOGGED_IN,
  payload,
});

export const authUserIsConfirmedNotLoggedIn = () => ({type: AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN});

export const authGetAuthStatusAndStartLoading = () => ({type: AUTH_WAIT_FOR_AUTH_STATUS_AND_START_LOADING});

export const authStartLoginCheckOnInterval = payload => ({
  type: AUTH_START_LOGIN_CHECK_ON_INTERVAL,
  payload,
});
