import {useEffect, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {
  createResponsiveStateReducer,
  responsiveStoreEnhancer,
} from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
import {all, cancel, fork, take} from 'redux-saga/effects';
import thunk from 'redux-thunk';
import {appInjectReduxModule, appReducerImmer, selectReduxModuleLoadStatusById} from '@actions/app.actions';
import {coursesReducerImmer} from '@actions/courses.actions';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import {profileImmerReducer} from '@actions/profile.actions';
import {ENABLE_REDUX_TESTING} from '@config';
import alertReducer from '@reducers/alert.reducer';
import authReducer from '@reducers/auth.reducer';
import cmsReducer from '@reducers/cms.reducers';
import componentsReducer from '@reducers/components.reducer';
import configReducer from '@reducers/config.reducers';
import helpReducer from '@reducers/help.reducer';
import manageReducer from '@reducers/manage.reducer';
import mapReducer from '@reducers/map.reducers';
import messagesReducer from '@reducers/messages.reducer';
import newsReducer from '@reducers/news.reducer';
import notificationsReducer from '@reducers/notifications.reducer';
import rolesReducer from '@reducers/roles.reducer';
import themeReducer from '@reducers/theme.reducer';
import {userReducer} from '@reducers/user.reducer';
import authSagas from '@sagas/auth.sagas';
import cmsSagas from '@sagas/cms.sagas';
import competencesSagas from '@sagas/competences.sagas';
import componentsSagas from '@sagas/components.sagas';
import configSagas from '@sagas/config.sagas';
import coursesSagas from '@sagas/courses.sagas';
import helpSagas from '@sagas/help.sagas';
import manageSagas from '@sagas/manage.sagas';
import mapSagas from '@sagas/map.sagas.new';
import messagesSagas from '@sagas/messages.sagas';
import newsSagas from '@sagas/news.sagas';
import profileSagas from '@sagas/profile.sagas';
import rolesSagas from '@sagas/roles.sagas';
import themeSagas from '@sagas/theme.sagas';
import {size} from '@styles/device';
import {LoadStatuses} from '@types/load.types';
import {modalsReducer} from './features/modals/registry';
import {resetGlobalConfigObject} from './store/util/config.util';
import {isObjectWithKeys} from './store-normalized/util/misc';

// from here https://github.com/AlecAivazis/redux-responsive
const defaultBreakpoints = {
  extraSmall: 480,
  small: 768,
  medium: 992,
  mobileCourseCatalog: Number.parseInt(
    size.mobileCourseCatalog.split('px')[0],
    10,
  ),
  large: 1200,
};

const browserReducer = createResponsiveStateReducer(defaultBreakpoints);

const enableReduxTesting = process.env && process.env.NODE_ENV !== 'production' && ENABLE_REDUX_TESTING;

// action type to replace the entire store with a new state (see below)
const TESTING_REPLACE_STATE = 'TESTING_REPLACE_STATE';

// wrapped reducer which allows an action to replace the entire store* (see below)
// this is useful for mocking when testing for example
// all other actions are sent to the normal reducer as usual
function createReplaceStateReducer(rootReducer) {
  return function reducer(state, action) {
    if (action.type === TESTING_REPLACE_STATE) {
      return {
        ...action.payload.state,
        auth: state.auth, // contains stuff like session id
        config: state.config, // also contains a function - not serializable
        browser: state.browser, // contains info for curr screen size
      };
    }

    return rootReducer(state, action);
  };
}

export function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    user: userReducer,
    app: appReducerImmer,
    modals: modalsReducer,
    auth: authReducer,
    alert: alertReducer,
    courses: coursesReducerImmer,
    profile: profileImmerReducer,
    messages: messagesReducer,
    roles: rolesReducer,
    form: formReducer,
    browser: browserReducer,
    theme: themeReducer,
    manage: manageReducer,
    notifications: notificationsReducer,
    news: newsReducer,
    config: configReducer,
    cms: cmsReducer,
    map: mapReducer,
    components: componentsReducer,
    help: helpReducer,
    // employees: null, // to be lazy loaded
    // admin: null, // to be lazy loaded
    // panelet: null, // to be lazy loaded
    ...injectedReducers,
  });

  return enableReduxTesting
    ? createReplaceStateReducer(rootReducer)
    : rootReducer;
}

export const employeesModule = () => {
  console.log('load employees.redux');
  const reducer = import(/* webpackChunkName: 'employees-reducer' */ '@actions/employees.actions').then(module => module.default);
  const saga = import(/* webpackChunkName: 'employees-sagas' */ '@sagas/employees.sagas').then(module => module.default);

  return [
    reducer,
    saga,
  ];
};

export const adminTracksModule = () => {
  console.log('load admin.redux');
  const reducer = import(/* webpackChunkName: 'admin-reducer' */ '@reducers/admin.reducers').then(module => module.default);
  const saga = import(/* webpackChunkName: 'admin-sagas' */ '@admin/store/sagas').then(module => module.default);

  return [
    reducer,
    saga,
  ];
};

export const paneletModule = () => {
  console.log('load dashboard.panelet.redux');
  const reducer = import(/* webpackChunkName: 'panelet-reducer' */ '@actions/panelet.actions').then(module => module.default);
  const saga = import(/* webpackChunkName: 'panelet-sagas' */ '@sagas/panelet.sagas').then(module => module.default);

  return [
    reducer,
    saga,
  ];
};

const __asyncStoreModules = {
  employees: employeesModule,
  admin: adminTracksModule,
  panelet: paneletModule,
};

function getStoreModule(moduleName) {
  const module = __asyncStoreModules[moduleName];

  return module ? module() : null;
}

function injectModuleFactory(store) {
  return async function injectModule(key, callback) {
    if (!key || store.injectingCurrent[key]) {
      return;
    }
    const {app: {reduxModules = {}} = {}} = store.getState() || {};
    const currentStatus = reduxModules[key];

    if (!currentStatus || currentStatus !== LoadStatuses.NOT_LOADED) return;

    store.injectingCurrent[key] = true;
    store.dispatch(appInjectReduxModule.request(key));
    const _module = getStoreModule(key);

    if (!_module) return;

    const [reducer, saga] = await Promise.all(_module);
    const awaitInject = [];

    if (saga) {
      awaitInject.push(store.injectSaga(key, {
        saga,
        // mode: 'DAEMON',
      }));
    }

    if (reducer) {
      awaitInject.push(store.injectReducer(key, reducer));
    }

    Promise.all(awaitInject).then(() => {
      store.dispatch(appInjectReduxModule.success(key));
      if (callback) callback();
    })
      .catch(error => {
        store.injectingCurrent[key] = false;
      })
      .finally(() => {
        store.injectingCurrent[key] = false;
      });
  };
};

function injectReducerFactory(store, isValid) {
  return async function injectReducer(key, reducer) {
    if (
      Reflect.has(store.injectedReducers, key)
      && store.injectedReducers[key] === reducer
    )
      return;

    store.injectedReducers[key] = reducer;

    try {
      await store.replaceReducer(createReducer(store.injectedReducers));
    } catch (error) {
      console.error('injectReducer error', {
        key,
        error,
      });
    }
  };
}

export function injectSagaFactory(store, isValid = true) {
  return async function injectSaga(key, descriptor = {}, args = {}) {
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode,
    };

    const {saga} = newDescriptor;

    const hasSaga = Reflect.has(store.injectedSagas, key);

    if (!hasSaga) {
      const task = await store.runSaga(saga, args);

      store.injectedSagas[key] = {
        ...newDescriptor,
        task,
      };
    }
  };
}

const rootSaga = store => function*() {
  // ...authSagas,
  yield fork(function* auth() {
    yield all(authSagas);
  });
  // ...coursesSagas,
  yield fork(function* courses() {
    yield all(coursesSagas);
  });
  // ...profileSagas,
  yield fork(function* profile() {
    yield all(profileSagas);
  });
  // manageSagas
  yield fork(function* manage() {
    yield all(manageSagas);
  });
  // ...competencesSagas,
  yield fork(function* competences() {
    yield all(competencesSagas);
  });
  // ...messagesSagas,
  yield fork(function* messages() {
    yield all(messagesSagas);
  });
  // ...rolesSagas,
  yield fork(function* roles() {
    yield all(rolesSagas);
  });
  // ...themeSagas,
  yield fork(function* theme() {
    yield all(themeSagas);
  });
  // ...newsSagas,
  yield fork(function* news() {
    yield all(newsSagas);
  });
  // ...configSagas,
  yield fork(function* config() {
    yield all(configSagas);
  });
  // ...cmsSagas,
  yield fork(function* cms() {
    yield all(cmsSagas);
  });
  // ...mapSagas,
  yield fork(function* map() {
    yield all(mapSagas);
  });
  // ...helpSagas,
  yield fork(function* help() {
    yield all(helpSagas);
  });
  // ...componentsSagas,
  yield fork(function* components() {
    yield all(componentsSagas);
  });

  // ...employeesSagas(), // lazy loaded
  // ...adminTracksSagas(), // (admin/tracks) lazy loaded
  // ...paneletSagas(), // (dashboard/panelet) lazy loaded

  // Listen for RESET_APP action (on logout/unauthorized).
  // Deletes global config object and removes all injected reducers and sagas
  yield fork(function* removeInjectedModulesOnAppReset() {
    while (true) {
      yield take([RESET_APP, RESET_STORE]);
      resetGlobalConfigObject();
      if (!isObjectWithKeys(store.injectedSagas)) continue;
      // Cancel all lazy loaded sagas
      for (const key of Object.keys(store.injectedSagas)) {
        yield cancel(store.injectedSagas[key].task);

        // wait for saga to be cancelled
        while (store.injectedSagas[key].task.isRunning()) {
          yield take('CANCELLED');
        }

        delete store.injectingCurrent[key];
        delete store.injectedSagas[key];
      }
    }
  });
};

export const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      console.error(error);

      store.dispatch({
        type: 'ERROR',
        payload: error,
      });
    },

  });

  const middlewares = [thunk, sagaMiddleware];

  /* eslint no-underscore-dangle: 0 */
  const devTools
    = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
      : undefined;

  const enhancer = devTools
    ? compose(
      applyMiddleware(...middlewares),
      responsiveStoreEnhancer,
      devTools,
    )
    : compose(applyMiddleware(...middlewares), responsiveStoreEnhancer);

  const store = createStore(createReducer(), initialState, enhancer);

  // Extensions
  store.runSaga = sagaMiddleware.run;

  store.injectSaga = injectSagaFactory(store, true);
  store.injectReducer = injectReducerFactory(store, true);
  store.injectModule = injectModuleFactory(store);

  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry
  store.injectingCurrent = {}; // Module registry (boolean)

  sagaMiddleware.run(rootSaga(store));

  return store;
};

export const useIsModuleLoaded = moduleId => {
  const loadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));

  return loadStatus === LoadStatuses.LOADED;
};

export const useInjectModule = (moduleId, disabled) => {
  const {injectModule} = useStore();
  const moduleLoadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));
  const isLoaded = moduleLoadStatus === LoadStatuses.LOADED;
  const isInjecting = useRef(moduleLoadStatus === LoadStatuses.IS_LOADING);
  const shouldInject = !disabled && moduleLoadStatus === LoadStatuses.NOT_LOADED && !isInjecting.current;

  useEffect(() => {
    if (!shouldInject) return;
    isInjecting.current = true;
    injectModule(moduleId, () => {
      isInjecting.current = false;
    });
  }, [injectModule, moduleId, shouldInject]);

  return isLoaded;
};
