import {css} from '@emotion/react';
import styled from '@emotion/styled';

export const highlightOnKeyboardFocusMixin = css`
  &.focus-visible {
    outline-color: rgb(77, 144, 254); // #4D90FE
    outline-offset: 2px;
    outline-style: auto;
    outline-width: 5px;
  }
`;

export const highlightOnKeyboardFocusMixinExtra = css`
  &.focus-visible {
    outline: 2px solid black;
  }
`;

export const screenreaderOnlyMinxin = css`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export const IdentityWarning = styled.div`
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFCA99;
  border-color: #E1B685;
`;
export const NoticeBanner = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 999999;
  padding: 20px 0;
  padding-bottom: 4px;
  line-height: 1.5;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-size: 1.0rem;
  font-weight: bold;
  text-align: center;

  background-color: var(--color-header-top);
  color: var(--color-header-text) !important;

  span {
    display: inline-block;
    margin-bottom: 16px;
  }

  .btn {
    display: inline-block;
    margin: -0.25em 20px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    border: 1px solid white;
    background: var(--color-main-dark);
  }
`;
