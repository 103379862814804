import {freeze} from 'immer';
import {createReducerAndActions} from '@src/store-normalized/setup/createDefault';
import {updateFetchStateFailure, updateFetchStateRequest, updateFetchStateSuccess} from '@src/store-normalized/util/loadstatus.utils';
import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import * as T from '@types/load.types';
import {initialState} from '../initial-state/employees.initial-state';
import {searchItems} from '../util/employees.util';

const employees = {
  getEmployees: {
    request: (state, action) => {
      const status = {
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      };

      const isOtherOrg = action?.payload?.orgId != null && state.list.orgId !== action.payload.orgId;

      state.list = isOtherOrg
        ? {
          ...initialState.list,
          orgId: action.payload.orgId,
          ...status,
        }
        : {
          ...state.list,
          ...status,
        };
    },
    success: (state, action) => {
      state.list.isFetching = false;
      state.list.status = T.LoadStatuses.LOADED;
      state.list.hasMore = action.payload.hasMore;
      state.list.hasChildren = action.payload.hasChildren;

      const data = freeze(action.payload.data);

      state.list.data = data;

      const updated = data.reduce((acc, employee) => {
        const id = employee.person_id;

        acc.byId[id] = employee;
        acc.allIds.push(id);

        return acc;
      }, {
        allIds: [],
        byId: {},
      });

      state.list.allIds = freeze(updated.allIds);
      state.list.byId = freeze(updated.byId);
    },
    failure: (state, action) => {
      state.list.isFetching = false;
      state.list.status = T.LoadStatuses.FAILED;
      state.normalizedData.employees.status = T.LoadStatuses.FAILED;
    },
  },
  getWorklist: {
    request: (state, action) => {
      // state.worklist.data = null;
      state.worklist.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.worklist.data = freeze(action.payload.data);
      state.worklist.ids = action.payload.ids;
      state.worklist.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.worklist.data = null;
      state.worklist.status = T.LoadStatuses.FAILED;
    },
  },
  switchEmployeeTab: (state, action) => {
    state.selectedPerson.tab = action.payload;
  },
  selectPerson: (state, action) => {
    if (action.payload.userName && state.selectedPerson.userName === action.payload.userName) return;

    state.selectedPerson.isFetching = true;
    state.selectedPerson.status = T.LoadStatuses.IS_LOADING;
    state.selectedPerson.userName = action.payload.userName;
  },
  fetchFunctions: {
    request: (state, action) => {
      state.functions.isFetching = true;
    },
    success: (state, action) => {
      state.functions.data = action.payload.functions;
      state.functions.isFetching = false;
    },
    failure: (state, action) => {
      state.functions.error = action.payload.error;
      state.functions.isFetching = false;
    },
  },
  fetchSelectedPerson: {
    request: (state, action) => {
      if (isObjectWithKeys(action?.payload?.partialUpdate)) {
        const partialLoadStatus = Object.keys(action.payload.partialUpdate).reduce((acc, key) => {
          acc[key] = T.LoadStatuses.IS_LOADING;

          return acc;
        }, {});

        state.selectedPerson.partialLoadStatus = partialLoadStatus;

        return;
      }

      state.selectedPerson.partialLoadStatus = initialState.selectedPerson.partialLoadStatus;

      if (action?.payload?.refresh) return;

      state.selectedPerson.data = null;
      state.selectedPerson.isFetching = true;
      state.selectedPerson.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.status = T.LoadStatuses.LOADED;
      state.selectedPerson.partialLoadStatus = initialState.selectedPerson.partialLoadStatus;
      if (!action?.payload?.person) return;
      state.selectedPerson.data = action.payload.person;
    },
    failure: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.error = action.payload.error;
      state.selectedPerson.status = T.LoadStatuses.FAILED;
      state.selectedPerson.partialLoadStatus = initialState.selectedPerson.partialLoadStatus;
    },
  },
  fetchSelectedPersonCompetences: {
    request: (state, action) => {
      if (action?.payload?.refresh) return;

      state.selectedPerson.data.summary.isFetching = true;
    },
    success: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.data = action.payload.data;
    },
    failure: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.error = action.payload.error;
    },
  },
  fetchSelectedPersonChecklists: {
    request: (state, action) => {
      if (action?.payload?.refresh) return;

      state.selectedPerson.isFetching = false;
      state.selectedPerson.elements.checklists = {isFetching: true};
    },
    success: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.data.checklists = action.payload.data;
      state.selectedPerson.elements.checklists = {isFetching: false};
    },
    failure: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.error = action.payload.error;
    },
  },
  fetchSelectedPersonReport: {
    request: (state, action) => {
      state.selectedPerson.report = {
        status: T.LoadStatuses.IS_LOADING,
        data: action.payload.userName === state?.selectedPerson?.report?.userName ? state.selectedPerson.report.data : null,
        userName: action.payload.userName,
      };
    },
    success: (state, action) => {
      state.selectedPerson.report = {
        status: T.LoadStatuses.LOADED,
        data: action.payload.data,
        userName: action.payload.userName,
      };
    },
    failure: (state, action) => {
      state.selectedPerson.report = {
        isFetching: false,
        status: T.LoadStatuses.FAILED,
        data: null,
        userName: null,
      };
    },
  },
  approveRejectSelfSign: {
    request: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.SAVING;
    },
    success: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.SAVED;
    },
    failure: (state, action) => {
      state.checklists.saving.status = T.LoadStatuses.FAILED;
    },
  },
  fetchOrganisation: {
    request: (state, action) => {
      if (action?.payload?.orgId) {
        if (state.organisation.orgId === action.payload.orgId) return;

        state.organisation.orgId = action.payload.orgId;
      }
      state.organisation.status = T.LoadStatuses.IS_LOADING;
      state.organisation.data = null;
    },
    success: (state, action) => {
      state.organisation.status = T.LoadStatuses.LOADED;
      state.organisation.data = action.payload.data;
    },
    failure: (state, action) => {
      state.organisation.status = T.LoadStatuses.FAILED;
      state.organisation.data = null;
    },
  },
  fetchTree: {
    request: (state, action) => {
      if (action.payload && action.payload.fetchMore) {
        const child = searchItems(state.tree.data, action.payload.orgId);

        if (child) {
          child.status = T.LoadStatuses.IS_LOADING;
        }
      } else {
        state.tree.status = T.LoadStatuses.IS_LOADING;
        state.tree.data = null;
      }
    },
    success: (state, action) => {
      if (action.payload && action.payload.fetchMore) {
        const child = searchItems(state.tree.data, action.payload.orgId);

        if (child) {
          child.status = T.LoadStatuses.LOADED;
          child.children = action.payload.data.children;
        }

        state.tree.status = T.LoadStatuses.LOADED;
      } else {
        state.tree.status = T.LoadStatuses.LOADED;
        state.tree.data = action.payload.data;
      }
    },
    failure: (state, action) => {
      state.tree.status = T.LoadStatuses.FAILED;
      state.tree.data = null;
    },
  },
  fetchTreeProgress: {
    request: null,
    success: (state, action) => {
      if (action.payload) {
        if (action.payload.orgId) {
          state.organisationProgress.byId[action.payload.orgId] = action.payload.data;
        }

        const child = searchItems(state.tree.data, action.payload.orgId);

        if (child) {
          child.progress_status = T.LoadStatuses.LOADED;
          child.progress = action.payload.data;
        }
      } else {
        state.tree.data = action.payload.data;
      }
    },
    failure: null,
  },
  fetchSelectedPersonActivities: {
    request: (state, action) => {
      if (action?.payload?.refresh) return;

      state.selectedPerson.data.summary.activities = {isFetching: true};
    },
    success: (state, action) => {
      state.selectedPerson.isFetching = false;
      state.selectedPerson.data = action.payload.data;
    },
  },
  fetchSelectedPersonEvents: {
    request: (state, action) => {
      if (action?.payload?.refresh) return;

      state.selectedPerson.elements.events = {status: T.LoadStatuses.IS_LOADING};
    },
    success: (state, action) => {
      state.selectedPerson.data.events = action.payload.data;
      state.selectedPerson.elements.events = {status: T.LoadStatuses.LOADED};
    },
  },
  fetchSelectedPersonExpiring: {
    request: (state, action) => {
      if (action?.payload?.refresh) return;

      state.selectedPerson.elements.expiring = {
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: (state, action) => {
      state.selectedPerson.data.expiring = action.payload.data;

      state.selectedPerson.elements.expiring = {
        status: T.LoadStatuses.LOADED,
        isFetching: false,
      };
    },
    failure: (state, action) => {
      state.selectedPerson.elements.expiring = {
        status: T.LoadStatuses.FAILED,
        isFetching: false,
      };
    },
  },
  fetchStatistics: {
    request: (state, action) => {
      if (action?.payload?.orgId != null && state?.statistics?.orgId !== action.payload.orgId) {
        state.statistics.data = initialState.statistics.data;
      }
      updateFetchStateRequest(state.statistics, {
        refresh: action?.payload?.refresh,
        orgId: action?.payload?.orgId,
      });
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.statistics, {data: action.payload.statistics});
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.statistics, {error: action.payload.error});
    },
  },
  fetchChecklists: {
    request: (state, action) => {
      if (action?.payload?.orgId != null && state?.checklists?.orgId !== action.payload.orgId) {
        state.checklists.data = initialState.checklists.data;
      }
      updateFetchStateRequest(state.checklists, {
        refresh: action?.payload?.refresh,
        orgId: action?.payload?.orgId,
      });
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.checklists, {data: action.payload.competences});
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.checklists, {error: action.payload.error});
    },
  },
  fetchExtraData: {
    request: (state, action) => {
      state.extraData.isFetching = true;
    },
    success: (state, action) => {
      state.extraData = {
        data: action.payload.data,
        isFetching: false,
      };
    },
    failure: (state, action) => {
      state.extraData.isFetching = false;
      state.extraData.error = action.payload.error;
    },
  },
  fetchActivities: {
    request: (state, action) => {
      state.activities.isFetching = true;
      if (action?.payload?.orgId) state.activities.orgId = action.payload.orgId;
    },
    success: (state, action) => {
      state.activities.isFetching = false;
      state.activities.data = action.payload.activities;
    },
    failure: (state, action) => {
      state.activities.isFetching = false;
      state.activities.error = action.payload.error;
    },
  },
  fetchExpiringCompetences: {
    request: (state, action) => {
      state.expiring.isFetching = true;
      state.expiring.status = T.LoadStatuses.IS_LOADING;

      if (action.payload.orgId) {
        if (state.expiring.orgId !== Number(action.payload.orgId)) {
          state.expiring.data = initialState.expiring.data;
        }
        state.expiring.orgId = Number(action.payload.orgId);
      }
    },
    success: (state, action) => {
      state.expiring.isFetching = false;
      state.expiring.status = T.LoadStatuses.LOADED;
      state.expiring.data = action.payload.expiring;
    },
    failure: (state, action) => {
      state.expiring.isFetching = false;
      state.expiring.error = action.payload.error;
    },
  },
  viewReport: {
    request: (state, action) => {
      state.report = {
        data: null,
        id: action.payload.reportId,
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: (state, action) => {
      state.report = {
        data: action.payload.data,
        status: T.LoadStatuses.LOADED,
      };
    },
    failure: (state, action) => {
      state.report = {
        data: null,
        status: T.LoadStatuses.FAILED,
        error: action.payload.error,
      };
    },
  },
  saveVerification: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  saveRequirements: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  saveRole: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;

      state.saving.error = action.payload.error;
    },
  },
  fetchEvents: {
    request: (state, action) => {
      if (action?.payload?.orgId) {
        if (state.events.orgId !== Number(action.payload.orgId)) {
          state.events.data = initialState.events.data;
          state.normalizedData.events = {
            ...initialState.normalizedData.events,
            status: T.LoadStatuses.IS_LOADING,
          };
        }
        state.events.orgId = Number(action.payload.orgId);
        state.events.status = T.LoadStatuses.IS_LOADING;
      }
      if (action?.payload?.refresh) return;

      state.events.status = T.LoadStatuses.IS_LOADING;
      state.events.error = null;
    },
    success: (state, action) => {
      if (action?.payload?.orgId) state.events.orgId = action.payload.orgId;
      if (action?.payload?.events) state.events.data = action.payload.events;
      state.events.status = T.LoadStatuses.LOADED;
      state.events.lastFetched = Date.now();
    },
    failure: (state, action) => {
      state.events.isFetching = false;
      state.events.error = action.payload.error;
    },
  },
  fetchEventsWaitlist: {
    request: (state, action) => {
      state.eventsWaitlist.isFetching = true;
      state.eventsWaitlist.error = null;
    },
    success: (state, action) => {
      state.eventsWaitlist.isFetching = false;
      state.eventsWaitlist.data = action.payload.eventsWaitlist;
    },
    failure: (state, action) => {
      state.eventsWaitlist.isFetching = false;
      state.eventsWaitlist.error = action.payload.error;
    },
  },
  updateEvents: (state, action) => {
    if (action.payload.empty) {
      if (state.normalizedData.events.eventsSortedByDate.length) {
        state.normalizedData.events = {
          ...initialState.normalizedData.events,
          status: T.LoadStatuses.LOADED,
        };
      } else {
        state.normalizedData.events.status = T.LoadStatuses.LOADED;
      }

      state.events.data = [];
      updateFetchStateSuccess(state.events, null);

      return;
    }

    state.normalizedData.events.status = T.LoadStatuses.LOADED;
    state.normalizedData.events.persons = action.payload.persons;
    state.normalizedData.events.employeeIdsByEventId = action.payload.employeeIdsByEventId;
    state.normalizedData.events.employeeIdsWaitlistByEventId = action.payload.employeeIdsWaitlistByEventId;
    state.normalizedData.events.employeeIdsConfirmedByEventId = action.payload.employeeIdsConfirmedByEventId;
    state.normalizedData.events.eventsSortedByDate = action.payload.eventsSortedByDate;

    updateFetchStateSuccess(state.events, null);
    state.events.data = action.payload.apiData;
  },
  putEmployeeModal: (state, action) => {
    const {
      personId,
      userName,
      orgId,
      isOpen,
      activeTab = initialState.employeeModal.activeTab,
    } = action?.payload || {};

    if (!isOpen) {
      state.employeeModal = initialState.employeeModal;

      return;
    }

    state.employeeModal = {
      isOpen,
      orgId,
      userName,
      personId,
      activeTab,
      tabHistory: [activeTab],
    };
  },
  putEmployeeModalTab: {
    popTab: (state, action) => {
      state.employeeModal.tabHistory.pop();
      state.employeeModal.activeTab = state.employeeModal.tabHistory.at(-1) || initialState.employeeModal.activeTab;
    },
    pushTab: (state, action) => {
      const activeTab = action?.payload || initialState.employeeModal.activeTab;

      if (state.employeeModal.activeTab !== activeTab) {
        state.employeeModal.activeTab = activeTab;
        state.employeeModal.tabHistory.push(activeTab);
      }
    },
    resetTabs: (state, action) => {
      state.employeeModal.activeTab = initialState.employeeModal.activeTab;
      state.employeeModal.tabHistory = initialState.employeeModal.tabHistory;
    },
  },
  removeRequirements: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = action.payload.error;
    },
  },
  // actions without reducers
  openEmployeeModal: null,
  closeEmployeeModal: null,
  addPerson: {
    request: null,
    success: null,
    failure: null,
  },
  editPassword: {
    request: null,
    success: null,
    failure: null,
  },
  fetchSelectedPersonCompetencesChildren: {
    request: null,
    success: null,
    failure: null,
  },
  editSelectedPerson: {
    request: null,
    success: null,
    failure: null,
  },
  employeeMove: {
    request: null,
    success: null,
    failure: null,
  },
  worklistAddRoles: {
    request: null,
    success: null,
    failure: null,
  },
  worklistSendMail: {
    request: null,
    success: null,
    failure: null,
  },
  resetPassword: {
    request: null,
    success: null,
    failure: null,
  },
  editViewInit: null,
  fetchExtradata: null,
  getFetchChecklists: null,
  updateSelectedPersonEmployment: {
    request: (state, action) => {
      state.saving.isSaving = true;
    },
    success: (state, action) => {
      state.saving.isSaving = false;
      state.saving.error = null;
    },
    failure: (state, action) => {
      state.saving.isSaving = false;

      state.saving.error = action.payload.error;
    },
  },
  updateSelectedPersonRoles: null,
  updateSelectedPersonRolesPost: null,
  worklistAdd: null,
  worklistClear: null,
  worklistRemove: null,
  addViewInit: null,
  reportViewInit: null,
};

const {actions, reducer} = createReducerAndActions({
  prefix: 'employees',
  initialState,
  actions: employees,
});

export {reducer as employeesReducerImmer};

export default reducer;

export const {
  openEmployeeModal: employeesOpenEmployeeModal,
  closeEmployeeModal: employeesCloseEmployeeModal,
  putEmployeeModal: employeesPutEmployeeModal,
  putEmployeeModalTab: employeesPutEmployeeModalTab,
  addPerson: employeesAddPerson,
  addViewInit: employeesInitAddView,
  approveRejectSelfSign: employeesApproveRejectSelfSign,
  editPassword: employeesEditPassword,
  editSelectedPerson: employeesEditSelectedPerson,
  editViewInit: employeesInitEditView,
  employeeMove: employeesMoveEmployee,
  fetchActivities: employeesFetchActivities,
  fetchChecklists: employeesFetchChecklists,
  fetchEvents: employeesFetchEvents,
  fetchEventsWaitlist: employeesFetchEventsWaitlist,
  fetchExpiringCompetences: employeesFetchExpiringCompetences,
  fetchExtradata: employeesFetchExtradata,
  fetchFunctions: employeesFetchFunctions,
  fetchOrganisation: employeesFetchOrganisation,
  fetchSelectedPerson: employeesFetchSelectedPerson,
  fetchSelectedPersonActivities: employeesFetchSelectedPersonActivities,
  fetchSelectedPersonChecklists: employeesFetchSelectedPersonChecklists,
  fetchSelectedPersonCompetences: employeesFetchSelectedPersonCompetences,
  fetchSelectedPersonCompetencesChildren: employeesFetchSelectedPersonCompetencesChildren,
  fetchSelectedPersonEvents: employeesFetchSelectedPersonEvents,
  fetchSelectedPersonExpiring: employeesFetchSelectedPersonExpiring,
  fetchSelectedPersonReport: employeesFetchSelectedPersonReport,
  fetchStatistics: employeesFetchStatistics,
  fetchTree: employeesFetchTree,
  fetchTreeProgress: employeesFetchTreeProgress,
  getEmployees: employeesGetEmployees,
  selectPerson: employeesSelectPerson,
  removeRequirements: employeesRemoveRequirements,
  viewReport: employeesViewReport,
  reportViewInit: employeesReportViewInit,
  resetPassword: employeesResetPassword,
  saveRequirements: employeesSaveRequirements,
  saveRole: employeesSaveRole,
  saveVerification: employeesSaveVerification,
  switchEmployeeTab: employeesSwitchEmployeeTab,
  updateEvents: employeesUpdateEvents,
  updateSelectedPersonEmployment: employeesUpdateSelectedPersonEmployment,
  updateSelectedPersonRoles: employeesUpdateSelectedPersonRoles,
  updateSelectedPersonRolesPost: employeesUpdateSelectedPersonRolesPost,
  getWorklist: employeesGetWorklist,
  worklistAdd: employeesWorklistAdd,
  worklistAddRoles: employeesWorklistAddRoles,
  worklistClear: employeesWorklistClear,
  worklistRemove: employeesWorklistRemove,
  worklistSendMail: employeesWorklistSendMail,
} = actions;
