import dayjs from 'dayjs';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _uniqWith from 'lodash/uniqWith';
import memoize from 'micro-memoize';
import {createSelector} from 'reselect';
import {EVENT_TYPES} from '@components/calendar-with-tooltip/util.jsx';
import {courseCatalog as courseCatalogRoutes} from '@routes/routes.manifest';
import {i18ndayjs} from '@src/i18n';
import {LoadStatuses} from '@types/load.types';
import {getRouteWithParams} from '@utils/routes.utils';
import {getCompentenceMetaData, getCompetenceCoverImage} from '../util/courses.util';
import {
  getConfigObject,
  getDefaultImage,
  getPropertiesForCurrLangAndTrack,
} from './config.selectors';
import {getNormalizedEmployeesEvents, selectEmployeesList} from './employees.selectors';
import {getNormalizedProfileEvents, getPassed, getPassedIdsNew, getProfileData, selectIsManager, selectProfileId} from './profile.selectors';

export const getNormalizedCompetenceDetails = ({courses: {normalizedData: {competenceDetails}}}) => competenceDetails;

export const getNormalizedCourses = ({courses: {normalizedData}}) => normalizedData;

export const getNormalizedCourseEvents = ({courses: {normalizedData: {courseEvents}}}) => courseEvents;

export const getNormalizedCompetencegroups = ({courses: {normalizedData: {competencegroups}}}) => competencegroups;

export const getGroupedCompetenceIds = ({courses: {normalizedData: {competenceIdsByGroupId}}}) => competenceIdsByGroupId;

export const getNormalizedCompetences = ({courses: {normalizedData: {competences}}}) => competences;

export const getNormalizedSelectedCompetenceGroup = ({courses: {normalizedData: {selectedCompetencegroup}}}) => selectedCompetencegroup;

export const getNormalizedSelectedCompetenceGroups = ({courses: {normalizedData: {selectedCompetencegroups}}}) => selectedCompetencegroups;

export const getCompetencesSearchTerm = ({courses: {competences: {searchTerm}}}) => searchTerm;

export const getCompetencesSearchResults = ({courses: {normalizedData: {competencesSearchResults}}}) => competencesSearchResults;

export const getCompetencesSearchState = createSelector(
  getCompetencesSearchResults,
  getCompetencesSearchTerm,
  ({status, empty}, searchTerm) => ({
    isActive: !!searchTerm,
    isLoading: status === LoadStatuses.IS_LOADING,
    isEmpty: empty,
    status,
    searchTerm,
  }),
);

export const getEventsGroupedByYearMonth = createSelector(
  getNormalizedCourseEvents,
  ({eventIdsByYearMonth, eventById}) => {
    const grouped = Object.entries(eventIdsByYearMonth)
      .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]))
      .map(([year, monthObj]) => {
        const months = Object.entries(monthObj)
          .sort((a, b) => Number.parseInt(a[0]) - Number.parseInt(b[0]))
          .map(([month, eventIds]) => ({
            monthName: i18ndayjs(`${year}-${month}`).format('MMMM'),
            month,
            events: eventIds.map(id => eventById[id]),
          }));

        return {
          year,
          months,
        };
      });

    return grouped;
  },
);

const getAutostartCourseTypes = createSelector(
  getConfigObject,
  configObject => configObject?.getProperty?.('routes.course-catalog.autostartCourseTypes') || [],
);

export const getNormalizedSelectedCompetences = createSelector(
  // getNormalizedCompetencegroups,
  getNormalizedSelectedCompetenceGroups,
  getCompetencesSearchTerm,
  getCompetencesSearchResults,
  getPassedIdsNew,
  getPassed,
  getDefaultImage,
  getAutostartCourseTypes,
  (
    // {status: competenceGroupsLoadStatus},
    {competences, status: competencesLoadStatus},
    searchTerm,
    {data: searchResultsData, status: searchStatus},
    passedCompetenceIds,
    passedCompetences,
    defaultImage,
    autoStartCourseTypes,
  ) => {
    const searchActive = !!searchTerm;

    let isFetching, competencesData;

    if (searchActive) {
      isFetching = searchStatus === LoadStatuses.IS_LOADING;
      competencesData = Object.values(searchResultsData);
    } else {
      isFetching = competencesLoadStatus === LoadStatuses.IS_LOADING;
      competencesData = competences;
    }

    const passedIdsSet = new Set(passedCompetenceIds);
    const autoStartCoursetypesSet = new Set(autoStartCourseTypes);

    const competencesWithProps = competencesData?.map?.(competence => {
      const {id, url, competence_type_key} = competence || {};

      if (!id) return null;

      const {durationText, points, passed} = getCompentenceMetaData({
        competence,
        passedIdsSet,
        passedCompetences,
      }) || {};

      return {
        ...competence,
        durationText,
        points,
        passed,
        coverImage: getCompetenceCoverImage(competence, defaultImage),
        shouldAutostartCourse: !url && autoStartCoursetypesSet.has(competence_type_key), // getShouldAutostartCourse(competence_type, url),
        competenceUrl: getRouteWithParams(courseCatalogRoutes.coursePreview.path, {cid: id}),
      };
    });

    return {
      isFetching,
      data: competencesWithProps,
      empty: !isFetching && !competencesData.length,
    };
  },
);

export const getCompetences = createSelector(
  ({courses: {competences}}) => competences,
  competences => ({
    ...competences,
    data: Array.isArray(competences.data)
    && competences.data.map(competence => {
      if (!Array.isArray(competence.files)) {
        return {
          ...competence,
          image: competence.content && competence.content.image,
          contentRoute: competence.url ? `/content/${competence.url}` : null,
        };
      }
      const cover = competence.files.find(file => file.title === 'cover');
      const durations
        = competence.durations
          && competence.durations.length
          && competence.durations[0].duration
        || null; // ToDo: Get dynamic value from API
      const durationType
        = competence.durations
          && competence.durations.length
          && competence.durations[0].type
        || null;

      return {
        ...competence,
        cover,
        image:
          cover && cover.url
          || competence.content && competence.content.image,
        contentRoute: competence.url ? `/content/${competence.url}` : null,
        durations,
        allDurations: competence.durations,
        durationType,
      };
    }),
  }),
);

export const getFeeaturedCompetences = createSelector(
  ({courses: {featuredCompetences}}) => featuredCompetences,
  featuredCompetences => {
    const competences = getCompetences({courses: {competences: featuredCompetences}});

    return competences;
  },
);

export const selectCourseEvents = createSelector(
  ({courses: {courseEvents}}) => courseEvents,
  getNormalizedCourseEvents,
  (courseEvents, {allEventIds, eventById, ...rest}) => ({
    ...courseEvents,
    ...rest,
    data: allEventIds.map(id => eventById[id]),
  }),
);

export const getCompetencegroups = ({courses: {competencegroups}}) => competencegroups;

export const getCourseCatalogNews = ({courses: {courseCatalogNews}}) => courseCatalogNews;

export const getCompetencetypes = ({courses: {competencetypes}}) => competencetypes;

export const getSelectedCatalogView = ({courses: {filters: {catalogView}}}) => catalogView;

export const getSelectedCourseKindTab = ({courses: {filters: {courseKind: {tab}}}}) => tab;

export const getSelectedCompetencegroupId = ({courses: {selectedCompetencegroupId}}) => selectedCompetencegroupId;

export const getSelectedSubcompetencegroupId = ({courses: {selectedSubcompetencegroupId}}) => selectedSubcompetencegroupId;

export const getSelectedSubSubcompetencegroupId = ({courses: {selectedSubSubcompetencegroupId}}) => selectedSubSubcompetencegroupId;

export const getSigningOnCourse = ({courses: {courseSignOn}}) => courseSignOn;

export const getSigningOffCourse = ({courses: {courseSignOff}}) => courseSignOff;

export const getCoursesSorting = ({courses: {sorting}}) => sorting;

export const getCourseFilters = ({courses}) => courses.filters;

export const getSelectedCompetencetypes = createSelector(
  ({courses: {filters: {selectedCompetencetypes}}}) => selectedCompetencetypes,
  selectedCompetencetypes =>
    Object.keys(selectedCompetencetypes).reduce(
      (ids, id) => selectedCompetencetypes[id] && [...ids, id] || ids,
      [],
    ),
);

export const getActiveCourse = ({courses: {activeCourse}}) => activeCourse;
export const selectActiveCourseMapId = ({courses: {activeCourseMapId}}) => activeCourseMapId;

export const getSignOnCourseResults = ({courses: {courseSignOn}}) => courseSignOn;

export const getFeaturedContentIds = createSelector(
  getPropertiesForCurrLangAndTrack,
  getConfigObject,
  (configForCurrLangAndMap, configObject) => {
    const featuredTiles = configObject.getProperty('routes.course-catalog.customToplevelSelector.featuredTiles');
    const featuredTilesForCurrMap = _get(
      configForCurrLangAndMap,
      'courseCatalog.featuredTiles.contentIds',
    );

    const ids = [];

    if (featuredTilesForCurrMap) {
      if (Array.isArray(featuredTilesForCurrMap)) {
        ids.push(featuredTilesForCurrMap);
      } else {
        ids.push([featuredTilesForCurrMap]);
      }
    }

    if (featuredTiles && featuredTiles.contentIds) {
      if (Array.isArray(featuredTiles.contentIds)) {
        ids.push(featuredTiles.contentIds);
      } else {
        ids.push([featuredTiles.contentIds]);
      }
    }

    return _uniqWith(ids, _isEqual);
  },
);

export const getNormalizedCourseEventsExtraData = createSelector(
  [
    selectProfileId,
    getProfileData,
    getNormalizedCourseEvents,
    getNormalizedProfileEvents,
    selectEmployeesList,
    getNormalizedEmployeesEvents,
    selectIsManager,
  ],
  (
    profileId,
    profileData,
    courseEvents = {},
    profileEvents = {},
    employees = {},
    employeesEvents = {},
    isManager = false,
    courseId = null,
  ) => {
    if (!profileId) return courseEvents;

    const extraDataNotLoadedOrFailed = [LoadStatuses.NOT_LOADED, LoadStatuses.FAILED].includes(profileEvents.status)
      || isManager && [LoadStatuses.NOT_LOADED, LoadStatuses.FAILED].includes(employeesEvents.status);

    const {
      eventById = {},
      allEventIds = [],
      eventIdsByCourseId = {},
    } = courseEvents || {};

    const {
      allIds: employeeIds = [],
      status: employeesStatus = LoadStatuses.NOT_LOADED,
    } = employees || {};

    const {
      employeeIdsConfirmedByEventId = {},
      employeeIdsWaitlistByEventId = {},
      employeeIdsByEventId = {},
      status: employeesEventsStatus = LoadStatuses.NOT_LOADED,
    } = employeesEvents || {};

    const noProfileEvents = !profileEvents?.eventsConfirmed?.length && !profileEvents?.eventsWaitlist?.length;
    const isEmployeesLoaded = ![LoadStatuses.NOT_LOADED, LoadStatuses.FAILED].includes(employeesStatus)
    || [LoadStatuses.NOT_LOADED, LoadStatuses.FAILED].includes(employeesEventsStatus);

    const notReady = isManager ? !isEmployeesLoaded : noProfileEvents;

    if (notReady) return courseEvents;

    const noEmployeesEvents = !Object.keys(employeeIdsByEventId).length;

    const getEmployeeIdsNotSignedUp = (eventId, selfSignedUp) => {
      const employeeIdsSignedUp = employeeIdsByEventId[eventId] || [];

      return employeeIds.filter(employeeId => {
        if (selfSignedUp && employeeId === profileId) return false;

        return !employeeIdsSignedUp.includes(employeeId);
      });
    };

    const eventIds = courseId
      ? eventIdsByCourseId[courseId] || []
      : allEventIds || [];

    const eventByIdExtraData = eventIds.reduce((acc, eventId) => {
      const event = eventById[eventId];

      if (!event?.id) return acc;

      const loadedExtraData = isManager ? isEmployeesLoaded : true;

      const isSelfInWaitlist = profileEvents.waitlistIds.includes(eventId);
      const isSelfConfirmed = profileEvents.confirmedIds.includes(eventId);

      const isSelfSignedUp = isSelfInWaitlist || isSelfConfirmed;

      acc[eventId] = {
        ...event,
        participantsData: {
          isSelfInWaitlist,
          isSelfConfirmed,
          isSelfSignedUp,
          employeeIdsSignedUp: [],
          employeeIdsNotSignedUp: [],
          employeeIdsWaitlist: [],
          employeeIdsConfirmed: [],
          employeesNotSignedUp: [],
          employeesWaitlist: [],
          employeesConfirmed: [],
        },
        loadedExtraData,
      };

      const me = isSelfSignedUp ? [profileId] : [];

      if (loadedExtraData && (!noProfileEvents || !noEmployeesEvents)) {
        if (isManager) {
          acc[eventId].participantsData.employeeIdsNotSignedUp = getEmployeeIdsNotSignedUp(eventId, isSelfSignedUp);
        }

        acc[eventId].participantsData.employeeIdsSignedUp = [...me, ...isManager && employeeIdsByEventId[eventId] || []];
        acc[eventId].participantsData.employeeIdsConfirmed = [...isSelfInWaitlist ? [] : me, ...isManager && employeeIdsConfirmedByEventId[eventId] || []];
        acc[eventId].participantsData.employeeIdsWaitlist = [...isSelfInWaitlist ? me : [], ...isManager && employeeIdsWaitlistByEventId[eventId] || []];
      } else if (loadedExtraData && isManager && (noProfileEvents && noEmployeesEvents)) {
        acc[eventId].participantsData.employeeIdsNotSignedUp = getEmployeeIdsNotSignedUp(eventId, isSelfSignedUp);
      }

      return acc;
    }, {});

    return {
      ...courseEvents,
      loadedExtraData: !extraDataNotLoadedOrFailed,
      eventById: eventByIdExtraData,
    };
  },
);

export const selectEventWithParticipants = createSelector(
  [
    getNormalizedCourseEventsExtraData,
    selectEmployeesList,
    getNormalizedEmployeesEvents,
    getNormalizedProfileEvents,
    selectProfileId,
    getProfileData,
    selectIsManager,
    (_, eventId) => eventId,
  ],
  (courseEvents, employees, employeesEvents, profileEvents, profileId, profileData, isManager, eventId) => {
    const {eventById = {}} = courseEvents || {};
    const {byId: employeeById = {}} = employees || {};
    const {persons: eventPersons = {}} = employeesEvents || {};

    const profile = profileEvents?.eventsConfirmed?.[0]?.person || profileEvents?.eventsWaitlist?.[0]?.person || profileData;

    const getPerson = personId => personId === profileId
      ? profile
      : eventPersons[personId] || employeeById[personId];

    const event = eventById?.[eventId] || {};

    if (!event?.id) return event;

    const {
      employeeIdsNotSignedUp = [],
      employeeIdsWaitlist = [],
      employeeIdsConfirmed = [],
    } = event?.participantsData || {};

    return {
      ...event,
      ...event?.participantsData,
      employeesNotSignedUp: employeeIdsNotSignedUp.map(getPerson)?.filter(Boolean),
      employeesWaitlist: employeeIdsWaitlist.map(getPerson)?.filter(Boolean),
      employeesConfirmed: employeeIdsConfirmed.map(getPerson)?.filter(Boolean),
    };
  },
);

export const memoizedSelectEventWithParticipants = memoize(eventId => createSelector(
  [
    getNormalizedCourseEventsExtraData,
    selectEmployeesList,
    getNormalizedEmployeesEvents,
    getNormalizedProfileEvents,
    selectProfileId,
    getProfileData,
    selectIsManager,
  ],
  (courseEvents, employees, employeesEvents, profileEvents, profileId, profileData, isManager) => {
    const {eventById = {}} = courseEvents || {};
    const {byId: employeeById = {}} = employees || {};
    const {persons: eventPersons = {}} = employeesEvents || {};

    const profile = profileEvents?.eventsConfirmed?.[0]?.person || profileEvents?.eventsWaitlist?.[0]?.person || profileData;

    const getPerson = personId => personId === profileId
      ? profile
      : eventPersons[personId] || employeeById[personId];

    const event = eventById?.[eventId] || {};

    if (!event?.id) return event;

    const {
      employeeIdsNotSignedUp = [],
      employeeIdsWaitlist = [],
      employeeIdsConfirmed = [],
    } = event?.participantsData || {};

    return {
      ...event,
      ...event?.participantsData,
      employeesNotSignedUp: employeeIdsNotSignedUp.map(getPerson)?.filter(Boolean),
      employeesWaitlist: employeeIdsWaitlist.map(getPerson)?.filter(Boolean),
      employeesConfirmed: employeeIdsConfirmed.map(getPerson)?.filter(Boolean),
    };
  },
));

export const getSelectedCompetenceTypes = createSelector(
  getNormalizedSelectedCompetenceGroups,
  competencegroups => {
    const {competences} = competencegroups || {};

    if (!competences?.length) return [];

    const types = [];
    const unique = new Set();

    competences.forEach(({
      competence_type,
      competence_type_id,
      competence_type_key,
    }) => {
      if (unique.has(competence_type_id)) return;

      unique.add(competence_type_id);

      types.push({
        competence_type,
        competence_type_id,
        competence_type_key,
      });
    });

    return types;
  },
);

export const getIsFetchingCompetenceGroups = createSelector(
  getNormalizedCompetencegroups,
  competencegroups => competencegroups.status === LoadStatuses.IS_LOADING,
);

export const getCompetencesList = createSelector(
  getCompetencesSearchTerm,
  getCompetencesSearchResults,
  getNormalizedSelectedCompetenceGroups,
  getIsFetchingCompetenceGroups,
  (searchTerm, searchResults, selectedCompetencegroups, isFetching) => {
    if (searchTerm) {
      const {data, status} = searchResults;

      return {
        data: !!data && Object.values(data),
        isFetching: status === LoadStatuses.IS_LOADING,
      };
    }

    return {
      data: selectedCompetencegroups?.competences,
      isFetching,
    };
  },
);

export const getIsFetchingCourseEvents = createSelector(
  getNormalizedCourseEvents,
  courseEvents => courseEvents.status === LoadStatuses.IS_LOADING,
);

export const getAllEventsSortedByDate = createSelector(
  getNormalizedCourseEvents,
  events => {
    const {eventById, allEventIds: sortedEventIds} = events || {};

    if (!sortedEventIds?.length) return [];

    return sortedEventIds.map(id => eventById[id]);
  },
);

export const getCompetenceDetailsById = createSelector(
  getNormalizedCompetenceDetails,
  getNormalizedCourseEventsExtraData,
  // getNormalizedEmployees,
  // getProfileData,
  (_, cid) => cid,
  (competenceDetails = {}, events = {}, /* employees = {},profile,  */cid) => {
    const {data = {}} = competenceDetails;
    // const {byId: employeeById = {}} = employees;

    if (!cid || !data[cid]) return null;

    const {eventById = {}, eventIdsByCourseId = {}} = events;

    const obj = {
      ...data[cid],
      events: [],
      calendarItems: {},
    };

    if (eventIdsByCourseId[cid]) {
      eventIdsByCourseId[cid].forEach(id => {
        const event = eventById[id];

        if (!event) return;

        obj.events.push(event);

        // const personIds = event?.participantsData?.employeeIdsConfirmed || [];
        // const persons = personIds.map(id => id === profile?.person_id ? profile : employeeById[id]).filter(Boolean);

        const startDate = dayjs(event.startdate || event.startDate);

        const year = startDate.year();
        const month = startDate.month();
        const yearMonthString = `${year}-${month}`;
        const dayNumber = startDate.format('D');

        const tooltip = {
          title: event.title,
          dateStart: startDate,
          time: startDate.format('HH:mm'),
          duration: event.duration,
          id: event.id,
          competence_id: event.competence_id,
          persons: [],
          eventType: EVENT_TYPES.courseEvent,
          location: event.location,
        };

        if (!obj.calendarItems[yearMonthString]) {
          obj.calendarItems[yearMonthString] = {};
        }

        if (!obj.calendarItems[yearMonthString][dayNumber]) {
          obj.calendarItems[yearMonthString][dayNumber] = [];
        }

        obj.calendarItems[yearMonthString][dayNumber].push(tooltip);
      });
    }

    return obj;
  },
);

export const memoizedGetCompetenceDetailsById = memoize(cid => createSelector(
  getNormalizedCompetenceDetails,
  getNormalizedCourseEventsExtraData,
  (competenceDetails = {}, events = {}) => {
    const {data = {}} = competenceDetails;

    if (!cid || !data[cid]) return null;

    const {eventById = {}, eventIdsByCourseId = {}} = events;

    const obj = {
      ...data[cid],
      events: [],
      calendarItems: {},
    };

    if (eventIdsByCourseId[cid]) {
      eventIdsByCourseId[cid].forEach(id => {
        const event = eventById[id];

        if (!event) return;

        obj.events.push(event);
        const startDate = dayjs(event.startdate || event.startDate);

        const year = startDate.year();
        const month = startDate.month();
        const yearMonthString = `${year}-${month}`;
        const dayNumber = startDate.format('D');

        const tooltip = {
          title: event.title,
          dateStart: startDate,
          time: startDate.format('HH:mm'),
          duration: event.duration,
          id: event.id,
          competence_id: event.competence_id,
          persons: [],
          eventType: EVENT_TYPES.courseEvent,
          location: event.location,
        };

        if (!obj.calendarItems[yearMonthString]) {
          obj.calendarItems[yearMonthString] = {};
        }

        if (!obj.calendarItems[yearMonthString][dayNumber]) {
          obj.calendarItems[yearMonthString][dayNumber] = [];
        }

        obj.calendarItems[yearMonthString][dayNumber].push(tooltip);
      });
    }

    return obj;
  },
));

export const getCourseEventIdsByYearMonth = ({courses: {normalizedData: {courseEvents: {eventIdsByYearMonth}}}}) => eventIdsByYearMonth;

export const getCourseSignStatus = ({courses: {courseSignOn, courseSignOff}}) => ({
  isSigningOn: !!courseSignOn?.isFetching,
  isSigningOff: !!courseSignOff?.isFetching,
});

const getSigningOnIds = ({courses: {courseSignOn: {courseIds: signOnIds}}}) => signOnIds;
const getSigningOffIds = ({courses: {courseSignOff: {courseIds: signOffIds}}}) => signOffIds;

export const selectIsSigningOnOrOffCourseEventIds = createSelector(
  getSigningOnIds,
  getSigningOffIds,
  (signOnIds = [], signOffIds = []) => {
    const data = {
      on: {},
      off: {},
      all: {},
    };

    signOnIds.forEach(id => {
      data.on[id] = true;
      data.all[id] = true;
    });
    signOffIds.forEach(id => {
      data.off[id] = true;
      data.all[id] = true;
    });

    return data;
  },
);

export const getIsSigningOnOrOffCourseEvent = createSelector(
  getCourseSignStatus,
  ({isSigningOn, isSigningOff}) => isSigningOn || isSigningOff,
);

export const getCourseCatalogDefaultCompetencegroup = createSelector(
  getPropertiesForCurrLangAndTrack,
  ({courseCatalog} = {}) => courseCatalog?.defaultSelectedCompetenceGroupId,
);

export const selectEventById = createSelector(
  getNormalizedCourseEventsExtraData,
  (_, eventId) => eventId,
  (events, eventId) => events?.eventById?.[eventId] || {},
);

export const memoizedSelectEventById = memoize(eventId => createSelector(
  getNormalizedCourseEventsExtraData,
  events => events?.eventById?.[eventId] || {},
));
