import {env} from './env';

export const ENABLE_REDUX_TESTING = false;
export const ENABLE_CYPRESS_HISTORY = false;

export const backendUrl = env.NODE_ENV !== 'development' && env.REACT_APP_BACKEND_URL || '/grape';
export const backendUrlFull = env.REACT_APP_BACKEND_FULL_URL || env.REACT_APP_BACKEND_URL;
export const backendUrlV2 = env.REACT_APP_BACKEND_URL_V2;
export const backendUrlV2Full = env.REACT_APP_BACKEND_FULL_URL_V2 || env.REACT_APP_BACKEND_URL_V2;
export const nanoLearningUrl = env.NODE_ENV === 'development' ? process.env.REACT_APP_NANO_LEARNING_URL : env.REACT_APP_NANO_LEARNING_URL || '';

export const courseCatalogExcludeCategory
  = env.REACT_APP_COURSE_CATALOG_EXCLUDE || '';

export const newsWidgetStart = env.REACT_APP_NEWS_START || '';
export const version = env.REACT_APP_VERSION || ''; // is set on build to match package.json version
export const buildHash = env.REACT_APP_GIT_SHA || ''; // this is set to match sha1 of git HEAD
export const publicUrl = env.REACT_APP_PUBLIC_URL || '';
export const nanoLearningLoginUrl
  = env.REACT_APP_NANO_LEARNING_LOGIN_URL || '';

export const configFileUrl = `${backendUrl}${env.REACT_APP_CONFIG_FILE_URL}`;
export const appTitle = env.REACT_APP_TITLE || '';
export const allowedLanguages = env.REACT_APP_LANGUAGES && env.REACT_APP_LANGUAGES.split(',') || ['no', 'en'];
export const combinedLogin = env.REACT_APP_ONE_LOGIN && env.REACT_APP_ONE_LOGIN;

export const helpPagesUrl =  env.REACT_APP_HELP_PAGES_URL;
export const helpPagesStartPath =  env.REACT_APP_HELP_PAGES_START_PATH;
export const helpPagesSearchUrl =  env.REACT_APP_HELP_PAGES_SEARCHURL;

export const supportUrl = env.REACT_APP_SUPPORT_URL;
export const tenant = env.REACT_APP_TENANT;
