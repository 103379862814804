import {mapDataToCalendarItems} from '@routes/employees/util';

export const getPersonCalendarItems = ({
  expiringData,
  eventsData,
}) => mapDataToCalendarItems([
  {
    eventType: 'expiringCompetence',
    data: expiringData,
    keys: {
      dateStart: 'valid_until',
      dateEnd: 'valid_until',
      id: 'competence_id',
      title: 'competence_title',
    },
  },
  {
    eventType: 'personEvent',
    data: eventsData,
    keys: {
      dateStart: 'startdate',
      dateEnd: 'enddate',
      id: 'id',
      title: 'title',
    },
    options: {
      hideDeadlines: true,
      hideEnding: true,
    },
  },
]);

export const sortRoles = data => {
  const roles = [];
  const hidden = [];
  const complete = [];

  if (!data?.length) return {
    roles,
    hidden,
    complete,
  };

  let allReq = data[0].passed_count + data[0].missing_count;
  let allMissing = data[0].missing_count;

  if (data[0].missing_count > 0) {
    roles.push(data[0]);
  } else {
    complete.push(data[0]);
  }

  data
    .slice(1)
    .sort((a, b) => {
      if (a.missing_count === b.missing_count) {
        return a.title > b.title ? 1 : -1;
      }

      return b.missing_count - a.missing_count;
    })
    .forEach((role, i) => {
      allReq = allReq + role.passed_count + role.missing_count;
      allMissing += role.missing_count;

      if (role.missing_count === 0) {
        complete.push(role);
      } else if (i < 8) {
        roles.push(role);
      } else {
        hidden.push(role);
      }
    });

  const progress = allMissing === 0 ? 0 : 100 - allMissing / allReq * 100;

  return {
    progress,
    roles,
    hidden,
    complete,
  };
};

export const eventsPartitionWaitlistConfirmed = events => {
  const waitlist = [];
  const confirmed = [];

  events?.forEach?.(event => {
    if (event?.waitlist === true) {
      waitlist.push(event);
    } else if (event?.waitlist === false) {
      confirmed.push(event);
    }
  });

  return {
    waitlist,
    confirmed,
  };
};
