import React, {Component, useEffect} from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import {useClearCacheCtx} from 'react-clear-cache';
import {connect, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {createSelector, createStructuredSelector} from 'reselect';
import {
  authGetAuthStatusAndStartLoading,
  authLogoutRequest,
  authStartLoginCheckOnInterval,
} from '@actions/auth.actions';
import {routerAppComponentDidMount} from '@actions/router.actions';
import CourseLoader from '@components/loading/courseLoader';
import Loading from '@components/loading/loading';
import {LoadingLine} from '@components/loading/loading-line';
import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import {allowedLanguages, buildHash, ENABLE_CYPRESS_HISTORY, nanoLearningLoginUrl, version} from '@config';
import {css} from '@emotion/react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import {faAt} from '@fortawesome/pro-solid-svg-icons/faAt';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faCalendarAlt} from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faCog} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faFileExcel} from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import {faHourglass} from '@fortawesome/pro-solid-svg-icons/faHourglass';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserCheck} from '@fortawesome/pro-solid-svg-icons/faUserCheck';
import LoadableAdminSwitch from '@routes/admin';
import {PrivateRoute} from '@routes/auth';
import LoadableCourseCatalogSwitch from '@routes/course-catalog';
import {Dummy} from '@routes/dummy/dummy';
import LoadableEmployeesContainer from '@routes/employees';
import LoadableLearningPathContainer from '@routes/learning-path';
import LoadableReportSwitch from '@routes/reports';
import {
  atlas as AtlasRoutes,
  courseCatalog as courseCatalogRoutes,
  dashboard,
  employees as employeesRoutes,
  help as helpRoutes,
  learningPath,
  logout as logoutRoute,
  myEducation as myEducationRoutes,
  onboarding,
} from '@routes/routes.manifest';
import {getAuthStatus, getStartUrl} from '@selectors/auth.selectors';
import {getConfigObject, getIsConfigLoaded} from '@selectors/config.selectors';
import {getActiveCourse} from '@selectors/courses.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {getIsMapCompleted, getLayoutClassname} from '@selectors/map.selectors.new';
import {
  getIsSuperuser,
  selectIsManager,
  selectProfileFullname,
  selectProfileId,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import {i18n} from '@src/i18n';
import {IdentityWarning} from '@styles/mixins';
import {loadableRetry} from '@utils/loadable-retry';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/misc.utils';
import {DebugPanel} from './features/debug';
import {ModalsRenderer} from './features/modals/modals-renderer';
import {WithEmployeesModule} from './store-normalized/module-injector/with-employees-module';
import {ResponsiveStyles} from './styles/responsive-styles';
import {DefaultRedirectRoute} from './default-redirect';
import '@styles/app.scss';
import '@styles/foundation.min.css';
import 'dayjs/locale/nb';
import 'focus-visible';

dayjs.extend(weekOfYear);

library.add(
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faAngleRight,
  faCog,
  faAt,
  faRedo,
  faTimes,
  faCalendarAlt,
  faPlay,
  faArrowRight,
  faSearch,
  faBars,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel,
  faCheck,
);

const selectAlert = ({alert}) => alert;

const selectAppLoading = createSelector(
  selectAlert,
  getConfigObject,
  (alert = {}, configObject = {}) => {
    const indicatorVariant = configObject?.getProperty?.('params.reloading-indicator-variant');
    const isReloading = alert?.type === 'alert-reloading';

    if (!isReloading) return null;

    return {
      indicatorVariant,
      isReloading,
    };
  },
);

const AppLoading = React.memo(() => {
  const loading = useSelector(selectAppLoading);

  if (!loading) return null;

  return loading.indicatorVariant === 'fullscreen'
    ? <CourseLoader />
    : <Loading />;
});

// makes history from react-router visible from outside
// this can example be used to change url without reloading the page
const CypressHistorySupport = () => {
  const history = useHistory();

  useEffect(() => {
    window.cyHistory = history;
  }, []);

  useEffect(() => {
    window.cyHistory = history;
  }, [history]);

  return null;
};

const enableCyHistory = process.env && process.env.NODE_ENV !== 'production' && ENABLE_CYPRESS_HISTORY;
const CyHistory = enableCyHistory
  ? <CypressHistorySupport />
  : null;

const CvContainer = loadableRetry(() => {
  console.log('load cv.container');

  return import(/* webpackChunkName: "cv.container" */ './routes/my-education/containers/my-education-cv/cv-container');
}, {name: 'cv.container'});

const LoginContainer = loadableRetry(() => {
  console.log('load login.container');

  return import(/* webpackChunkName: "login.container" */ './routes/auth/containers/login-container');
}, {
  name: 'login.container',
  fallback: <LoadingLine />,
});

const HelpContainer = loadableRetry(() => {
  console.log('load help.container');

  return import(/* webpackChunkName: "help.container" */ './routes/help/containers/help-container/help-container');
}, {name: 'help.container'});

const MessageCheck = loadableRetry(() => {
  console.log('load message.check');

  return import(/* webpackChunkName: "message.check" */ './common/components/message-check/message-check');
}, {name: 'message.check'});

const MyEducationSwitch = loadableRetry(() => {
  console.log('load myEducation.switch');

  return import(/* webpackChunkName: "my.education" */ './routes/my-education/my-education-switch');
}, {
  name: 'my.education',
  fallback: <LoadingLine />,
});

const NanoLearningPlayerContainer = loadableRetry(() => {
  console.log('load nano.player');

  return import(/* webpackChunkName: "nano.player" */ './routes/nano-learning/containers/nano-learning-player-container');
}, {name: 'nano.player'});

const CoursePlayer = loadableRetry(() => {
  console.log('load course.player');

  return import(/* webpackChunkName: "course.player" */ './routes/course-catalog/components/course-player/course-player');
}, {name: 'course.player'});

const Atlas = loadableRetry(() => {
  console.log('load Atlas');

  return import(/* webpackChunkName: "atlas" */ './routes/atlas/index');
}, {
  name: 'atlas',
  fallback: <LoadingLine />,
});

const employeesContainerRoutes = [...Object.values(employeesRoutes), dashboard.userPreview];
const cvContainerRoutes = [myEducationRoutes.cvEdit.path, myEducationRoutes.cvAdd.path];
const learningPathRoutes = [learningPath.track.path, onboarding.track.path, onboarding.main.path, learningPath.main.path];
const _helpRoutes = [helpRoutes.search, helpRoutes.item, helpRoutes.startpage];

const setLanguage = () => {
  const langCodeNavigator = getTwoletterLangCodeFromNavigatorLanguage();
  let locale = localStorage.getItem('language');

  if (langCodeNavigator && !locale) {
    locale = langCodeNavigator;
  }

  if (!allowedLanguages.includes(locale)) {
    locale = allowedLanguages[0];
  }

  localStorage.setItem('language', locale);

  const dayjsLocale = {
    no: 'nb',
    en: 'en-us',
  }[locale];

  dayjs.extend(customParseFormat);
  dayjs.locale(dayjsLocale);
};

const handleToggleParams = () => {
  const isSetMapLocation
    = window.location.pathname.includes('/set-atlas');
  const isLoadConfig
    = window.location.pathname.includes('/load-config');
  const params = new URL(document.location).searchParams;
  const langId = params.get('lang');

  if (isSetMapLocation) {
    const track = window.location.pathname.split('/')[2];

    localStorage.setItem('track', track);
    localStorage.setItem('language', langId || 'no');
    Object.entries(localStorage).forEach(([k, v]) => {
      if (/^track-data\/\d+$/.test(k)) {
        localStorage.removeItem(k);
      }
    });
  }

  if (isLoadConfig) {
    const config = window.location.pathname.split('/')[2];

    localStorage.setItem('config', config);
    localStorage.setItem('track', config);
    localStorage.removeItem('learningportalConfig');
    window.location = '/';
  }
};

const CacheBuster = ({children}) => {
  const {isLatestVersion, emptyCacheStorage} = useClearCacheCtx();

  useEffect(() => {
    if (!isLatestVersion) {
      localStorage.removeItem('learningportalConfig');
      emptyCacheStorage();
      window.location.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLatestVersion]);

  return isLatestVersion && children
    ? children
    : null;
};

class App extends Component {
  componentDidMount() {
    const {authGetAuthStatusAndStartLoading, pollAuthStatus} = this.props;

    setLanguage();

    if (buildHash || version) {
      // eslint-disable-next-line no-console
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }

    // the process is like this:
    // 1. check if the user is logged in or not, display loading while we wait
    // 2. if not logged in: display login, else: continue to step 3
    // 3. get config, display loading while we wait
    // 4. when the config is done loading, load the rest
    authGetAuthStatusAndStartLoading();

    // check if the user is still logged in
    pollAuthStatus({intervalMs: 1000 * 60 * 60});
  }

  render() {
    const {
      sessionId,
      activeCourse,
      secondLogin,
      authStatus,
      pageLayout,
      isManager,
      isSuperuser,
      loadedConfig,
      profilePersonId,
      profileFullname,
      profileUserName,
      profileError,
      logout,
    } = this.props;

    handleToggleParams();

    if (
      !Cookies.get('tg-visit')
      || !sessionId
      || sessionId === 'sd'
      || secondLogin
      || profileError
      || authStatus.isLoggedIn === false
    ) {
      return (
        <>
          {CyHistory}
          <CacheBuster />
          <div className={`app-wrapper menu-two ${pageLayout}`}>
            {nanoLearningLoginUrl
              ? <LoginContainer to={nanoLearningLoginUrl} />
              : (
                <>
                  <LoginContainer />
                  {!window.location.pathname.includes('login') && !window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to={window.location.pathname !== 'login' && '/login?redirect=' + window.location.pathname}
                    />
                  ) || window.location.pathname.includes('logout') && (
                    <Redirect
                      from="*"
                      to="/login"
                    />
                  )}
                </>
              )}
            <Notifications />
          </div>
        </>
      );
    }

    const isReady = loadedConfig && !!authStatus.isLoggedIn && !!profileUserName;

    if (!isReady) return <CacheBuster><CourseLoader appload /></CacheBuster>;

    return (
      <>
        <>
          {CyHistory}
          <CacheBuster />
          <DebugPanel />
          <MessageCheck />
          {!!activeCourse && activeCourse.type === 24
            ? <NanoLearningPlayerContainer courseId={activeCourse.cid} />
            : (
              <ResponsiveStyles>
                <div
                  key="portal-above"
                  id="portal-above"
                >
                  {(isManager || isSuperuser) && <WithEmployeesModule><ModalsRenderer accessLevel={3} /></WithEmployeesModule>}
                  <ModalsRenderer accessLevel={0} />
                </div>
                <div
                  key="side-modal"
                  id="side-modal"
                />
                <div
                  key="persons-container"
                  id="persons-container"
                />
                {/* Routes shown above other content */}
                <Route
                  path={cvContainerRoutes}
                  component={CvContainer}
                  breadcrumbs
                />
                <div className="app-wrapper__menu">
                  <Menu />
                </div>
                {!!activeCourse?.cid && activeCourse.status !== 0
                  ? (
                    <div className="app-wrapper__content fullscreen">
                      <CoursePlayer />
                    </div>
                  )
                  : (
                    <div className="app-wrapper__content">
                      <Switch>
                        {(!Cookies.get('tg-visit') || !!profileError) && <LoginContainer />}
                        <Route
                          path={logoutRoute}
                          render={() => {
                            logout();

                            return <Redirect to="/login" />;
                          }}
                        />
                        <PrivateRoute
                          path={'/admin/*'}
                          component={LoadableAdminSwitch}
                        />
                        <PrivateRoute
                          path={'/dashboard/*'}
                          component={LoadableReportSwitch}
                        />
                        <PrivateRoute
                          path={courseCatalogRoutes.main.path}
                          component={LoadableCourseCatalogSwitch}
                        />
                        <PrivateRoute
                          path={myEducationRoutes.main.path}
                          component={MyEducationSwitch}
                        />
                        <PrivateRoute
                          path={employeesContainerRoutes}
                          component={LoadableEmployeesContainer}
                        />
                        <PrivateRoute
                          path={AtlasRoutes.main.path}
                          component={Atlas}
                        />
                        <PrivateRoute
                          path={learningPathRoutes}
                          component={LoadableLearningPathContainer}
                        />
                        <PrivateRoute
                          path={_helpRoutes}
                          component={HelpContainer}
                        />
                        <PrivateRoute
                          path="/dummy"
                          component={Dummy}
                        />
                        <DefaultRedirectRoute />
                      </Switch>
                    </div>
                  )}
                <Notifications />
                <AppLoading />
              </ResponsiveStyles>
            )}
        </>

        {Boolean(localStorage.getItem('bart') && profilePersonId?.toString?.() === localStorage.getItem('bart')) && (
          <IdentityWarning>
            {i18n('person.you-have-now')}
            <strong css={css({marginLeft: '.4em'})}>{profileFullname}</strong>
          </IdentityWarning>
        )}
      </>
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  isMapCompleted: PropTypes.any,
  authStatus: PropTypes.shape({}).isRequired,
  startUrl: PropTypes.string,
  authGetAuthStatusAndStartLoading: PropTypes.func.isRequired,
  pollAuthStatus: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
  startUrl: null,
  isMapCompleted: null,
};

const selectSessionId = ({auth: {sessionId} = {}} = {}) => sessionId;
const selectSecondLogin = ({auth: {secondLogin} = {}} = {}) => secondLogin;
const selectProfileError = ({profile: {error} = {}} = {}) => error;

const mapStateToProps = createStructuredSelector({
  sessionId: selectSessionId,
  secondLogin: selectSecondLogin,
  authStatus: getAuthStatus,
  activeCourse: getActiveCourse,
  pageLayout: getLayoutClassname,
  isManager: selectIsManager,
  isSuperuser: getIsSuperuser,
  isMobile: getIsMobile,
  isMapCompleted: getIsMapCompleted,
  startUrl: getStartUrl,
  loadedConfig: getIsConfigLoaded,

  profilePersonId: selectProfileId,
  profileFullname: selectProfileFullname,
  profileUserName: selectProfileUserName,
  profileError: selectProfileError,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  routerAppComponentDidMount,
  authGetAuthStatusAndStartLoading,
  logout: authLogoutRequest,
  pollAuthStatus: authStartLoginCheckOnInterval,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
