import {select, take} from 'redux-saga/effects';
import {appInjectReduxModule, selectReduxModuleLoadStatuses} from '@actions/app.actions';
import {authUserIsConfirmedLoggedIn} from '@actions/auth.actions';
import {
  profileFetchPerson,
  // profileFetchPerson,
  profileFetchPersonFull,
  profileSetActiveOrgId,
} from '@actions/profile.actions';
import {userSetPartial, userSuccess} from '@actions/user.actions';
import {
  getOrganisationId,
  getProfile,
  selectProfileId,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import {LoadStatuses} from '@types/load.types';

const __IS_DEV__ = process?.env?.NODE_ENV === 'development';

// common util sagas

export function* waitForInjectedReduxModule(moduleId) {
  const statuses = yield select(selectReduxModuleLoadStatuses);

  if (__IS_DEV__ && (!moduleId || !statuses.hasOwnProperty(moduleId))) {
    throw new Error(`Status for redux-module ${moduleId} not found`);
  }

  while (statuses[moduleId] !== LoadStatuses.LOADED) {
    const action = yield take(`${appInjectReduxModule.success}`);

    if (action?.payload === moduleId) return true;
  }

  return true;
}

export function* waitForProfile(returnProfile) {
  let profile = yield select(getProfile);

  if (profile?.data?.person_id != null) return returnProfile ? profile : null;

  while (true) {
    yield take([
      `${profileFetchPersonFull.success}`,
      `${profileFetchPerson.success}`,
    ]);
    profile = yield select(getProfile);

    if (profile?.data?.person_id) return returnProfile ? profile : null;
  }
}

export function* waitForOrgId(returnOrgId = true) {
  let orgId = yield select(getOrganisationId);

  if (orgId) return returnOrgId ? orgId : null;

  while (true) {
    yield take(`${profileSetActiveOrgId}`);
    orgId = yield select(getOrganisationId);

    if (orgId) return returnOrgId ? orgId : null;
  }
}

export function* waitForUsername() {
  const currentUsername = yield select(selectProfileUserName);

  if (currentUsername) return currentUsername;

  while (true) {
    const action = yield take([
      `${authUserIsConfirmedLoggedIn().type}`,
      `${userSuccess().type}`,
      `${userSetPartial().type}`,
      `${profileFetchPerson.success}`,
      `${profileFetchPersonFull.success}`,
    ]);

    const payloadUsername = action?.payload?.username || action?.payload?.authProfile?.user_name;

    //
    if (payloadUsername) return payloadUsername;

    const username = yield select(selectProfileUserName);

    if (username) return username;
  }
}

export function* waitForProfileId() {
  const currentPid = yield select(selectProfileId);

  if (currentPid) return currentPid;

  while (true) {
    const action = yield take([
        `${userSuccess().type}`,
        `${userSetPartial().type}`,
        `${profileFetchPerson.success}`,
        `${profileFetchPersonFull.success}`,
    ]);

    if (action?.payload?.person_id) return action.payload.person_id;
    const personId = yield select(selectProfileId);

    if (personId) return personId;
  }
}
