import dayjs from 'dayjs';
import {css} from '@emotion/react';
import {i18n, i18ndayjs} from '@src/i18n';
import {parseDate} from '@utils/misc.utils';

export const requiredArgs = ['data', 'keys', 'eventType'];

export const EVENT_TYPES = {
  personEvent: 'personEvent',
  expiringCompetence: 'expiringCompetence',
  deadline: 'deadline',
  activity: 'activity',
  courseEvent: 'courseEvent',
};

// this defines the order of the subgroups
export const EVENT_GROUPINGS = [
  EVENT_TYPES.personEvent,
  EVENT_TYPES.expiringCompetence,
  EVENT_TYPES.deadline,
  EVENT_TYPES.activity,
  EVENT_TYPES.courseEvent,
];

export const isValidEventType = eventType => EVENT_TYPES.hasOwnProperty(eventType);

// linktype: 'wrapper' | 'button' | 'title'
// return: {[linktype]: {onClick?: () => {}, url?: string, text?: string, jsx?: JSX.Element}}
export const tooltipItemOptions = {
  [EVENT_TYPES.courseEvent]: {
    getMetaText: item => item?.location || 'Online',
    showSignOnButton: true,
    showParticipants: false,
  },
  [EVENT_TYPES.personEvent]: {
    getLinks: item => {
      const {
        id: eventId,
        data: {competence_id} = {},
      } = item || {};

      if (!competence_id && !eventId) return null;

      return {
        button: {
          url: `/course-catalog/${competence_id || eventId}`,
          text: i18n('globals.open'),
        },
      };
    },
    openModalOnPersonClick: true,
    getMetaText: item => item?.location || item?.data?.location?.title || 'Online',
    children: ({item, profileId}) => {
      const {persons = [], data = null} = item || {};

      if (!data || !persons.length) return null;

      const selfSigned = persons?.find?.(({person_id}) => person_id === profileId);
      const onlySelfSigned = selfSigned && persons.length === 1;

      if (onlySelfSigned) {
        return (
          <div
            css={css({
              color: '#1a1a1a',
              fontWeight: 400,
              fontSize: '14px',
              marginTop: '-.25rem',
            })}
          >
            {i18n('course.confirmed-text')}
            {' '}
            {data?.waitlist ? ' venteliste' : ''}
          </div>
        );
      }

      return null;
    },
  },
  [EVENT_TYPES.expiringCompetence]: {
    getLinks: item => item?.data?.competence_id && {title: {url: `/course-catalog/${item.data.competence_id}`}},
    getMetaText: item => {
      const {competence_type: {title} = {}} = item?.data || {};

      return title || null;
    },
    openModalOnPersonClick: true,
  },
};

const defaultGetEventSubgroupings = eventType => items => {
  if (!isValidEventType(eventType) || !items?.length) return [];

  const grouped = {};

  items.forEach(item => {
    const {
      id = null,
      data: {person = null} = {},
    } = item;

    if (!id) return;

    if (grouped[id]) {
      if (!person) return;

      grouped[id].persons.push(person);
    } else {
      grouped[id] = {
        ...item,
        persons: person ? [person] : [],
        eventType,
      };
    }
  });

  return Object.entries(grouped).map(([id, values]) => ({
    ...values,
    id,
  }));
};

const getPersonEventsSubgroups = defaultGetEventSubgroupings(EVENT_TYPES.personEvent);
const getExpiringCompetencesSubgroups = defaultGetEventSubgroupings(EVENT_TYPES.expiringCompetence);

const getSubgroupings = (items, type) => {
  switch (type) {
  case 'personEvent': {
    return getPersonEventsSubgroups(items);
  }
  case 'courseEvent': {
    return items;
  }
  case 'expiringCompetence': {
    return getExpiringCompetencesSubgroups(items);
  }
  case 'deadline': {
    return {};
  }
  case 'activity': {
    return {};
  }
  default: {
    return {};
  }
  }
};

export const getEventGroupTitle = (type, plural, dateFlag) => {
  switch (type) {
  case 'personEvent': {
    return i18n(`competence.personEvent${plural ? 's' : ''}${dateFlag}`);
  }
  case 'courseEvent': {
    return i18n(`competence.personEvent${plural ? 's' : ''}${dateFlag}`);
  }
  case 'expiringCompetence': {
    return i18n(`competence.expiringCompetence${plural ? 's' : ''}${dateFlag}`);
  }
  default: {
    return '';
  }
  }
};

export const getDateFlag = date => {
  const isToday = date.isSame(dayjs(), 'day');
  const isPassed = !isToday && date.isBefore(dayjs());

  const dateFlag = isToday ? '--today' : isPassed ? '--passed' : '--future';

  return dateFlag;
};

export const getGroupedTooltipItems = items => {
  const eventGroups = EVENT_GROUPINGS.reduce((acc, group) => {
    acc[group] = [];

    return acc;
  }, {});

  items?.forEach(item => {
    if (!Object.keys(eventGroups).includes(item?.eventType)) {
      return;
    }

    const date = i18ndayjs(item?.dateStart || item?.startDate || item?.date || item?.startdate);
    const dateFlag = getDateFlag(date);

    const {
      getTitle,
      getLinks,
      getMetaText,
      openModalOnPersonClick,
      showSignOnButton,
      children,
    } = tooltipItemOptions[item.eventType] || {};

    const tooltipItem = {
      ...item,
      date,
      dateFlag,
      title: item?.title || getTitle?.(item),
      links: getLinks?.(item),
      metaText: getMetaText?.(item),
      openModalOnPersonClick,
      showSignOnButton,
      children: typeof children === 'function'
        ? children({item})
        : children || null,
    };

    eventGroups[item.eventType].push(tooltipItem);
  });

  return EVENT_GROUPINGS.reduce((acc, type) => {
    const items = eventGroups[type];

    if (!items?.length) return acc;

    const {dateFlag, date} = items[0] || {};

    acc.push({
      type,
      title: getEventGroupTitle(type, items.length > 1, dateFlag),
      items: getSubgroupings(items, type),
      date,
      dateFlag,
    });

    return acc;
  }, []);
};

export const isObject = variable => !!variable && typeof variable === 'object' && !Array.isArray(variable);

export const isValidArgObj = obj => isObject(obj) && requiredArgs.every(key => Object.keys(obj).includes(key));

export const dateToYearMonthStr = date => {
  const [year, month] = [date?.getFullYear?.(), date?.getMonth?.()];

  return year != null && month != null
    ? [year, month].join('-')
    : null;
};

export const isSameDate = (date1, date2) => date1.getDate() === date2.getDate()
  && date1.getMonth() === date2.getMonth()
  && date1.getFullYear() === date2.getFullYear();

export const partitionDate = date => {
  if (date instanceof Date) {
    return {
      date,
      dayNumber: date.getDate(),
      yearMonthStr: dateToYearMonthStr(date),
    };
  }

  return {
    date: null,
    dayNumber: null,
    yearMonthStr: null,
  };
};

export const getItem = (item, keys) => {
  const [dateStart, dateEnd] = [
    parseDate(item?.[keys?.dateStart]),
    parseDate(item?.[keys?.dateEnd]),
  ];

  return {
    dateStart,
    dateEnd,
    title: item?.[keys?.title],
    id: item?.[keys?.id],
    location: item?.[keys?.location],
  };
};

export const getCalendarDays = ({month, year}) => {
  const date = new Date(year, Number.parseInt(month, 0), '01');
  const mydays = [];
  const mystart = [];

  const start_date = new Date(date);
  const today = new Date();

  if (start_date.getDay() !== 1) {
    while (start_date.getDay() !== 1) {
      start_date.setDate(start_date.getDate() - 1);
      mystart.push({
        date: start_date.getDate(),
        today: false,
        day: start_date.getDay(),
        month: false,
      });
    }
  }

  let myweeks = mystart.reverse();

  while (date.getMonth() === Number.parseInt(month, 0)) {
    if (date.getDay() === 1) {
      mydays.push(myweeks);
      myweeks = [];
      myweeks.push({
        date: date.getDate(),
        day: date.getDay(),
        today: isSameDate(date, today),
        month: true,
      });
    } else {
      myweeks.push({
        date: date.getDate(),
        today: isSameDate(date, today),
        month: true,
      });
    }
    date.setDate(date.getDate() + 1);
  }

  if (date.getDay() !== 0) {
    while (date.getDay() !== 1) {
      myweeks.push({
        date: date.getDate(),
        today: false,
        day: date.getDay(),
        month: false,
      });
      date.setDate(date.getDate() + 1);
    }
  }
  mydays.push(myweeks);

  return mydays;
};

export const getBackgroundColor = items => {
  // temp
  if (items?.length) {
    return items
      .some(it => it.eventType === EVENT_TYPES.expiringCompetence)
      ? '#FFECEC'
      : '#EFEFEF';
  }

  return null;
};

export const getBorderWidth = ({
  today,
  hasItems,
  disabled,
}) => {
  if (disabled) return 0;

  if (hasItems || today) {
    return today ? 2 : 1;
  }

  return 1;
};

export const getBorderColor = ({
  today,
  hasItems,
  backgroundColor,
}) => !!today && '#181818' || !!hasItems && backgroundColor || '#DEDDDB';

export const getOutline = ({
  today,
  hasItems,
  disabled,
  backgroundColor,
}) => {
  const width = getBorderWidth({
    today,
    hasItems,
    disabled,
  });
  const color = getBorderColor({
    today,
    hasItems,
    backgroundColor,
  });

  return `0 0 0 ${width}px ${color}`;
};

export const getTime = date => date?.toTimeString?.()?.split(' ')?.[0]?.slice?.(0, 5);
