import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {competencesToggle} from '@actions/competences.actions';
import completed from '@assets/svg/completed.svg';
import {FilesList} from '@components/files-list/files-list';
import {LoadingSpinnerMinimalSmall} from '@components/loading/loading-spinner';
import {css, useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {selectToggleCompetenceProgress} from '@selectors/map.selectors.new';
import {selectIsManager} from '@selectors/profile.selectors';
import {selectLearningPathTrackExtraData} from '@selectors/tracks.selectors';
import {CheckboxControlled, Tooltip} from '@snapper/core';
import {validNumberOrNull} from '@src/features/modals/components/common-modals/checklist-modal';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';

const CheckRaw = {
  both: 'both',
  either: 'either',
  manager: 'manager',
  self: 'self',
};

// when manager checks self:
// if check_raw === 'both': one request as manager and one as user (self) - (50% each) (?) (not sure)
// if check_raw === 'either: one request as user (self) (100%)
// if check_raw === 'manager': one request as manager (100%)
// if check_raw === 'self': one request as user (self) (100%)

// when manager checks employee:
// if check_raw === 'both': one request as manager (50%)
// if check_raw === 'either: one request as manager (100%)
// if check_raw === 'manager': one request as manager (100%)
// if check_raw === 'self': no action

// when employee checks self:
// if check_raw === 'both': one request as user (self) (50%)
// if check_raw === 'either: one request as user (self) (100%)
// if check_raw === 'manager': no action
// if check_raw === 'self': one request as user (self) (100%)

// http://localhost:4001/grape/reports/personfullcompetencereportjson/6?fields=id,date,competence_title,competence_type,valid_until,competence_date,competence(id,files)

const Wrapper = styled.div`
  .status-wrapper {
    margin-right: .5rem;

    .spinner-wrapper {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      transition: opacity 0.3s ease-in-out;
      margin-top: 4px;
    }

    &.busy {
      pointer-events: none;

      .checkbox__outer-wrapper {
        opacity: 0;
      }

      .spinner-wrapper {
        opacity: 1;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .status-text-wrapper {
    span {
      font-size: .9rem;
      display: block;
      color: #565656;

      &:first-of-type {
        margin-top: 4px;
      }
    }
  }
`;

const doneIcon = (
  <img
    src={completed}
    alt="all-ok"
    style={{width: 20}}
  />
);

const checkboxWrapperStyle = {
  marginLeft: '-.75rem',
  marginRight: '-.5rem',
  position: 'relative',
};

const CheckboxWithTooltip = ({
  progress,
  onCheck,
}) => {
  const {scheme: {primary = {}} = {}} = useTheme();

  return (
    <Tooltip
      text={i18n('globals.approve')}
      alignCenter
      alignTop
      onHover
    >
      <div
        style={checkboxWrapperStyle}
        className="checkbox-wrapper"
      >
        <div className="spinner-wrapper">
          <LoadingSpinnerMinimalSmall
            color={primary.color700}
            backgroundColor="#fff"
            trackColor={primary.color50}
          />
        </div>
        <CheckboxControlled
          checked={progress >= 50}
          onClick={onCheck}
        />
      </div>
    </Tooltip>
  );
};

export const TrackChecklistItem = ({
  competence,
  trackId: propsTrackId,
  mapId: propsMapId,
  roleId: propsRoleId,
  personId: propsPersonId,
}) => {
  const dispatch = useDispatch();

  const {childrenById: competenceExtraDataById = {}} = useSelector(selectLearningPathTrackExtraData);
  const toggleCompetenceProgress = useSelector(selectToggleCompetenceProgress);

  const {checklist = {}, competence_id, passed} = competence || {};
  const {
    check_by_manager, // should be checked by manager (bool)
    check_by_user, // should be checked by user (bool)
    check_raw, // string enum (both | either | manager | self)
    is_check_manager, // checked by manager (date string)
    is_check_user, // checked by manager (date string)
    is_check_manager_name,
  } = checklist || {};

  const extraData = competenceExtraDataById[competence_id] || {};
  const {checklist: checklistExtraData = {}} = extraData || {};

  const checkRaw = check_raw || checklistExtraData.check_raw;

  const isManager = useSelector(selectIsManager);
  const managerName = is_check_manager_name || checklistExtraData.is_check_manager_name;
  const isCheckedByManager = is_check_manager || checklistExtraData.is_check_manager;
  const isCheckedByUser = is_check_user || checklistExtraData.is_check_user;

  const isBusy = toggleCompetenceProgress[competence_id];

  const isWaitingForManager = !isManager && checkRaw === CheckRaw.both && isCheckedByUser && !isCheckedByManager
    || !isManager && checkRaw === CheckRaw.manager && !isCheckedByManager;

  const [progress, setProgress] = useState(() => {
    if (checkRaw === CheckRaw.both) {
      return isCheckedByUser && isCheckedByManager
        ? 100
        : isCheckedByUser || isCheckedByManager
          ? 50
          : 0;
    }

    if (checkRaw === CheckRaw.either) {
      return isCheckedByUser || isCheckedByManager ? 100 : 0;
    }

    if (checkRaw === CheckRaw.manager) {
      return isCheckedByManager ? 100 : 0;
    }

    if (checkRaw === CheckRaw.self) {
      return isCheckedByUser ? 100 : 0;
    }

    return 0;
  });

  const {
    id: paramsId,
    mapId: paramsChildId,
  } = useParams();

  const paramsTrackId = paramsChildId || paramsId;
  const paramsParentId = paramsChildId
    ? paramsId
    : null;

  const trackId = validNumberOrNull(paramsTrackId || propsTrackId);
  const parentId = paramsParentId == null
    ? null
    : Number(paramsParentId);

  const mapId = validNumberOrNull(propsMapId || paramsTrackId);
  const roleId = validNumberOrNull(propsRoleId);
  const personId = validNumberOrNull(propsPersonId);

  const handleCheck = useCallback(() => {
    if (progress === 100 || isWaitingForManager) return;

    let newProgress = isManager ? 100 : 50;

    if (!isManager) {
      switch (checkRaw) {
      case CheckRaw.self:
      case CheckRaw.either: {
        newProgress = 100;

        break;
      }
      case CheckRaw.both: {
        newProgress = isCheckedByManager ? 100 : 50;

        break;
      }
      case CheckRaw.manager: {
        newProgress = 0;

        break;
      }
    // No default
      }
    }

    dispatch(competencesToggle({
      cid: competence_id,
      trackId,
      parentId,
      checkRaw,
      onSuccess: () => {
        setProgress(newProgress);
      },
      onError: () => {
        // setIsBusy(false);
      },
    }));
  }, [competence_id, dispatch, progress, parentId, trackId, isManager, checkRaw, isCheckedByManager, isWaitingForManager]);

  if (!competence) return null;
  if (!trackId && !mapId && !roleId && !personId) return null;

  return (
    <Wrapper
      className={classNames('competence-card', 'employee', {
        checked: progress === 100,
        disabled: isWaitingForManager,
        busy: isBusy,
      })}
    >
      <div className="grid-x">
        <div className="small-12 cell">
          <div className="roles-list__competence">
            <label className="roles-list__competence-title">
              <div
                className={classNames(
                  'status-wrapper',
                  'roles-list__competence-status',
                  {
                    disabled: isWaitingForManager,
                    busy: isBusy,
                  },
                )}
              >
                {progress === 100
                  ? doneIcon
                  : (
                    <CheckboxWithTooltip
                      onCheck={handleCheck}
                      progress={progress}
                    />
                  )}
              </div>
              <div className="grid-x">
                <div className="cell small-12 medium-8 info-wrapper">
                  <div className="small-header">
                    {i18nCourseGetCompetenceType(competence)}
                  </div>
                  {competence?.competence_title}
                </div>
                {!!competence?.files?.length && (
                  <FilesList
                    className="files-list"
                    files={competence.files.map(({title, url}) => ({
                      title,
                      url,
                    }))}
                    wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                  />
                )}
                {competence?.passed === 100 && !!competence?.grade && (
                  <div className="cell small-12 medium-4">
                    <div className="course-grade">
                      {i18n('globals.grade')}
                      :
                      {' '}
                      {competence.grade}
                    </div>
                  </div>
                )}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="status-text-wrapper">
        {isCheckedByUser && !isWaitingForManager || isCheckedByManager && isManager
          ? (
            <span>
              Du godkjente
              {' '}
              {dayjs(isCheckedByUser || isCheckedByManager, 'DD.MM.YYYY HH:mm').format('D. MMM YYYY')}
            </span>
          )
          : null}
        {isCheckedByManager && !isManager
          ? (
            <span>
              Godkjent av
              {' '}
              {managerName}
              {' '}
              -
              {dayjs(isCheckedByManager, 'DD.MM.YYYY HH:mm').format('D. MMM YYYY')}
            </span>
          )
          : null}
        {!isManager && isWaitingForManager
          ? (
            <span>
              {isCheckedByUser
                ? 'Du er ferdig, venter på godkjenning av leder'
                : 'Dette punktet må godkjennes av en leder'}
            </span>
          )
          : null}
      </div>
    </Wrapper>
  );
};
