import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import get from 'lodash/get';
import {getCompetenceType} from '@src/store/util/competence-identity';
import {isStringWithLength} from '@utils/string.utils';
import {en as ENObjects} from './languages/en';
import {no as NOObjects} from './languages/no';
import {toDotPaths} from './languages/util';
import 'dayjs/locale/nb';
import 'dayjs/locale/en-gb';

dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.updateLocale('nb', {weekdaysShort: 'søn._man._tir._ons._tor._fre._lør.'.split('_')});

export const MISSING_TRANSLATION = '[missing translation]';
export const DEFAULT_LANGUAGE = 'no';
export const STANDARD_DATE_FORMAT = 'DD.MM.YYYY';
const dateFormatsByLang = {
  no: 'DD.MM.YYYY',
  en: 'MM/DD/YY',
};

const __loggedErrorsFor = {};
const __translationObjects = {
  no: NOObjects,
  en: ENObjects,
};
const __translationDotPaths = {
  no: null,
  en: null,
};

export const getCurrLang = () => {
  try {
    let lang = localStorage.getItem('language');

    if (lang) return lang;

    const config = localStorage.getItem('learningportalConfig');

    if (config) {
      const {params} = JSON.parse(config) || {};

      lang = params?.['default-language']?.toLowerCase?.() || 'no';
    } else {
      lang = 'no';
    }
    localStorage.setItem('language', lang);

    return lang;
  } catch (error) {
    console.error(error);
    localStorage.setItem('language', 'no');

    return 'no';
  }
};

export const i18nTranslationsObject = key => {
  const lang = getCurrLang();

  return get(__translationObjects[lang], key);
};

/**
 * Gets translated strings. Strings are retrieved from i18n/languages/*-json. Current language is retrieved automatically from localStorage or configFile.
 *
 * Examples:
 *
 *  i18n('name') -> gets the translated string for 'name'
 *
 *  i18n('name', {'lowerCase': true}) -> gets the translated string for 'name' in lower case
 *
 *  i18n('name', {'ellipsis': true}) -> gets the translated string for 'name' with ellipsis: "..." added at the end
 *
 *  i18n('a.b') -> gets the translated string from strings -> a -> b
 *
 *  i18n('happy_birthday_to_x', {functionArgs: {'x':'Test mc. Test' } }) -> applies the placeholder values to the string
 *
 * If not found, defaultValue-parameter or '[missing translation]' is returned.
 */

export const i18n = (key, options, fallback = '') => {
  if (!key || typeof key !== 'string') return '';

  const lang = getCurrLang();

  if (!__translationDotPaths[lang]) __translationDotPaths[lang] = toDotPaths(__translationObjects[lang]);

  let translation = __translationDotPaths[lang][key];

  if (!isStringWithLength(translation)) {
    if (!__loggedErrorsFor[`${lang}-${key}`]) {
      console.error(`[i18n] Missing i18n for key ${key} and lang ${lang}`);
      __loggedErrorsFor[`${lang}-${key}`] = true;
    }

    return fallback || `${MISSING_TRANSLATION} - ${key}`;
  }

  if (!options) return translation;

  if (options?.functionArgs) {
    Object.keys(options.functionArgs).forEach(op => {
      translation = translation.replace('[' + op + ']', options.functionArgs[op]);
    });
  }
  if (options?.lowerCase) {
    translation = translation.toLocaleLowerCase();
  } else if (options?.upperCase) {
    translation = translation.toLocaleUpperCase();
  }
  if (options?.ellipsis) {
    translation = lang === 'no'
      ? `${translation} ...`
      : `${translation}...`;
  }

  return translation;
};

export const i18nCourseGetCompetenceType = course => {
  const competenceType = getCompetenceType(course);

  if (!competenceType) return '';

  return i18n(`globals.course-types.${competenceType}`, null, course?.competence_type?.title || competenceType);
};

export const i18nFormatTimeRange = (from, to) => {
  const lang = getCurrLang();

  try {
    const timeFrom = dayjs(from).format('HH:mm');
    const timeTo = dayjs(to).format('HH:mm');

    return lang === 'no'
      ? `kl. ${timeFrom} - ${timeTo}`
      :  `${timeFrom} - ${timeTo}`;
  } catch (error) {
    console.error(error);

    return '';
  }
};

/**
 * example(language is "en")
 *
 * 18nFormatTimeAgo('hour', 5) -> 5 hours ago
 */
export const i18nFormatTimeAgo = (timeUnit, val) => {
  const lang = getCurrLang();

  if (lang === 'en') {
    switch (timeUnit) {
    case 'hour': {
      return `${val} hour${val === 1 ? '' : 's'} ago`;
    }
    case 'minute': {
      return `${val} minute${val === 1 ? '' : 's'} ago`;
    }
    case 'day': {
      return `${val} day${val === 1 ? '' : 's'} ago`;
    }
    case 'lessThanMinute': {
      return '< 1 minute ago';
    }
    default: {
      return `[i18n] i18nFormatTimeAgo not configured for ${timeUnit} and ${lang}`;
    }
    }
  }

  switch (timeUnit) {
  case 'hour': {
    return `${val} time${val === 1 ? '' : 'r'} siden`;
  }
  case 'minute': {
    return `${val} minutt${val === 1 ? '' : 'er'} siden`;
  }
  case 'day': {
    return `${val} dag${val === 1 ? '' : 'er'} siden`;
  }
  case 'lessThanMinute': {
    return '< 1 minutt siden';
  }
  default: {
    return `[i18n] i18nFormatTimeAgo not configured for ${timeUnit} and ${lang}`;
  }
  }
};

/**
 * Create a new dayjs-instance, localized for the current language.
 * Any arguments are passed down to dayjs()
 */

export const i18ndayjs = (...args) => dayjs(...args);

export const i18nTimeDifferenceString = (d1, d2) => {
  try {
    return i18ndayjs(d2).from(d1, true);
  } catch {
    return '';
  }
};

const isInvalidDate = obj => {
  try {
    return Number.isNaN(obj?.getTime?.());
  } catch {
    return true;
  }
};

export const i18nLocaleDateStrToStdDateStr = date => {
  // const lang = getCurrLang();
  const lang = 'no';

  const getDateByLocale = () => {
    const m = dayjs(date, dateFormatsByLang[lang]);

    if (!m.isValid()) {
      return null;
    }

    return m.format(STANDARD_DATE_FORMAT);
  };

  if (date === null || isInvalidDate(date)) {
    return null;
  }
  if (date === '') {
    return '';
  }

  if (date && date instanceof Date) {
    const m = dayjs(date, dateFormatsByLang[lang]);

    if (!m.isValid()) {
      return null;
    }

    return m.format(STANDARD_DATE_FORMAT);
  }

  return getDateByLocale();
};

export const i18nOverrideString = (key, newString) => {
  if (!isStringWithLength(key) || !isStringWithLength(newString)) return;

  const lang = getCurrLang();

  __translationDotPaths[lang][key] = newString;
};

export const i18nFormatDate = dateStr => {
  if (!isStringWithLength(dateStr)) return '';

  try {
    const hasTimePart = dateStr.match('[0-9]{4}-{0-9}{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}');

    const datedayjs = dayjs(dateStr, 'YYYY-MM-DD' + (hasTimePart ? ' hh:mm:ss' : ''));

    return datedayjs.format('DD.MM.YYYY');
  } catch (error) {
    console.error(error);

    return '';
  }
};
