export const toDotPaths = (obj, prefix = '') => Object.keys(obj).reduce((acc, k) => {
  const pre = prefix.length ? prefix + '.' : '';

  if (typeof obj[k] === 'object') {
    Object.assign(acc, toDotPaths(obj[k], pre + k));
  } else {
    acc[pre + k] = obj[k];
  }

  return acc;
}, {});
