/* eslint-disable react/jsx-fragments */
import React, {useMemo} from 'react';
import {produce} from 'immer';
import memoize from 'micro-memoize';
import {useDispatch, useSelector} from 'react-redux';
import {matchPath, useLocation} from 'react-router';
import {useHistory} from 'react-router-dom';
import {authLogoutRequest} from '@actions/auth.actions';
import {coursesGetCompetencegroupsList} from '@actions/courses.actions';
import {MenuTwo} from '@components/menu/menu-two/MenuTwo';
import {helpPagesUrl} from '@config';
import {css, useTheme} from '@emotion/react';
import {LoadableCourseBuilderModal} from '@features/modals/components/manager-modals';
import {modalsPutModal} from '@features/modals/store/actions';
import LoadableCourseCatalogSwitch from '@routes/course-catalog';
import LoadableEmployeesContainer from '@routes/employees';
import {
  courseCatalog, dashboard,
  employees as employeeRoutes, help,
  myEducation,
} from '@routes/routes.manifest';
import {getConfigObject, getCurrLanguage, getCurrTrack} from '@selectors/config.selectors';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import {selectWorkList} from '@selectors/employees.selectors';
import {
  getIsMobile,
  getIsMobileCourseCatalog,
} from '@selectors/global.selectors';
import {
  getMessageUnreadCount,
  getOrganisationId,
  getProfileData,
  selectIsManager,
  selectProfileId,
} from '@selectors/profile.selectors';
import {isDark} from '@snapper/core';
import {ModalIds} from '@src/features/modals/modal-ids';
import {i18n} from '@src/i18n';
import {getAccess} from '@utils/misc.utils';
import {getRouteWithParams} from '@utils/routes.utils';
import SearchBar from '../search-bar/search-bar';
import DesktopMenu from './desktop-menu/desktop-menu';
import {injectAICourseBuilderMenuItem} from './TEMP-course-builder-menu-item';

const menuWrapperCSS = memoize(textColor => css`
  display: contents;

  &.dark-text {
    div[role="button"] {
      color: ${textColor} !important;

      img {
        filter: invert(1);
      }
    }

    input {
      color: ${textColor} !important;
    }

    input::placeholder {
      color: rgba(0,0,0,.5) !important;
    }

    input {
      background-color: transparent !important;
      border: 1px solid rgba(0,0,0,.25) !important;

      &:focus {
        border: 1px solid ${textColor} !important;
      }
    }

    .menu-ico svg {
      color: ${textColor} !important;
    }
  }
`);

export const getColorConfigForGroup = ({
  courseGroupId,
  pathname,
  isMobile,
  configObject,
  forMenu,
}) => {
  const defaultColor = configObject.getProperty(`params.menu.${isMobile ? 'baseColorMobile' : 'baseColorDesktop'}`) || 'rgb(1, 0, 49)';

  if (forMenu) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  if (!isMobile) {
    return {
      color: configObject.getProperty('params.menu.baseColorDesktop'),
      createGradient: false,
    };
  }

  const noActiveCourseGroup = isMobile && pathname === '/course-catalog'
    || !isMobile && !courseGroupId
    || pathname === '/atlas';

  if (noActiveCourseGroup) {
    return {
      color: defaultColor,
      createGradient: false,
    };
  }

  return {
    color:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.baseColor`) || defaultColor,
    createGradient:
      configObject.getProperty(`params.menu.configByCourseGroupId.${courseGroupId}.gradient`) || false,
  };
};

const getMenuItems = ({
  configObject,
  access,
  history,
  orgId,
  isManager,
  showDashboard,
  location,
  worklistCount,
  dispatch,
}) => {
  if (access.learingportal) {
    const baseTitle = configObject.getProperty('params.default-title-key')
      && i18n(configObject.getProperty('params.default-title-key'));

    const accessLevel = configObject.getProperty('params.menu.access');
    const enableCourseBuilder = isManager && configObject.getProperty('routes.ai-content-creator.enable');

    let menuLinksManager = [];
    let menuLinksEmployee = [];

    if (isManager) {
      menuLinksManager = [{
        name: i18n('menu.my-page'),
        title: i18n('menu.my-page'),
        label: i18n('menu.my-page'),
        onClick: () => {
          history.push(getRouteWithParams(myEducation.main.path, {}));
        },
        type: 'featureLink',
        attach: ['mobile'],
        path: '/my-education',
        active: matchPath(location.pathname, {
          path: '/my-education',
          exact: false,
        }),
      },
      {
        name: i18n('menu.course-catalog'),
        label: i18n('menu.course-catalog'),
        title: `${i18n('menu.course-catalog')}${baseTitle ? ` - ${baseTitle}` : ''}`,
        onClick: () => {
          history.push(getRouteWithParams(courseCatalog.main.path, {}));
        },
        onMouseOver: () => {
          LoadableCourseCatalogSwitch.preload();
          dispatch(coursesGetCompetencegroupsList({initial: true}));
        },
        path: '/course-catalog',
        type: 'featureLink',
        attach: ['mobile'],
        active: matchPath(location.pathname, {
          path: '/course-catalog',
          exact: false,
        }),
      },
      {
        name: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        label: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        title: accessLevel === 3 && i18n('menu.units') || i18n('menu.employees'),
        type: 'featureLink',
        attach: ['mobile'],
        onClick: () => {
          history.push(getRouteWithParams(employeeRoutes.employees, {orgId}));
        },
        onMouseOver: () => {
          LoadableEmployeesContainer.preload();
        },
        path: getRouteWithParams(employeeRoutes.employees, {orgId}),
        active: !location.pathname.includes('worklist') && matchPath(location.pathname, {
          path: '/employees',
          exact: false,
        }),
      }];

      if (worklistCount && worklistCount > 0) {
        menuLinksManager.push({
          name: `${i18n('menu.worklist')} (${worklistCount})`,
          label: i18n('menu.worklist'),
          title: i18n('menu.worklist'),
          type: 'featureLink',
          attach: ['mobile'],
          onClick: () => {
            history.push(getRouteWithParams(employeeRoutes.worklist, {orgId}));
          },
          path: getRouteWithParams(employeeRoutes.worklist, {orgId}),
          active: location.pathname.includes('worklist'),
        });
      }

      if (showDashboard) {
        menuLinksManager.push({
          name: i18n('menu.dashboard'),
          label: i18n('menu.dashboard'),
          title: i18n('menu.dashboard'),
          type: 'featureLink',
          attach: ['mobile'],
          onClick: () => {
            history.push(getRouteWithParams(dashboard.search, {orgId}));
          },
          path: getRouteWithParams(dashboard.search, {orgId}),
          active: matchPath(location.pathname, {
            path: '/dashboard/',
            exact: false,
          }),
        });
      }
    } else {
      menuLinksEmployee = [
        {
          name: i18n('menu.my-page'),
          title: i18n('menu.my-page'),
          label: i18n('menu.my-page'),
          onClick: () => {
            history.push(getRouteWithParams(myEducation.main.path, {}));
          },
          type: 'featureLink',
          attach: ['mobile'],
          path: '/my-education',
          active: matchPath(location.pathname, {
            path: '/my-education',
            exact: false,
          }),
        },
        {
          name: i18n('menu.course-catalog'),
          label: i18n('menu.course-catalog'),
          title: `${i18n('menu.course-catalog')}${
          baseTitle && ` - ${baseTitle}` || ''
        }`,
          onClick: () => {
            history.push(getRouteWithParams(courseCatalog.main.path, {}));
          },
          onMouseOver: () => {
            LoadableCourseCatalogSwitch.preload();
            dispatch(coursesGetCompetencegroupsList({initial: true}));
          },
          path: '/course-catalog',
          type: 'featureLink',
          attach: ['mobile'],
          active: matchPath(location.pathname, {
            path: '/course-catalog',
            exact: false,
          }),
        },
      ];
    }

    if (access.userAccess !== 'crew') {
      let managerSubmenuItems = isManager && (access.userAccess === 'superuser' || access.userAccess === 'admin')
        ? configObject.getProperty('params.menu.data')
        : [];

      if (isManager && enableCourseBuilder) {
        managerSubmenuItems = produce(managerSubmenuItems, draft => {
          injectAICourseBuilderMenuItem(draft, {
            onMouseOver: () => LoadableCourseBuilderModal.preload(),
            onClick: () => {
              dispatch(modalsPutModal({
                modalId: ModalIds.COURSE_BUILDER,
                isOpen: true,
                payload: {
                  initialSelectedTool: 'ai',
                  initialStep: 1,
                },
              }));
              history.push('/admin/tracks');
            },
          });
        });
      }

      return isManager
        ? {
          menuLinks: menuLinksManager,
          adminLinks: managerSubmenuItems,
        }
        : {menuLinks: menuLinksEmployee};
    }

    return isManager
      ? {
        menuLinks: menuLinksManager,
        adminLinks: [],
      }
      : {menuLinks: menuLinksEmployee};
  } else {
    const menuData = [{
      label: i18n('menu.admin'),
      type: 'menu',
      items: [
        {
          label: i18n('menu.administration'),
          onClick: () => window.location = '/admin',
          type: 'title',
        },
        {
          type: 'separator',
          margin: ['0', '0', '20px', '0'],
        },

        /* CUSTOMER HAS ONBOARDING */
        ...!!access?.onboarding && [{
          label: i18n('menu.onboarding'),
          subLabel: i18n('menu.my-onboarding-text'),
          onClick: () => window.location = '/admin/onboardings',
          type: 'featureLink',
        }] || [],
        /* CUSTOMER HAS LEARNINGPATHS */
        ...!!access?.learning && [{
          label: i18n('menu.maps'),
          subLabel: i18n('menu.my-maps-text'),
          onClick: () => window.location = '/admin/maps',
          type: 'featureLink',
        }] || [],
        /* CUSTOMER HAS GATHERINGS */
        ...!!access?.gathering && [{
          label: i18n('menu.gathering'),
          subLabel: i18n('menu.my-gathering-text'),
          onClick: () => window.location = '/admin/gathering',
          type: 'featureLink',
        }] || [],
        ...Boolean(access.gathering || access.learning) && [{
          label: i18n('menu.resource-pages'),
          subLabel: i18n('menu.resource-pages-text'),
          onClick: () => window.location = '/admin/resources',
          type: 'featureLink',
        }] || [],
        {
          label: i18n('menu.persons'),
          subLabel: i18n('menu.persons-text'),
          onClick: () => window.location = '/admin/persons',
          type: 'featureLink',
        },
      ],
    }];

    // /* CUSTOMER HAS ONBOARDING */
    // access.onboarding && menuData[0].items.push({
    //   label: i18n('menu.onboarding'),
    //   subLabel: i18n('menu.my-onboarding-text'),
    //   onClick: () => window.location = '/admin/onboardings',
    //   type: 'featureLink',
    // });

    // /* CUSTOMER HAS LEARNINGPATHS */
    // access.learning && menuData[0].items.push({
    //   label: i18n('menu.maps'),
    //   subLabel: i18n('menu.my-maps-text'),
    //   onClick: () => window.location = '/admin/maps',
    //   type: 'featureLink',
    // });

    // /* CUSTOMER HAS GATHERINGS */
    // access.gathering && menuData[0].items.push({
    //   label: i18n('menu.gathering'),
    //   subLabel: i18n('menu.my-gathering-text'),
    //   onClick: () => window.location = '/admin/gathering',
    //   type: 'featureLink',
    // });

    // /* IF YOU HAVE GATHERING OR LEARNING PATH ADD RESOURCES */
    // Boolean(access.gathering || access.learning) && menuData[0].items.push({
    //   label: i18n('menu.resource-pages'),
    //   subLabel: i18n('menu.resource-pages-text'),
    //   onClick: () => window.location = '/admin/resources',
    //   type: 'featureLink',
    // });

    // menuData[0].items.push({
    //   label: i18n('menu.persons'),
    //   subLabel: i18n('menu.persons-text'),
    //   onClick: () => window.location = '/admin/persons',
    //   type: 'featureLink',
    // });

    return {
      menuLinks: [],
      adminLinks: menuData,
    };
  }
};

const getMessagesText = messagesCount => {
  if (messagesCount === 100) return i18n('person.menu.messagesTxt100OrMoreUnread');
  if (messagesCount === 1) return i18n('person.menu.messagesTxtOneUnread');
  if (messagesCount > 1) return i18n('person.menu.messagesTxtUnread', {functionArgs: {x: messagesCount}});

  return i18n('person.menu.messagesTxt');
};

const MenuContext = React.createContext({});

const MenuContextProvider = ({component}) => {
  const dispatch = useDispatch();

  const onLogoutClick = React.useCallback(() => {
    dispatch(authLogoutRequest());
  }, [dispatch]);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const configObject = useSelector(getConfigObject);

  const profileData = useSelector(getProfileData);
  const isManager = useSelector(selectIsManager);
  const track = useSelector(getCurrTrack);
  const lang = useSelector(getCurrLanguage);

  const selectedCourseGroupId = useSelector(getSelectedCompetencegroupId);
  const isMobileCourseCatalog = useSelector(getIsMobileCourseCatalog);

  const orgId = useSelector(getOrganisationId);
  const personId = useSelector(selectProfileId);
  const messagesCount = useSelector(getMessageUnreadCount);
  const isMobile = useSelector(getIsMobile);

  const {ids: worklistIds = []} = useSelector(selectWorkList);
  const worklistCount = worklistIds?.length;

  // const access = useMemo(
  //   () =>
  //     getAccess(configObject.getProperty('params.customerType') && configObject.getProperty('params.customerType').split('') || ['2', '10'], configObject.getProperty('params.access'))
  //   , [configObject],
  // );

  const {
    menuConfig,
    showDashboard,
    useMap,
    enableEditProfile,
    access,
  } = useMemo(() => {
    const config = configObject.getProperty('params.menu') || {};

    if (Object.keys(config).includes('configByTrack')) {
      config.logo = configObject.getProperty(`params.menu.configByTrack.${track}.configByLang.${lang}.logo`);
    }

    const showDashboard = configObject.isModuleEnabled('reports');
    const useMap = configObject.isMapActivated;

    const enableEditProfile = configObject.getProperty('routes.profile.enable');

    const access = getAccess(configObject.getProperty('params.customerType') && configObject.getProperty('params.customerType').split('') || ['2', '10'], configObject.getProperty('params.access'));

    return {
      menuConfig: config,
      showDashboard,
      useMap,
      enableEditProfile,
      access,
    };
  }, [configObject, track, lang]);

  const {
    menuLinks = [],
    adminLinks = [],
  } = useMemo(() => getMenuItems({
    configObject,
    history,
    access,
    isManager,
    orgId,
    showDashboard,
    location,
    worklistCount,
    dispatch,
  }), [
    access,
    configObject,
    history,
    orgId,
    location,
    showDashboard,
    isManager,
    worklistCount,
    dispatch,
  ]);

  /*
  const setDocumentTitle = links =>
    links
      && links.map(({title, active, children}) => {
        if (active && title) {
          document.title = title + (appTitle ? ` - ${appTitle}` : '');
        }

        return setDocumentTitle(children);
      })
    ;
*/
  // setDocumentTitle(menuLinksManager);

  const colorConfigForGroup = useMemo(() => getColorConfigForGroup({
    courseGroupId: selectedCourseGroupId,
    pathname: location.pathname,
    isMobile: isMobileCourseCatalog,
    configObject,
    forMenu: true,
  }), [selectedCourseGroupId, location.pathname, isMobileCourseCatalog, configObject]);

  const fullname = profileData?.fullname;

  const menuData = useMemo(() => {
    if (menuConfig?.type === 'menu-two') return null;

    if (menuConfig?.type === 'menu-default' && useMap) {
      return {
        config: {
          align: 'center',
          width: 1500,
          height: '70px',
          primaryColor: theme.mainMenuBackgroundColor || menuConfig.color,
          dropdownWidth: 800,
          dropdownAlign: 'center',
        },
        buttons: [{
          label: i18n('menu.logout'),
          onClick: onLogoutClick,
          icon: 'fas door-open',
          type: 'simpleButton',
        }],
        logo: {src: menuConfig.logo},
        items: menuConfig.access && menuConfig.access >= 2
          ? adminLinks
          : null,
      };
    }

    const messagesTxt = getMessagesText(messagesCount);

    return fullname == null
      ? {config: {primaryColor: '#f0f0f0'}}
      : {
        config: {
          height: '70px',
          primaryColor: theme.mainMenuBackgroundColor || menuConfig.color,
        },
        search: isManager
          ? {
            placeholder: i18n('globals.search'),
            search: (searchTerm, context) => ({
              result: <SearchBar
                term={searchTerm}
                showMenu={context.closeMenu}
              />,
            }),
          }
          : null,
        logo: {
          src: menuConfig?.logo || '/images/snapper.svg',
          href: '/',
        },
        items: isMobile
          ? menuLinks
          : adminLinks,
        profile: {
          displayName: fullname,
          notification: messagesCount,
          items: [
            {
              label: i18n('person.menu.competences'),
              subLabel: i18n('person.menu.competencesTxt'),
              onClick: () => {
                history.push(getRouteWithParams(myEducation.cvView.path, {personId}));
              },
              type: 'featureLink',
            },
            ...enableEditProfile
              ? [{
                label: i18n('person.menu.profile'),
                subLabel: i18n('person.menu.profileTxt'),
                onClick: () => {
                  history.push(getRouteWithParams(myEducation.profileView.path, {personId}));
                },
                type: 'featureLink',
              }]
              : [],
            {
              label: i18n('person.menu.messages'),
              subLabel: messagesTxt,
              onClick: () => {
                history.push(getRouteWithParams(myEducation.messagesView.path, {personId}));
              },
              type: 'featureLink',
            },
            {type: 'separator'},
            ...helpPagesUrl
              ? [
                {
                  label: i18n('person.menu.help'),
                  subLabel: i18n('person.menu.helpTxt'),
                  onClick: () => {
                    history.push(getRouteWithParams(help.startpage, {}));
                  },
                  type: 'featureLink',
                },
              ]
              : [],
            {
              label: i18n('person.menu.logout'),
              subLabel: i18n('person.menu.logoutTxt'),
              onClick: () => {
                history.push('/logout');
              },
              type: 'featureLink',
            },
          ],
        },
      };
  }, [
    fullname,
    menuConfig,
    enableEditProfile,
    useMap,
    onLogoutClick,
    isManager,
    messagesCount,
    history,
    personId,
    adminLinks,
    menuLinks,
    isMobile,
    theme,
  ]);

  const ctxValue = React.useMemo(() => ({
    menuData,
    colorConfigForGroup,
    menuLinks,
    menuConfig,
    useMap,
  }), [
    menuData,
    colorConfigForGroup,
    menuLinks,
    menuConfig,
    useMap,
  ]);

  return (
    <MenuContext.Provider value={ctxValue}>
      {component}
    </MenuContext.Provider>
  );
};

const Menu = React.memo(() => {
  const history = useHistory();
  const orgId = useSelector(getOrganisationId);
  const isMobile = useSelector(getIsMobile);
  const dispatch = useDispatch();

  const onLogoutClick = React.useCallback(() => {
    dispatch(authLogoutRequest());
  }, [dispatch]);

  const {
    menuData,
    colorConfigForGroup,
    menuLinks,
    menuConfig,
    useMap,
  } = React.useContext(MenuContext);

  const {mainMenuColor} = useTheme();

  const wrapperCSS = menuWrapperCSS(mainMenuColor);
  const isDarkText = React.useMemo(() => isDark(mainMenuColor), [mainMenuColor]);

  return (
    <div
      css={wrapperCSS}
      className={isDarkText && 'dark-text'}
    >
      {menuConfig?.type === 'menu-two' && (
        <MenuTwo
          onLogoutClick={onLogoutClick}
          variant={isMobile ? 'hamburger' : 'horizontal'}
          createGradient={colorConfigForGroup.createGradient}
          baseColor={colorConfigForGroup.color}
          {...menuConfig || {}}
        />
      ) || menuConfig?.type === 'menu-default' && useMap && (
        <DesktopMenu
          orgId={orgId}
          isMobile={isMobile}
          data={menuData}
          menuLinks={null}
          onLogoutClick={onLogoutClick}
          history={history}
        />
      ) || (
        <DesktopMenu
          orgId={orgId}
          isMobile={isMobile}
          data={menuData}
          menuLinks={menuLinks}
          onLogoutClick={onLogoutClick}
        />
      )}
    </div>
  );
});

const WithContext = React.memo(() => <MenuContextProvider component={<Menu />} />);

export default WithContext;
