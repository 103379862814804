import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';
import {ArrowCircleUpRight} from 'phosphor-react';
import PropTypes from 'prop-types';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {ActionButton} from '@components/buttons/actionButton';
import {CloseButtonGray} from '@components/buttons/closeButtonGray';
import {LoadingSpinner} from '@components/loading/loading-spinner';
import {css} from '@emotion/react';
import {faSpinner} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getIsMobile} from '@selectors/global.selectors';
import {i18n} from '@src/i18n';
import {
  ActionButtonsWrapper,
  BodyWrapper,
  BottomButtonsWrapper,
  ContentWrapper,
  LearnMoreLink,
  LearnMoreWrapper,
  StyledModalTitle,
  TitleWrapper,
  Wrapper,
} from './modal-styles';
// import './modal.scss';

const spinnerCss = css({
  margin: 0,
  padding: 0,
  position: 'absolute',
});

/**
 * A dialog-box where the user has to make a decision(or possibly close the modal)
 * @example
 * <Modal
 *  title="Add employee"
 *  submitButtonText="Add it"
 *  onClose={()=>setShowModal(false)}
 *  onSubmit={addNewModal}
 * >
 * Modal content
 * </Modal>
 */
const Modal = React.memo(({
  onClose,
  onConfirm,
  title,
  cancelButtonText,
  submitButtonText,
  submitButtonProps,
  fullWidth,
  classNameContent,
  classNameWrapper,
  bodyWrapperStyle,
  wrapperStyle,
  cancelButtonStyle,
  submitButtonStyle,
  variant,
  disableActionButtons,
  disableCancelCloseButtons,
  withReadMoreLnk,
  children,
  isFetching,
  isMobile: propsIsMobile,
  cancelButtonStyledAsSubmitButton,
  WrapperComponent,
  showButtonSpinner,
  size = 'auto',
  closeOnClickOutside,
  outerCss,
  loadingIndicatorType = 'text',
  backdrop = true,
  // stickyFooterElement,
  // ...props
}) => {
  // title is either a string or a component
  const TitleComponent = title;
  const ModalWrapper = WrapperComponent ?? Wrapper;

  const isMobileSelector = useSelector(getIsMobile);

  const isMobile = propsIsMobile ?? isMobileSelector;

  const ref = useRef(null);

  const handleOutSideClick = useCallback(() => {
    if (closeOnClickOutside === false || !onClose) return;
    onClose();
  }, [closeOnClickOutside, onClose]);

  const contentWrapperStyle = useMemo(() => ({
    ...wrapperStyle,
    ...isFetching && !showButtonSpinner ? {display: 'none'} : {},
  }), [wrapperStyle, isFetching, showButtonSpinner]);

  return createPortal(
    <ModalWrapper
      role="dialog"
      className={classNameWrapper || ''}
      css={outerCss}
    >
      {backdrop && (
        <div
          role="presentation"
          className="modal__backdrop"
          onClick={handleOutSideClick}
        />
      )}
      {isFetching && <LoadingIndicator type={loadingIndicatorType} />}
      <ContentWrapper
        fullWidth={fullWidth}
        isMobile={isMobile}
        className={classNames('modal__content', classNameContent, size ? `--size-${size}` : '')}
        ref={ref}
        style={contentWrapperStyle}
      >
        {variant !== 'warning' && !disableCancelCloseButtons && <CloseButtonGray onClick={onClose}/>}

        {!!TitleComponent && (
          <TitleWrapper isMobile={isMobile}>
            <TitleComponent />
          </TitleWrapper>
        )}
        <BodyWrapper
          css={bodyWrapperStyle}
          className="modal__body-wrapper"
        >
          {children}
        </BodyWrapper>
        {(withReadMoreLnk || !disableActionButtons) && (
          <BottomButtonsWrapper isMobile={isMobile}>
            {withReadMoreLnk && (
              <LearnMoreWrapper>
                <LearnMoreLink
                  href={withReadMoreLnk.href}
                  target="_blank"
                >
                  {withReadMoreLnk.text || i18n('globals.read-more')}
                </LearnMoreLink>
                <ArrowCircleUpRight
                  weight="fill"
                  style={{fontSize: '1.1em'}}
                />
              </LearnMoreWrapper>
            )}
            {!disableActionButtons && (
              <ActionButtonsWrapper>
                {!disableCancelCloseButtons && (
                  <ActionButton
                    key="cancel"
                    variant={cancelButtonStyledAsSubmitButton ? 'primary' : 'cancel'}
                    onClick={onClose}
                    className={`action-button action-button--cancel${cancelButtonStyledAsSubmitButton ? '' : ' --primary'}${showButtonSpinner ? ' --button-spinner' : ''}`}
                    css={cancelButtonStyle}
                  >
                    {cancelButtonText || i18n('globals.cancel')}
                  </ActionButton>
                )}
                <ActionButton
                  key="submit"
                  variant={variant === 'warning' ? 'danger' : 'primary'}
                  onClick={onConfirm}
                  className={`action-button${showButtonSpinner ? ' --button-spinner' : ''}`}
                  css={submitButtonStyle}
                  {...submitButtonProps}
                >
                  {showButtonSpinner && (
                    <FontAwesomeIcon
                      css={spinnerCss}
                      icon={faSpinner}
                      size="xl"
                      spin
                    />
                  )}
                  <span
                    style={{
                      opacity: showButtonSpinner ? 0 : 1,
                      margin: 0,
                    }}
                  >
                    {submitButtonText || i18n('globals.confirm')}
                  </span>
                </ActionButton>
              </ActionButtonsWrapper>
            )}
          </BottomButtonsWrapper>
        )}
      </ContentWrapper>
    </ModalWrapper>,
    document.querySelector('#modal'),
  );
});

const LoadingIndicator = ({type = 'spinner'}) => {
  const maxLoadingState = 3;
  const loadingChar = '.';
  const [loadingState, setLoadingState] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState(loadingState =>
        loadingState >= maxLoadingState ? 0 : loadingState + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  if (type === 'spinner') return <div className="modal__loading-spinner"><LoadingSpinner backdrop={false} /></div>;

  return (
    <p className="modal__loading-text">
      {i18n('globals.loading')}
      {' '}
      {loadingChar.repeat(loadingState)}
    </p>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,

  // if the data for the modal is loaded
  dataLoaded: PropTypes.bool,

  // if true - displays a loading animation untill dataLoaded is true and then opens the modal
  waitForLoadedDataWithAnimation: PropTypes.bool,
  size: PropTypes.oneOf(['auto', 'small']),
};

Modal.defaultProps = {
  children: null,
  className: null,
  classNameContent: null,
  dataLoaded: false,
  waitForLoadedDataWithAnimation: false,
  size: 'auto',
};

export const Title = React.memo(({children, ...props}) => (
  <StyledModalTitle {...props}>
    {children}
  </StyledModalTitle>
));

Modal.Title = Title;

export default Modal;
