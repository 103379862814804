import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {selectSessionId} from '@selectors/auth.selectors';

const PrivateRoute = ({component: Component, sessionId, ...rest}) => (
  <Route
    {...rest}
    render={({...props}) => sessionId
      ? <Component {...props} />
      : (
        <Redirect
          to={{
            pathname: '/login',
            state: {referrer: props.location},
          }}
        />
      )}
  />
);

PrivateRoute.propTypes = {sessionId: PropTypes.string};
PrivateRoute.defaultProps = {sessionId: undefined};

const mapStateToProps = createStructuredSelector({sessionId: selectSessionId});

export default connect(mapStateToProps)(PrivateRoute);
