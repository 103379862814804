const prefix = '[ROUTER]';

// app

export const ROUTER_APP_COMPONENT_DID_MOUNT = `${prefix} APP COMPONENT DID MOUNT`;
export const routerAppComponentDidMount = () => ({type: ROUTER_APP_COMPONENT_DID_MOUNT});

// messges
export const ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT = `${prefix}/MY-EDUCATION/MESSAGES/VIEW/DID/MOUNT`;
export const routerMyEducationMessagesMainViewDidMount = payload => ({
  type: ROUTER_MY_EDUCATION_MESSAGE_VIEW_DID_MOUNT,
  payload,
});

// my education
export const ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT = `${prefix}/MY-EDUCATION/MAIN/VIEW/DID/MOUNT`;
export const ROUTER_MY_EDUCATION_PROFILE_VIEW_DID_MOUNT = `${prefix}/MY-EDUCATION/PROFILE/VIEW/DID/MOUNT`;
export const ROUTER_MY_EDUCATION_ROLE_VIEW_DID_MOUNT = `${prefix} MY EDUCATION ROLE VIEW DID MOUNT`;
export const ROUTER_MY_EDUCATION_COURSE_VIEW_DID_MOUNT = `${prefix} MY EDUCATION COURSE VIEW DID MOUNT`;

export const routerMyEducationMainViewDidMount = payload => ({
  type: ROUTER_MY_EDUCATION_MAIN_VIEW_DID_MOUNT,
  payload,
});

export const routerMyEducationProfileViewDidMount = () => ({type: ROUTER_MY_EDUCATION_PROFILE_VIEW_DID_MOUNT});

export const routerMyEducationRoleViewDidMount = payload => ({
  type: ROUTER_MY_EDUCATION_ROLE_VIEW_DID_MOUNT,
  payload,
});

export const routerMyEducationCourseViewDidMount = payload => ({
  type: ROUTER_MY_EDUCATION_COURSE_VIEW_DID_MOUNT,
  payload,
});

// employees

export const ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT = 'ROUTER_EMPLOYEES_MAIN_VIEW_DID_MOUNT';
export const ROUTER_EMPLOYEES_LIST_DID_MOUNT = 'ROUTER_EMPLOYEES_LIST_DID_MOUNT';

export const routerEmployeesListDidMount = payload => ({
  type: ROUTER_EMPLOYEES_LIST_DID_MOUNT,
  payload,
});
