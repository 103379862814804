export const removeURLProtocol = url => url.replace(/(^\w+:|^)\/\//, '');

// This regex matches URLs with or without the protocol
// It will match URLs like https://www.example.com or www.example.com
// It will also exclude URLs that are already contained in single or double quotes
export const urlRegex = /Æ/;

export const wrapURLsWithA = text =>
  // Wrap the URLs in <a> tags, and add target="_blank" and rel="noopener noreferrer" attributes
  text

// const html = text.replace(urlRegex, url => {
//   const protocol = /^https?:\/\//.test(url) ? '' : 'https://';

//   return `<a href="${protocol}${url}" target="_blank" rel="noopener noreferrer" class="text-link">${removeURLProtocol(url)}</a>`;
// });

// return html;
;

export const isStringWithLength = value => !!value && typeof value === 'string' && value.length > 0;

export const normalizeUtfSpaces = str => isStringWithLength(str) ? str.replaceAll(/[\u00A0\u2000-\u200B\u202F\u205F\u3000]/g, ' ') : str;

export const randString = (len = 12) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  let str = '';

  for (let i = 0; i < len; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

export const truncate = (str, len) => isStringWithLength(str) && str.length > len
  ? `${str.slice(0, Math.max(0, len))}...`
  : str || '';

export const capitalize = str => isStringWithLength ? str.charAt(0).toUpperCase() + str.slice(1) : str;
export const simpleStr = str => {
  const trimmed = str?.trim?.();

  return trimmed
    ? trimmed
      .toString()
      .toLowerCase()
      .replaceAll(/[^\da-zäåæöø]/g, '')
    : '';
};

export const isNullOrWhitespace = str => !str || !(str || '')?.trim?.();

export const cleanPhoneNumber = str => {
  if (!isStringWithLength(str)) return null;
  if (str.startsWith('00')) return str.replace('00', '+');
  if (str.startsWith('+')) return str;

  return `+47${str}`;
};
