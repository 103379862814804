// all modal ids should be defined here
export const ModalIds = {
  EMPLOYEE: 'EMPLOYEE',
  SELF_SIGN_READ: 'SELF_SIGN_READ',
  SELF_SIGN_SELECT: 'SELF_SIGN_SELECT',
  COMPETENCE: 'COMPETENCE',
  CHECKLIST: 'CHECKLIST',
  COURSE: 'COURSE',
  COURSE_EVENT: 'COURSE_EVENT',
  COURSE_EVENT_ATTENDANCE: 'COURSE_EVENT_ATTENDANCE',
  SEND_MESSAGE: 'SEND_MESSAGE',
  BART: 'BART',
  EMPLOYMENT: 'EMPLOYMENT',
  PERSON_ROLES: 'PERSON_ROLES',
  PERSON_COMPETENCES: 'PERSON_COMPETENCES',
  SIGNATURE: 'SIGNATURE',
  COURSE_BUILDER: 'COURSE_BUILDER',
  EMPLOYEES_LIST: 'EMPLOYEES_LIST',
};
