import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const mobileViewBreakpoint = '490px';

export const PopupWrapper = styled.div`
  position: relative;
  margin-top: 0.7em;
  left: -1.1em;
  background: white;
  border: ${props =>
  props.listIsOpen ? '2px solid #ededed' : '2px solid transparent'};
  z-index: ${props => props.listIsOpen ? '3' : '1'};
  border-radius: 5px;
  padding: 1em;
  width: 310px;

  @media (max-width: ${mobileViewBreakpoint}) {
    position: fixed;
    margin-top: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    padding-top: 1.87em;
    padding-left: 1.86em;

    ${props =>
    props.listIsOpen
      ? css`
            height: 100%;
          `
      : ''}
  }
`;

export const Language = styled.div`
  display: flex;
  margin: 0.85em 0em;
  margin-bottom: 0.45em;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  &.focus-visible:not(.opened-by-mouse) {
    outline: 1px dashed gray;
  }

  @media (max-width: ${mobileViewBreakpoint}) {
    &:not(.outside) {
      margin-bottom: 1em;
    }
  }
`;

export const LangFlagWrapper = styled.div`
  margin-right: 1em;
  svg {
    width: 27px;
    height: 27px;
  }
`;
export const LangName = styled.div``;
export const SelectStatusDot = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: ${props =>
  props.isActive ? '1px solid transparent' : '1px solid #A4A19F'};
  margin-left: auto;
  position: relative;

  background: ${props => props.isActive ? '#002EFF' : 'white'};
`;

export const ChooseLangHeader = styled.p`
  margin: 0;
  margin-top: 0px;
  font-size: 1.2em;
  margin-bottom: 1.2em;
  padding-top: 0.9em;
`;

export const SelectStatusDotFill = styled.div`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  background: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Separator = styled.div`
  position: absolute;
  left: 0;
  ${props =>
  ({
    large: css`
        height: 2px;
        width: 100%;
        background: #ededed;
      `,
    small: css`
        height: 1.4px;
        width: auto;
        background: #f2f0ed;
        position: relative;
        margin-left: calc(30px + 0.5em);
      `,
    undefined: '',
  }[props.variant])}
`;

export const Help = styled.div`
  text-align: center;
  text-align: center;
  font-size: 0.91em;
  padding-top: 1em;
  p {
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: #002eff;
    :hover {
      cursor: pointer;
    }
    &.focus-visible {
      color: blue;
    }
  }
`;

export const LanguageList = styled.div``;

export const ChooserWrapper = styled.div``;

export const CloseBtn = styled(FontAwesomeIcon)`
  display: none;
  position: absolute;
  right: 1em;
  top: 1.78em;
  color: #5f5f5f;
  font-size: 1.2em;

  :hover {
    cursor:       pointer;
  }

  &.focus-visible {
    outline: 1px dashed gray;
  }
  @media (max-width: ${mobileViewBreakpoint}) {
    display: 
    ${     props => props.listIsOpen ? 'block' : 'none'};
  }
`;

export const OpenListItemsWrapper = styled.div``;
