import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTrackExtraData} from '@actions/map.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {routerMyEducationRoleViewDidMount} from '@actions/router.actions';
import {TrackChecklistItem} from '@components/competences/Components/track-checklist-item';
import Modal from '@components/modal/modal';
import styled from '@emotion/styled';
import {useModalSelector} from '@features/modals/store/selectors';
import {selectActiveOrgId, selectIsManager, selectProfileId} from '@selectors/profile.selectors';
import {selectLearningPathChildrenData} from '@selectors/tracks.selectors';
import {isObjectWithKeys} from '@snapper/core';
import {ModalIds} from '@src/features/modals/modal-ids';
import {getCompetenceId} from '@src/store/util/competence-identity';

const notification = error => error
  ? {
    text: 'Kunne ikke sjekke kompetanse',
    color: 'red',
  }
  : {
    text: 'Kompetanse sjekket',
    color: 'green',
  };

export const validNumberOrNull = (value, defaultValue = null) => {
  const parsed = Number(value);

  return Number.isNaN(parsed)
    ? defaultValue
    : parsed;
};

const Wrapper = styled.div`
  h2 {
    margin-top: 0;
  }

  .description {
    padding-bottom: .75rem;

    p:first-of-type {
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .checklist-items-wrapper {
    margin-top: 1rem;
    /* border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 1rem; */
    /* padding-bottom: 0.5rem; */
  }

  label {
    cursor: pointer;
  }

  .competence-card {
    padding: .75rem 1.25rem;
    margin: 0;
    margin-bottom: .5rem;
    transition: all .2s ease-in-out;
    box-shadow: none;
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;

    &.checked,
    &.disabled,
    &.busy {
      label {
        cursor: default;
      }
    }

    &.checked,
    &.disabled {
      /* padding-bottom: .5rem; */
    }

    &.checked {
      /* background: #f4fff4;
      border: 1px solid #9bd59491;
      background: #dcf5e7;
      background: #b3e6c8;
      background: #75d09b; */
      background: #f1fdf6;
      // border: 1px solid #b3e6c8;
      border-radius: 4px;
      // background: #dcf5e7;
      background: #ecf4ee;
    
      .grid-x .info-wrapper {
        color: rgb(51, 50, 56);
      }
    }

    &.disabled {
      background: #f6fafe;
      /* border: 1px solid #e4f0fb; */
    }
  }

  .status-wrapper {
    margin-right: 1rem !important;
    display: flex;
    align-self: center;

    img {
      display: flex;
      min-width: 22px;
      min-height: 22px;
    }
  }

  .status-text-wrapper {
    margin-top: .25rem;

    span {
      font-size: .75rem !important;
      margin-left: 2.25rem;
    }
  }

  .grid-x .info-wrapper {
    font-weight: 600;
    color: #4a4a4a;
    font-size: 1rem;
    width: 100%;

    .small-header {
      font-size: .75rem;
      font-weight: 400;
      color: #666;
      line-height: 1.1;
    }
  }

  .roles-list__competence {
    padding: 0;
  }


  @media (max-width: 425px) {
    .grid-x .info-wrapper {
      font-size: .875rem;
    }

    .status-wrapper {
      margin-right: .5rem !important;
    }

    .status-text-wrapper {
      margin-top: .25rem;
    }
  }

`;

// const selectChecklistChildren = createSelector(
// selectCompetenceChildren,

const ChecklistModal = React.memo(({close}) => {
  const {
    isOpen,
    personId: personIdPayload,
    orgId: orgIdPayload,
    competenceId: cidPayload,
    competence: competencePayload = {},
    roleId,
    trackId: trackIdPayload,
    mapId: mapIdPayload,
    refetchOnClose,
    loadChildrenLocally,
  } = useModalSelector(ModalIds.CHECKLIST);

  const dispatch = useDispatch();

  const [isLoadingChildren, setIsLoadingChildren] = React.useState(false);
  const [localChildren, setLocalChildren] = React.useState(null);
  const [isChecking, setIsChecking] = React.useState({});

  const getIsChecking = useCallback(_cid => !!isChecking[_cid], [isChecking]);

  const handleCheckedOrError = useCallback((_cid, error) => {
    dispatch(notificationsAdd({notification: notification(error)}));

    setIsChecking(prev => ({
      ...prev,
      [_cid]: false,
    }));
  }, [dispatch]);

  const isManager = useSelector(selectIsManager);

  const profileId = useSelector(selectProfileId);
  const activeOrgId = useSelector(selectActiveOrgId);

  const pid = validNumberOrNull(personIdPayload || profileId);
  const cid = validNumberOrNull(cidPayload || getCompetenceId(competencePayload));
  const orgId = validNumberOrNull(orgIdPayload || activeOrgId);
  const isSelf = !personIdPayload || pid === profileId;

  const competenceById = useSelector(selectLearningPathChildrenData);

  const competence = competencePayload || competenceById[cid];

  const trackId = validNumberOrNull(trackIdPayload || competence?.trackId);
  const mapId = validNumberOrNull(mapIdPayload || competence?.mapId);

  const children = useMemo(() => {
    if (localChildren) return localChildren;
    if (competence?.children?.length && isObjectWithKeys(competence.children[0])) return competence.children;
    if (competence?.children_ids?.length) return competence.children_ids.map(id => competenceById[id]);

    return [];
  }, [competence, localChildren, competenceById]);

  const handleClose = useCallback(() => {
    if (trackId || mapId) {
      dispatch(fetchTrackExtraData({
        trackId: mapId || trackId,
        cid,
      }));
    } else if (roleId) {
      dispatch(routerMyEducationRoleViewDidMount({
        roleId,
        skipLoadStatus: true,
      }));
    }
    if (close) close();
  }, [close, roleId, dispatch, trackId, mapId, cid]);

  React.useEffect(() => {
    if (!isOpen || !loadChildrenLocally || !cid) return () => {};

    setIsLoadingChildren(true);

    return () => {
      setIsLoadingChildren(false);
    };
  }, [isOpen, loadChildrenLocally, cid]);

  const description = competence?.description || competence?.abstract || competence?.short_description;

  return (
    <Modal
      onClose={handleClose}
      classNameContent="checklist-modal"
      disableActionButtons
    >
      <Wrapper>
        <h2>{competence?.title || competence?.competence_title}</h2>
        {!!description && (
          <div
            className="description"
            dangerouslySetInnerHTML={{__html: description}}
          />
        )}
        <div className="checklist-items-wrapper">
          {isOpen && children?.length
            ? children.map(child => (
              <TrackChecklistItem
                key={getCompetenceId(child)}
                competence={child}
                trackId={trackId}
                mapId={mapId}
              />
            ))
            : null}
        </div>
      </Wrapper>
    </Modal>
  );
});

export default ChecklistModal;
