import {createSelector} from 'reselect';
import {LoadStatuses} from '@types/load.types';
import {selectEmployeesList} from './employees.selectors';

export const selectRoles = ({roles: {roles}}) => roles;
export const _selectRolesData = ({roles: {roles: {data} = {}} = {}}) => data;
export const selectRolesOrganisations = ({roles: {organisations}}) => organisations;
export const selectRoleDetails = ({roles: {roleDetails}}) => roleDetails;

export const selectRoleDetailsById = createSelector(
  selectRoleDetails,
  selectEmployeesList,
  (_, roleId) => roleId,
  (roleDetails, employeesState, roleId) => {
    if (!roleId) return {};

    const {
      byId: roleById,
      status: rolesStatus,
    } = roleDetails;

    const {
      data = null,
      status = LoadStatuses.NOT_LOADED,
    } = roleById[roleId] || {};

    if (status !== LoadStatuses.LOADED) return {
      data,
      employees: [],
      status: rolesStatus === LoadStatuses.IS_LOADING
        ? LoadStatuses.IS_LOADING
        : status,
    };
    const idNum = Number(roleId);
    const {byId: employeeById = {}} = employeesState || {};
    const roleEmployees = [];

    Object.values(employeeById).forEach(employee => {
      const {roleIds = []} = employee;

      if (roleIds.includes(idNum)) {
        roleEmployees.push(employee);
      }
    });

    return {
      data,
      employees: roleEmployees,
      status,
    };
  },
);
