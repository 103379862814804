import {produce} from 'immer';
import {RESET_APP, RESET_STORE} from '@actions/global.actions';

const resetActions = new Set([RESET_APP, RESET_STORE]);

/**
* Using Curried produce here. Check this out on: https://immerjs.github.io/immer/curried-produce
**/
export const withProduce = (initialState, reducers) => (state = initialState, action) => {
  const {type, payload} = action;

  /**
  * when an action is get dispatched, first checking if we have a reducer corresponding that action, if yes then
  *  call reducer method with draftstate and payload.
  * otherwise, return state
  */
  if (reducers[type]) {
    return produce(state, draft => reducers[type](draft, action)); // todo: pass only payload ?
  }

  if (resetActions.has(type)) return initialState;

  return state;
};

/**
* @description:- using withProduce method to give draft state.
**/
