import React from 'react';
import {useSelector} from 'react-redux';
import {selectReduxModuleLoadStatusById} from '@actions/app.actions';
import {useInjectModule} from '@store';
import {LoadStatuses} from '@types/load.types';
import {ReduxModuleIds} from '../constants';

export const ReduxModuleInjector = React.memo(({
  moduleId,
  disabled,
  renderAllways,
  children,
}) => {
  const loadStatus = useSelector(selectReduxModuleLoadStatusById(moduleId));
  const disableInitialLoad = disabled || loadStatus !== LoadStatuses.NOT_LOADED;

  useInjectModule(moduleId, disableInitialLoad);

  const isLoaded = loadStatus === LoadStatuses.LOADED;
  const shouldRender = renderAllways || isLoaded && !disabled;

  if (shouldRender) return children || null;

  return null;
}, (prev, next) => prev.renderAllways === next.renderAllways
  && prev.disabled === next.disabled
  && prev.moduleId === next.moduleId);

export const EmployeesModuleInjector = React.memo(({disabled}) => (
  <ReduxModuleInjector
    moduleId={ReduxModuleIds.employees}
    disabled={disabled}
  />
));

export const AdminModuleInjector = React.memo(({disabled}) => (
  <ReduxModuleInjector
    moduleId={ReduxModuleIds.admin}
    disabled={disabled}
  />
));

// TODO: use this component in app.jsx to listen for actions to preload modules through redux (before rendering)

// export const ModuleInjectorListener = React.memo(({moduleId, onLoaded}) => {
//   const loadStatus = useSelector(state => selectReduxModuleLoadStatusById(state, moduleId));
//   const disabled = loadStatus !== LoadStatuses.SHOULD_LOAD;

//   const prevLoadStatus = React.useRef(loadStatus);

//   React.useEffect(() => {
//     if (loadStatus === LoadStatuses.LOADED && prevLoadStatus.current !== LoadStatuses.LOADED) {
//       onLoaded();
//     }
//     prevLoadStatus.current = loadStatus;

//     return () => {};
//   }, [loadStatus, onLoaded]);

//   if (disabled) return null;

//   return <ReduxModuleInjector moduleId={moduleId} />;
// }, (prev, next) => prev.moduleId === next.moduleId);
