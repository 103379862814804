// add breakpoints here, from largest to smallest
export const breakpointsMap = new Map([
  ['tablet', '768px'],
  ['mobile', '425px'],
  ['mobileSm', '375px'],
  ['mobileXs', '320px'],
]);

export const breakpoints = {
  ...Object.fromEntries(breakpointsMap),
  base: [...breakpointsMap][0][1],
};

export const breakpointKeys = [...breakpointsMap.keys()];
