/* eslint-disable no-use-before-define */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

export const useOnClickOutside = (refs, callback) => {
  const listener = useCallback(
    event => {
      const forAny = fn =>
        Array.isArray(refs)
          ? refs.reduce(
            (bool, ref) =>
              !!(bool || ref && ref.current && fn(ref.current)),
            false,
          )
          : refs && refs.current && fn(refs.current);

      if (
        !forAny(current => current.contains(event.target))
      ) {
        callback(event);
      }
    },
    [callback, refs],
  );

  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [listener]);
};

// Usage: const { width, height } = useWindowDimensions();
export const useWindowDimensions = ({useOuterHeight, useOuterWidth, useMaxInnerOuterHeight}) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions({
    useOuterHeight,
    useOuterWidth,
    useMaxInnerOuterHeight,
  }));

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions({
        useOuterHeight,
        useOuterWidth,
        useMaxInnerOuterHeight,
      }));
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowDimensions;
};

const getWindowDimensions = ({useOuterHeight, useOuterWidth, useMaxInnerOuterHeight}) => {
  let height = useOuterHeight ? window.outerHeight : window.innerHeight;

  if (useMaxInnerOuterHeight) {
    height = Math.max(window.outerHeight, window.innerHeight);
  }
  const width = useOuterWidth ? window.outerWidth : window.innerWidth;

  return {
    width,
    height,
  };
};
