import React, {useCallback} from 'react';
import memoize from 'micro-memoize';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';
import {modalsCloseModal} from './store/actions';
import {selectModals} from './store/selectors';
import {
  modalComponents,
  modalIdsByAccessLevel,
  modalOptions,
} from './registry';

const isDev = process?.env?.NODE_ENV === 'development';

const selectByAccessLevel = memoize(accessLevel => createSelector(
  selectModals,
  state => modalIdsByAccessLevel?.[accessLevel]?.reduce((acc, id) => {
    acc[id] = state[id];

    return acc;
  }, {}),
));

const selectRenderedIds = memoize(accessLevel => createSelector(
  selectByAccessLevel(accessLevel),
  state => Object.keys(state).filter(id => {
    const {isOpen} = state[id] || {};

    return isOpen || modalOptions[id]?.renderAllways;
  }),
));

// renders all modals defined in ./registry.js, for the given access level
// this is to allow lazy loading of modal components based on the user's access level
// if a modal is not open, it will not be rendered, unless it has renderAllways option
export const ModalsRenderer = React.memo(({accessLevel = 0}) => {
  const dispatch = useDispatch();
  const renderedIds = useSelector(selectRenderedIds(accessLevel));

  const handleClose = useCallback(id => {
    dispatch(modalsCloseModal(id));
  }, [dispatch]);

  return (
    <React.Fragment key={accessLevel}>
      {renderedIds.map(id => {
        const Component = modalComponents[id];

        if (!Component) {
          if (isDev) throw new Error(`Modal component for id "${id}" not found!`);

          return null;
        }

        return (
          <Component
            key={id}
            close={handleClose.bind(null, id)}
          />
        );
      })}
    </React.Fragment>
  );
});
