import {freeze, original} from 'immer';
import {i18n} from '@src/i18n';
import {createReducerAndActions} from '@src/store-normalized/setup/createDefault';
import {updateFetchStateFailure, updateFetchStateRequest, updateFetchStateSuccess} from '@src/store-normalized/util/loadstatus.utils';
import {getMilliseconds} from '@src/store-normalized/util/time';
import * as T from '@types/load.types';

export const initialState = {
  competences: {
    isFetching: false,
    isFetchingInitialCompetences: false,
    data: null,
    error: null,
    isExpanded: false,
    searchTerm: '',
  },
  featuredCompetences: {
    isFetching: false,
    data: null,
    error: null,
  },
  courseEvents: {
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    error: null,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  selectedCompetencesIds: [],
  sorting: ['alpha', i18n('globals.sort-alpha')],
  competencetypes: {
    isFetching: false,
    data: null,
    error: null,
  },
  currentSignature: null,
  competencegroups: {
    isFetching: false,
    data: null,
    error: null,
  },
  filters: {selectedCompetencetypes: []},
  courseCatalogNews: {
    isFetching: false,
    data: null,
    error: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  competenceDetails: {
    isFetching: false,
    data: null,
    error: null,
  },
  activeCourse: null,
  activeCourseMapId: null,
  courseSignOn: {
    courseIds: [],
    isFetching: false,
    ceid: null,
    error: null,
    results: {
      errors: [],
      correct: [],
    },
  },
  courseSignOff: {
    courseIds: [],
    ceid: null,
    isFetching: false,
    status: '',
  },
  courseSignCourse: {
    isFetching: false,
    ceid: null,
    error: null,
    success: false,
    status: '',
  },
  courseDeleteFileForPerson: {
    isFetching: false,
    error: null,
    success: false,
    status: false,
  },
  normalizedData: {
    competencegroups: {
      ids: [],
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competences: {
      ids: [],
      data: {},
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceIdsByGroupId: {
      data: {},
      status: T.LoadStatuses.NOT_LOADED,
    },
    subCategoriesByGroupId: {
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selectedCompetencegroups: {
      ids: null,
      subCategories: [],
      competences: [],
      data: [],
      status: T.LoadStatuses.NOT_LOADED,
    },
    courseEvents: {
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
      allEventIds: [],
      eventById: {},
      eventIdsByCourseId: {},
      eventIdsByYearMonth: {},
    },
    eventParticipantsByEventId: {
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    selfSignedOnEventIds: {
      eventIds: [],
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competenceDetails: {
      ids: [],
      data: {},
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
    competencesSearchResults: {
      searchTerm: '',
      ids: [],
      data: {},
      empty: true,
      error: null,
      status: T.LoadStatuses.NOT_LOADED,
    },
  },
};
const courses = {
  getCompetences: {
    request: (state, action) => {
      state.competences = {
        ...initialState.competences,
        isFetching: true,
        searchTerm: state.competences.searchTerm,
      };
    },
    success: (state, action) => {
      state.competences = {
        ...initialState.competences,
        data: action.payload.competences,
        isExpanded: false,
        searchTerm: state.competences.searchTerm,
      };
    },
    failure: (state, action) => {
      state.competences.error = action.payload.error;
    },
  },
  updateSearchResults: {
    request: (state, action) => {
      state.normalizedData.competencesSearchResults = {
        ...initialState.normalizedData.competencesSearchResults,
        status: T.LoadStatuses.IS_LOADING,
      };
    },
    success: (state, action) => {
      state.normalizedData.competencesSearchResults = {
        ...initialState.normalizedData.competencesSearchResults,
        status: T.LoadStatuses.LOADED,
        ...action.payload.reset
          ? {}
          : {
            searchTerm: action.payload.searchTerm,
            empty: action.payload.competences.length === 0,
            ...action.payload.competences?.reduce?.((acc, c) => {
              acc.data[c.id] = c;
              acc.ids.push(c.id);

              return acc;
            }, {
              ids: [],
              data: {},
            }),
          },
      };
    },
  },
  getCompetencegroupsList: {
    request: (state, action) => {
      state.normalizedData.competencegroups.isFetching = true;
      state.normalizedData.competencegroups.status = T.LoadStatuses.IS_LOADING;
      state.normalizedData.subCategoriesByGroupId.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.normalizedData.competencegroups.data = action.payload.data;
      state.normalizedData.competencegroups.ids = action.payload.ids;
      state.normalizedData.competencegroups.isFetching = false;
      state.normalizedData.competencegroups.isLoaded = true;
      state.normalizedData.competencegroups.status = T.LoadStatuses.LOADED;
      state.normalizedData.subCategoriesByGroupId.data = action.payload.subCategoriesByGroupId;
      state.normalizedData.subCategoriesByGroupId.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.normalizedData.competencegroups =  {
        ...initialState.normalizedData.competencegroups,
        status: T.LoadStatuses.FAILED,
      };
      state.normalizedData.subCategoriesByGroupId = {
        ...initialState.normalizedData.subCategoriesByGroupId,
        status: T.LoadStatuses.FAILED,
      };
    },
  },
  loadCompetencegroup: {
    request: (state, action) => {
      state.normalizedData.selectedCompetencegroups.data = null;
      state.normalizedData.selectedCompetencegroups.ids = [action.payload.competencegroupId];
      state.normalizedData.selectedCompetencegroups.subCategories = [];
      state.normalizedData.selectedCompetencegroups.competences = [];
      state.normalizedData.selectedCompetencegroups.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      const groupId = action.payload.competencegroupId;
      const groupData = state.normalizedData.competencegroups.data[groupId];

      const competenceIds = state.normalizedData.competenceIdsByGroupId.data[groupId];
      const competenceById = state.normalizedData.competences.data;

      state.normalizedData.selectedCompetencegroups.ids = [groupId];
      state.normalizedData.selectedCompetencegroups.data = groupData;
      state.normalizedData.selectedCompetencegroups.subCategories = groupData?.children;
      state.normalizedData.selectedCompetencegroups.competences = competenceIds?.map(id => competenceById[id]) || [];
      state.normalizedData.selectedCompetencegroups.isFetching = false;
      state.normalizedData.selectedCompetencegroups.status = T.LoadStatuses.LOADED;
    },
  },
  loadCompetencegroupsSubcategories: {
    request: (state, action) => {
      state.normalizedData.selectedCompetencegroups.ids = [...action.payload.parentGroupIds, ...action.payload.subCategoryIds];
      state.normalizedData.selectedCompetencegroups.competences = [];
      state.normalizedData.selectedCompetencegroups.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.normalizedData.selectedCompetencegroups.ids = [
        ...action.payload.parentGroupIds,
        ...action.payload.subCategoryIds,
      ];
      state.normalizedData.selectedCompetencegroups.competences = freeze([...new Set([
        ...action.payload.subCategoryIds.length
          ? action.payload.subCategoryIds
          : action.payload.parentGroupIds,
      ]
        .flatMap(groupId => {
          const competenceIds = state.normalizedData
            .competenceIdsByGroupId.data[groupId];

          return competenceIds;
        }))]
        .map(competenceId => {
          const competence = original(state.normalizedData.competences.data[competenceId]);

          return competence;
        })
        .filter(Boolean) || []);
      state.normalizedData.selectedCompetencegroups.status = T.LoadStatuses.LOADED;
    },
  },
  getCompetencegroupChildren: {
    request: (state, action) => {
      state.normalizedData.competenceIdsByGroupId.status = T.LoadStatuses.IS_LOADING;
      state.normalizedData.competences.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.normalizedData.competenceIdsByGroupId.data[action.payload.competencegroupId] = action.payload.ids;
      state.normalizedData.competenceIdsByGroupId.status = T.LoadStatuses.LOADED;
      state.normalizedData.competenceIdsByGroupId.error = null;

      state.normalizedData.competences.data = {
        ...state.normalizedData.competences.data,
        ...action.payload.data,
      };
      state.normalizedData.competences.ids = [...new Set([
        ...state.normalizedData.competences.ids,
        ...action.payload.ids,
      ])];

      state.normalizedData.competences.error = null;
      state.normalizedData.competences.status = T.LoadStatuses.LOADED;
    },
  },
  setSearchterm: (state, action) => {
    state.competences.searchTerm = action.payload.searchTerm;

    if (action.payload.searchTerm) {
      state.normalizedData.competencesSearchResults.status = T.LoadStatuses.IS_LOADING;
    }
  },
  setExpand: (state, action) => {
    state.competences.isExpanded = true;
  },
  getCourseEvents: {
    request: (state, action) => {
      // state.courseEvents.isFetching = true;
      // state.courseEvents.status = T.LoadStatuses.IS_LOADING;
      updateFetchStateRequest(state.courseEvents, action.payload);
    },
    success: (state, action) => {
      // state.courseEvents.isFetching = false;
      // state.courseEvents.status = T.LoadStatuses.LOADED;
      // console.log('current', original(state.courseEvents));
      updateFetchStateSuccess(state.courseEvents);
    },
    failure: (state, action) => {
      // state.courseEvents.error = action.payload.error;
      // state.courseEvents.status = T.LoadStatuses.FAILED;
      updateFetchStateFailure(state.courseEvents, action.payload);
    },
  },
  updateEvents: {
    success: (state, action) => {
      const {data, partial, empty} = action.payload;

      const {
        allEventIds,
        eventById,
        eventIdsByCourseId,
        eventIdsByYearMonth,
      } = data || {};

      if (!partial) updateFetchStateSuccess(state.courseEvents, null);

      if (empty && partial) {
        state.normalizedData.courseEvents.status = state.normalizedData.courseEvents.status === T.LoadStatuses.LOADED
          ? T.LoadStatuses.LOADED
          : T.LoadStatuses.LOADED_PARTIAL;

        return;
      } else if (empty && !partial) {
        state.normalizedData.courseEvents = {
          ...initialState.normalizedData.courseEvents,
          status: T.LoadStatuses.LOADED,
        };

        return;
      }

      state.normalizedData.courseEvents.allEventIds = allEventIds || [];
      state.normalizedData.courseEvents.eventById = eventById || {};
      state.normalizedData.courseEvents.eventIdsByCourseId = eventIdsByCourseId || {};
      state.normalizedData.courseEvents.eventIdsByYearMonth = eventIdsByYearMonth || {};
      state.normalizedData.courseEvents.status = state.normalizedData.courseEvents.status === T.LoadStatuses.LOADED
        ? T.LoadStatuses.LOADED
        : partial ? T.LoadStatuses.LOADED_PARTIAL : T.LoadStatuses.LOADED;
    },
  },

  beginSignature: (state, action) => {
    state.currentSignature = action.payload;
  },

  fetchCourseCatalogNews: {
    request: (state, action) => {
      state.courseCatalogNews.isFetching = true;
      state.courseCatalogNews.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.courseCatalogNews.data = action.payload.data;
      state.courseCatalogNews.status = T.LoadStatuses.LOADED;
      state.courseCatalogNews.isFetching = false;
    },
    failure: (state, action) => {
      state.courseCatalogNews.error = action.payload.error;
      state.courseCatalogNews.status = T.LoadStatuses.FAILED;
      state.courseCatalogNews.isFetching = false;
    },
  },

  getCompetenceDetails: {
    request: (state, action) => {
      state.competenceDetails = {
        ...initialState.competenceDetails,
        isFetching: true,
        data: null,
        error: null,
      };
    },
    success: (state, action) => {
      state.competenceDetails = {
        ...initialState.competenceDetails,
        isFetching: false,
        data: action.payload.competenceDetails,
        error: null,
      };

      state.normalizedData.competenceDetails.ids.push(action.payload.cid);
      state.normalizedData.competenceDetails.data[action.payload.cid] = action.payload.competenceDetails;
    },
    failure: (state, action) => {
      state.competenceDetails.isFetching = false;
      state.competenceDetails.data = null;
      state.competenceDetails.error = action.payload.error;
    },
  },

  startCourse: (state, action) => {
    state.activeCourse = {
      type: action.payload.type || state.activeCourse.type,
      cid: action.payload.cid || state.activeCourse.cid,
      status: 0,
    };

    state.activeCourseMapId = action.payload.mapId || null;
  },

  runCourse: (state, action) => {
    state.activeCourse = {
      type: action.payload.type || state.activeCourse?.type,
      cid: action.payload.cid || state.activeCourse?.cid,
      url: action.payload.url || state.activeCourse.url,
      opened: action.payload.opened || state.activeCourse.opened,
      status: 1,
    };

    state.activeCourseMapId = action.payload.mapId || null;
  },

  runNanoCourse: (state, action) => {
    state.activeCourse = {
      type: action.payload.type || state.activeCourse?.type,
      cid: action.payload.cid || state.activeCourse?.cid,
      url: action.payload.url || state.activeCourse?.url,
      status: 1,
    };

    state.activeCourseMapId = action.payload.mapId || null;
  },

  setActiveCourse: (state, action) => {
    state.activeCourse = {status: 0};
    state.activeCourseMapId = null;
  },

  closeCourse: (state, action) => {
    state.activeCourse = null;
    state.activeCourseMapId = null;
  },

  courseSignOff: {
    request: (state, action) => {
      const {ceid} = action.payload;

      if (ceid && !state.courseSignOff.courseIds.includes(ceid)) {
        state.courseSignOff.courseIds.push(ceid);
      }
      state.courseSignOff.ceid = action.payload.ceid;
      state.courseSignOff.isFetching = true;
      state.courseSignOff.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      const {ceid} = action.payload;

      if (ceid) {
        state.courseSignOff.courseIds = state.courseSignOff.courseIds.filter(id => id !== ceid);
      }
      state.courseSignOff.ceid = null;
      state.courseSignOff.isFetching = false;
      state.courseSignOff.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.courseSignOff = {
        ...initialState.courseSignOff,
        isFetching: false,
        status: T.LoadStatuses.FAILED,
      };
    },
  },

  courseSignOn: {
    request: (state, action) => {
      const {ceid} = action.payload;

      state.courseSignOn = {
        ...initialState.courseSignOn,
        courseIds: state.courseSignOn.courseIds.includes(ceid)
          ? state.courseSignOn.courseIds
          : [...state.courseSignOn.courseIds, ceid],
        isFetching: true,
        ceid,
        results: null,
      };
    },
    success: (state, action) => {
      const {ceid} = action.payload || {};

      if (ceid) {
        state.courseSignOn.courseIds = state.courseSignOn.courseIds.filter(id => id !== ceid);
      }
      state.courseSignOn.isFetching = false;
      state.courseSignOn.status = true;
    },
    failure: (state, action) => {
      const {ceid} = action.payload || {};

      if (ceid) {
        state.courseSignOn.courseIds = state.courseSignOn.courseIds.filter(id => id !== ceid);
      }
      state.courseSignOn.isFetching = false;
      state.courseSignOn.error = action.payload.error;
    },
    reset: (state, action) => {
      // reset to the state before anyone was signed on so we can sign on more folks when we open the modal next time
      state.courseSignOn = initialState.courseSignOn;
    }
  },

  courseSignOnResults: (state, action) => {
    state.courseSignOn.results = action.payload.results;
    state.courseSignOn.isFetching = false;
  },

  courseSignOffResults: (state, action) => {
    state.courseSignOff.results = action.payload.results;
    state.courseSignOff.isFetching = false;
  },

  signCourse: {
    request: (state, action) => {
      state.courseSignCourse = {
        ...initialState.courseSignCourse,
        isFetching: true,
        ceid: action.payload.courseId,
      };
    },
    success: (state, action) => {
      state.courseSignCourse = {
        isFetching: false,
        success: true,
        status: action.payload.status,
      };
    },
    failure: (state, action) => {
      state.courseSignCourse = {
        isFetching: false,
        error: action.payload.error,
      };
    },
  },

  // setSorting
  setSorting: (state, action) => {
    state.sorting = action.payload;
  },

  // getFeaturedCompetences
  getFeaturedCompetences: {
    request: (state, action) => {
      state.featuredCompetences = {
        ...initialState.featuredCompetences,
        isFetching: true,
      };
    },
    success: (state, action) => {
      state.featuredCompetences = {
        ...initialState.featuredCompetences,
        data: action.payload.featuredCompetences,
      };
    },
    failure: (state, action) => {
      state.featuredCompetences = initialState.featuredCompetences;
    },
  },

  courseDeleteFileForPersonResults: (state, action) => {
    state.courseDeleteFileForPerson.isFetching = false;
  },

  courseDeleteFileForPerson: {
    request: (state, action) => {
      state.courseDeleteFileForPerson = {
        ...initialState.courseDeleteFileForPerson,
        isFetching: true,
      };
    },
    success: (state, action) => {
      state.courseDeleteFileForPerson.isFetching = false;
      state.courseDeleteFileForPerson.status = true;
    },
    failure: (state, action) => {
      state.courseDeleteFileForPerson.isFetching = false;
      state.courseDeleteFileForPerson.error = action.payload.error;
      state.courseDeleteFileForPerson.status = false;
    },
  },
  courseError: (state, error) => {
    console.log({error});
  },
  // actions without reducers
  autoFetchCourseEventsFull: null,
  fetchEventsForCompetence: null,
  courseFailure: null,
  courseFinished: null,
  loadCompetenceDetails: {
    request: null,
    success: null,
    failure: null,
  },
  loadCourseEventsFull: {
    request: null,
    success: null,
    failure: null,
  },
};

const {actions, reducer} = createReducerAndActions({
  prefix: 'courses',
  initialState,
  actions: courses,
});

export {reducer as coursesReducerImmer};

export const {
  beginSignature: coursesBeginSignature,
  closeCourse: coursesCloseCourse,
  courseFailure: coursesCourseFailure,
  fetchCourseCatalogNews: coursesFetchCourseCatalogNews,
  courseDeleteFileForPerson: coursesDeleteFileForPerson,
  courseDeleteFileForPersonResults: coursesDeleteFileForPersonResults,
  courseFinished: coursesCourseFinished,
  courseSignOff: coursesCourseSignOff,
  courseSignOn: coursesCourseSignOn,
  courseSignOffResults: coursesCourseSignOffResults,
  courseSignOnResults: coursesCourseSignOnResults,
  courseError: coursesCourseError,
  fetchEventsForCompetence: coursesFetchEventsForCompetence,
  getCompetenceDetails: coursesGetCompetenceDetails,
  getCompetencegroupChildren: coursesGetCompetencegroupChildren,
  getCompetencegroupsList: coursesGetCompetencegroupsList,
  getCompetences: coursesGetCompetences,
  getCourseEvents: coursesGetCourseEvents,
  getFeaturedCompetences: coursesGetFeaturedCompetences,
  loadCompetenceDetails: coursesLoadCompetenceDetails,
  loadCompetencegroup: coursesLoadCompetencegroup,
  loadCompetencegroupsSubcategories: coursesLoadCompetencegroupsSubcategories,
  loadCourseEventsFull: coursesLoadCourseEventsFull,
  runCourse: coursesRunCourse,
  runNanoCourse: coursesRunNanoCourse,
  setActiveCourse: coursesSetActiveCourse,
  setExpand: coursesSetExpand,
  setSearchterm: coursesSetSearchterm,
  setSorting: coursesSetSorting,
  signCourse: coursesSignCourse,
  startCourse: coursesStartCourse,
  updateEvents: coursesUpdateEvents,
  updateSearchResults: coursesUpdateSearchResults,
  autoFetchCourseEventsFull: coursesAutoFetchCourseEventsFull,
} = actions;
