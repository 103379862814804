import {useSelector} from 'react-redux';
import {CloseButton} from '@components/modal/modal-styles';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getIsMobile} from '@selectors/global.selectors';
import {i18n} from '@src/i18n';

export const CloseButtonGray = ({onClick}) => {
  const isMobile = useSelector(getIsMobile);

  return (
    <CloseButton
      onClick={onClick}
      className="close-btn"
      ariaLabel={i18n('globals.close')}
      title={i18n('globals.close')}
      isMobile={isMobile}
    >
      {' '}
      <FontAwesomeIcon icon={faTimes} />
      {' '}
    </CloseButton>
  );
};
