import {RESET_APP, RESET_STORE} from '@actions/global.actions';
import * as rolesActions from '@actions/roles.actions';
import {LoadStatuses} from '@types/load.types';

const initialState = {
  roles: {
    isFetching: false,
    data: null,
    error: null,
  },
  required: {
    isFetching: false,
    data: null,
    error: null,
  },
  organisations: {
    isFetching: false,
    data: null,
    error: null,
  },
  roleDetails: {
    byId: {},
    allIds: [],
    status: LoadStatuses.NOT_LOADED,
  },
};

const roles = (state = initialState, action) => {
  switch (action.type) {
  case RESET_APP:
  case RESET_STORE: {
    return initialState;
  }
  case rolesActions.FETCH_ROLE_REQUEST: {
    return {
      ...state,
      roleDetails: {
        ...state.roleDetails,
        status: LoadStatuses.IS_LOADING,
        [action.payload.roleId]: {
          ...state.roleDetails[action.payload.roleId],
          status: LoadStatuses.IS_LOADING,
        },
      },
    };
  }
  case rolesActions.FETCH_ROLE_SUCCESS: {
    const {role} = action?.payload || {};
    let {id = null} = role || {};

    if (!id) return state;

    id = Number(id);

    return {
      ...state,
      roleDetails: {
        ...state.roleDetails,
        byId: {
          ...state.roleDetails.byId,
          [id]: {
            data: role,
            status: LoadStatuses.LOADED,
          },
        },
        allIds: state.roleDetails.allIds.includes(id)
          ? state.roleDetails.allIds
          : [...state.roleDetails.allIds, id],
        status: LoadStatuses.LOADED,
      },
    };}
  case rolesActions.FETCH_ROLE_FAILURE: {
    return {
      ...state,
      roleDetails: {
        ...state.roleDetails,
        [action.payload.roleId]: {
          ...state.roleDetails[action.payload.roleId],
          status: LoadStatuses.FAILED,
        },
        status: LoadStatuses.FAILED,
      },
    };
  }
  case rolesActions.FETCH_ROLES_REQUEST: {
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  }
  case rolesActions.FETCH_ROLES_SUCCESS: {
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        data: action.payload.roles,
      },
    };
  }
  case rolesActions.FETCH_ROLES_FAILURE: {
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  case rolesActions.FETCH_ROLES_REQUIRED_REQUEST: {
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  }
  case rolesActions.FETCH_ROLES_REQUIRED_SUCCESS: {
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: false,
        data: action.payload.roles,
      },
    };
  }
  case rolesActions.FETCH_ROLES_REQUIRED_FAILURE: {
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  case rolesActions.FETCH_ORGANISATIONS_REQUEST: {
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  }
  case rolesActions.FETCH_ORGANISATIONS_SUCCESS: {
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: false,
        data: action.payload.organisations,
      },
    };
  }
  case rolesActions.FETCH_ORGANISATIONS_FAILURE: {
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: false,
        error: action.payload.error,
      },
    };
  }
  default: {
    return state;
  }
  }
};

export default roles;
