import {courseCatalog, employees} from '@routes/routes.manifest';

export const getRouteWithParams = (url, params) => Object.entries(params).reduce(
  (createdUrl, [paramKey, paramValue]) => createdUrl.replace(`:${paramKey}?`, paramValue || '').replace(`:${paramKey}`, paramValue || ''),
  url,
)
  // if the url is eg /articles/:id
  // and params is {item: /a-article}
  // we dont want to get /articles//a-article
  .replace('//', '/')
  // if the url is eg /track/:mapId?
  // and mapId is not present in params
  // we dont want to get /track/:mapId?
  .replaceAll(/\/:[^/]+?\?/g, '');

export const getRoutesBreadcrumbsArray = moduleRoutes =>
  Object.values(moduleRoutes)
    .sort(({index: indexA}, {index: indexB}) => {
      if (indexA === indexB) {
        throw new Error('You must define different indexes for each route');
      }

      return indexA < indexB ? -1 : 1;
    })
    .map(({path, breadcrumb}) => ({
      path,
      breadcrumb,
    }));

export const getEmployeePreviewPath = userName =>
  getRouteWithParams(employees.employeePreview, {userName});

export const getCoursePath = cid =>
  getRouteWithParams(courseCatalog.coursePreview.path, {cid});
