import _get from 'lodash/get';
import * as T from '@types/load.types';

export const initialConfigObject = {
  data: null,
  getProperty: () => {},
  isModuleEnabled: () => {},
  isMapActivated: undefined,
  status: T.LoadStatuses.NOT_LOADED,
  __meta: null,
};

const defaultEnabledModules = new Set(['my-education', 'profile']);

var __global__configObject = {...initialConfigObject};

export const resetGlobalConfigObject = () => {
  __global__configObject = {...initialConfigObject};
};

/* Get a property using dot syntax, eg a.b.c.d
      Returns undefined if the property is not found. */
const getProperty = data => key => _get(data, key);
const isModuleEnabled = configRoutes => moduleName => configRoutes?.[moduleName]?.disabled === undefined
  ? configRoutes?.[moduleName]?.enable === true || defaultEnabledModules.has(moduleName)
  : !configRoutes?.[moduleName]?.disabled;

// Returns a config-object with data and methods for accessing that data.
export const createOrGetConfigObject = (data, __meta) => {
  if (__global__configObject.data && __global__configObject.__meta === __meta) return __global__configObject;

  if (!data?.routes) return initialConfigObject;

  const {routes = {}} = data || {};

  const _isModuleEnabled = isModuleEnabled(routes);
  const _getProperty = getProperty(data);

  const newConfigObject = {
    ...initialConfigObject,
    data,
    isMapActivated: _isModuleEnabled('atlas') && data.params?.['start-route'],
    isModuleEnabled: _isModuleEnabled,
    getProperty: _getProperty,
    status: T.LoadStatuses.LOADED,
    __meta,
  };

  __global__configObject = newConfigObject;

  return newConfigObject;
};
