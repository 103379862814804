import styled from '@emotion/styled';

const StyledLoadingLine = styled.div`
  &.loader-line {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    overflow: hidden;
    /* background-color: #ddd; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &:before {
      content: "";
      position: absolute;
      left: -50%;
      height: 3px;
      width: 40%;
      background-color: ${props => props.theme.scheme.primary.color200};
      -webkit-animation: lineAnim 1s linear infinite;
      -moz-animation: lineAnim 1s linear infinite;
      animation: lineAnim 1s linear infinite;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }

  @keyframes lineAnim {
    0% {
        left: -40%;
    }
    50% {
        left: 20%;
        width: 80%;
    }
    100% {
        left: 100%;
        width: 100%;
    }
  }
`;

export const LoadingLine = ({className = ''}) => <StyledLoadingLine className={`loader-line ${className}`} />;
