import cloneDeep from 'lodash/cloneDeep';
import {isObjectWithKeys} from '@snapper/core';
import {createReducerAndActions} from '@src/store-normalized/setup/createDefault';
import {updateFetchStateFailure, updateFetchStateRequest, updateFetchStateSuccess} from '@src/store-normalized/util/loadstatus.utils';
import {mergeCompetencesNonNullish} from '@src/store-normalized/util/misc';
import {getMilliseconds} from '@src/store-normalized/util/time';
import * as T from '@types/load.types';
import {getCompetenceId} from '../util/competence-identity';

const initialState = {
  activeOrg: {orgId: null},
  person: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
    storeManager: false,
    orgId: null,
    organisations: null,
    tenets: {
      status: T.LoadStatuses.NOT_LOADED,
      data: null,
    },
    competencelevel: {
      status: T.LoadStatuses.NOT_LOADED,
      data: [],
      error: null,
    },
  },
  helptexts: null,
  tab: 'role',
  section: 'competences',
  selfSign: {
    byId: {},
    data: null,
    status: T.LoadStatuses.NOT_LOADED,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  pendingChecklists: {
    data: {},
    status: T.LoadStatuses.NOT_LOADED,
  },
  competences: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    isSubFetching: false,
    data: [],
    error: null,
    updated: null,
  },
  menu: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  expiring: {
    isFetching: false,
    data: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  tasks: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  tenets: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  passed: {
    isFetching: false,
    data: [],
    error: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
  },
  passed_full: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  events: {
    isFetching: false,
    data: null,
    tracks: null,
    error: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  summary: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
    status: T.LoadStatuses.NOT_LOADED,
    lastFetched: 0,
    refetchAfter: getMilliseconds({minutes: 5}),
  },
  show_role: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  roles: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  report: {
    data: null,
    error: null,
    updated: null,
    isFetching: false,
  },
  cvs: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  cvs_full: {
    isFetching: false,
    data: null,
    error: null,
    updated: null,
  },
  specialroles: {
    data: null,
    superuser: false,
    driftsjef: false,
  },
  isFetching: false,
  updated: null,
  normalizedData: {
    events: {
      status: T.LoadStatuses.NOT_LOADED,
      eventsConfirmed: [],
      eventsWaitlist: [],
      confirmedIds: [],
      waitlistIds: [],
    },
  },
};

const actions = {
  fetchPerson: {
    request: (state, action) => {
      if (!action.payload?.refresh) {
        state.person.isFetching = true;
        // state.person.data = null;
        state.person.updated = null;
      }
    },
    success: (state, action) => {
      state.person.isFetching = false;
      state.person.data = action.payload.person;
      // if (action.payload.competencelevel) {
      //   state.person.competencelevel = action.payload.competencelevel;
      // }
      state.person.updated = new Date();
    },
    failure: (state, action) => {
      state.person.isFetching = false;
      state.person.data = null;
      state.person.error = action.payload.error;
      state.person.updated = null;
    },
  },
  fetchPersonFull: {
    request: (state, action) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
      state.updated = new Date();
    },
  },
  fetchCompetenceLevel: {
    request: (state, action) => {
      state.person.competencelevel.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.person.competencelevel.data = action.payload.competencelevel;
      state.person.competencelevel.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.person.competencelevel.error = action.payload.error;
      state.person.competencelevel.status = T.LoadStatuses.ERROR;
    },
  },
  editPerson: {
    request: (state, action) => {
      state.isFetching = true;
    },
    success: (state, action) => {
      state.isFetching = false;
    },
    failure: (state, action) => {
      state.person.isFetching = false;
      state.person.data = null;
      state.person.error = action.payload.error;
      state.person.updated = null;
    },
  },
  setActiveOrgId: (state, action) => {
    const orgId = !!action.payload && Number.parseInt(action.payload, 10);

    state.activeOrg.orgId = orgId;
  },
  setManager: (state, action) => {
    state.person.storeManager = true;
  },
  setSpecialroles: (state, action) => {
    state.specialroles.data = action.payload.data;
    state.specialroles.superuser = action.payload.superuser;
    state.specialroles.driftsjef = action.payload.driftsjef;
  },
  selfSign: {
    request: (state, action) => {
      updateFetchStateRequest(state.selfSign, action.payload);
      // state.selfSign.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.selfSign, action.payload);
      // state.selfSign.status = T.LoadStatuses.LOADED;
      // state.selfSign.data = action.payload.data;
      // state.selfSign.byId = action.payload.byId;
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.selfSign, action.payload);
      // state.selfSign.status = T.LoadStatuses.LOADED;
    },
  },
  cancelSelfSign: {
    request: (state, action) => {
      state.selfSign.status = T.LoadStatuses.IS_LOADING; // ?
    },
    success: (state, action) => {
      state.selfSign.status = T.LoadStatuses.NEED_RELOAD;
    },
    failure: (state, action) => {
      state.selfSign.status = T.LoadStatuses.FAILED;
    },
  },
  selfSignNeedsReload: (state, action) => {
    state.selfSign.status = T.LoadStatuses.NEED_RELOAD;
  },
  switchTab: (state, action) => {
    state.tab = action.payload;
  },
  switchSection: (state, action) => {
    state.section = action.payload;
  },
  fetchPersonEvents: {
    request: (state, action) => {
      updateFetchStateRequest(state.events, {refresh: !!action.payload?.refresh});

      if (action.payload?.refresh) return;

      state.normalizedData.events.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.events, null);

      state.events.data = action.payload?.events?.personevents
        ? action.payload.events.personevents.filter(pe => pe.competence_type !== 28)
        : state.events.data;

      state.events.tracks = action.payload?.events?.personevents
        ? action.payload.events.personevents.filter(pe => pe.competence_type === 28)
        : state.events.tracks;

      const updated = action.payload
        ? action.payload?.events?.personevents?.reduce?.((acc, event) => {
          if (event.confirmed && !event.waitlist) {
            acc.eventsConfirmed.push(event);
            acc.confirmedIds.push(event.id);
          } else if (event.waitlist) {
            acc.eventsWaitlist.push(event);
            acc.waitlistIds.push(event.id);
          }

          return acc;
        }, {
          eventsConfirmed: [],
          eventsWaitlist: [],
          confirmedIds: [],
          waitlistIds: [],
        })
        : null;

      try {
        if (updated) {
          state.normalizedData.events.eventsConfirmed = updated.eventsConfirmed;
          state.normalizedData.events.eventsWaitlist = updated.eventsWaitlist;
          state.normalizedData.events.confirmedIds = updated.confirmedIds;
          state.normalizedData.events.waitlistIds = updated.waitlistIds;
          state.normalizedData.events.status = T.LoadStatuses.LOADED;
        }
      } catch (error) {
        console.error(error);
      }
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.events, {error: action.payload?.error});
      state.normalizedData.events.status = T.LoadStatuses.FAILED;
    },
  },
  updatePendingChecklists: (state, action) => {
    if (!action.payload?.data) return;

    state.pendingChecklists.data = action.payload.data;
    state.pendingChecklists.status = T.LoadStatuses.LOADED;
  },
  signedOnSingleEvent: (state, action) => {
    state.events.data.push(action.payload.event);
  },
  setLastMessageTimestamp: (state, action) => {
    state.person.data.data.lastMessage = action.payload.last_message;
  },
  fetchHelptexts: (state, action) => {
    state.helptexts = action.payload.data;
  },
  fetchAllOrganisations: {
    request: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.IS_LOADING;
      state.person.tenets.data = null;
    },
    success: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.LOADED;
      state.person.tenets.data = action?.payload?.data?.length == null
        ? null
        : action.payload.data;
    },
    failure: (state, action) => {
      state.person.tenets.status = T.LoadStatuses.FAILED;
      state.person.tenets.data = null;
    },
  },
  fetchRole: {
    request: (state, action) => {
      const {refresh, skipLoadStatus, roleId} = action?.payload || {};

      if (refresh || skipLoadStatus) return;

      state.show_role.isFetching = true;
      state.show_role.data = roleId != null && Number(roleId) === state.show_role.data?.id
        ? state.show_role.data
        : null;
      state.show_role.updated = null;
    },
    success: (state, action) => {
      state.show_role.isFetching = false;
      state.show_role.data = action.payload.role;
      state.show_role.updated = new Date();
    },
    failure: (state, action) => {
      state.show_role.isFetching = false;
      state.show_role.data = null;
      state.show_role.updated = null;
    },
  },
  fetchCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.data = null;
      state.cvs_full.updated = null;
    },
    success: (state, action) => {
      const {cv: fetchedCv} = action.payload;

      state.cvs_full.isFetching = false;
      if (!state.cvs_full.data) {
        state.cvs_full.data = {};
      }
      state.cvs_full.data[fetchedCv.id] = fetchedCv;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.data = null;
      state.cvs_full.updated = null;
      state.cvs_full.error = true;
    },
  },
  fetchMyTasks: {
    request: (state, action) => {
      state.tasks.status = T.LoadStatuses.IS_LOADING;
      // state.tasks.data = null;
    },
    success: (state, action) => {
      state.tasks.status = T.LoadStatuses.LOADED;
      state.tasks.data = action.payload.competences;
    },
    failure: (state, action) => {
      state.tasks.status = T.LoadStatuses.FAILED;
      state.tasks.data = null;
    },
  },
  fetchCvs: {
    request: (state, action) => {
      state.cvs.isFetching = true;
      state.cvs.updated = null;
    },
    success: (state, action) => {
      state.cvs.isFetching = false;
      state.cvs.data = Object.fromEntries(action.payload.cvs.map(cv => [cv.id, cv]));
      state.cvs.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs.isFetching = false;
      state.cvs.error = true;
      state.cvs.updated = null;
    },
  },
  editCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.updated = null;
      state.cvs_full.data = null;
    },
    success: (state, action) => {
      const {cv: fetchedCvFromEdit} = action.payload;

      state.cvs_full.data = {};
      state.cvs_full.data[fetchedCvFromEdit.id] = fetchedCvFromEdit;

      state.cvs_full.isFetching = false;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.data = null;
      state.cvs_full.error = true;
      state.cvs_full.updated = null;
    },
  },
  removeCv: {
    request: (state, action) => {
      state.cvs_full.isFetching = true;
      state.cvs_full.updated = null;
    },
    success: (state, action) => {
      const {removedId} = action.payload;

      delete state.cvs.data[removedId];
      delete state.cvs_full.data[removedId];

      state.cvs_full.isFetching = false;
      state.cvs_full.updated = new Date();
    },
    failure: (state, action) => {
      state.cvs_full.isFetching = false;
      state.cvs_full.error = true;
      state.cvs_full.updated = null;
    },
  },
  fetchReport: {
    request: (state, action) => {
      state.report.isFetching = true;
      // state.report.data = null;
      state.report.updated = null;
    },
    success: (state, action) => {
      state.report.isFetching = false;
      state.report.data = action.payload.data;
      state.report.updated = new Date();
    },
    failure: (state, action) => {
      state.report.isFetching = false;
      state.report.data = null;
      state.report.error = true;
      state.report.updated = null;
    },
  },
  fetchRoles: {
    request: (state, action) => {
      state.roles.isFetching = true;
      state.roles.updated = null;
      // state.roles.data = null;
    },
    success: (state, action) => {
      state.roles.isFetching = false;
      state.roles.data = action.payload.roles;
      state.roles.updated = new Date();
    },
    failure: (state, action) => {
      state.roles.isFetching = false;
      state.roles.data = null;
      state.roles.updated = null;
    },
  },
  fetchPersonSummary: {
    request: (state, action) => {
      updateFetchStateRequest(state.summary, action?.payload);
      state.summary.updated = null;
      // state.summary.isFetching = true;
      // state.summary.data = null;
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.summary, action?.payload);
      if (!isObjectWithKeys(action.payload?.summary)) return;

      if (!state.summary?.data) state.summary.data = {};
      state.summary.updated = new Date();

      // if no data in current state, just set the data from payload
      if (!state.summary.data?.requirement?.length) {
        state.summary.data = {
          ...state.summary.data,
          ...action.payload.summary,
        };

        return;
      }

      const {summary = {}} = action.payload;

      // update each category
      for (const categoryKey of Object.keys(summary)) {
        const categoryRoles = summary[categoryKey] || [];

        // if no data in state, set data from payload
        if (!state.summary.data?.[categoryKey]?.length) {
          state.summary.data[categoryKey] = categoryRoles || [];

          continue;
        }

        // update each role in category
        for (const role of categoryRoles) {
          if (!isObjectWithKeys(role)) continue;

          const index = state.summary.data[categoryKey].findIndex(r => r.id === role.id);

          if (index === -1) {
            // if no role in current state, set data from payload
            state.summary.data[categoryKey].push(role);

            continue;
          }

          // update role in current state (merge)
          state.summary.data[categoryKey][index] = {
            ...state.summary.data[categoryKey][index],
            ...role,
          };
        }
      };
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.summary, action?.payload);
      // state.summary.isFetching = false;
      // state.summary.data = null;
      state.summary.updated = null;
    },
  },
  fetchExpiring: {
    request: (state, action) => {
      updateFetchStateRequest(state.expiring, action?.payload);
      state.expiring.updated = null;
      // state.expiring.isFetching = true;
      // state.expiring.data = null;
      // state.expiring.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      updateFetchStateSuccess(state.expiring, action?.payload);
      // state.expiring.isFetching = false;
      // state.expiring.data = action.payload.data;
      // state.expiring.status = T.LoadStatuses.LOADED;
      state.expiring.updated = new Date();
    },
    failure: (state, action) => {
      updateFetchStateFailure(state.expiring, action?.payload);
      state.expiring.updated = null;
    },
  },
  fetchPassedCompetences: {
    request: (state, action) => {
      state.passed.isFetching = true;
      // state.passed.data = [];
      state.passed.updated = null;
      state.passed.status = T.LoadStatuses.IS_LOADING;
    },
    success: (state, action) => {
      state.passed.isFetching = false;
      state.passed.data = action.payload.competences;
      state.passed.updated = null;
      state.passed.status = T.LoadStatuses.LOADED;
    },
    failure: (state, action) => {
      state.passed.isFetching = null;
      state.passed.data = [];
      state.passed.updated = null;
      state.passed.error = true;
      state.passed.status = T.LoadStatuses.FAILED;
    },
  },
  fetchPassedCompetencesFull: {
    request: (state, action) => {
      state.passed_full.isFetching = true;
      // state.passed_full.data = [];
      state.passed_full.updated = null;
    },
    success: (state, action) => {
      state.passed_full.isFetching = false;
      state.passed_full.data = action.payload.competences;
      state.passed_full.updated = null;
    },
    failure: (state, action) => {
      state.passed_full.isFetching = null;
      state.passed_full.data = null;
      state.passed_full.updated = null;
      state.passed_full.error = true;
    },
  },
  fetchPersonCompetences: {
    request: (state, action) => {
      state.competences.status = T.LoadStatuses.IS_LOADING;
      state.competences.isFetching = true;
      state.competences.updated = null;
    },
    success: (state, action) => {
      state.competences.status = T.LoadStatuses.LOADED;
      state.competences.isFetching = false;
      state.competences.data = action.payload.competences;
      state.competences.updated = new Date();
    },
    failure: (state, action) => {
      state.competences.status = T.LoadStatuses.FAILED;
      state.competences.isFetching = false;
      state.competences.data = [];
      state.competences.error = action.payload.error;
      state.competences.updated = null;
    },
  },
  fetchCompetencesChildren: {
    request: (state, action) => {
      state.competences.isSubFetching = true;
    },
    success: (state, action) => {
      state.competences.isSubFetching = false;
      state.competences.data = action.payload.competences;
      state.competences.updated = new Date();
    },
    failure: (state, action) => {
      state.competences.isSubFetching = false;
    },
  },
  fetchShowRoleChildren: {
    request: (state, action) => {
      state.competences.isSubFetching = true;
      state.show_role.isSubFetching = true;
    },
    success: (state, action) => {
      state.competences.isSubFetching = false;
      state.show_role.isSubFetching = false;
      state.show_role.data = action.payload.competences;
    },
  },
  insertOrUpdatePassedCompetences: (state, action) => {
    const {competences = []} = action.payload || {};

    if (!competences.length) return;

    const {data} = state.passed;

    if (data?.length) {
      competences.forEach(competence => {
        const index = data.findIndex(c => getCompetenceId(c) === getCompetenceId(competence));

        if (index > -1) {
          data[index] = mergeCompetencesNonNullish(cloneDeep(data[index]), competence);
        } else {
          data.push({
            ...competence,
            id: competence?.phce_id || competence?.id,
          });
        }
      });
    } else {
      state.passed.data = competences;
    }
  },
  // actions without reducers
  editPassword: {
    request: null,
    success: null,
    failure: null,
  },
  createSelfSign: {
    request: null,
    success: null,
    failure: null,
  },
  deleteProfilePicture: {
    request: null,
    success: null,
    failure: null,
  },
  changeProfilePicture: {
    request: null,
    success: null,
    failure: null,
  },
  addCv: {
    request: null,
    success: null,
    failure: null,
  },
  updateOneCompetence: {
    request: null,
    success: null,
    failure: null,
  },
  autoFetchSelfSign: null,
  toggleCompetence: null,
  fetchRequirements: null,
  updatePassedCompetences: null,
  cheatCompetence: null,
  updateActiveOrgId: null,
};

export const {
  actions: profileActions,
  reducer: profileImmerReducer,
} = createReducerAndActions({
  prefix: 'profile',
  initialState,
  actions,
});

export const {
  fetchPerson: profileFetchPerson,
  fetchPersonFull: profileFetchPersonFull,
  editPerson: profileEditPerson,
  setActiveOrgId: profileSetActiveOrgId,
  setManager: profileSetManager,
  setSpecialroles: profileSetSpecialroles,
  selfSign: profileSelfSign,
  cancelSelfSign: profileCancelSelfSign,
  selfSignNeedsReload: profileSelfSignNeedsReload,
  switchTab: profileSwitchTab,
  switchSection: profileSwitchSection,
  fetchPersonEvents: profileFetchPersonEvents,
  signedOnSingleEvent: profileSignedOnSingleEvent,
  setLastMessageTimestamp: profileSetLastMessageTimestamp,
  fetchHelptexts: profileFetchHelptexts,
  fetchAllOrganisations: profileFetchAllOrganisations,
  fetchRole: profileFetchRole,
  fetchCv: profileFetchCv,
  fetchMyTasks: profileFetchMyTasks,
  fetchCvs: profileFetchCvs,
  editCv: profileEditCv,
  removeCv: profileRemoveCv,
  fetchReport: profileFetchReport,
  fetchRoles: profileFetchRoles,
  fetchPersonSummary: profileFetchPersonSummary,
  fetchExpiring: profileFetchExpiring,
  fetchPassedCompetences: profileFetchPassedCompetences,
  fetchPassedCompetencesFull: profileFetchPassedCompetencesFull,
  fetchPersonCompetences: profileFetchPersonCompetences,
  fetchCompetencesChildren: profileFetchCompetencesChildren,
  fetchShowRoleChildren: profileFetchShowRoleChildren,
  editPassword: profileEditPassword,
  fetchRequirements: profileFetchRequirements,
  createSelfSign: profileCreateSelfSign,
  deleteProfilePicture: profileDeleteProfilePicture,
  updateOneCompetence: profileUpdateOneCompetence,
  updatePassedCompetences: profileUpdatePassedCompetences,
  cheatCompetence: profileCheatCompetence,
  updateActiveOrgId: profileUpdateActiveOrgId,
  changeProfilePicture: profileChangeProfilePicture,
  addCv: profileAddCv,
  toggleCompetence: profileToggleCompetence,
  fetchCompetenceLevel: profileFetchCompetenceLevel,
  updatePendingChecklists: profileUpdatePendingChecklists,
  insertOrUpdatePassedCompetences: profileInsertOrUpdatePassedCompetences,
  autoFetchSelfSign: profileAutoFetchSelfSign,
} = profileActions;

export default profileImmerReducer;
