import {createSelector} from 'reselect';

export const AnimationStatus = {
  IDLE: 'idle',
  FORWARD: 'forward',
  BACKWARD: 'backward',
};

export const getCourseCatalogComponentState = ({components: {courseCatalog: {state: courseCatalogState}}}) => courseCatalogState;
export const getCourseCatalogLayoutConfig = ({components: {courseCatalog: {layout}}}) => layout || {};

export const selectMapComponent = ({components: {map}}) => map;
export const selectMapComponentIsInitialLoad = ({components: {map: {isInitialLoad}}}) => isInitialLoad;

export const selectMapComponentAnimationIndex = ({components: {map: {animationIndex}}}) => animationIndex;
export const selectMapComponentAnimationTargetIndex = ({components: {map: {animationTargetIndex}}}) => animationTargetIndex;

export const selectMapComponentAnimationStatus = createSelector(
  selectMapComponentAnimationIndex,
  selectMapComponentAnimationTargetIndex,
  selectMapComponentIsInitialLoad,
  (animationIndex, animationTargetIndex, isInitialLoad) => {
    const reachedTarget = animationIndex === animationTargetIndex;

    const status = reachedTarget
      ? AnimationStatus.IDLE
      : animationIndex < animationTargetIndex
        ? AnimationStatus.FORWARD
        : AnimationStatus.BACKWARD;

    return {
      status,
      reachedTarget,
      isInitialLoad,
    };
  },
);

export const selectMapComponentSelectedDotIndex = ({components: {map: {selectedDotIndex}}}) => selectedDotIndex;
export const selectMapComponentLastOpenedIndex = ({components: {map: {lastOpenedIndex}}}) => lastOpenedIndex;
