import axios from 'axios';
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {competencesToggleSuccess} from '@actions/competences.actions';
import {componentsSetMap} from '@actions/components.actions';
import {coursesCourseFinished, coursesSignCourse, coursesStartCourse} from '@actions/courses.actions';
import {employeesSaveVerification} from '@actions/employees.actions';
import * as mapCoursesActions from '@actions/map.actions';
import {profileCreateSelfSign, profileInsertOrUpdatePassedCompetences, profileUpdatePassedCompetences} from '@actions/profile.actions';
import {backendUrl, backendUrlV2} from '@config';
import {selectMapComponent} from '@selectors/components.selectors';
import {
  getCurrTrack,
  getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';
import {
  completeExtraDataStatusByTrackIdSelector,
  extraDataByCompetenceIdSelector,
  getCourseTitleParams,
  getIsAllMapDotsCompleted,
} from '@selectors/map.selectors.new';
import {getTrack, selectLearningPathChildrenData} from '@selectors/tracks.selectors';
import {isObjectWithKeys} from '@snapper/core';
import {mergeCompetencesNonNullish} from '@src/store-normalized/util/misc';
import {LoadStatuses} from '@types/load.types';
import {retry} from '@utils/sagas.utils';
import {fetchCompetenceAPI} from '../api/api';
import {fetchParentAndChildrenPersonCompetencesAPI, fetchPersonCompetenceAPI} from '../api/person-competences';
import {getCompetenceId, getCompetenceType, isChecklistItemSelfSignOrSignature} from '../util/competence-identity';
import {waitForProfileId, waitForUsername} from './app.sagas';
import {getMapOrTrackIdFromCompetenceId} from './profile.sagas';

const watchLearningPathAction = takeEvery(
  [
    `${profileCreateSelfSign.success}`,
    `${competencesToggleSuccess().type}`,
    `${coursesSignCourse.success}`,
    `${employeesSaveVerification.success}`,
    `${coursesCourseFinished().type}`,
  ],
  function* onAction(action) {
    try {
      const {payload = {}, type} = action || {};
      const competenceId = getCompetenceId(payload);

      if (!competenceId) return;

      const trackId = payload?.trackId || payload?.mapId || (yield call(getMapOrTrackIdFromCompetenceId, {competenceId}));

      if (!trackId) return;

      yield put(mapCoursesActions.fetchTrackExtraData({
        trackId,
        cid: competenceId,
      }));
    } catch (error) {
      console.error(error);
    }
  },
);

export function* updateMapComponentState(action) {
  try {
    const {
      mapId,
      dotts = [],
      verification: verificationPayload,
      outro: outroPayload,
      firstOpenIndex,
      completedCount,
      skipAnimation,
    } = action?.payload || {};

    if (mapId == null) return;

    const outro = outroPayload || dotts.find(t => getCourseTitleParams(t?.title)?.includes?.('outro'));
    const verification = verificationPayload || dotts.find(t => getCourseTitleParams(t?.title)?.includes?.('verification'));

    const dottsWithoutVerificationAndOutro = dotts.filter(dot => dot.id !== verification?.id && dot.id !== outro?.id);
    const completedLength = completedCount == null
      ? dottsWithoutVerificationAndOutro.filter(dot => dot?.status === 'DONE').length
      : completedCount;

    const currentComponentState = yield select(selectMapComponent);

    const isInitialLoad = Number(currentComponentState.id) !== Number(mapId);

    const shouldSkipAnimation = skipAnimation == null
      ? isInitialLoad
      : skipAnimation;

    const animationIndex = shouldSkipAnimation
      ? completedLength - 1
      : firstOpenIndex == null
        ? currentComponentState.animationIndex
        : firstOpenIndex - 1;

    yield put(componentsSetMap({
      id: Number(mapId),
      animationIndex,
      animationTargetIndex: Math.min(animationIndex + 1, dottsWithoutVerificationAndOutro.length - 1),
      skipAnimation: shouldSkipAnimation,
    }));
  } catch (error) {
    console.error(error);
  }
}

function* watchFetchMapSuccess({payload}) {
  const {
    data = {},
    firstOpenIndex,
    completedCount,
    skipAnimation,
    skipComponentUpdate,
  } = payload || {};

  const {
    id: mapId,
    dotts = [],
    verification,
    outro,
  } = data?.tracks?.[0] || {};

  if (!mapId || !dotts?.length || skipComponentUpdate) return;

  try {
    yield call(updateMapComponentState, {
      payload: {
        mapId,
        dotts,
        verification,
        outro,
        firstOpenIndex,
        completedCount,
        skipAnimation,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapCourses(action) {
  const {
    id: idPayload,
    refetch,
    selectFirstDot: shouldSelectFirstDot = false,
    skipAnimation,
    firstOpenIndex,
    skipComponentUpdate,
  } = action.payload;

  const mapId = idPayload || (yield select(getCurrTrack));

  if (!mapId) return;

  const trackId = Number(mapId);

  yield put(mapCoursesActions.fetchMapCoursesRequest({
    refetch,
    id: trackId,
  }));

  try {
    const mapCourses = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/tracks/${trackId}/view`,
      params: {id: trackId},
      withCredentials: true,
    })
      .then(res => res.data));

    const dottsData = mapCourses?.tracks?.[0]?.dotts || [];
    const indexOfFirstOpenDot = firstOpenIndex == null
      ? dottsData.findIndex(t => t.status === 'OPEN')
      : firstOpenIndex;

    if (indexOfFirstOpenDot != null && indexOfFirstOpenDot > -1) {
      yield put(mapCoursesActions.fetchTrackExtraData({
        trackId,
        cid: getCompetenceId(dottsData[indexOfFirstOpenDot]),
      }));
    }

    /* FIND IF WE HAVE VERIFICATION OR NOT. */
    const verification = dottsData.find(t => getCourseTitleParams(t.title).includes('verification'));
    const outro = dottsData.find(t => getCourseTitleParams(t.title).includes('outro'));

    if (verification?.status === 'DONE') yield put(mapCoursesActions.setMapIsVerified({}));
    if (outro?.status === 'DONE') yield put(mapCoursesActions.setOutroIsCompleted({}));

    // lock all dots after the first open one
    const dotts = dottsData
      .map((dot, index) =>
        indexOfFirstOpenDot === -1
        || index <= indexOfFirstOpenDot
        || dot.id === verification?.id
        || dot.id === outro?.id
          ? {
            ...dot,
            real_status: dot.status,
            index,
          }
          : {
            ...dot,
            status: 'LOCKED',
            real_status: dot.status,
            index,
          });

    if (mapCourses?.tracks?.[0]?.dotts?.length) mapCourses.tracks[0].dotts = dotts;

    yield put(mapCoursesActions.fetchMapCoursesSucceeded({
      mapId: trackId,
      data: mapCourses,
      skipAnimation: skipAnimation == null ? undefined : skipAnimation,
      firstOpenIndex: indexOfFirstOpenDot,
      skipComponentUpdate,
    }));

    const isAllDone = yield select(getIsAllMapDotsCompleted);

    if (isAllDone) {
      const badge = yield select(getPropertiesForCurrLangAndTrackBadge);

      if (badge) yield put(mapCoursesActions.selectMapCourse({id: 'badge'}));
    }

    if (indexOfFirstOpenDot > -1 && shouldSelectFirstDot) {
      const dot = dotts?.[indexOfFirstOpenDot];

      if (!dot) return;

      yield put(mapCoursesActions.selectMapCourse({id: dot.id}));

      if (dot.autostart) {
        console.log('autostart stuff.');
        yield put(coursesStartCourse({
          cid: dot.id,
          type: 'nano',
          mapId: trackId,
        }));
      }
    }
  } catch (error) {
    yield put(mapCoursesActions.fetchMapCoursesFailed({
      id: trackId,
      error,
    }));
    console.error(error);
  }
}

// fetches additional data for children competences of active learning-path/map
export function* fetchTrackExtraDataSaga(action) {
  const {payload, type} = action || {};

  const {
    trackId,
    mapId,
    cid,
    parentCid,
    childrenIds: childrenIdsPayload,
    personId: personIdPayload,
    userName: userNamePayload,
  } = payload || {};

  const profileId = yield call(waitForProfileId);
  const profileUserName = yield call(waitForUsername);
  const userName = userNamePayload || profileUserName;

  const isEmployeeCompetence = !!personIdPayload && personIdPayload !== profileId
    || !!userNamePayload && userNamePayload !== profileUserName;

  if (cid && !isEmployeeCompetence) {
    try {
      // we are only fetching data for one competence (e.g. when user has completed a competence)
      const competenceById = yield select(selectLearningPathChildrenData);

      const parentCompetences = parentCid
        ? [competenceById?.[parentCid]]
        : isObjectWithKeys(competenceById) && Object.values(competenceById)
          .filter(item => item?.children_ids?.some?.(id => id === cid)) || [];

      if (parentCompetences?.length) {
        // if parent, fetch children for parent and children
        for (const parent of parentCompetences) {
          const parentId = getCompetenceId(parent);
          const parentChildrenCompetences = yield call(fetchParentAndChildrenPersonCompetencesAPI, {cid: parentId});

          if (!parentChildrenCompetences?.length) continue;

          const passedCompetences = parentChildrenCompetences.filter(item => item.passed);

          if (passedCompetences.length) {
            yield put(profileInsertOrUpdatePassedCompetences({competences: passedCompetences}));
            if (passedCompetences[0].passed === 100) {
              yield put(profileUpdatePassedCompetences({
                data: passedCompetences[0],
                cid,
                mapId,
                isPassed: true,
              }));
            }
          }

          if (parentChildrenCompetences.length) yield put(mapCoursesActions.insertOrUpdateChildrenCompetences({
            parentId: trackId,
            children: {data: parentChildrenCompetences},
          }));
        }
      } else {
        // no parents, fetch only data for this competence
        const personCompetence = yield call(fetchPersonCompetenceAPI, {
          cid,
          username: userName,
        });

        if (personCompetence) {
          if (personCompetence.passed) yield put(profileInsertOrUpdatePassedCompetences({competences: [personCompetence]}));
          yield put(mapCoursesActions.insertOrUpdateChildrenCompetences({
            parentId: trackId,
            children: {data: [personCompetence]},
          }));
          if (personCompetence.passed === 100) {
            yield put(profileUpdatePassedCompetences({
              data: personCompetence,
              cid,
              mapId,
              isPassed: true,
            }));
          }
        }
      }

      return {error: null};
    } catch (error) {
      console.error(error);

      return {error};
    }
  }
  // we are fetching data for all children competences of active learning-path/map
  try {
    if (!trackId) throw new Error('NO_TRACK_ID');

    const status = yield select(completeExtraDataStatusByTrackIdSelector(trackId));

    if (status === LoadStatuses.IS_LOADING) return {error: null};

    const cidsPayload = [...new Set([cid, ...childrenIdsPayload || []])].filter(Boolean);
    let cids = cidsPayload;
    const skipCids = [];

    if (cidsPayload.length) {
      for (const id of cids) {
        if (skipCids.includes(id)) continue;

        const {status} = yield select(extraDataByCompetenceIdSelector(id));

        if (status === LoadStatuses.IS_LOADING) skipCids.push(id);
      }
    } else {
      cids = undefined;
    }

    yield put(mapCoursesActions.fetchTrackExtraDataRequest({
      trackId,
      all: !cids?.length,
      cids,
    }));

    // const {status: currentPassedStatus} = yield select(getPassed);

    // if ([LoadStatuses.IS_LOADING, LoadStatuses.NOT_LOADED].includes(currentPassedStatus)) {
    //   yield take([
    //     `${profileFetchPassedCompetences.success}`,
    //     `${profileFetchPassedCompetences.failure}`,
    //   ]);
    // }

    const trackChildren = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/tracks/${trackId}/children`,
      params: {
        fields: 'id,track_image,title,children_ids,layout,description',
        limit: 100,
        users_organisations_only: 1,
      },
      withCredentials: true,
    }).then(res => res.data?.filter(c => cid
      ? getCompetenceId(c) === cid || c.children_ids?.some?.(childId => childId === cid)
      : true)?.map(item => ({
      ...item,
      trackId,
    })))) || [];

    const allChildrenIds = [...new Set(trackChildren.flatMap(item => {
      const competence_id = getCompetenceId(item);

      if (cid) {
        if (competence_id === cid) return [competence_id, ...item.children_ids || []];
        if (item.children_ids?.some?.(id => id === cid)) return [competence_id, ...item.children_ids || []/* cid*/];

        return [];
      } else if (childrenIdsPayload) {
        if (childrenIdsPayload.includes(competence_id) || item.children_ids?.some?.(id => childrenIdsPayload.includes(id))) {
          return [competence_id, ...item.children_ids || []];
        }

        return [];
      } else {
        return [competence_id, ...item.children_ids || []];
      }
    }))];

    if (!allChildrenIds?.length) {
      yield put(mapCoursesActions.fetchTrackExtraDataSuccess({trackId: trackId || mapId}));

      return {error: null};
    }

    if (!cids) {
      cids = [...new Set(allChildrenIds)];

      yield put(mapCoursesActions.fetchTrackExtraDataRequest({
        trackId,
        all: !cidsPayload?.length,
        cids,
      }));
    }

    const filteredChildren = trackChildren
      .filter(item => allChildrenIds.includes(getCompetenceId(item)) || item.children_ids?.some?.(id => allChildrenIds.includes(id)));

    const joinedData = {};

    if (filteredChildren?.length) {
      const selfSignIds = filteredChildren
        .filter(item => isChecklistItemSelfSignOrSignature(item))
        .map(item => getCompetenceId(item));
      const complexCompetenceIds = filteredChildren
        .filter(item => ['complex', 'checklist', 'equivalents', 'track'].includes(getCompetenceType(item)))
        .map(item => getCompetenceId(item));

      const complexCompetenceCalls = complexCompetenceIds.map(id => call(fetchParentAndChildrenPersonCompetencesAPI, {cid: id}));
      const selfSignCalls = selfSignIds.map(id => call(fetchCompetenceAPI, {cid: id}));
      const personCompetencesCalls = selfSignIds.map(id => call(fetchPersonCompetenceAPI, {
        cid: id,
        username: userName,
      }));

      const {selfSignRes, complexRes, personCompetencesRes} = yield all({
        selfSignRes: all(selfSignCalls),
        complexRes: all(complexCompetenceCalls),
        personCompetencesRes: all(personCompetencesCalls),
      });

      if (selfSignRes?.length) {
        selfSignRes.forEach(item => {
          if (!item?.competence_id) return;
          joinedData[item.competence_id] = item;
        });
      }
      if (complexRes?.length) {
        complexRes.flat().forEach(item => {
          if (!item?.competence_id) return;
          joinedData[item.competence_id] = mergeCompetencesNonNullish(
            joinedData[item.competence_id],
            item,
          );
        });
      }
      if (personCompetencesRes?.length) {
        personCompetencesRes?.forEach(item => {
          if (!item?.competence_id) return;
          joinedData[item.competence_id] = mergeCompetencesNonNullish(
            joinedData[item.competence_id],
            item,
          );
        });
      }
    }

    const ids = [...new Set([...allChildrenIds, ...cids, Object.keys(joinedData)].filter(Boolean).map(Number))];

    const competences = [];
    const passedCompetences = [];

    ids.forEach(id => {
      const competence = mergeCompetencesNonNullish(
        trackChildren.find(competence => getCompetenceId(competence) === id),
        joinedData[id],
        {
          trackId,
          mapId,
        },
      );

      if (!getCompetenceId(competence)) return;

      if (!isEmployeeCompetence && competence.passed) passedCompetences.push(competence);
      competences.push(competence);
    });

    if (passedCompetences.length) yield put(profileInsertOrUpdatePassedCompetences({competences: passedCompetences}));
    if (!isEmployeeCompetence && competences.length) yield put(mapCoursesActions.insertOrUpdateChildrenCompetences({
      parentId: trackId,
      children: {data: competences},
    }));

    yield put(mapCoursesActions.fetchTrackExtraDataSuccess({
      trackId: trackId || mapId,
      children: trackChildren,
      joinedData,
      competences,
      passedCompetences,
    }));

    return {
      error: null,
      children: trackChildren,
      selfSignData: joinedData,
      competences,
    };
  } catch (error) {
    console.error('fetchTrackExtraData error', error);

    yield put(mapCoursesActions.fetchTrackExtraDataFailure({
      trackId,
      error,
    }));

    return {
      error,
      children: [],
      selfSignData: {},
      competences: [],
    };
  }
}

function* getTrackDataSaga(trackId) {
  try {
    const track = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/competences/${trackId || ''}`,
      params: {
        fields: 'track_image,title,children_ids,layout,description',
        users_organisations_only: 1,
      },
      withCredentials: true,
    }).then(res => res.data));

    const {children_ids = [], layout} = track || {};

    yield put(mapCoursesActions.fetchTrackExtraData({
      trackId,
      childrenIds: children_ids,
      onlyFetchFirstOpen: layout === 'track',
    }));

    while (true) {
      const {payload: {competences = [], error, trackId} = {}} = yield take([
      `${mapCoursesActions.fetchTrackExtraDataSuccess().type}`,
      `${mapCoursesActions.fetchTrackExtraDataFailure().type}`,
      ]);

      if (trackId !== track.id) continue;

      if (error) throw error;

      return {
        error,
        data: track,
        tracks: competences,
      };
    }
  } catch (error) {
    return {error};
  }
}

export function* fetchTrack(action) {
  const {id, parentId} = action.payload || {};

  const idNumber = !!id && Number(id);
  const parentIdNumber = !!parentId && Number(parentId);

  if (!idNumber && !parentIdNumber) return;

  try {
    const {
      data: currentData,
      tracks: currentTracks,
      status: currentStatus,
      parentTrack: currentParentTrack,
    } = yield select(getTrack);

    const currentId = currentData?.id;
    const currentParentId = currentParentTrack?.data?.id;

    const trackIsCurrentTrack = !!idNumber && currentId === idNumber;
    const parentIsCurrentParent = !!parentIdNumber && currentParentId === parentIdNumber;

    if (trackIsCurrentTrack && parentIsCurrentParent) return;

    const shiftTrackToTop = !!parentIdNumber && currentId === parentIdNumber;
    const shiftParentToBottom = !!idNumber && currentParentId === idNumber;

    const newTrackState = {
      ...shiftParentToBottom
        ? {
          data: currentParentTrack.data,
          tracks: currentParentTrack.tracks,
          status: currentParentTrack.status,
        }
        : {},
      parentTrack: shiftTrackToTop
        ? {
          data: currentData,
          tracks: currentTracks,
          status: currentStatus,
        }
        : undefined,
    };

    if (shiftTrackToTop || shiftParentToBottom) {
      yield put(mapCoursesActions.fetchTrackSuccess(newTrackState));
    }

    const shouldFetchTrack = !!idNumber && !shiftParentToBottom && !trackIsCurrentTrack;
    const shouldFetchParentTrack = !!parentIdNumber && !shiftTrackToTop && !parentIsCurrentParent;

    if (!shouldFetchTrack && !shouldFetchParentTrack) return;

    yield put(mapCoursesActions.fetchTrackRequest());
    // if (id) {
    const calls = {
      ...shouldFetchTrack ? {track: call(getTrackDataSaga, idNumber)} : {},
      ...shouldFetchParentTrack ? {parentTrack: call(getTrackDataSaga, parentIdNumber)} : {},
    };

    const res = yield all(calls);

    const payload = {
      ...shouldFetchTrack
        ? {
          data: res.track.data,
          tracks: res.track.tracks,
          status: res.track.status,
        }
        : {},
      ...shouldFetchParentTrack
        ? {
          parentTrack: {
            data: res.parentTrack.data,
            tracks: res.parentTrack.tracks,
            status: res.parentTrack.status,
          },
        }
        : {},
      status: LoadStatuses.LOADED,
    };

    yield put(mapCoursesActions.fetchTrackSuccess(payload));
  } catch (error) {
    console.log('errore');
    console.error(error);
    yield put(mapCoursesActions.fetchTrackFailure({
      error,
      errorReason: error?.response?.status === 404 ? 'COMPETENCE_NOT_FOUND' : 'UNKNOWN',
    }));
  }
}

export function* fetchMapCompetence(action) {
  const {id} = action.payload || {};

  console.log('start up....', id);
  if (!id) return;

  yield put(mapCoursesActions.fetchMapCompetenceRequest({id}));
  try {
    const competence = yield retry(() => axios
      .request({
        method: 'GET',
        url: `${backendUrlV2}/competences/${id}`,
        params: {fields: 'title,files,children'},
        withCredentials: true,
      })
      .then(res => res.data));

    console.log('is done', competence);
    yield put(mapCoursesActions.fetchMapCompetenceSucceeded({data: competence}));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapVerificationCourse(action) {
  yield put(mapCoursesActions.fetchMapVerificationCompetenceRequest());
  const {cid} = action.payload || {};

  if (!cid) return;

  try {
    const course = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?fields=short_description,files`,
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapVerificationCompetenceSucceeded({data: course.competences[0]}));
  } catch (error) {
    console.error(error);
  }
}

const exportObj = [
  watchLearningPathAction,
  takeEvery(mapCoursesActions.fetchTrackExtraData().type, fetchTrackExtraDataSaga),
  takeLatest(mapCoursesActions.fetchMapCourses().type, fetchMapCourses),
  takeEvery(mapCoursesActions.fetchTrack().type, fetchTrack),
  takeLatest(mapCoursesActions.fetchMapCompetence().type, fetchMapCompetence),
  takeLatest(
    mapCoursesActions.fetchMapVerificationCompetence().type,
    fetchMapVerificationCourse,
  ),
  takeLatest(mapCoursesActions.fetchMapCoursesSucceeded().type, watchFetchMapSuccess),
];

export default exportObj;
