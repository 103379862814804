import cloneDeep from 'lodash/cloneDeep';
import memoize from 'micro-memoize';
import {createSelector, createStructuredSelector} from 'reselect';
import {initialState} from '@reducers/map.reducers';
import {validNumberOrNull} from '@src/features/modals/components/common-modals/checklist-modal';
import {isObjectWithKeys, mergeCompetencesNonNullish} from '@src/store-normalized/util/misc';
import {LoadStatuses} from '@types/load.types';
import {verifyPassed} from '@utils/misc.utils';
import {getCompetenceId, getCompetenceType, getPassedTasksInfo, isNanoOrEcourse, isSelfSign, isTrackCompetence} from '../util/competence-identity';
import {selectTrackData as selectMapData} from './map.selectors.new';
import {getPassed, getSelfSign, selectProfileCompetences, selectProfileId} from './profile.selectors';

export const objectKeysOrEmptyArray = (obj = {}) => isObjectWithKeys(obj)
  ? Object.keys(obj)
  : [];

const emptyLearningPath = {
  data: {},
  children: [],
  childrenById: {},
  status: LoadStatuses.NOT_LOADED,
};

const emptyTrackAndMap = {
  track: emptyLearningPath,
  map: emptyLearningPath,
};

const _selectMap = state => state?.map || initialState;

export const getTrack = state => state?.map?.track || initialState.track;

export const selectNormalizedLearningPathCompetence = createSelector(
  _selectMap,
  ({competenceById = {}} = {}) => competenceById || {},
);

const selectPassedById = createSelector(
  getPassed,
  ({byId = {}} = {}) => byId,
);

const selectPassedIds = createSelector(
  getPassed,
  ({allIds = []} = {}) => allIds,
);

const selectSelfSignById = createSelector(
  getSelfSign,
  ({byId = {}} = {}) => byId,
);

const selectProfileCompetencesById = createSelector(
  selectProfileCompetences,
  ({byId = {}} = {}) => byId,
);

const joinMapOrTrackData = ({
  isMap,
  selectedTrack = {},
  selectedMap = {},
  passedDataById,
  selfSignById,
  profileCompetenceById,
  profileId,
  passedCompetenceIds,
  competenceById,
}) => {
  const trackId = selectedTrack ? selectedTrack?.data?.id : null;
  const mapId = selectedMap ? selectedMap?.id : null;

  if (!trackId && !mapId) return emptyLearningPath;

  let children = [];
  let childrenIds = [];
  let data = {};
  const childrenById = {};
  const allIds = [];

  if (isMap) {
    children = selectedMap?.tracks?.[0]?.dotts || [];
    children.forEach(item => {
      const id = getCompetenceId(item);

      childrenIds.push(id);
      childrenById[id] = item;
    });
    data = {
      id: selectedMap.id,
      title: selectedMap.title,
    };
  } else {
    children = selectedTrack.tracks || [];
    childrenIds = selectedTrack.data?.children_ids || children.map(item => getCompetenceId(item));
    data = selectedTrack.data || {};
    children.forEach(item => {
      const id = getCompetenceId(item);

      childrenById[id] = item;
    });
  }

  if (childrenIds?.length) {
    childrenIds.forEach(id => {
      if (!allIds.includes(id)) allIds.push(id);

      const competence = childrenById[id];

      if (!competence) return;

      if (competence?.children_ids?.length) {
        competence.children_ids.forEach(cid => {
          if (!allIds.includes(cid)) allIds.push(cid);
        });
      }
    });
  }

  const childrenExtraData = [];
  const childrenExtraDataById = {};

  allIds?.forEach?.(cid => {
    const competence = childrenById[cid];

    if (!competence) return;

    const passedObj = passedDataById[cid];
    const selfSignObj = selfSignById[cid];

    const {
      pchce_id,
      date: passed_date,
      ...passed
    } = passedObj || {};

    childrenExtraDataById[cid] = mergeCompetencesNonNullish(
      cloneDeep(competenceById[cid]),
      cloneDeep(profileCompetenceById[cid]),
      cloneDeep(passed),
      cloneDeep(competence),
      {
        files: [
          ...competence?.files || [],
          ...competence?.competence?.files || [],
          ...profileCompetenceById?.[cid]?.competence?.files || [],
        ].filter(file => file?.title !== 'cover'),
      },
      {
        passed_id: pchce_id,
        phceId: pchce_id,
        profile_id: profileId,
        competence_id: cid, // getCompetenceId(competence),
        competence_type: getCompetenceType(competence),
        short_description: competence.abstract || competence.short_description,
        competence_title: competence.title,
        passed_date,
      },
      cloneDeep(selfSignObj),
      {
        trackId,
        mapId,
      },
    );

    const mergedCompetence = childrenExtraDataById[cid];

    // remove duplicate files
    mergedCompetence.files = mergedCompetence.files
      .filter((file, index, self) => self.findIndex(f => f.id === file.id) === index);

    // filter duplicate children_ids
    if (mergedCompetence?.children_ids?.length) {
      mergedCompetence.children_ids = mergedCompetence.children_ids
        .filter((childId, index, self) => self.indexOf(childId) === index);
    }

    mergedCompetence.isPassed = verifyPassed(mergedCompetence);

    const passedInfo = getPassedTasksInfo({
      competence: mergedCompetence,
      passedCompetenceIds,
    });

    mergedCompetence.passedInfo = passedInfo;

    if (childrenIds.includes(mergedCompetence.competence_id)) {
      childrenExtraData.push(mergedCompetence);
    }
  });

  const nestedChildren = childrenExtraData.map(item => {
    const {children = []} = item;

    if (children?.length) {
      const newChildren = children?.map?.(child => {
        const id = getCompetenceId(child);

        return mergeCompetencesNonNullish(
          cloneDeep(childrenById[id]),
          cloneDeep(child),
          cloneDeep(childrenExtraDataById[id]),
        );
      });

      item.children = newChildren;

      return item;
    } else {
      return item;
    }
  }) || [];

  return {
    data,
    children: nestedChildren,
    childrenById: childrenExtraDataById,
    status: LoadStatuses.LOADED,
  };
};

// memoized selector for active track/map (learning path) with extra info for child competences:
// files list, phce/passed, waiting for approval etc.
export const memoizedSelectTrackExtraData = memoize(type => createSelector(
  selectProfileId,
  selectPassedIds,
  selectPassedById,
  selectSelfSignById,
  selectProfileCompetencesById,
  getTrack,
  selectMapData,
  selectNormalizedLearningPathCompetence,
  (
    profileId,
    passedCompetenceIds = [],
    passedDataById = {},
    selfSignById = {},
    profileCompetenceById = {},
    selectedTrack = {},
    selectedMap = {},
    competenceById = {},
  ) => {
    if (!profileId) return emptyTrackAndMap;

    const isTrackSelected = type !== 'map' && !!selectedTrack?.data?.id;
    const isMapSelected = type !== 'track' && !!selectedMap?.id;

    if (
      !isTrackSelected && !isMapSelected
      || type === 'track' && !isTrackSelected
      || type === 'map' && !isMapSelected
    ) return emptyTrackAndMap;

    return {
      map: isMapSelected
        ? joinMapOrTrackData({
          isMap: true,
          selectedMap,
          passedDataById,
          selfSignById,
          profileCompetenceById,
          profileId,
          passedCompetenceIds,
          competenceById,
        })
        : emptyLearningPath,
      track: isTrackSelected
        ? joinMapOrTrackData({
          selectedTrack,
          passedDataById,
          selfSignById,
          profileCompetenceById,
          profileId,
          passedCompetenceIds,
          competenceById,
        })
        : emptyLearningPath,
    };
  },
));

export const selectLearningPathTrackExtraData = createSelector(
  memoizedSelectTrackExtraData('track'),
  data => data?.track,
);

export const selectLearningPathMapExtraData = createSelector(
  memoizedSelectTrackExtraData('map'),
  data => data?.map,
);

// selector for track (learning path) - sections view
export const selectTrackSectionsView = createSelector(
  selectLearningPathTrackExtraData,
  track => {
    if (!track?.data?.id) return emptyLearningPath;

    const {
      data: {
        title,
        id,
        track_image,
        description,
      },
      children = [],
      status,
    } = track;
    let openedIdx = 0;

    const items = children?.map?.((competence, idx) => {
      if (!competence?.passedInfo) return null;

      const {
        passedInfo: {
          total: tasksTotal,
          completed: tasksCompleted,
          isPassed,
          passedPercent,
          waitingForApproval,
        },
      } = competence;

      const allChildrenPassed = !!competence?.children?.length && competence.children.every(child => child?.passedInfo?.isPassed);
      const passed = isPassed || allChildrenPassed;

      if (idx === 0) openedIdx = 0;
      if (passed && openedIdx === idx) openedIdx = idx + 1;

      const isOpened = idx <= openedIdx;
      const isCompleted = isOpened && passed;
      const prevSiblingTitle = idx > 0
        ? children[idx - 1]?.title || ''
        : '';

      return {
        ...competence,
        tasksCompleted,
        tasksTotal,
        isLocked: !isOpened,
        prevSiblingTitle,
        isCompleted,
        isLastItem: idx === children.length - 1,
        waitingForApproval,
        passedPercent,
        index: idx,
        type: getCompetenceType(competence),
        isTrack: isTrackCompetence(competence),
        isNanoOrEcourse: isNanoOrEcourse(competence),
        isSelfSign: isSelfSign(competence),
        passed: !allChildrenPassed && competence?.children?.length && !!competence.children[0]?.passedInfo
          ? Math.floor((competence.children.filter(child => child?.passed || child?.passedInfo?.isPassed)?.length || 0) / competence.children.length * 100)
          : allChildrenPassed && 100 || competence.passed,
      };
    });

    return {
      ...track,
      title,
      id,
      track_image,
      description,
      children: items,
      status,
      firstOpenCompetence: items.find(item => item?.index === openedIdx),
    };
  },
);

export const selectLearningPathExtraData = createStructuredSelector({
  track: selectLearningPathTrackExtraData,
  map: selectLearningPathMapExtraData,
  sections: selectTrackSectionsView,
});

export const selectLearningPathChildrenData = createSelector(
  selectPassedIds,
  selectLearningPathExtraData,
  (passedIds, {track, map, sections}) => {
    const allCompetenceIds = [...new Set([
      ...objectKeysOrEmptyArray(track?.childrenById),
      ...objectKeysOrEmptyArray(map?.childrenById),
      ...objectKeysOrEmptyArray(sections?.childrenById),
    ])].map(validNumberOrNull).filter(Boolean);

    const merged = {};

    allCompetenceIds.forEach(cid => {
      merged[cid] = mergeCompetencesNonNullish(
        cloneDeep(track?.childrenById?.[cid]),
        cloneDeep(map?.childrenById?.[cid]),
        cloneDeep(sections?.childrenById?.[cid]),
      );

      const passedInfo = getPassedTasksInfo({
        competence: merged[cid],
        passedCompetenceIds: passedIds,
      });

      merged[cid].passedInfo = passedInfo;
      // merged[cid].passed = (passedInfo?.isPassed || passedIds.includes(cid)) && 100 || merged[cid].passed;
    });

    return merged;
  },
);
