import axios from 'axios';
import {all, call, delay, put, race, select, take, takeEvery, takeLatest} from 'redux-saga/effects';
import {authUnauthorized} from '@actions/auth.actions';
import * as MA from '@actions/messages.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {userSuccess} from '@actions/user.actions';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import {retry} from '@utils/sagas.utils';

const DELAY = 5 * 60 * 1000; // 5 minutes interval (300000 ms)

/*
* USED FOR POLLING FOR NEW MESSAGES.
* */
export function* checkMessages() {
  console.log('checkMessages');

  try {
    const {data: {messages = []} = {}} = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/api/messages?is_read=0&fields=message_id&limit=100`, // `${backendUrl}/api/messages?is_read=0&fields=message_id&with_count=1`
      withCredentials: true,
    }));

    return messages;
  } catch (error) {
    return {error};
  }
}

function* pollMessages() {
  while (true) {
    try {
      yield put(MA.messagesCheckMessagesRequest());

      const messages = yield call(checkMessages);

      if (!messages?.error) {
        yield put(MA.messagesCheckMessagesSuccess({unreadCnt: messages.length}));
      }

      yield delay(DELAY);
    } catch (error) {
      console.error('message check failed', {error});
      yield put(MA.messagesCheckMessagesFailure({error}));
    }
  }
}

const startMessagesCheck = takeLatest(
  userSuccess().type,
  function* startMessagesCheck() {
    while (true) {
      yield race([call(pollMessages), take('STOP_MESSAGES_CHECK')]);
    }
  },
);

export function* setReadMessage(action) {
  yield put(MA.messageSetReadRequest());

  try {
    const {message_id} = action.payload?.message || {};

    if (!message_id) return;

    const unreadCnt = yield select(state => state.messages.unread.cnt);

    yield retry(() => axios.request({
      method: 'POST',
      params: {
        fields: 'message_id',
        view: 'full',
      },
      url: `${backendUrl}/api/messages/${message_id}`,
      withCredentials: true,
    }));

    yield unreadCnt >= 100
      ? call(checkMessages)
      : put(MA.messagesCheckMessagesSuccess({unreadCnt: unreadCnt - 1}));
  } catch (error) {
    console.error(error);
    yield put(MA.messageSetReadFailure({error}));
  }
}

/*
* GET ALL DATA FROM THE MESSAGES.
* */
export function* getMessages(action) {
  const {limit = 20, offset = 0} = action.payload || {};

  yield put(MA.messagesGetRequest());

  try {
    const {data: {messages}} = yield retry(() => axios.request({
      method: 'GET',
      params: {
        fields: 'ts,message_id,is_read,files,subject,ts,is_sms,text,sender_name',
        limit: limit + 1,
        offset,
      },
      url: `${backendUrl}/api/messages`,
      withCredentials: true,
    }));

    yield put(MA.messagesGetSuccess({
      messages: messages.slice(0, limit),
      offset,
      limit,
      hasMore: messages.length === limit + 1,
    }));
  } catch (error) {
    console.error(error);
    yield put(MA.messagesGetFailure({error}));
  }
}

function* sendMessage(action) {
  const {
    username: user_name,
    userNames,
    title,
    emailBody,
    callback,
  } = action.payload || {};

  if (!user_name && !userNames?.length) return;

  yield put(MA.messagesSendMessagePostRequest());

  const formData = new FormData();

  formData.append('user_name', user_name);
  formData.append('title', title);
  formData.append('text', emailBody);
  formData.append('send_medium', 'email');

  try {
    yield all({
      email: retry(() => axios.request({
        method: 'POST',
        url: `${backendUrl}/api/messages`,
        data: formData,
        withCredentials: true,
      })),
    });

    if (callback) {
      yield callback({employee: {user_name}});
    }

    yield put(MA.messagesSendMessagePostSuccess());
    yield put(notificationsAdd({
      notification: {
        text: i18n('message.message-sent-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(MA.messagesSendMessagePostFailure({error}));
    yield put(notificationsAdd({
      notification: {
        text: i18n('globals.error'),
        color: 'red',
      },
    }));
    if (callback) {
      yield callback({employee: {user_name}});
    }
  }
}

const exportObj = [
  startMessagesCheck,
  takeLatest(MA.messagesGetMessages().type, getMessages),
  takeLatest(MA.messagesCheckMessages().type, checkMessages),
  takeEvery(MA.messagesSendMessage().type, sendMessage),
  takeEvery(MA.messageSetRead().type, setReadMessage),
];

export default exportObj;
