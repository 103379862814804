import {LoadingSpinner} from '@components/loading/loading-spinner';
import {loadableRetry} from '@utils/loadable-retry';

export const LoadableCourseBuilderModal = loadableRetry(() => {
  console.log('load modal.course-builder');

  return import(/* webpackChunkName: "modal.course-builder" */ '@features/course-builder');
}, {name: 'modal.course-builder'});

export const LoadableBartModal = loadableRetry(() => {
  console.log('load modal.bart');

  return import(/* webpackChunkName: "modal.bart" */ './bart-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.bart',
});

export const LoadableSendMessageModal = loadableRetry(() => {
  console.log('load modal.send-message');

  return import(/* webpackChunkName: "modal.sendmessage" */ './send-message-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.sendmessage',
});

export const LoadableEmploymentModal = loadableRetry(() => {
  console.log('load modal.employment');

  return import(/* webpackChunkName: "modal.employment" */ './employment-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.employment',
});

export const LoadableCourseEventModal = loadableRetry(() => {
  console.log('load modal.courseevent');

  return import(/* webpackChunkName: "modal.courseevent" */ './course-event-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.courseevent',
});

export const LoadableEmployeeModal = loadableRetry(() => {
  console.log('load modal.employee');

  return import(/* webpackChunkName: "modal.employee" */ './employee-modal');
}, {
  // fallback: <LoadingSpinner />,
  name: 'modal.employee',
});

export const LoadablePersonRolesModal = loadableRetry(() => {
  console.log('load modal.personroles');

  return import(/* webpackChunkName: "modal.personroles" */ './person-roles-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.personroles',
});

export const LoadablePersonCompetencesModal = loadableRetry(() => {
  console.log('load modal.personcompetences');

  return import(/* webpackChunkName: "modal.personcompetences" */ './person-competences-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.personcompetences',
});
