import axios from 'axios';
import {freeze, produce} from 'immer';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import {END, eventChannel} from 'redux-saga';
import {
  all,
  call,
  debounce,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import {competencesToggleSuccess} from '@actions/competences.actions';
import * as CA from '@actions/courses.actions';
import {
  employeesFetchEvents,
  employeesGetEmployees,
  employeesSaveVerification,
} from '@actions/employees.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import {
  profileFetchPersonEvents,
  profileUpdatePassedCompetences,
} from '@actions/profile.actions';
import {backendUrl, backendUrlV2, nanoLearningUrl} from '@config';
import {modalsPutModal} from '@features/modals/store/actions';
import {getModalSelector} from '@features/modals/store/selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {
  getCompetencesSearchTerm,
  getCourseCatalogDefaultCompetencegroup,
  getGroupedCompetenceIds,
  getNormalizedCompetenceDetails,
  getNormalizedCompetencegroups,
  getNormalizedCourseEvents,
  getSelectedCompetencegroupId,
  getSelectedCompetencetypes,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  selectCourseEvents,
} from '@selectors/courses.selectors';
import {
  getNormalizedEmployeesEvents,
  selectEmployeesEvents,
  selectEmployeesEventsStatus,
  selectEmployeesList,
  selectEmployeesRoot,
} from '@selectors/employees.selectors';
import {
  getNormalizedProfileEvents,
  getProfile,
  selectIsManager,
  selectProfileEvents,
} from '@selectors/profile.selectors';
import {ModalIds} from '@src/features/modals/modal-ids';
import {parseEvent} from '@src/hooks/store/course/util';
import {getShouldFetch} from '@src/store-normalized/util/loadstatus.utils';
import {getChildrenDataRecurse} from '@utils/misc.utils';
import {stringifyUrlParams} from '@utils/requests.utils';
import {retry} from '@utils/sagas.utils';
import {isStringWithLength} from '@utils/string.utils';
import * as T from '../../store/types/load.types';
import * as CT from '../types/competence';
import {getCompetenceId} from '../util/competence-identity';
import {getYearMonthString, reduceEventsFullUpdate} from '../util/courses.util';
import {waitForOrgId, waitForProfileId, waitForUsername} from './app.sagas';
import {cmsFetchContentListAPI} from './cms.sagas';

const defaultCompetenceTypes = 'complex,ecourse,equivalents,course,content,nano,track';

const defaultCompetencesFields = [
  'short_description',
  'files',
  'title',
  'modified',
  'course_type',
  'competence_type_id',
  'competence_type_key',
  'durations',
  'has_parents',
  'competence_type',
  'url',
  'category',
  // 'description',
  'content(image,imageAltText,teaserForListView,id,video,files)',
];

const defaultCourseEventFields = [
  'short_description',
  'title',
  'startdate',
  'competence_type',
  'competence_id',
  'enddate',
  'available_count',
  'participants_count',
  'sign_on_deadline',
  'sign_off_deadline',
  'max_participants',
  'waitlist',
  'confirmed',
  // 'description',
  'confirmed(profile_image,fullname,firstname,lastname,user_name)',
  'waitlist',
  'person',
  'location',
  'user_file_upload_allowed',
  'user_file_upload_date_limit',
  'files',
].join(',');

// const defaultFieldsCmsContent = [
//   'title',
//   'image',
//   'video',
//   'imageCaption',
//   'imageAltText',
//   'authorText',
//   'authorImage',
//   'files',
//   'teaserForListView',
//   'category',
//   'short_description',
//   'description',
// ];

function* runCallback(callback, args) {
  if (!callback) return;

  try {
    if (typeof callback === 'function') {
      callback(args);
    } else if (callback?.type) {
      yield put(callback);
    }
  } catch (error) {
    console.error(error);
  }
}

const params_competence_details = encodeURI(stringifyUrlParams({
  view: 'full',
  fields: [
    'description',
    'short_description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'competence_id',
    'competence_type_key',
    'durations',
    'children(competence_type,description,competence_type_key,competence_type_id,title,competence_id,files)',
  ].join(','),
}));

const params_competence_events = encodeURI(stringifyUrlParams({
  fields_events: ['title', 'location', 'files', 'user_file_upload_allowed', 'user_file_upload_date_limit'].join(','),
  view: 'full',
}));

const CourseAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* getCompetenceById(action) {
  try {
    const {cid} = action.payload;

    const fields = defaultCompetencesFields.join(',') + ',description';

    const params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
    }));

    const competence = yield retry(() =>
      CourseAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?${params}`,
          withCredentials: true,
        })
        .then(response => response.data.competences));

    return {
      ...competence[0],
      cid: competence[0].id,
    };
  } catch (error) {
    console.error(error);

    return {};
  }
}

function* getCompetencegroupChildren(action) {
  try {
    const {competencegroupId} = action?.payload || {};

    const configObject = yield select(getConfigObject);
    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const allowChildCompetenceForGroupIds = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

    const fields = (action?.payload?.fields || defaultCompetencesFields).join(',');

    const baseCompetenceGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    const params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]': competencegroupId || baseCompetenceGroupId || '',
      'types[]': defaultCompetenceTypes,
    }));

    yield put(CA.coursesGetCompetencegroupChildren.request({competencegroupId}));

    const limit = 100;

    const {competenceIds, data} = yield retry(() =>
      CourseAxios
        .request({
          method: 'GET',
          params: {
            children: '1',
            limit: limit + 1,
          },
          url: `${backendUrl}/api/competences/${params ? '?' + params : ''}`,
        })
        .then(response => {
          const {competences} = response.data;

          const ids = [];
          const data = {};

          if (!competences) return {
            competenceIds: ids,
            data,
          };

          const filtered = !!competencegroupId && !allowChildCompetenceForGroupIds.includes(competencegroupId)
            ? competences.filter(c => !c.has_parents)
            : competences;

          filtered.forEach(competence => {
            ids.push(competence.id);
            data[competence.id] = competence;
          });

          return {
            competenceIds: ids,
            data,
          };
        }));

    yield put(CA.coursesGetCompetencegroupChildren.success({
      ids: competenceIds,
      data,
      competencegroupId,
    }));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetencegroupChildren.failure({error}));
  }
}

function* loadCompetencegroup(action) {
  try {
    const {competencegroupId, subCategoryIds} = action?.payload || {};

    if (!competencegroupId) return;

    const groupedCompetences = yield select(getGroupedCompetenceIds);
    const toplevelGroups = yield select(getNormalizedCompetencegroups);

    const isLoaded = !!groupedCompetences?.data?.[competencegroupId];

    if (isLoaded) {
      yield put(CA.coursesLoadCompetencegroup.success({
        competencegroupId,
        subCategoryIds,
      }));
    } else {
      const isLoadingTopLevelGroups = toplevelGroups?.status === T.LoadStatuses.IS_LOADING;

      if (isLoadingTopLevelGroups) {
        yield take([
        `${CA.coursesGetCompetencegroupsList.success}`,
        `${CA.coursesGetCompetencegroupsList.failure}`,
        ]);
      }

      const action = {
        payload: {
          competencegroupId,
          subCategoryIds,
        },
      };

      yield put(CA.coursesLoadCompetencegroup.request({competencegroupId}));
      yield fork(getCompetencegroupChildren, action);

      while (true) {
        const _action = yield take(`${CA.coursesGetCompetencegroupChildren.success}`);

        if (action?.payload?.competencegroupId === competencegroupId) {
          yield put(CA.coursesLoadCompetencegroup.success({competencegroupId}));
          break;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* loadCompetencegroupsSubCategories(action) {
  try {
    const {parentGroupIds, subCategoryIds} = action?.payload || {};

    if (!parentGroupIds?.length && !subCategoryIds?.length) return;

    const groupedCompetences = yield select(getGroupedCompetenceIds);

    const groupsNotLoaded = groupedCompetences?.data
      ? [...parentGroupIds || [], ...subCategoryIds || []].filter(id => !groupedCompetences?.data?.[id])
      : [...parentGroupIds || [], ...subCategoryIds || []];

    if (groupsNotLoaded.length) {
      const mapToAction = competencegroupId => call(getCompetencegroupChildren, {payload: {competencegroupId}});

      yield put(CA.coursesLoadCompetencegroupsSubcategories.request({
        parentGroupIds,
        subCategoryIds,
      }));
      yield all(groupsNotLoaded.map(mapToAction));
      yield put(CA.coursesLoadCompetencegroupsSubcategories.success({
        parentGroupIds,
        subCategoryIds,
      }));
    } else {
      yield put(CA.coursesLoadCompetencegroupsSubcategories.success({
        parentGroupIds,
        subCategoryIds,
      }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCompetencegroupsList(action) {
  try {
    const initial = action?.payload?.initial;

    if (initial) {
      const {status: currentStatus} = yield select(getNormalizedCompetencegroups);

      if (currentStatus !== T.LoadStatuses.NOT_LOADED) return;
    }

    yield put(CA.coursesGetCompetencegroupsList.request());

    const configObject = yield select(getConfigObject);
    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const configStartAtGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    const baseCompetenceGroupId = configObject?.isMapActivated
      ? !!configStartAtGroupId && configStartAtGroupId
      : configObject?.getProperty?.('routes.course-catalog.startAtGroupId') || configStartAtGroupId;

    const disallowedGroupIds = configObject?.getProperty?.('routes.course-catalog.disalowwedCompetenceGroupIds') || [];

    const {
      data,
      ids: competencegroupIds,
      subCategoriesByGroupId,
    } = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/competencegroups${
          baseCompetenceGroupId
            ? `/${baseCompetenceGroupId}`
            : ''
        }`,
        params: {
          fields: 'color,title,files,children(title)',
          ...action?.payload?.params,
        },
      }).then(response => {
        const groups = response?.data?.competencegroups
          ? baseCompetenceGroupId
            ? response?.data?.competencegroups?.[0]?.children
            : response?.data?.competencegroups
          : {};

        if (!groups?.length) return {};

        const ids = [];
        const data = {};
        const subCategoriesByGroupId = {};

        groups.filter(({id}) => !disallowedGroupIds?.includes(id))?.forEach?.(group => {
          const {id: groupId} = group;

          ids.push(groupId);

          const subCategories = group?.children?.reduce(getChildrenDataRecurse, []);

          subCategoriesByGroupId[groupId] = subCategories;

          const subCategoryIds = subCategories?.map(({id}) => id);

          data[groupId] = {
            ...group,
            subCategoryIds,
          };
        });

        return {
          ids,
          data,
          subCategoriesByGroupId,
        };
      }));

    yield put(CA.coursesGetCompetencegroupsList.success({
      ids: competencegroupIds,
      data: data || [],
      subCategoriesByGroupId,
    }));

    if (competencegroupIds !== undefined) {
      const defaultCompetencegroupId = yield select(getCourseCatalogDefaultCompetencegroup);

      const competencegroupId = defaultCompetencegroupId || competencegroupIds[0];

      yield call(loadCompetencegroup, {
        payload: {
          competencegroupId,
          subCategoryIds: subCategoriesByGroupId[competencegroupId],
        },
      });
    }
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetencegroupsList.failure({error}));
  }
}

function* getCompetences(action) {
  try {
    const searchTerm = yield select(getCompetencesSearchTerm) || null;

    yield searchTerm
      ? put(CA.coursesUpdateSearchResults.request({}))
      : put(CA.coursesUpdateSearchResults.success({reset: true}));

    const selectedSubcompetencegroupId = yield select(getSelectedSubcompetencegroupId);

    const selectedSubSubcompetencegroupId = yield select(getSelectedSubSubcompetencegroupId);

    const selectedCompetencetypes = yield select(getSelectedCompetencetypes);
    const selectedCompetencegroupId = [yield select(getSelectedCompetencegroupId)];

    const configObject = yield select(getConfigObject);

    const types = selectedCompetencetypes?.length
      ? selectedCompetencetypes.join(',')
      : 'complex,ecourse,equivalents,course,content,nano,track';

    const fields = (action?.payload?.fields || defaultCompetencesFields).join(',');

    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const baseCompetenceGroupId = configForCurrLangAndTrack?.courseCatalog?.startAtGroupId;

    let params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
          selectedSubSubcompetencegroupId
          || selectedSubcompetencegroupId
          || selectedCompetencegroupId
          || baseCompetenceGroupId
          || '',
      'types[]': types,
    }));

    const disalowwedCompetenceGroupIds
      = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];
    const allowChildCompetenceForGroupIds
      = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

    if (params) {
      params = `?${params}`;
    }

    yield put(CA.coursesGetCompetences.request());

    const limit = 100;

    const competences = yield searchTerm
      ? retry(() => CourseAxios
        .request({
          method: 'GET',
          params: {
            term: searchTerm,
            not_course_groups: disalowwedCompetenceGroupIds.join(','),
          },
          url: `${backendUrlV2}/competences/search`,
        })
        .then(response => response.data))
      : retry(() => CourseAxios
        .request({
          method: 'GET',
          params: {
            children: '1',
            limit: limit + 1,
          },
          url: `${backendUrl}/api/competences/${params}`,
        })
        .then(response => response.data.competences));

    let filter_out = null;

    filter_out = selectedCompetencegroupId
        && !allowChildCompetenceForGroupIds.includes(selectedCompetencegroupId[0])
      ? competences.filter(c => !c.has_parents)
      : competences;

    let hasMore = false;

    if (filter_out.length >= limit) {
      hasMore = true;
      filter_out = filter_out.slice(0, limit - 1);
    }
    yield put(CA.coursesGetCompetences.success({
      competences: filter_out,
      hasMore,
    }));

    if (searchTerm) {
      yield put(CA.coursesUpdateSearchResults.success({
        competences: filter_out,
        hasMore,
        searchTerm,
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetences.failure({error}));
  }
}

function* getFeaturedCompetences(action) {
  const cids = action?.payload?.cids;

  yield put(CA.coursesGetFeaturedCompetences.request());

  try {
    const featuredCompetences = yield all(cids.map(cid => call(getCompetenceById, {payload: {cid}})));

    yield put(CA.coursesGetFeaturedCompetences.success({featuredCompetences}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetFeaturedCompetences.failure({error}));
  }
}

export function* getCourseEventsAPI(payload) {
  try {
    const {userName, profileId} = payload || {};

    let params = '';

    params = userName
      ? encodeURI(stringifyUrlParams({
        fields: defaultCourseEventFields,
        view: 'full',
        waitlist: 1,
        confirmed: 1,
        user_name: userName,
      }))
      : encodeURI(stringifyUrlParams({
        fields: defaultCourseEventFields,
        view: 'full',
      }));

    if (params) params = `?${params}`;

    const data = yield retry(() => CourseAxios
      .request({
        method: 'get',
        url: userName
          ? `${backendUrl}/api/personevents${params}`
          : `${backendUrl}/api/events${params}`,
        params: {limit: 100},
        withCredentials: true,
      })
      .then(function ({data}) {
        return userName
          ? {
            ...data,
            personevents: data?.personevents?.map?.(({files, ...event}) => ({
              ...event,
              userFiles: files,
            })) || [],
          }
          : data;
      }));

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* updateCourseEvents(action) {
  try {
    const {
      events = [],
      partial = false,
    // remove = false,
    } = action?.payload || {};

    if (!events?.length) {
      yield put(CA.coursesUpdateEvents.success({
        empty: true,
        partial,
      }));

      return;
    }

    if (!partial) {
    // if not partial update: fully replace events in store
      const normalized = events.reduce(reduceEventsFullUpdate, {
        allEventIds: [],
        eventById: {},
        eventIdsByCourseId: {},
        eventIdsByYearMonth: {},
      });

      yield put(CA.coursesUpdateEvents.success({
        data: freeze({
          allEventIds: normalized.allEventIds,
          eventById: normalized.eventById,
          eventIdsByCourseId: normalized.eventIdsByCourseId,
          eventIdsByYearMonth: normalized.eventIdsByYearMonth,
        }),
        partial: false,
      }));

      return;
    }

    const prevState = yield select(getNormalizedCourseEvents);

    // if partial update: update events in store
    const eventsToInsert = [];
    const eventsToUpdate = [];

    events.forEach(event => {
      if (!event?.id) return;

      const {id} = event || {};
      const prevEvent = prevState.eventById[id];

      if (!prevEvent) {
        eventsToInsert.push(event.__isParsedEvent
          ? event
          : parseEvent(event));

        return;
      }

      const newEvent = event.__isParsedEvent
        ? event
        : parseEvent(event);

      Object.keys(prevEvent).forEach(key => {
        const newValue = newEvent[key];
        const prevValue = prevEvent[key];

        if (newValue === undefined && prevValue !== undefined) {
          if (key === 'duration' && !prevValue?.formatted) return;
          if (key === '__isFullView' && prevValue === false) return;

          newEvent[key] = prevEvent[key];
        }
      });

      const updatedNotUndefined = differenceWith(toPairs(newEvent), toPairs(prevEvent), isEqual)
        .filter(([key, value]) => value != null
      && (key !== 'duration' && !value?.formatted)
      && !(key === '__isFullView' && value === false));

      if (updatedNotUndefined.length) {
        eventsToUpdate.push({
          id,
          updated: updatedNotUndefined,
        });
      }
    });

    if (!eventsToInsert.length && !eventsToUpdate.length) {
      yield put(CA.coursesUpdateEvents.success({
        partial,
        empty: true,
      }));

      return;
    }

    const updatedState = produce(prevState, draft => {
      eventsToInsert.forEach(event => {
        if (!event?.id) return;

        const {id} = event || {};

        draft.allEventIds.push(id);
        draft.eventById[id] = event;

        const courseId = event.competence_id;
        const [year, month] = getYearMonthString(event.startDate).split('-');

        if (!draft.eventIdsByCourseId[courseId]) {
          draft.eventIdsByCourseId[courseId] = [];
        }
        draft.eventIdsByCourseId[courseId].push(id);

        if (!draft.eventIdsByYearMonth[year]) {
          draft.eventIdsByYearMonth[year] = {};
        }
        if (!draft.eventIdsByYearMonth[year][month]) {
          draft.eventIdsByYearMonth[year][month] = [];
        }
        draft.eventIdsByYearMonth[year][month].push(id);
      });

      eventsToUpdate.forEach(({id, updated}) => {
        if (!draft.eventById[id]) return;

        updated.forEach(([key, value]) => {
          draft.eventById[id][key] = value;
        });
      });
    });

    yield put(CA.coursesUpdateEvents.success({
      data: freeze(updatedState),
      partial: true,
    }));
  } catch (error) {
    yield put(CA.coursesUpdateEvents.failure({error}));
    console.error(error);
  }
}

function* getCourseEvents(action) {
  const current = yield select(selectCourseEvents);
  const refresh = action?.payload?.refresh || getShouldFetch(current)?.refresh;

  yield put(CA.coursesGetCourseEvents.request({refresh}));

  try {
    const {events} = yield call(getCourseEventsAPI, {});

    yield call(updateCourseEvents, {
      payload: {
        events,
        type: 'all',
      },
    });

    yield put(CA.coursesGetCourseEvents.success());
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCourseEvents.failure({error}));
  }
}

export function* fetchEventsForCompetence({payload}) {
  const {courseId, returnData = false} = payload || {};

  if (!courseId) return null;

  try {
    const {data: {events}} = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        params: {children: '1'},
        url: `${backendUrl}/api/competences/${courseId}/events?${params_competence_events}`,
      }));

    yield call(updateCourseEvents, {
      payload: {
        events,
        type: 'competence',
        courseId,
        partial: true,
      },
    });

    return returnData ? events : null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

function* fetchCompetenceDetails(action) {
  try {
    const cid = action?.payload?.cid == null
      ? null
      : Number(action.payload.cid);

    yield put(CA.coursesCloseCourse());

    if (!cid) return;
    yield put(CA.coursesGetCompetenceDetails.request({cid}));

    const {data: {competences: [competenceDetails]}} = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        params: {children: '1'},
        url: `${backendUrl}/api/competences/${cid}?${params_competence_details}`,
        withCredentials: true,
      }));

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
      */
      const eventsDetails = yield call(fetchEventsForCompetence, {
        payload: {
          courseId: competenceDetails.competence_id,
          returnData: true,
        },
      });

      competenceDetails.events = eventsDetails;

      /*
      # if manager, and course details */

      yield call(waitForProfileId);

      const isManager = yield select(selectIsManager);

      if (isManager) {
        const orgId = yield call(waitForOrgId, true);
        const employeesEvents = yield select(selectEmployeesEvents);
        const shouldFetchEmployeesEvents = getShouldFetch(employeesEvents, {orgId});

        if (shouldFetchEmployeesEvents) {
          yield put(employeesFetchEvents(shouldFetchEmployeesEvents));
        }

        const employees = yield select(selectEmployeesRoot);

        const shouldFetchEmployees = getShouldFetch(employees?.list, {orgId});

        if (shouldFetchEmployees) {
          yield put(employeesGetEmployees({
            all: true,
            ...shouldFetchEmployees,
          }));
        }
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (const element of competenceDetails.children) {
        element.competence_type = {
          competence_type: element.competence_type,
          competence_type_id: element.competence_type_id,
        };
      }
    }

    yield put(CA.coursesGetCompetenceDetails.success({
      competenceDetails,
      cid,
    }));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetenceDetails.failure({error}));
  }
}

function* loadCompetenceDetails(action) {
  const {cid, refetch} = action.payload || {};

  if (!cid) return;

  yield put(CA.coursesLoadCompetenceDetails.request());

  try {
    const {data: competenceById} = yield select(getNormalizedCompetenceDetails);

    if (!competenceById[cid] || refetch) {
      yield call(fetchCompetenceDetails, {payload: {cid}});
    }

    yield put(CA.coursesLoadCompetenceDetails.success({cid}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesGetCompetenceDetails.failure({error}));
  }
}

export const getIncludesPerson = (personsArray, person) => {
  if (!personsArray?.length || !person || !person?.id && !person?.user_name) return false;

  for (const element of personsArray) {
    const {id, user_name} = element || {};

    if (!id && !user_name) continue;
    if (id === person.id || user_name === person.user_name) return true;
  }

  return false;
};

export function* getIncludesProfile(personsArray) {
  if (!personsArray?.length) return false;

  try {
    const username = yield call(waitForUsername);
    const pid = yield call(waitForProfileId);

    return getIncludesPerson(personsArray, {
      id: pid,
      user_name: username,
    });
  } catch {
    return false;
  }
}

function* courseEventsGetShouldRefetch(signedPersons, courseId) {
  if (!signedPersons?.length) return false;

  const isManager = yield select(selectIsManager);

  const includesSelf = yield call(getIncludesProfile, signedPersons);
  const refetchEmployeesEvents = isManager && (signedPersons.length > 1 || !includesSelf);

  return {
    refetchEventsForCompetence: !!courseId,
    refetchCourseEvents: !courseId,
    refetchProfileEvents: includesSelf,
    refetchEmployeesEvents,
  };
}

function* courseEventsRefetchIfNeeded(payload) {
  const {persons: signedPersons, courseId, callback} = payload || {};

  const shouldRefetch = yield call(courseEventsGetShouldRefetch, signedPersons, courseId);

  if (!shouldRefetch) {
    yield runCallback(callback);

    return;
  }

  yield put(CA.coursesLoadCourseEventsFull({
    ...shouldRefetch,
    courseId,
    callback,
  }));
}

function* courseSignOn(action) {
  const {
    courseEvent,
    employees,
    callback,
    courseId,
    noNotification,
  } = action.payload || {};

  const ceid = courseEvent?.id;

  if (!ceid) return;

  try {
    const {data: person} = yield select(getProfile);
    const signedEmployees = employees || [person];

    yield put(CA.coursesCourseSignOn.request({ceid}));

    const results = {
      correct: [],
      errors: [],
    };

    const calls = [];

    for (const signedEmployee of signedEmployees) {
      const {firstname, lastname, user_name} = signedEmployee;
      const fullname = `${firstname} ${lastname}`;
      const user = fullname.trim() === '' ? user_name : fullname;

      calls.push(retry(() => CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/api/personevents/${ceid}?fields=id,waitlist`, // person(person_id,user_name,firstname,lastname,fullname)`,
          params: {user_name},
          withCredentials: true,
        })
        .then(({data}) => {
          results.correct.push({
            user,
            waitlist: data.personevents?.[0]?.waitlist,
            message: data.message,
          });
        })
        .catch(error => {
          console.error(error);

          results.errors.push({
            user,
            error,
            errorMessage: error.response?.data?.message || error?.message || 'Ukjent feil',
          });

          return error;
        })));
    };

    yield all(calls);

    const errorNotification = !noNotification
      && !!results.errors.length
      && !results.correct.length && {
      notification: {
        title: 'Feil',
        text: results.errors[0].errorMessage,
        color: 'red',
      },
    };

    if (employees) {
      if (errorNotification) {
        yield put(notificationsAdd(errorNotification));
        if (callback) yield runCallback(callback, 'error');
      }
    } else {
      if (results.correct && results.correct.length > 0) {
        yield put(notificationsAdd({
          notification: {
            text: results.correct[0].message,
            color: 'green',
          },
        }));
      } else if (results.errors && results.errors.length > 0) {
        yield put(notificationsAdd(errorNotification));
        if (callback) yield runCallback(callback, 'error');
      }
    }

    yield put(CA.coursesCourseSignOnResults({results}));
    yield put(CA.coursesCourseSignOn.success({results}));

    if (callback) yield runCallback(callback, results);

    yield call(courseEventsRefetchIfNeeded, {
      persons: signedEmployees,
      courseId,
      callback: CA.coursesCourseSignOn.success({ceid}),
    });
  } catch (error) {
    console.error(error);
    if (callback) yield runCallback('error');

    yield put(CA.coursesCourseSignOn.failure({
      error,
      ceid,
    }));
  }
}

function* courseSignOff(action) {
  const {courseEventId, employees, callback, courseId} = action.payload || {};

  try {
    const {data: person} = yield select(getProfile);

    const signedEmployees = employees
      ? Array.isArray(employees)
        ? employees
        : [employees]
      : [person];

    yield put(CA.coursesCourseSignOff.request({ceid: courseEventId}));

    const results = {
      correct: [],
      errors: [],
    };

    yield all(signedEmployees.map(({fullname, user_name}) => {
      const user = isStringWithLength(fullname) && fullname.trim() !== ''
        ? fullname
        : user_name;

      return retry(() => CourseAxios.request({
        method: 'PUT',
        url: `${backendUrl}/api/personevents/${courseEventId}?fields=id`, // ,person(person_id,user_name,firstname,lastname,fullname)`,
        params: {
          action: 'off',
          user_name,
        },
        withCredentials: true,
      })
        .then(({data}) => {
          if (data?.message?.startsWith('Error:')) {
            results.errors.push({
              user,
              message: data.message,
            });
          } else {
            results.correct.push({
              user,
              message: data.message,
            });
          }
        })
        .catch(error => {
          console.error(error);
          results.errors.push({
            user,
            error,
          });

          return error;
        }));
    }));

    let notification;

    if (results.correct && results.correct.length > 0) {
      notification = {
        text: results.correct[0].message,
        color: 'green',
      };
    } else if (results.errors && results.errors.length > 0) {
      if (callback) callback('error');

      notification = {
        title: 'Feil',
        text: results.errors[0]?.error?.message || results.errors[0]?.message || 'Ukjent feil',
        color: 'red',
      };
    }

    yield put(notificationsAdd({notification}));

    yield put(CA.coursesCourseSignOff.success({results}));

    if (callback) yield runCallback(callback, results);

    yield call(courseEventsRefetchIfNeeded, {
      persons: signedEmployees,
      courseId,
      callback: CA.coursesCourseSignOff.success({ceid: courseEventId}),
    });
  } catch (error) {
    if (callback) yield runCallback(callback, 'error');
    console.error(error);
    yield put(CA.coursesCourseSignOff.failure({
      error,
      ceid: courseEventId,
    }));
  }
}

function* onCourseStart(action) {
  try {
    const {cid, type, mapId} = action.payload;
    const token = yield select(state => state.auth.sessionId);

    if (CT.CourseTypes.ecourse.includes(type)) {
      const {valid, lmsstarturls} = yield retry(() =>
        axios
          .request({
            method: 'get',
            url: `${backendUrl}/api/lmsstarturls?competence_ids=${cid}&external=1`,
            withCredentials: true,
          })
          .then(response => response.data));

      const [{urls}] = lmsstarturls;

      if (valid === true && urls[0]) {
        // CLEAR THE INFO BOX.

        // when i18n is activated in grape, use this instread
        // const open_url =  `${backendUrl}/transfer?token=${token}&url=${urls[0].id}`;

        const open_url = `${urls[0].id}`;
        const win = window.open(open_url, '_blank');

        yield put(CA.coursesRunCourse({
          url: open_url,
          opened: !!win,
          mapId,
        }));
      } else {
        yield put(CA.coursesCourseError('no valid url for course'));
      }
    } else if (CT.CourseTypes.nano.includes(type)) {
      const nanoCourseStartUrl = `${nanoLearningUrl}?id=${cid}`;

      yield put(CA.coursesRunNanoCourse({
        url: nanoCourseStartUrl,
        cid,
        type,
        mapId,
      }));
    } else {
      const iframeMessageChannel = yield retry(() => eventChannel(emmiter => {
        window.addEventListener('message', ({data}) => {
          if (
            data === 'IFRAME_COURSE_FINISHED'
              || data === 'TAB_COURSE_FINISHED'
          ) {
            emmiter();
            emmiter(END);
          }
        });

        return () => {
          window.removeEventListener('message');
        };
      }));

      yield take(iframeMessageChannel);
      yield put(CA.coursesCourseFinished({
        cid,
        mapId,
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(CA.coursesCourseFailure({error}));
  }
}

function* onMapDottCompleted(action) {
  const {
    id,
    cid: cidPayload,
    courseId,
    mapId: mapIdPayload,
    trackId: trackIdPayload,
    competence,
    data,
  } = action.payload || {};

  try {
    const cid = cidPayload ?? courseId ?? getCompetenceId(competence) ?? id ?? null;
    const mapId = mapIdPayload ?? trackIdPayload ?? competence?.mapId ?? competence?.trackId ?? null;

    if (cid == null) return;

    yield put(profileUpdatePassedCompetences({
      cid,
      mapId,
      disableStatusUpdateCheck: true,
      skipPageReload: true,
      data: {passed: data?.passed == null ? 100 : data.passed},
    }));

    // if (mapId != null && cid != null) {
    // yield put(fetchTrackExtraData({
    //   trackId: mapId,
    //   cid,
    // }));
    // }
  } catch (error) {
    console.error(error);
  }
}

function* onCourseSign(action) {
  let status;
  const {
    courseId,
    password,
    checked,
    mapId: mapIdPayload,
    trackId: trackIdPayload,
    callback,
  } = action.payload || {};

  try {
    const current = yield select(getModalSelector(ModalIds.SIGNATURE));

    const mapId = mapIdPayload || current?.competence.mapId;
    const trackId = trackIdPayload || current?.competence?.trackId;
    const bodyFormData = new FormData();

    bodyFormData.set('formIndex', '0');

    if (checked) {
      bodyFormData.set('checked', '1');
    } else {
      bodyFormData.set('password', password);
    }

    const data = yield retry(() => CourseAxios
      .request({
        method: 'POST',
        url: `${backendUrl}/courses/sign_digitally/${courseId}`,
        data: bodyFormData,
        config: {headers: {'Content-Type': 'multipart/form-data'}},
      })
      .then(response => response.data));

    status = data.status;

    if (data.statuscode === -1) {
      yield put(CA.coursesSignCourse.success({
        status,
        courseId,
        mapId,
        trackId,
      }));

      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'green',
        },
      }));

      if (callback) callback?.(status);

      yield put(modalsPutModal({
        modalId: ModalIds.SIGNATURE,
        isOpen: false,
      }));

      // yield put(profileUpdatePassedCompetences({skipPageReload: true}));
    } else {
      if (status) {
        yield put(notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        }));
      }
      yield put(CA.coursesSignCourse.failure());
    }
  } catch (error) {
    console.error(error);

    if (status) {
      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'red',
        },
      }));
    }
    yield put(CA.coursesSignCourse.failure({error}));
  }
}

export function* courseCatalogFetchNews(action) {
  yield put(CA.coursesFetchCourseCatalogNews.request());

  const {ids, limit = 50} = action.payload || {};

  try {
    // old endpoint:
    // const catalogNews = yield all(ids.flatMap(id =>
    //   retry(() =>
    //     CourseAxios
    //       .request({
    //         method: 'GET',
    //         url: `${backendUrl}/api/cms/${id}/pages?fields=teaser,body,image,category,author`,
    //       })
    //       .then(res => res?.data?.pages || []))));

    // yield put(CA.coursesFetchCourseCatalogNews.success({data: catalogNews.flat() || []}));

    // new endpoint
    // (should we use the same id as news on frontpage?)
    const catalogNews = yield call(cmsFetchContentListAPI, ids, limit);

    yield put(CA.coursesFetchCourseCatalogNews.success({data: catalogNews}));
  } catch (error) {
    console.error(error);
    yield put(CA.coursesFetchCourseCatalogNews.failure({error}));
  }
}

export function* loadCourseEventsFull(action) {
  const {
    courseId,
    refetchEventsForCompetence,
    refetchCourseEvents,
    refetchEmployees,
    refetchEmployeesEvents,
    refetchProfileEvents,
    callback,
    initialLoad,
    refresh,
  } = action?.payload || {};

  const isManager = yield select(selectIsManager);

  try {
    const toTake = [
      refetchCourseEvents && {
        effect: take([
      `${CA.coursesUpdateEvents.success}`,
      `${CA.coursesUpdateEvents.failure}`,
        ]),
        selector: selectCourseEvents,
      },
      refetchEmployees && isManager && {
        effect: take([
      `${employeesGetEmployees.success}`,
      `${employeesGetEmployees.failure}`,
        ]),
        selector: selectEmployeesRoot,
      },
      refetchEmployeesEvents && isManager && {
        effect: take([
      `${employeesFetchEvents.success}`,
      `${employeesFetchEvents.failure}`,
        ]),
        selector: selectEmployeesEvents,
      },
      refetchProfileEvents && {
        effect: take([
      `${profileFetchPersonEvents.success}`,
      `${profileFetchPersonEvents.failure}`,
        ]),
        selector: selectProfileEvents,
      },
    ].filter(Boolean);

    const {status: allEventsStatus} = yield select(getNormalizedCourseEvents);

    const allEventsNotLoaded = allEventsStatus === T.LoadStatuses.NOT_LOADED;

    if (allEventsNotLoaded || refetchCourseEvents || initialLoad || refresh) {
      yield put(CA.coursesGetCourseEvents({refresh}));
    }

    const {status: profileEventsStatus} = yield select(getNormalizedProfileEvents);

    const profileEventsNotLoaded = profileEventsStatus === T.LoadStatuses.NOT_LOADED;

    if (profileEventsNotLoaded || refetchProfileEvents) {
      yield put(profileFetchPersonEvents({refresh: refetchProfileEvents}));
    }

    if (courseId && refetchEventsForCompetence) {
      yield put(CA.coursesFetchEventsForCompetence({courseId}));
    }

    if (isManager) {
      const {status: employeesEventsStatus} = yield select(getNormalizedEmployeesEvents);
      const {status: employeesStatus} = yield select(selectEmployeesList);

      const employeesEventsNotLoaded = employeesEventsStatus === T.LoadStatuses.NOT_LOADED;
      const employeesNotLoaded = employeesStatus === T.LoadStatuses.NOT_LOADED;

      const shouldFetchEmployees = employeesNotLoaded || refetchEmployees;
      const shouldFetchEmployeesEvents = employeesEventsNotLoaded || refetchEmployeesEvents;

      if (shouldFetchEmployees) yield put(employeesGetEmployees({all: true}));
      if (shouldFetchEmployeesEvents) yield put(employeesFetchEvents({refresh: refetchEmployeesEvents}));
    }

    if (toTake.length) {
      yield all(toTake.map(function* ({effect, selector}) {
        const {status} = yield select(selector);

        if ([T.LoadStatuses.NOT_LOADED, T.LoadStatuses.IS_LOADING, T.LoadStatuses.IS_LOADING_MORE].includes(status)) {
          yield effect;
        }
      }));
    }
    if (callback) yield runCallback(callback, 'success');
    yield put(CA.coursesLoadCourseEventsFull.success({}));
  } catch (error) {
    console.error(error);
    if (callback) yield runCallback(callback, 'error');
    yield put(CA.coursesLoadCourseEventsFull.failure({error}));
  }
}

function* autoFetchCourseEventsFull(action) {
  const {
    courseEvents: checkCourseEvents = true,
    employeesEvents: checkEmployeesEvents = true, // false,
    profileEvents: checkProfileEvents = true, // false,
    // eventsForCompetence: checkEventsForCompetence = false,
  } = action?.payload || {};

  let fetchEmployeesEvents = false;
  let fetchProfileEvents = false;
  let fetchCourseEvents = false;

  if (checkEmployeesEvents) {
    const isManager = yield select(selectIsManager);

    if (isManager) {
      const employeesEvents = yield select(selectEmployeesEventsStatus);

      fetchEmployeesEvents = getShouldFetch(employeesEvents);
    }
  }
  if (checkCourseEvents) {
    const courseEvents = yield select(selectCourseEvents);

    fetchCourseEvents = getShouldFetch(courseEvents);
  }
  if (checkProfileEvents) {
    const profileEvents = yield select(selectProfileEvents);

    fetchProfileEvents = getShouldFetch(profileEvents);
  }

  if (!fetchCourseEvents && !fetchEmployeesEvents && !fetchProfileEvents) {
    return;
  }

  yield put(CA.coursesLoadCourseEventsFull({
    initialLoad: !!fetchCourseEvents?.full,
    refresh: !!fetchCourseEvents?.refresh,
    refetchProfileEvents: !!fetchProfileEvents?.refresh,
    refetchEmployeesEvents: !!fetchEmployeesEvents?.refresh,
  }));
}

const watchCompletedCompetence = takeEvery([
    `${CA.coursesSignCourse.success}`,
    competencesToggleSuccess().type,
    `${employeesSaveVerification.success}`,
], onMapDottCompleted);

const exportObj = [
  watchCompletedCompetence,
  takeEvery(`${CA.coursesCourseSignOn}`, courseSignOn),
  takeEvery(`${CA.coursesCourseSignOff}`, courseSignOff),
  takeLatest(`${CA.coursesGetCompetences}`, getCompetences),
  takeLatest(
    `${CA.coursesGetFeaturedCompetences}`,
    getFeaturedCompetences,
  ),
  debounce(400, `${CA.coursesSetSearchterm}`, getCompetences),
  takeLatest(
    `${CA.coursesGetCompetencegroupChildren}`,
    getCompetencegroupChildren,
  ),
  takeLatest(
    `${CA.coursesLoadCompetencegroup}`,
    loadCompetencegroup,
  ),
  takeLatest(
    `${CA.coursesLoadCompetencegroupsSubcategories}`,
    loadCompetencegroupsSubCategories,
  ),
  takeLeading(
    `${CA.coursesGetCompetencegroupsList}`,
    getCompetencegroupsList,
  ),
  takeLatest(`${CA.coursesGetCourseEvents}`, getCourseEvents),
  takeLatest(
    `${CA.coursesFetchCourseCatalogNews}`,
    courseCatalogFetchNews,
  ),
  takeLatest(`${CA.coursesStartCourse}`, onCourseStart),
  takeEvery(`${CA.coursesSignCourse}`, onCourseSign),
  takeLatest(
    `${CA.coursesLoadCourseEventsFull}`,
    loadCourseEventsFull,
  ),
  takeLatest(`${CA.coursesUpdateEvents}`, updateCourseEvents),
  takeLatest(`${CA.coursesLoadCompetenceDetails}`, loadCompetenceDetails),
  takeLatest(`${CA.coursesFetchEventsForCompetence}`, fetchEventsForCompetence),
  takeLatest(`${CA.coursesAutoFetchCourseEventsFull}`, autoFetchCourseEventsFull),
];

export default exportObj;
