/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, {useState} from 'react';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {coursesCloseCourse} from '@actions/courses.actions';
import {css} from '@emotion/react';
import {linearGradientWithAdjustableColorStop} from '@facepaint';
import {faHome} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getIsMapCompleted} from '@selectors/map.selectors.new';
import {i18n, MISSING_TRANSLATION} from '@src/i18n';
import {highlightOnKeyboardFocusMixin} from '@styles/mixins';
import {lightOrDark} from '@utils/misc.utils';
import {
  Base,
  Button,
  display,
  HamburgerMenuBackdrop,
  HamburgerMenuItemsWrapper,
  HamburgerMenuToggle,
  Logo,
  row,
  separate,
  Stack,
} from './styles';

export const MenuTwoButton = ({
  icon,
  text,
  allowMotion,
  onLogoutClick,
  allowLogout,
  variant,
  routeHomeWhenActive,
  lastItem,
  onClick,
  ...props
}) => {
  const [flip, triggerFlip] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  const {to} = props;

  const mapIsCompleted = useSelector(getIsMapCompleted);

  const shouldNavigateHome = () => routeHomeWhenActive && location.pathname === to;

  const onClickMotion = () => {
    if (allowMotion) triggerFlip(!flip);
  };

  const onClickLogout = () => {
    if (allowLogout) onLogoutClick();
  };

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      onClickMotion();
      onClickLogout();
      if (shouldNavigateHome()) {
        dispatch(coursesCloseCourse());
      }
    }
  };

  if (
    shouldNavigateHome()
    && (mapIsCompleted === false || mapIsCompleted === null)
  ) {
    return null;
  }

  const disableIcons = variant === 'hamburger';
  const hasIcon = ![MISSING_TRANSLATION, undefined, null, false, ''].includes(icon);

  return (
    <Button
      className={`navmenu-button-${variant}`}
      onClick={() => onClickHandler()}
      {...props}
      variant={variant}
      lastItem={lastItem}
    >
      {Boolean(icon) && allowMotion
        ? (
          <motion.div
            variants={{
              down: {rotate: 0},
              up: {rotate: 180},
            }}
            animate={flip ? 'up' : 'down'}
            transition={{duration: 0}}
            initial="down"
          >
            {!disableIcons && hasIcon && <FontAwesomeIcon icon={icon} />}
          </motion.div>
        )
        : shouldNavigateHome() && !disableIcons
          ? (
            <FontAwesomeIcon
              icon={faHome}
              style={{marginRight: 5}}
            />
          )
          :         !disableIcons && hasIcon && <FontAwesomeIcon icon={icon} />}
      {Boolean(icon) && <span>&nbsp;&nbsp;</span>}
      <span>{shouldNavigateHome() ? i18n('map.gohome') : text}</span>
    </Button>
  );
};

export const MenuTwo = props => {
  const {
    buttons,
    logo,
    onLogoutClick,
    baseColor,
    createGradient,
    variant,
    showSwitchTrackVersionButton,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [hamburgerMenuState, setHamburgerMenuState] = useState('closed');

  function pathSelector(obj) {
    if (obj.routeHomeWhenActive && location.pathname === obj.to) {
      return '/';
    }

    return obj.to;
  }

  const gradient = linearGradientWithAdjustableColorStop(
    baseColor,
    0.4,
    1,
    0.1,
  );

  const lightOrderBg = lightOrDark(baseColor);

  const menuButtons = buttons && Object.keys(buttons) || [];

  const getMenuButtonText = buttonConfig =>
    buttonConfig.textKey && i18n(buttonConfig.textKey)
    || buttonConfig.text
    || `[${buttonConfig.to}]`;

  return (
    <Base
      className="menu-two-wrapper"
      css={[
        createGradient ? gradient : {backgroundColor: baseColor},
        {borderBottom: 'none'},
      ]}
    >
      <Stack as="nav">
        {/* home button */}
        <Link
          to="/"
          tabIndex={0}
          onClick={() => {
            setHamburgerMenuState('closedByNavigation');
            dispatch(coursesCloseCourse());
          }}
          css={css`
              ${highlightOnKeyboardFocusMixin};
            `}
        >
          <Logo
            className="menu-two-logo"
            src={logo}
          />
        </Link>
        {variant === 'hamburger' && (
          <HamburgerMenuToggle
            className={`hamburgermenu-toggle ${hamburgerMenuState}`}
            variants={{
              closed: {rotate: 0},
              open: {rotate: 90},
            }}
            animate={hamburgerMenuState === 'open' ? 'open' : 'closed'}
            transition={{
              type: 'tween',
              stiffness: 9_999_999, // disable spring effect
              duration: 0.25,
            }}
            initial="closed"
            onClick={() =>
              setHamburgerMenuState(old =>
                old === 'open' ? 'closedByToggleButton' : 'open')}
            tabIndex="0"
            role="button"
            aria-label="Open menu"
          >
            <FontAwesomeIcon
              icon={
                  hamburgerMenuState === 'open'
                    ? ['fas', 'times']
                    : ['fas', 'bars']
                }
              color={lightOrderBg === 'light' ? 'black' : 'white'}
            />
          </HamburgerMenuToggle>
        )}

        {/* items for horizontal */}
        {variant === 'horizontal' && (
          <div css={[row, separate, display]}>
            {menuButtons.map((key, i, arr) => buttons[key].to
                  && buttons[key].to.includes('https:')
                  && !buttons[key].allowLogout
              ? (
                <a
                  key={i}
                  href={pathSelector(buttons[key])}
                  tabIndex={0}
                  aria-label={getMenuButtonText(buttons[key])}
                  css={css`
                      ${highlightOnKeyboardFocusMixin};
                    `}
                >
                  <MenuTwoButton
                    lastItem={i === arr.length - 1}
                    variant="horizontal"
                    tabIndex={-1}
                    onLogoutClick={onLogoutClick}
                    {...buttons[key]}
                    text={getMenuButtonText(buttons[key])}
                  />
                </a>
              )
              : (
                <Link
                  key={i}
                  to={pathSelector(buttons[key])}
                  tabIndex={0}
                  aria-label={getMenuButtonText(buttons[key])}
                  css={css`
                      ${highlightOnKeyboardFocusMixin};
                    `}
                >
                  <MenuTwoButton
                    lastItem={i === arr.length - 1}
                    variant="horizontal"
                    tabIndex={-1}
                    onLogoutClick={onLogoutClick}
                    {...buttons[key]}
                    text={getMenuButtonText(buttons[key])}
                  />
                </Link>
              ))}
          </div>
        )}

        {variant === 'hamburger' && hamburgerMenuState === 'open' && (
          <HamburgerMenuBackdrop
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{
              ease: 'easeOut',
              duration: 0.2,
            }}
          />
        )}

        {/* items for hamburger

          if the hamburger-menu is clsoed by the toggle-button, we want this lsit to animte closing
          if it is closed by navigation we want to hide this lsit right away and skip the animated closing
          */}
        {variant === 'hamburger'
            && hamburgerMenuState !== 'closedByNavigation' && (
              <HamburgerMenuItemsWrapper
                state={hamburgerMenuState}
                aria-hidden={hamburgerMenuState !== 'open'}
                variants={{
                  closed: {
                    height: 0,
                    overflow: 'hidden',
                  },
                  open: {
                    height: '100vh',
                    overflow: 'visible',
                  },
                }}
                animate={hamburgerMenuState === 'open' ? 'open' : 'closed'}
                transition={{
                  ease: 'easeOut',
                  duration: hamburgerMenuState === 'open' ? 0.37 : 0.25,
                }}
                initial="closed"
              >
                {showSwitchTrackVersionButton && (
                  <>
                    <MenuTwoButton
                      icon={['fas', 'code-branch']}
                      variant="hamburger"
                      onClick={() => {
                        window.location.href = '/set-atlas/barn';
                      }}
                      text="Til barneversjon"
                    />
                    <MenuTwoButton
                      icon={['fas', 'code-branch']}
                      variant="hamburger"
                      onClick={() => {
                        window.location.href = '/set-atlas/ungdom';
                      }}
                      text="Til ungdomsversjon"
                    />
                  </>
                )}
                {menuButtons.map((key, i, arr) => buttons[key].to
                    && buttons[key].to.includes('https:')
                  ? (
                    <a
                      key={key}
                      href={pathSelector(buttons[key])}
                      tabIndex={0}
                      aria-label={getMenuButtonText(buttons[key])}
                      css={css`
                        ${highlightOnKeyboardFocusMixin};
                      `}
                    >
                      <MenuTwoButton
                        lastItem={i === arr.length - 1}
                        variant="hamburger"
                        tabIndex={-1}
                        onLogoutClick={onLogoutClick}
                        {...buttons[key]}
                        text={getMenuButtonText(buttons[key])}
                      />
                    </a>
                  )
                  : (
                    <Link
                      key={key}
                      to={pathSelector(buttons[key])}
                      tabIndex={0}
                      onClick={() => {
                        setHamburgerMenuState('closedByNavigation');
                        if (onLogoutClick && buttons[key].allowLogout) {
                          onLogoutClick();
                        }
                      }}
                      css={css`
                        ${highlightOnKeyboardFocusMixin};
                      `}
                    >
                      <MenuTwoButton
                        lastItem={i === arr.length - 1}
                        variant="hamburger"
                        tabIndex={-1}
                        onLogoutClick={onLogoutClick}
                        {...buttons[key]}
                        text={i18n(buttons[key].textKey)}
                        icon={null}
                      />
                    </Link>
                  ))}
              </HamburgerMenuItemsWrapper>
        )}
      </Stack>
    </Base>
  );
};

MenuTwoButton.propTypes = {
  // array in the form [FONT_AWESOME_ICON_FAMILY, ICON_NAME]
  icon: PropTypes.array,
  text: PropTypes.string.isRequired,
  allowMotion: PropTypes.bool,
  onLogoutClick: PropTypes.func,
  allowLogout: PropTypes.bool,
};

MenuTwoButton.defaultProps = {
  icon: null,
  allowMotion: false,
  onLogoutClick: false,
  allowLogout: false,
};
