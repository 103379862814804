import dayjs from 'dayjs';
import {backendUrl} from '@config';
import {i18ndayjs} from '@src/i18n';
import {isStringWithLength} from './string.utils';

export const NOOP = () => {};
export const NullFn = () => null;

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

/* eslint-disable one-var */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */
// based on https://awik.io/determine-color-bright-dark-using-javascript/

export const lightOrDark = color => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.startsWith('rgb')) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 200) {
    return 'light';
  }

  return 'dark';
};

/* eslint-enable one-var */
/* eslint-enable no-param-reassign */
/* eslint-enable prefer-destructuring */
/* eslint-enable no-bitwise */

// gets the full url for an image, possibly prepending the backend-url if the url is absolute
export const getImageUrl = src => {
  if (!isStringWithLength(src)) return '';

  if (src.startsWith(backendUrl)) {
    return src;
  }
  if (src.charAt(0) === '/') {
    return backendUrl + src;
  }

  return src;
};

export const removeHTML = (str, keepLine = true) => {
  if (!isStringWithLength(str)) return '';

  var tmp = document.createElement('DIV');

  if (keepLine) {
    tmp.innerHTML = str.replace('<br />', '[br]');

    return (tmp.textContent || tmp.textContent || '').replace('[br]', '<br />');
  } else {
    tmp.innerHTML = str;

    return tmp.textContent || tmp.textContent || '';
  }
};

export const getAccess = (orgAccess, userAccess) => {
  const access = {
    onboarding: false,
    learning: false,
    userAccess: userAccess && userAccess || 'crew',
    gathering: false,
    users: 20,
    maps: 5,
    learingportal: false,
  };

  if (orgAccess.length === 4) {
    access.onboarding = orgAccess[0] === '1' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 3) {
    access.learning = orgAccess[0] === '1' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 2) {
    access.meeting = orgAccess[0] === '1' && true || false;
    access.learingportal = orgAccess[0] === '2' && true || false;
    orgAccess = orgAccess.slice(1);
  }

  if (orgAccess.length === 1 && orgAccess[0] === '3') {
    access.users = 200;
  } else if (orgAccess.length === 1 && orgAccess[0] === '2') {
    access.users = 50;
  }

  return access;
};

// given a html-node, return all the parents of this node as a list, excuding the document-element
export const  parentsForHtmlNode = node => {
  if (!node) {
    return [];
  }
  let current = node;
  const list = [];

  while (current.parentNode != null && current.parentNode !== document.documentElement) {
    list.push(current.parentNode);
    current = current.parentNode;
  }

  return list;
};

export const getTwoletterLangCodeFromNavigatorLanguage = () => {
  const navLang = !!navigator.language && navigator.language.toLocaleLowerCase();

  if (!navLang) {
    return null;
  }

  if (navLang.startsWith('en-')) {
    return 'en';
  }

  return {
    'nb-no': 'no',
    'nn-no': 'no',
  }[navLang] || 'no';
};

export const emptyDate = () => {
  const d = new Date();

  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
  d.setFullYear(0);
  d.setMonth(0);
  d.setDate(1);

  return d;
};

// https://stackoverflow.com/a/44198641
export const isValidDate = date =>
  !!date
  && Object.prototype.toString.call(date) === '[object Date]'
  && !Number.isNaN(date);

export const lastDateThisYear = () => {
  const d = new Date();

  d.setMonth(11);
  d.setDate(31);
  d.setHours(23, 59, 59);

  return d;
};

export const verifyPassed = competence => {
  if (!competence || !competence?.passed) return false;

  if (competence.passed === 100 && competence.valid_until) {
    let validUntil = dayjs(competence.valid_until, 'DD.MM.YYYY');

    if (!validUntil.isValid()) validUntil = dayjs(competence.valid_until);
    if (!validUntil.isValid()) validUntil = null;

    if (validUntil && validUntil.isBefore(dayjs())) return false;
  }

  return competence.passed === 100;
};

export const lastDateNextYear = () => {
  const d = lastDateThisYear();

  d.setFullYear(d.getFullYear() + 1);

  return d;
};

export const addifNotExistsUsingKey = (arr, elem, key) => {
  if (!arr.some(e => e[key] === elem[key])) {
    return [...arr, elem];
  }

  return arr;
};

export const addifNotExists = (arr, elem) => {
  if (!arr.includes(elem)) {
    return [...arr, elem];
  }

  return arr;
};

export const removeDuplicatesUsingKey = (arr, key) => {
  const res = [];

  for (const elem of arr) {
    if (!res.some(r => r[key] === elem[key])) {
      res.push(elem);
    }
  }

  return res;
};

export const daysDifference = (dateMax, dateMin) => dateMax.diff(dateMin, 'days');

export const getDateNumLocal = date => {
  const obj = i18ndayjs(date);
  const dateNum = `${obj.date()}`;
  const cardinal = obj.format('Do').replace(dateNum, '');

  return [`${dateNum.padStart(2, '0')}`, `${cardinal}`];
};

export const parseDate = dateStr => {
  if (!dateStr || typeof dateStr !== 'string') return null;

  const date = Date.parse(dateStr.replaceAll(' ', 'T'));

  return date ? new Date(date) : null;
};

export const eventHasStarted = event => {
  const now = new Date();
  const start = parseDate(event.startdate);

  return start && start <= now;
};

export const verifyDeadlines = event => {
  const {
    sign_off_deadline,
    sign_on_deadline,
  } = event;

  const now = new Date();

  const signOnDeadline = parseDate(sign_on_deadline);
  const signOffDeadline = parseDate(sign_off_deadline);

  const enableSignOn = signOnDeadline > now;
  const enableSignOff = signOffDeadline > now;

  const daysToSignOn = enableSignOn ? daysDifference(signOnDeadline, now) : 0;
  const daysToSignOff =  enableSignOff ? daysDifference(signOffDeadline, now) : 0;

  return {
    signOnDeadline,
    signOffDeadline,
    enableSignOn,
    enableSignOff,
    daysToSignOn,
    daysToSignOff,
  };
};

export const verifyDeadlineDates = (signOnDeadlineDate, signOffDeadlineDate)  => {
  const now = new Date();

  const enableSignOn = signOnDeadlineDate > now;
  const enableSignOff = signOffDeadlineDate > now;

  const daysToSignOn = enableSignOn ? daysDifference(signOnDeadlineDate, now) : 0;
  const daysToSignOff =  enableSignOff ? daysDifference(signOffDeadlineDate, now) : 0;

  return {
    signOnDeadline: signOnDeadlineDate,
    signOffDeadline: signOffDeadlineDate,
    enableSignOn,
    enableSignOff,
    daysToSignOn,
    daysToSignOff,
  };
};

export const getChildrenIdsRecurse = (data, acc = []) => {
  const {children} = data || {};

  if (!children?.length || !data) return [];

  children?.forEach?.(child => {
    acc.push(child.id);
    getChildrenIdsRecurse(child, acc);
  });

  return [...acc];
};

export function getChildrenDataRecurse(r, a) {
  var b = {};

  Object.keys(a).forEach(function (k) {
    if (k !== 'children') {
      b[k] = a[k];
    }
  });

  r.push(b);

  if (Array.isArray(a.children)) {
    b.children = a.children.map(function (a) {
      return a.id;
    });

    return a.children.reduce(getChildrenDataRecurse, r);
  }

  return r;
}

// https://stackoverflow.com/a/41658173
function isClassComponent(component) {
  return (
    typeof component === 'function'
      && !!component.prototype.isReactComponent
  );
}

function isFunctionComponent(component) {
  return (
    typeof component === 'function'
      && String(component).includes('return React.createElement')
  );
}

export function isReactComponent(component) {
  return (
    isClassComponent(component)
      || isFunctionComponent(component)
  );
}

export const htmlStringExtractText = htmlStr => {
  if (!htmlStr || typeof htmlStr !== 'string') return '';

  const node = new DOMParser().parseFromString(htmlStr, 'text/html');

  return node?.body?.innerText || '';
};

export const containsHtml = text => !!text && typeof text === 'string' && /<[^>]*>/.test(text);

export const sliceHtmlText = (text, maxLength) => {
  if (!text || typeof text !== 'string') return '';

  const rawText = containsHtml(text) ? htmlStringExtractText(text) : text;
  const slicedText = rawText.slice(0, maxLength);

  return slicedText.length < rawText.length
    ? `${slicedText}...`
    : slicedText;
};

export const focusElementByDataId = id => {
  if (!id) return;

  setTimeout(() => {
    const input = document.querySelector(`[data-id="${id}"]`);

    if (input) input?.focus?.();
  }, 0);
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const filterUndefined = obj => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== undefined));

export const retryFetch = async ({
  fetchFn,
  onStart,
  onSuccess,
  onFail,
  onEnd,
  maxRetries = 4,
}) => {
  let retryCount = 0;

  if (onStart) onStart();

  const retry = async () => {
    try {
      const data = await fetchFn();

      if (data) {
        if (onEnd) onEnd(data);
        if (onSuccess) onSuccess(data);

        return data;
      }

      throw new Error('No data');
    } catch (error) {
      // dont retry if request was canceled with AbortController
      if (error?.name === 'CanceledError') {
        if (onEnd) onEnd(null);

        return null;
      }
      retryCount++;
      if (retryCount < maxRetries) return retry();
      if (onFail) onFail(error);
      if (onEnd) onEnd(null);

      return null;
    }
  };

  return retry();
};

export const getTimestamp = () => Math.round(Date.now() / 1000);
export const unixTimestampMs = () => dayjs().valueOf();
export const pickRandom = arr => arr[Math.floor(Math.random() * arr.length)];
export const randInt = (min = 0, max = 1) => Math.floor(Math.random() * (max - min + 1) + min);
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
