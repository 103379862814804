import {call, delay, race, take} from 'redux-saga/effects';
import {AUTH_LOGOUT_REQUEST} from '@actions/auth.actions';

function* retrySaga(
  callback,
  attempts = 1,
  wait = 2000,
  ...args
) {
  for (let i = 0; i <= attempts; i += 1) {
    try {
      const [logout, response] = yield race([
        take(AUTH_LOGOUT_REQUEST),
        call(callback, ...args),
      ]);

      if (logout) throw new Error('Cancelled');

      return response;
    } catch (error) {
      if (
        error?.message === 'Cancelled'
        || [error?.status, error?.request?.status, error?.response?.status].includes(401)
      ) {
        throw error;
      }

      if (i < attempts) {
        yield delay(wait);
      } else {
        throw error;
      }
    }
  }

  return callback;
}

export const retry = function(callback, attempts, wait) {
  return call(retrySaga, callback, attempts, wait);
};

export default retry;
