import axios from 'axios';
import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import * as configActions from '@actions/config.actions';
import {buildHash, configFileUrl} from '@config';
import {getConfigObject, selectConfig} from '@selectors/config.selectors';
import {i18nOverrideString} from '@src/i18n';
import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import {LoadStatuses} from '@types/load.types';
import {retry} from '@utils/sagas.utils';
import {isStringWithLength} from '@utils/string.utils';
import {waitForProfileId, waitForUsername} from './app.sagas';

const cleanBuildHash = hash => isStringWithLength(hash)
  ? hash
    ?.replaceAll?.(/[\n\r"]/g, '')
    ?.trim()
  : null;

const isDev = process.env && process.env.NODE_ENV === 'development';

const watchConfigSuccess = takeLatest(
  configActions.configGetConfigSuccess().type,
  function* onSuccess(action) {
    try {
      const {payload} = action || {};
      const {config, __meta} = payload || {};

      if (!isObjectWithKeys(config)) return;

      const pid = yield call(waitForProfileId);

      if (pid == null) return;

      const overrideTexts = config['over-ride-texts'];

      if (isObjectWithKeys(overrideTexts)) {
        Object.keys(overrideTexts).forEach(key => {
          i18nOverrideString(key, overrideTexts[key]);
        });
      };

      if (Array.isArray(config.css) && config.css.length > 0) {
        config.css.forEach(url => {
          if (!isStringWithLength(url)) return;

          const sheet = document.createElement('link');

          sheet.rel = 'stylesheet';
          sheet.href = url;
          sheet.type = 'text/css';
          document.head.append(sheet);
        });
      }

      localStorage.setItem('config-pid', pid);
      localStorage.setItem('config-app-version', cleanBuildHash(buildHash));
      localStorage.setItem('learningportalConfig', JSON.stringify(config));
    } catch (error) {
      console.error(error);
    }
  },
);

function* getValidLocalConfigData() {
  // console.log('getValidLocalConfigData .....');
  try {
    // if bart is set, we are looking at the page from the perspective of another user,
    // so we need to fetch the config for that user
    const bartId = localStorage.getItem('bart');

    if (bartId) return null;

    const profileId = yield call(waitForProfileId);
    const localStoragePersonId = localStorage.getItem('config-pid');

    let shouldFetchConfig = !localStoragePersonId || profileId !== Number(localStoragePersonId);

    // console.log({
    //   shouldFetchConfig,
    //   profileId,
    //   localStoragePersonId,
    // });

    if (shouldFetchConfig) {
      localStorage.setItem('config-pid', profileId);

      return null;
    }

    let localStorageAppVersion = localStorage.getItem('config-app-version');

    // {localStorageBuildHash: localStorageAppVersion});

    if (!localStorageAppVersion) return null;

    localStorageAppVersion = cleanBuildHash(localStorageAppVersion
      ?.split?.('.')
      ?.at(-1));

    // console.log({localStorageBuildHash: localStorageAppVersion});

    if (!localStorageAppVersion) return null;
    const currentBuildHash = cleanBuildHash(buildHash);

    shouldFetchConfig = !currentBuildHash || localStorageAppVersion !== currentBuildHash;
    // console.log({shouldFetchConfig});

    if (shouldFetchConfig) return null;

    const localStorageConfig = JSON.parse(localStorage.getItem('learningportalConfig'));

    return localStorageConfig || null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchConfigObject(action) {
  yield call(waitForUsername);

  const {payload} = action || {};
  const {backgroundUpdate, skipLoadStatus} = payload || {};

  const current = yield select(selectConfig);
  const configObject = yield select(getConfigObject);

  if (current?.status === LoadStatuses.IS_LOADING) return;

  if (configObject?.status === LoadStatuses.LOADED && !backgroundUpdate) {
    yield put(configActions.configGetConfigSuccess({__meta: 'loaded__not_background_update'}));

    return;
  }

  if (!backgroundUpdate) {
    const localStorageConfig = yield call(getValidLocalConfigData);

    if (localStorageConfig) {
      // if valid config in localStorage,we can use that while fetching new config in background
      yield put(configActions.configGetConfigSuccess({
        config: localStorageConfig,
        __meta: 'loaded_from_localstorage',
      }));

      yield put(configActions.configGetConfig({backgroundUpdate: true}));

      return;
    }

    // if not valid config in localStorage, remove old one if exists
    localStorage.removeItem('learningportalConfig');
  }

  if (!skipLoadStatus) yield put(configActions.configGetConfigRequest({backgroundUpdate}));

  try {
    if (!configFileUrl) {
      console.warn('Url for config-file not configured. Please configure using key REACT_APP_CONFIG_FILE_URL in environmentfile');
      yield put(configActions.configGetConfigSuccess({
        config: {},
        __meta: 'no_config_file_url',
      }));

      return;
    }

    const config = yield retry(() => axios.request({
      method: 'GET',
      headers: {'X-Grape-Lang': localStorage.getItem('language') || 'no'},
      url: configFileUrl, // ${configObject ? '?id=' + configObject : ''}`,
      withCredentials: true,
    })
      .then(response => response.data));

    if (!isObjectWithKeys(config)) {
      console.error('Invalid JSON for config-file');

      if (process.env.NODE_ENV === 'development') {
        alert('Invalid JSON for config file');
      }

      yield put(configActions.configGetConfigFailure({error: {}}));

      return;
    }

    if (!config.hasOwnProperty('params')) {
      config.params = {};
    }

    if (!config.params.hasOwnProperty('theme')) {
      config.params.theme = localStorage.getItem('theme') || 'default';
    }

    yield put(configActions.configGetConfigSuccess({
      config,
      __meta: Date.now(),
    }));
  } catch (error) {
    console.error(error);
    yield put(configActions.configGetConfigFailure({error}));
  }
}

export function* waitForConfigObject(returnObject = false) {
  const configObject = yield select(getConfigObject);

  if (configObject?.status === LoadStatuses.LOADED) {
    if (returnObject) return configObject;

    return true;
  }

  const configData = yield select(selectConfig);

  if ([LoadStatuses.NOT_LOADED, LoadStatuses.FAILED].includes(configData?.status)) {
    yield fork(fetchConfigObject);
  }

  let config = yield select(getConfigObject);
  let status = config?.status;

  while (status !== LoadStatuses.LOADED) {
    yield take(configActions.CONFIG_GET_CONFIG_SUCCESS);
    config = yield select(getConfigObject);
    status = config?.status;
  }

  if (returnObject) return yield select(getConfigObject);

  return true;
}

const exportObj = [
  watchConfigSuccess,
  takeLatest(configActions.CONFIG_GET_CONFIG, fetchConfigObject),
];

export default exportObj;
