export const actionTypes = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  LOADED_PARTIAL: 'LOADED_PARTIAL',
  FAILURE: 'FAILURE',
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  RESET: 'RESET',
  MERGE: 'MERGE',
};

export const {
  REQUEST,
  SUCCESS,
  LOADED_PARTIAL,
  FAILURE,
  INSERT,
  UPDATE,
  DELETE,
  RESET,
  MERGE,
} = actionTypes;

export const fetchActionTypes = [
  REQUEST,
  SUCCESS,
  FAILURE,
  LOADED_PARTIAL,
];

export const updateActionTypes = [
  MERGE,
  LOADED_PARTIAL,
  INSERT,
  UPDATE,
  DELETE,
  RESET,
];

export const BASE = 'BASE';

export const allActionTypes = Object.keys(actionTypes);
