import React, {useMemo} from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import {createPortal} from 'react-dom';
import styled from '@emotion/styled';
import {i18n} from '@src/i18n';
import {Backdrop} from './backdrop';
import {FluidSpinner} from './loading-spinner-fluid';

const spinnerTypes = {fluid: FluidSpinner};
const isProd = process?.env?.NODE_ENV === 'production';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner__text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }

  &.--no-border {
    .loading-spinner-bg-stroke {
      stroke: none !important;
    }
  }

  &.--no-shadow {
    .loading-spinner-bg-blur {
      filter: none !important;
    }
  }
`;

const animationProps = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  exit: {opacity: 0},
};

export const LoadingSpinner = React.memo(({
  loading = true,
  type = 'fluid',
  size = 100,
  backdrop,
  backdropPosition = 'absolute',
  spinnerPosition = 'absolute',
  transition,
  targetNode,
  border,
  shadow,
  minimal,
  text = false, // Text to display below the spinner, true = default text, string = custom text
  color,
  children = null,
  backgroundColor = '#ffffff',
  trackColor = '#E6EAF0',
  backdropColor,
}) => {
  if (!loading) return null;

  if (!type || !spinnerTypes[type]) {
    if (!isProd) throw new Error(`Invalid spinner type: ${type}`);

    return null;
  }

  const SpinnerComponent = spinnerTypes[type];

  const _border = border === undefined ? !minimal : border !== false;
  const _shadow = shadow === undefined ? !minimal : shadow !== false;
  const _backdrop = backdrop === undefined ? !minimal : backdrop !== false;
  const _transition = transition === undefined ? !minimal : transition !== false;
  const _duration = useMemo(() => ({duration: _transition ? .5 : 0}), [_transition]);

  const Element = useMemo(() => (
    <Wrapper
      className={classNames('spinner', 'spinner-outer-wrapper', {
        '--no-border': _border === false,
        '--no-shadow': _shadow === false,
        '--minimal': minimal,
      })}
      initial={animationProps.initial}
      animate={animationProps.animate}
      exit={animationProps.exit}
      transition={_duration}
    >
      {!!_backdrop && (
        <Backdrop
          position={backdropPosition}
          color={backdropColor}
        />
      )}
      <SpinnerComponent
        size={size}
        position={spinnerPosition}
        backgroundColor={backgroundColor}
        color={color}
        trackColor={trackColor}
      >
        {children}
      </SpinnerComponent>
      {!!text && (
        <div className="spinner__text">
          {text === true
            ? i18n('globals.loading', {ellipsis: true})
            : text}
        </div>
      )}
    </Wrapper>
  ), [
    _border,
    _shadow,
    _backdrop,
    _duration,
    backdropPosition,
    backdropColor,
    size,
    spinnerPosition,
    backgroundColor,
    color,
    trackColor,
    children,
    text,
    minimal,
  ]);

  if (targetNode) {
    return createPortal(Element, targetNode);
  }

  return Element;
});

export const LoadingSpinnerMinimalSmall = React.memo(({
  color = '#0a0a0a',
  backgroundColor = '#F5F5F5',
  trackColor = '#ffffff',
  size = 32,
}) => (
  <LoadingSpinner
    size={size}
    backgroundColor={backgroundColor}
    color={color}
    trackColor={trackColor}
    backdrop={false}
    minimal
  />
));
