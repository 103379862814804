import React from 'react';
import {LoadingSpinner} from '@components/loading/loading-spinner';
import {loadableRetry} from '@utils/loadable-retry';

export const LoadableSelfSignReadModal = loadableRetry(() => {
  console.log('load modal.selfsign-read');

  return import(/* webpackChunkName: "modal.selfsignread" */ './self-sign-read-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.selfsignread',
});

export const LoadableSelfSignSelectModal = loadableRetry(() => {
  console.log('load modal.selfsignselect');

  return import(/* webpackChunkName: "modal.selfsignselect" */ './self-sign-select-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.selfsignselect',
});

export const LoadableCompetenceModal = loadableRetry(() => {
  console.log('load modal.competence');

  return import(/* webpackChunkName: "modal.competence" */ './competence-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.competence',
});

export const LoadableChecklistModal = loadableRetry(() => {
  console.log('load modal.checklist');

  return import(/* webpackChunkName: "modal.checklist" */ './checklist-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.checklist',
});

export const LoadableSignatureModal = loadableRetry(() => {
  console.log('load modal.signature');

  return import(/* webpackChunkName: "modal.signature" */ './signature-modal');
}, {
  fallback: <LoadingSpinner />,
  name: 'modal.signature',
});
