import {BASE} from '../util/constants';

export function createAction(type, meta) {
  const action = payload => ({
    type,
    payload,
    ...meta ? {meta} : {},
  });

  action.toString = () => type;
  action.type = type;

  return action;
};

export function createReduxActions({
  prefix,
  actionName,
  subTypes = [],
  keepNames = false,
  meta,
}) {
  if (!actionName) {
    throw new Error('actionName is required');
  }

  let _actionName = actionName;

  if (!keepNames && prefix) {
    _actionName = `${prefix}/${actionName}`;
  }

  const actions = {[BASE]: createAction(_actionName, meta)};

  if (!subTypes.length) {
    return actions;
  }

  for (const type of subTypes) {
    if (type !== BASE) {
      actions[type] = createAction(`${_actionName}/${type}`, meta);
    }
  }

  return actions;
};
