import * as React from 'react';
import {useSelector} from 'react-redux';
import {selectIsManager, selectProfileUserName} from '@selectors/profile.selectors';
import {loadableRetry} from '@utils/loadable-retry';

const Component = loadableRetry(() => {
  console.log('load debug.panel');

  return import(/* webpackChunkName: "debug.panel" */ './debug-panel');
}, {name: 'debug.panel'});

export const DebugPanel = React.memo(() => {
  const userName = useSelector(selectProfileUserName);
  const isManager = useSelector(selectIsManager);

  const DEBUG = isManager && userName === '__DEBUG__';

  if (!DEBUG) return null;

  return <Component />;
});
