import axios from 'axios';
import dayjs from 'dayjs';
import {produce} from 'immer';
import {
  all,
  call,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as alertAction from '@actions/alert.actions';
import {authUnauthorized} from '@actions/auth.actions';
import {componentsSetMap} from '@actions/components.actions';
import * as CA from '@actions/courses.actions';
import {
  employeesFetchSelectedPerson,
  employeesFetchSelectedPersonCompetences,
  employeesFetchSelectedPersonCompetencesChildren,
  employeesFetchSelectedPersonReport,
} from '@actions/employees.actions';
import {selectMapCourse} from '@actions/map.actions';
import * as mapCoursesActions from '@actions/map.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import * as PA from '@actions/profile.actions';
import {fetchRole} from '@actions/roles.actions';
import {routerMyEducationMainViewDidMount} from '@actions/router.actions';
import {backendUrl, backendUrlV2} from '@config';
import {getCourseEventsAPI, updateCourseEvents} from '@sagas/courses.sagas';
import {getAuthStatus} from '@selectors/auth.selectors';
import {getPropertiesForCurrLangAndTrackBadge} from '@selectors/config.selectors';
import {getSelectedPerson, getSelectedPersonId, selectEmployeesList} from '@selectors/employees.selectors';
import {
  getIsAllMapDotsCompleted,
  getMapCourses,
  selectMapDottsWithoutVerificationAndOutro,
  selectMapTrackId,
} from '@selectors/map.selectors.new';
import {
  getCompetences,
  getOrganisationId,
  getPassed,
  getPassedCompetences,
  getPassedIds,
  getProfile,
  getRoles,
  getSelectedRoleId,
  getSelfSign,
  getShowRoles,
  selectActiveOrgId,
  selectIsManager,
  selectProfileId,
  selectProfileRolesSummary,
  selectProfileUserName,
} from '@selectors/profile.selectors';
import {selectLearningPathTrackExtraData} from '@selectors/tracks.selectors';
import {i18n} from '@src/i18n';
import {getShouldFetch, isFailedOrNotLoaded} from '@src/store-normalized/util/loadstatus.utils';
import {isObjectWithKeys} from '@src/store-normalized/util/misc';
import {stringifyUrlParams} from '@utils/requests.utils';
import {retry} from '@utils/sagas.utils';
import {fetchCompetenceChildrenProgressAPI} from '../api/person-competences';
import {getCompetenceId, isChecklistCompetence} from '../util/competence-identity';
import {waitForOrgId, waitForProfileId, waitForUsername} from './app.sagas';
import {AdminNames} from './auth.sagas';
import {waitForConfigObject} from './config.sagas';

const ProfileAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

const delay = ms => new Promise(res => setTimeout(res, ms));

function* forceRefreshSelfSign() {
  yield put(PA.profileSelfSign({refresh: true}));
}

function* autoFetchSelfSign() {
  const current = yield select(getSelfSign);
  const shouldFetch = getShouldFetch(current);

  if (!shouldFetch) return;

  yield put(PA.profileSelfSign({
    refresh: shouldFetch?.refresh,
    full: shouldFetch?.full,
  }));
}

export function* updateActiveOrgIdSaga(action) {
  const orgId = action.payload;
  const prevOrgId = yield select(getOrganisationId);

  yield put(PA.profileSetActiveOrgId(orgId));

  if (orgId === prevOrgId) return;

  localStorage.setItem('orgId', orgId);

  yield put(CA.coursesGetCourseEvents({}));
}

export function* fetchAllOrganisations() {
  yield put(PA.profileFetchAllOrganisations());

  try {
    const data = yield retry(() => ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/persons/allMyOrganisations`,
        withCredentials: true,
      })
      .then(response => response.data));

    yield put(PA.profileFetchAllOrganisations.success({data}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchAllOrganisations.failure({error}));
  }
}

function* createSelfSign(action) {
  const {
    formData,
    onSuccess,
    onError,
    mapId,
    trackId,
    personId: personIdPayload,
  } = action.payload || {};

  if (!isObjectWithKeys(formData)) throw new Error('formData is not an object');

  const personId = personIdPayload || formData?.personId;
  const profileId = yield select(selectProfileId);
  const isManager = yield select(selectIsManager);
  const managerRegisteredEmployeeCompetence = !!personId && personId !== profileId && isManager;

  if (!managerRegisteredEmployeeCompetence) yield put(PA.profileCreateSelfSign.request());

  // eslint-disable-next-line no-extend-native
  Date.prototype.toJSON = function () {
    return dayjs(this).format('YYYY-MM-DDThh:mm:00.000Z');
  };

  try {
    const data = yield retry(() => ProfileAxios.request({
      method: 'POST',
      headers: {'Content-type': 'application/json'},
      url: `${backendUrlV2}/personcompetences/`,
      data: JSON.stringify(formData),
      withCredentials: true,
    }).then(response => response.data));

    // files could either be from multi-file-picker or from single-file-picker
    const filesList = formData.files?.files
      ?? (isObjectWithKeys(formData?.files) && Object.values(formData.files).map(f => f?.[0])
        .filter(Boolean));

    if ((filesList?.length || 0) > 0) {
      try {
        yield all(filesList.map(file => retry(() => {
          const formPost = new FormData();

          formPost.append('file', file);
          formPost.append('timestamp', Math.round(Date.now() / 1000));

          return ProfileAxios.request({
            method: 'POST',
            url: `${backendUrl}/files/savemanagefiles/person_has_course_event/${data.id}`,
            data: formPost,
            withCredentials: true,
          });
        })));

        yield put(notificationsAdd({
          notification: {
            color: 'green',
            text: data.passed === 100 && i18n('person.registered-competence-passed') || i18n('person.registered-competence'),
          },
        }));
      } catch (error) {
        console.error(error);

        yield put(notificationsAdd({
          notification: {
            color: 'red',
            text: 'En feil har oppstått',
          },
        }));

        return;
        // if (error.code === 401) {
        //   yield put(authUnauthorized({error}));
        // }
        // yield put(PA.profileCreateSelfSign.failure({error}));
      }
    }

    yield put(PA.profileCreateSelfSign.success({
      ...data,
      mapId,
      trackId,
    }));

    if (data.passed === 100) {
      if (managerRegisteredEmployeeCompetence) {
        const employees = yield select(selectEmployeesList);
        const {user_name: employeeUserName} = employees?.byId?.[personId] || {};

        if (!employeeUserName) return;

        yield put(employeesFetchSelectedPersonReport({
          userName: employeeUserName,
          personId,
        }));
        yield put(employeesFetchSelectedPerson({userName: employeeUserName}));
      } else {
        const isLocationRole = window.location.href.includes('/role');
        const isLocationLearningPath = !isLocationRole && window.location.href.includes('/learning-path/');

        yield isLocationRole || isLocationLearningPath || trackId || mapId
          ? put(PA.profileFetchPersonSummary({refresh: true}))
          : put(PA.profileFetchReport({refresh: true}));

        if (mapId) {
          yield put(PA.profileUpdatePassedCompetences({
            mapId,
            cid: formData.competenceId,
            isPassed: true,
          }));
        }
      }
    }

    if (!managerRegisteredEmployeeCompetence) {
      const userName = yield select(selectProfileUserName);

      yield put(PA.profileUpdateOneCompetence({
        cid: formData.competenceId,
        userName,
      }));
    }

    if (onSuccess) onSuccess();
  } catch (error) {
    if (!managerRegisteredEmployeeCompetence) yield put(PA.profileCreateSelfSign.failure({error}));

    yield put(notificationsAdd({
      notification: {
        color: 'red',
        text: error?.message || i18n('errors.default'),
      },
    }));

    if (onError) onError(error);
    console.log(error);
  };
}

function* fetchProfileCompetenceLevelSaga(action) {
  const {person_id, refetch} = action.payload || {};

  let personId = person_id;

  if (!personId) personId = yield call(waitForProfileId);

  yield put(PA.profileFetchCompetenceLevel.request());

  try {
    const {data} = yield retry(() => axios.request({
      url: `${backendUrlV2}/persons/${personId}/competencelevel`,
      method: 'GET',
      withCredentials: true,
    }));

    const competencelevel = [
      {
        title: i18n('person.level.total'),
        progress: Math.round(data.competence_level_total),
      },
      {
        title: i18n('person.level.mandatory'),
        progress: Math.round(data.competence_level_required),
      },
      {
        title: i18n('person.level.recomended'),
        progress: Math.round(data.competence_level_optional),
      },
      {
        title: i18n('person.level.personal'),
        progress: Math.round(data.competence_level_personal),
      },
    ];

    yield put(PA.profileFetchCompetenceLevel.success({competencelevel}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCompetenceLevel.failure({error}));
  }
}

function* fetchPerson(action) {
  console.log('fetch person action is', action);
  const {
    partialUpdate = false,
    refresh = false,
    userName,
    // myEducationEnabled,
  } = action.payload || {};

  const configObject = yield call(waitForConfigObject, true);

  yield put(PA.profileFetchPerson.request(action?.payload));

  const myEducationEnabled = configObject.isModuleEnabled('my-education');

  const personId = yield select(selectProfileId);

  try {
    if (personId && myEducationEnabled !== false) {
      yield put(PA.profileFetchCompetenceLevel({person_id: personId}));
    }

    const profileData = yield retry(() => ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/person`,
        params: {
          fields:
            'person_id,firstname,lastname,profile_image,email,mobile,fullname,user_name,roles(title,role_type_role_type,organisation_id),positions(organisation_id,title,role_type_role_type),organisations(organisation_id,extern_organisation_id,id,title,brand_id),data(avatar,last_message)',
        },
        withCredentials: true,
      })
      .then(response => response.data?.persons?.[0]));

    const person_id = profileData.person_id;

    if (!personId && person_id && myEducationEnabled !== false) {
      yield put(PA.profileFetchCompetenceLevel({person_id}));
    }

    let isCheif = false;
    let pos = null;

    if (Array.isArray(profileData?.positions)) {
      pos = profileData.positions.some(p => AdminNames.has(p.role_type_role_type));
      isCheif = pos;
    }

    if (!isCheif && Array.isArray(profileData?.roles)) {
      pos = profileData.roles.some(p => AdminNames.has(p.role_type_role_type));
      isCheif = pos;
    }

    const activeOrgId = yield select(selectActiveOrgId);

    let orgIdFinal = activeOrgId ?? localStorage.getItem('orgId');

    if (isCheif) {
      yield put(PA.profileSetManager());

      /*
       * IF WE ARE CHEIF, CHECK ALL ORGS AND REMOVE ANY THAT ARE NOT CHEIF.
       *
       * */
      const {
        positions = [],
        roles = [],
      } = profileData || {};

      if (positions.length) {
        const rpos = positions.filter(p => AdminNames.has(p.role_type_role_type));

        if (rpos.length !== 0 && !orgIdFinal) {
          orgIdFinal = rpos[0].organisation_id;
        }
      }

      /*
      *  IF USER IS NOT LEADER FOR ITS ORG, CHECK ITS ROLES.
      * */
      if (!orgIdFinal && roles.length) {
        const rrpos = roles.filter(p => AdminNames.has(p.role_type_role_type));

        if (rrpos.length && positions?.[0]?.organisation_id) {
          orgIdFinal = positions[0].organisation_id;
        }
      }
    }

    if (orgIdFinal && !activeOrgId) {
      yield call(updateActiveOrgIdSaga, {payload: Number.parseInt(orgIdFinal, 10)});
    }

    const superuser = !!profileData?.roles?.some?.(p => p.role_type_role_type === 'superuser') || false;

    yield put(PA.profileSetSpecialroles({
      data: null,
      superuser,
    }));

    // profileData.competencelevel = competencelevel;

    yield put(PA.profileFetchPerson.success({
      person: profileData,
      positions: profileData.positions,
      // competencelevel,
    }));
  } catch (error) {
    console.error(error);
    yield put(authUnauthorized({error}));
    yield put(PA.profileFetchPerson.failure({error}));
  }
}

export function* fetchCompetencesChildrenSaga({payload}) {
  const {
    courseId,
    root,
    isOtherCompetences,
    isOptionalCompetences,
  } = payload || {};

  if (!courseId) return;

  try {
    const orgId = yield call(waitForOrgId);// yield select(getOrganisationId);

    const isEmployeeView = root === 'show-employee'
    || root === 'employee-activites';

    const personId = yield isEmployeeView
      ? select(getSelectedPersonId)
      : select(selectProfileId);

    const selectedEmployee = isEmployeeView
      ? yield select(getSelectedPerson)
      : {};

    let competences = [];

    let _root = root;

    if (window.location.pathname.startsWith('/learning-path/') || window.location.pathname.startsWith('/atlas/')) {
      _root = 'track';
    }

    switch (_root) {
    case 'track': {
      break;
    }
    case 'show-role': {
      yield put(PA.profileFetchShowRoleChildren.request());
      competences = yield select(getShowRoles);
      if (competences?.data) competences = competences.data;
      break;
    }
    case 'employee-activites':
    case 'show-employee': {
      yield put(employeesFetchSelectedPersonCompetencesChildren.request());
      break;
    }
    default: {
      yield put(PA.profileFetchCompetencesChildren.request());
      competences = yield select(getCompetences);
    }
    }

    const {data} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
      params: {
        state: 'all',
        limit: 100,
        fields: 'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
      },
      withCredentials: true,
    }));

    switch (_root) {
    case 'track': {
      yield put(mapCoursesActions.trackSetCompetenceChildren({
        id: courseId,
        data,
      }));

      return;
    }
    case 'show-role': {
      const newCompetences = produce(competences, draft => {
        if (!draft?.requirements?.length) return;

        for (const competence of draft.requirements) {
          if (competence?.competence_id === courseId) {
            if (!competence.competence) return;
            competence.competence.children = data;
          }
        }
      });

      yield put(PA.profileFetchShowRoleChildren.success({competences: newCompetences}));

      break;
    }

    case 'employee-activites': {
      const newData = produce(selectedEmployee.data, draft => {
        const target = draft?.summary?.activities?.children?.find?.(p => p.competence_id === courseId);

        if (target) target.children = data;
      });

      yield put(employeesFetchSelectedPersonCompetences.success({data: newData}));

      break;
    }
    case 'show-employee': {
      const key = isOptionalCompetences
        ? 'optional'
        : isOtherCompetences
          ? 'other'
          : 'requirement';

      const newData = produce(selectedEmployee.data, draft => {
        const targetCompetences = draft?.summary?.[key];

        if (!targetCompetences?.length) return;

        for (const targetCompetence of targetCompetences) {
          if (!targetCompetence?.children?.length) continue;

          const target = targetCompetence.children.find(p => p.competence_id === courseId);

          if (target) {
            target.children = data;

            break;
          }
        }
      });

      yield put(employeesFetchSelectedPersonCompetences.success({data: newData}));

      break;
    }
    default: {
      const newCompetences = produce(competences, draft => {
        for (const competence of draft) {
          if (competence?.competence_id === courseId) {
            competence.children = data;
          }
        }
      });

      yield put(PA.profileFetchCompetencesChildren.success({competences: newCompetences}));
    }
    }
  } catch (error) {
    console.error(error);

    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }

    if (root === 'show-role') {
      yield put(PA.profileFetchShowRoleChildren.failure({error}));
    } else if (root === 'show-employee') {
      yield put(employeesFetchSelectedPersonCompetencesChildren.failure({error}));
    } else {
      yield put(PA.profileFetchCompetencesChildren.failure({error}));
    }
  }
}

/*
 *
 *   -- updateOneCompetence --
 *
 *   Update one competence and go back to redux.
 *   and passed competence
 *
 * */

export function* updateOneCompetence(action) {
  const {cid, userName, mapId} = action.payload;

  try {
    yield put(PA.profileUpdateOneCompetence.request());

    const competences = yield retry(() =>
      ProfileAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences/${cid}`,
          params: {
            fields:
              'title,description,valid_until,requirement_type,certificate_url,person_competence_id,description,competence_id,competence_type,competence(files,competence_title,checked_by,short_description,title,competence_type,competence_type_id,competence_id),passed,manager_check_user_id',
            limit: 101,
            user_name: userName,
          },
          withCredentials: true,
        })
        .then(response => response.data.personcompetences));

    if (competences && competences.length !== 0) {
      const isPasssed = competences[0].passed === 100;

      if (window.location.href.includes('/role')) {
        const userRolesCompetences = yield select(getShowRoles);
        const userCompetence = userRolesCompetences.data.requirements.findIndex(urc => urc.competence_id === competences[0].competence_id);

        competences[0].competence.competence_type = competences[0].competence_type;
        competences[0].competence.competence_title = competences[0].competence.title;
        competences[0].requirement_type = 'Mandatory';

        const newCompetences = produce(userRolesCompetences, draft => {
          if (userCompetence === -1) {
            draft.data.requirements.push(competences[0]);
          } else {
            draft.data.requirements[userCompetence] = competences[0];
          }
        });

        yield put(PA.profileFetchRole.success({role: newCompetences.data}));
      };

      if (isPasssed) {
        if (mapId) {
          yield put(PA.profileUpdatePassedCompetences({
            mapId,
            cid,
          }));
        }
        /* UPDATE PASSED COMPETENCES */
        const usersPassed = yield select(getPassed);
        const userPassedCompetence = usersPassed.data.findIndex(urc => urc.competence_id === competences[0].competence_id);

        const newUsersPassed = produce(usersPassed.data, draft => {
          if (userPassedCompetence === -1) {
            draft.push({
              competence_id: competences[0].competence_id,
              date: competences[0].date,
              requirement: competences[0].requirement,
              valid_until: competences[0].valid_until,
              passed: 100,
              id: competences[0].id,
              person_competence_id: competences[0].person_competence_id,
            });
          } else {
            console.log('exists');
          }
        });

        yield put(PA.profileFetchPassedCompetences.success({competences: newUsersPassed}));
      }
    }
    yield put(PA.profileUpdateOneCompetence.success());
  } catch (error) {
    console.log(error);
  }
};

const mapRolesObjectToArray = roles => isObjectWithKeys(roles)
  ? Object.values(roles).map(role => {
    const {
      role_id: id,
      role_title: title,
      passed: passed_cids = [],
      missing: missing_cids = [],
      description,
      role_type: type = 'role',
    } = role || {};

    return {
      id,
      type,
      title,
      passed_cids,
      missing_cids,
      passed_count: passed_cids.length || 0,
      missing_count: missing_cids.length || 0,
      description,
      passed: passed_cids.length
        ? Math.round(passed_cids.length / (passed_cids.length + missing_cids.length) * 100)
        : 0,
    };
  })
  : [];

/** This endpoint is 4x faster than the old one, but we are currently missing files (cover image).
 * For now we use it in parallell with the old one for getting learning path progress
 * on roles with only one competence of type "track" (learning paths) */
export function* fetchPersonRolesSummaryAPI2(action) {
  const {personId} = action?.payload || {};
  const pid = personId || (yield call(waitForProfileId));

  try {
    const data = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrlV2}/persons/${pid}/rolecompetences`,
      withCredentials: true,
    })
      .then(response => {
        const {
          data: {
            mandatory_roles = {},
            optional_roles = {},
            personal = {},
            other = {},
          } = {},
        } = response;

        const requirement = mapRolesObjectToArray(mandatory_roles);
        const optional = mapRolesObjectToArray(optional_roles);

        requirement.push({
          courses: [],
          description: false,
          id: -2,
          missing_count: personal.missing?.length || 0,
          passed_count: personal.passed?.length || 0,
          title: 'Personlige kompetansekrav',
          type: 'role',
        });

        return {
          requirement,
          optional,
          other: [{
            description: false,
            id: 0,
            title: 'Andre kompetanser',
          }],
        // personal: {mapRolesObjectToArray(personal),}
        };
      }));

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

export function* fetchRoleCompetencesAPI(action) {
  const {roleId, userName} = action?.payload || {};

  if (!roleId) return null;

  const username = userName || (yield call(waitForUsername));

  try {
    const data = yield retry(() => axios.request({
      method: 'GET',
      url: `${backendUrl}/api/roles/${roleId}`,
      params: {
        fields: 'required_competences(id,title,short_description,competence_type_key),optional_competences(id,title,short_description,competence_type_key)',
        user_name: username,
        role_ids: roleId,
      },
    }).then(response => response.data?.roles?.[0]) || null);

    return data;
  } catch (error) {
    console.error(error);

    return null;
  }
}

// fetches "passed"-data 2 levels deep for a role with only one competence of type "track" (learning path)
// calculates the total progress of the track from the children progress
function* fetchPersonRolesLearningPathProgress(action) {
  try {
    const {pid, username, orgId} = action?.payload || {};

    const personId = pid || (yield call(waitForProfileId));
    const _username = username || (yield call(waitForUsername));
    const _orgId = orgId || (yield call(waitForOrgId));

    let currentSummary = yield select(selectProfileRolesSummary);

    let {
      optional,
      requirement,
    } = currentSummary?.data || {};

    if (!currentSummary?.data?.requirement) {
      // if we don't have the data, race between the old and new endpoint
      const res = yield race({
        apiV2Data: call(fetchPersonRolesSummaryAPI2, {payload: {personId}}),
        apiV1Action: take(PA.profileFetchPersonSummary.success),
      });

      currentSummary = res?.apiV1Action?.payload?.summary || res?.apiV2Data;
      optional = currentSummary?.optional || [];
      requirement = currentSummary?.requirement || [];
    }

    const potentialSingleChildOfTypeTrack = [...optional, ...requirement]
      .filter(role => role?.type === 'role' && role?.missing_count === 1 && role?.passed_count === 0)
      .map(role => role.id) || [];

    if (!potentialSingleChildOfTypeTrack.length) return;

    const tracksProgressMap = {};

    yield all(potentialSingleChildOfTypeTrack
      .map(function* (roleId) {
        if (!roleId) return;

        const role = yield call(fetchRoleCompetencesAPI, {
          payload: {
            roleId,
            userName: _username,
          },
        });

        const {
          id: cid,
          competence_type_key: type,
        } = role?.required_competences?.[0] || role?.optional_competences?.[0] || {};

        if (!cid || type !== 'track') return;

        const trackProgress = yield call(fetchCompetenceChildrenProgressAPI, {
          payload: {
            cid,
            personId,
            orgId: _orgId,
          },
        });

        if (!trackProgress) return;

        tracksProgressMap[roleId] = {
          role_id: roleId,
          competence_id: cid,
          passed: trackProgress.passed,
          children_count: trackProgress.children_count,
        };
      }));

    if (!isObjectWithKeys(tracksProgressMap)) return;

    const updatedSummary = produce(currentSummary, draft => {
      const roles = [...draft?.optional || [], ...draft?.requirement || []];

      roles.forEach(role => {
        const trackProgress = !!role?.id && tracksProgressMap[role.id];

        if (!trackProgress) return;

        role.trackData = tracksProgressMap[role.id];
      });
    });

    yield put(PA.profileFetchPersonSummary.success({summary: updatedSummary}));
  } catch (error) {
    console.error(error);

    return;
  }
}

/*
 *
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 *
 * */
export function* fetchPersonsSummary(action) {
  try {
    const userName = yield call(waitForUsername);
    const personId = yield call(waitForProfileId);
    const orgId = yield call(waitForOrgId);

    yield put(PA.profileFetchPersonSummary.request(action?.payload));

    yield spawn(fetchPersonRolesLearningPathProgress, {
      payload: {
        personId,
        username: userName,
        orgId,
      },
    });

    const summary = yield retry(() => ProfileAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/roles`,
        params: {
          fields: 'description,files',
          role_meta_types: 'position,role',
          summary: 1,
          user_name: userName,
        },
        withCredentials: true,
      })
      .then(response => response.data));

    yield put(PA.profileFetchPersonSummary.success({summary}));
  } catch (error) {
    console.error(error);
    if (error.code === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPersonSummary.failure({error}));
  }
}

function* fetchPassedCompetences(payload) {
  yield put(PA.profileFetchPassedCompetences.request());

  // TODO use this when we can fetch a single competence
  const params = payload?.dirty
    ? {
      state: 'passed',
      limit: 100,
      // lms: 1,
      dirty: 1,
      fields: 'passed,competence_id,points,date',
    }
    : {
      state: 'passed',
      limit: 100,
      // lms: 1,
      fields: 'passed,competence_id,points,date,valid_until',
    };

  try {
    const {data: {personcompetences: competences}} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences`,
      params,
      withCredentials: true,
    }));

    yield put(PA.profileFetchPassedCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPassedCompetences.failure({error}));
  }
}

function* fetchSelfSign(action) {
  const refresh = action.payload?.refresh || action.payload?.reload;
  const selfSign = yield select(getSelfSign);

  if (!refresh && !isFailedOrNotLoaded(selfSign)) return;

  yield put(PA.profileSelfSign.request({refresh}));

  try {
    const {data: {competences = []} = {}} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/persons/pendingchecklists`,
      withCredentials: true,
    }));

    const checklists = {};
    const selfsign = [];
    const byId = {};

    for (const competence of competences) {
      const cid = getCompetenceId(competence);

      if (isChecklistCompetence(competence)) {
        checklists[cid] = competence;
      }
      byId[cid] = competence;
      selfsign.push(competence);
    }

    yield put(PA.profileUpdatePendingChecklists({data: checklists}));
    yield put(PA.profileSelfSign.success({
      data: selfsign,
      byId,
    }));
  } catch (error) {
    console.error(error);
    yield put(PA.profileSelfSign.failure({error}));
  }
}

function* cancelMySelfSign(action) {
  const {courseId, phceId, callback} = action.payload;

  const personId = yield select(selectProfileId);

  yield put(PA.profileCancelSelfSign.request());

  try {
    const data = {
      personId,
      courseId,
      passed: 0,
    };

    yield retry(() => ProfileAxios.request({
      headers: {'Content-Type': 'application/json'},
      method: 'DELETE',
      url: `${backendUrlV2}/personcompetences/${phceId}`,
      data,
      withCredentials: true,
    }));

    yield put(PA.profileCancelSelfSign.success());

    if (callback) {
      callback();
    }
    yield put(notificationsAdd({
      notification: {
        color: 'green',
        text: i18n('person.cancel-competence-success'),
      },
    }));
  } catch (error) {
    console.error(error);
    if (callback) callback();
    yield put(PA.profileCancelSelfSign.failure({error}));
  }
};

function* fetchPassedCompetencesFull(action) {
  try {
    const {selfSign, summary, expiring} = action?.payload || {};

    const currentSelfSign = yield select(getSelfSign);
    const currentSummary = yield select(state => state.profile.summary);
    const currentExpiring = yield select(state => state.profile.expiring);

    const shouldFetchSelfSign = selfSign && getShouldFetch(currentSelfSign);
    const shouldFetchSummary = summary && getShouldFetch(currentSummary);
    const shouldFetchExpiring = expiring && getShouldFetch(currentExpiring);

    if (shouldFetchSelfSign) yield spawn(forceRefreshSelfSign);
    if (shouldFetchSummary) yield put(PA.profileFetchPersonSummary({refresh: shouldFetchSummary.refresh}));
    if (shouldFetchExpiring) yield put(PA.profileFetchExpiring({refresh: shouldFetchExpiring.refresh}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPassedCompetencesFull.failure({error}));
  }
}

function* fetchMissingCompetencesAPI() {
  yield put(PA.profileFetchPersonCompetences.request());

  try {
    const {data: {personcompetences: competences = []} = {}} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences`,
      params: {
        state: 'missing',
        limit: 100,
        isthins: 1,
        fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
      },
      withCredentials: true,
    }));

    for (const competence of competences) {
      competence.competence_type_id = competence?.competence_type?.competence_type_id;
      competence.id = competence?.competence_id;
      competence.children = [];
      competence.expanded = false;
      if (competence?.competence?.files?.length) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.profileFetchPersonCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPersonCompetences.failure({error}));
  }
}

function* fetchPersonCompetencesAPI() {
  yield put(PA.profileFetchPersonCompetences.request());

  try {
    const {data: {personcompetences: competences = []} = {}} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences`,
      params: {
        state: 'all',
        limit: 100,
        fields:
              'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
      },
      withCredentials: true,
    }));

    for (const competence of competences) {
      competence.competence_type_id = competence?.competence_type?.competence_type_id;
      competence.id = competence.competence_id;
      competence.children = [];
      competence.expanded = false;

      if (competence?.competence?.files?.length) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }

    yield put(PA.profileFetchPersonCompetences.success({competences}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(PA.profileFetchPersonCompetences.failure({error}));
  }
}

function* updateProgress() {
  const personCurrent = yield select(getProfile);
  const rolesCurrent = yield select(getRoles);
  const passedIdsCurrent = yield select(getPassedCompetences);

  try {
    const updated = Array.isArray(rolesCurrent?.data) && isObjectWithKeys(passedIdsCurrent)
      ? produce({
        personCurrent,
        rolesCurrent,
        passedIdsCurrent,
      }, draft => {
        const person = draft.personCurrent;
        const roles = draft.rolesCurrent;
        const passed_ids = draft.passedIdsCurrent;

        if (person.data && Array.isArray(roles.data) && passed_ids) {
          for (const pos of roles.data) {
            let comp_passed_ids = 0;

            if (passed_ids.keys.length > 0) {
              for (const element of pos.required_competences) {
                if (passed_ids.keys.includes(element.id)) {
                  comp_passed_ids += 1;
                }
              }
            }
            pos.progress = 0;
            pos.taskdone = comp_passed_ids;
            pos.tasks = pos.required_competences.length;
            if (comp_passed_ids !== 0) {
              pos.progress = Math.round(comp_passed_ids / pos.tasks * 100);
            }
          }

          const roles_update = [];

          for (const pos of roles.data) {
            if (pos.id === person.data.positions[0].id) {
              roles_update.push(pos);
              break;
            }
          }
          for (const pos of roles.data) {
            if (pos.required_competences.length !== 0 && pos.id !== person.data.positions[0].id) {
              roles_update.push(pos);
            }
          }

          updated.updatedRoles = roles_update;
        }
      })
      : null;

    if (updated) {
      yield put(PA.profileFetchRoles.success({roles: updated.updatedRoles}));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchProfileEvents() {
  console.log('fetchProfileEvents');
  try {
    yield put(PA.profileFetchPersonEvents.request());

    const userName = yield call(waitForUsername); // yield select(selectProfileUserName);
    const my_events = yield call(getCourseEventsAPI, {userName});

    yield put(PA.profileFetchPersonEvents.success({events: my_events}));

    yield call(updateCourseEvents, {
      payload: {
        events: my_events?.personevents,
        partial: true,
        type: 'profile',
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function* fetchPersonEvents(userName) {
  console.log('fetchPersonEvents');

  try {
    const profileUsername = yield call(waitForUsername);// select(selectProfileUserName);

    const events = yield call(getCourseEventsAPI, {userName});

    yield put(PA.profileFetchPersonEvents.success({events}));

    yield call(updateCourseEvents, {
      payload: {
        events: events?.personevents,
        partial: true,
        type: userName === profileUsername ? 'profile' : 'employee',
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export function* fetchFullPerson(action) {
  let userName = action?.payload?.userName;

  if (!userName) userName = yield select(selectProfileUserName);

  try {
    const loggedIn = yield select(getAuthStatus);

    // IF WE ARE NOT LOGGED IN JUST RETURN.
    if (!loggedIn?.isLoggedIn) return;

    // const atlasActivated = configObject.isMapActivated;
    const myEducationEnabled = localStorage.getItem('my-education-enabled') || (yield call(waitForConfigObject, true))?.isModuleEnabled?.('my-education');

    yield put(PA.profileFetchPersonFull.request());

    yield userName
      ? spawn(fetchPerson, {
        payload: {
          myEducationEnabled,
          userName,
        },
      })
      : call(fetchPerson, {payload: {myEducationEnabled}});

    if (myEducationEnabled) {
      userName = userName || (yield select(selectProfileUserName));

      yield spawn(fetchPersonEvents, userName);
      yield spawn(fetchPassedCompetences, {userName});
    }

    /*
    THIS SHOULD NOT BE CALLED ANY MORE, LEGACY
    if (atlasActivated) {
      yield call(updateMapCompetences, {alreadyFetchedOutroAndVerification: myEducationEnabled});
    }
     */

    // const profile = yield select(getProfile);
    //
    // if (!profile?.data?.user_name) yield take(`${PA.profileFetchPerson.success}`);

    yield put(PA.profileFetchPersonFull.success());
    yield put(alertAction.actionClear());
  } catch (error) {
    console.error(error);
  }
}

function* editPassword({payload: {data: {newPassword, oldPassword}, callback}}) {
  yield put(PA.profileEditPassword.request());

  try {
    const userName = yield select(selectProfileUserName);

    const query = encodeURI(stringifyUrlParams({
      password: newPassword,
      old_password: oldPassword,
      password_match: newPassword,
    }, 'person_data'));

    // TODO: some sort of feedback with result for the user
    const {data: return_data} = yield call(ProfileAxios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${query}`,
      withCredentials: true,
    });

    if (return_data.valid) {
      yield put(PA.profileEditPassword.success());
      yield put(notificationsAdd({
        notification: {
          text: i18n('person.password-change-success'),
          color: 'green',
        },
      }));
      if (callback) callback();
    } else {
      yield put(PA.profileEditPassword.failure({}));
      yield put(notificationsAdd({
        notification: {
          text: return_data.message,
          color: 'red',
        },
      }));
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditPassword.failure({error}));
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.password-change-failure'),
        color: 'red',
      },
    }));
  }
}

function* fetchExpiringCompetences(action) {
  try {
    const userName = yield call(waitForUsername);

    yield put(PA.profileFetchExpiring.request(action?.payload));

    const {data: {personcompetences: data}} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/personcompetences`,
      params: {
        user_name: userName,
        fields:
            'id,passed,date,valid_until,competence_title,competence_id,competence_type(title,competence_type)',
        state: 'expiring',
      },
      withCredentials: true,
    }));

    yield put(PA.profileFetchExpiring.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchExpiring.failure({error}));
  }
}

export function* getMapOrTrackIdFromCompetenceId({
  competenceId,
  mapId: mapIdPayload,
  trackId: trackIdPayload,
  isTrack,
  isMap,
}) {
  if (mapIdPayload) return mapIdPayload;
  if (trackIdPayload) return trackIdPayload;

  const pathname = window.location.pathname;

  try {
    if (isMap || !isTrack || pathname.includes('/atlas/')) {
      const dotts = yield select(selectMapDottsWithoutVerificationAndOutro);

      const isMapCompetence = !!competenceId && dotts?.some?.(dott => dott.id === competenceId);

      if (isMapCompetence) return yield select(selectMapTrackId);
    }
    const trackIdFromPath = pathname.includes('/learning-path/')
      ? pathname.split('/').at(-1)
      : null;
    const trackId = trackIdFromPath == null
      ? null
      : Number(trackIdFromPath);

    if (trackId) return trackId;

    return null;
  } catch (error) {
    console.error(error);

    return null;
  }
}

/*
 *  FUNCTION ON UPDATING ALL COMPETENCES, WILL CONTROL THE REST OF THE PROCESS AS WELL.
 */
function* updateCompetences(action) {
  const {
    cid: refetchCompetenceOfId,
    data = {},
    mapId: mapIdPayload = null,
    isPassed: payloadIsPassed,
    section: payloadSection = '',
    // skipPageReload: payloadSkipPageReload,
    disableStatusUpdateCheck,
    // trackId,
  } = action?.payload || {};

  try {
    const isPassed = data?.passed === 100 || payloadIsPassed;

    const section = window.location.href.includes('/role')
      ? 'roles'
      : payloadSection;

    const userName = yield select(selectProfileUserName);
    const mapCourses = yield select(getMapCourses);

    const mapId = mapIdPayload ?? (yield call(getMapOrTrackIdFromCompetenceId, {competenceId: refetchCompetenceOfId}));

    const isCurrentActiveMap = !!mapId && mapCourses.data?.id === Number(mapId);
    const activeTrack = yield select(selectLearningPathTrackExtraData);

    const isCurrentActiveTrack = !!mapId && activeTrack?.data?.id === Number(mapId);

    if (!isCurrentActiveTrack && !isCurrentActiveMap) yield call(fetchPassedCompetences, {
      cid: [refetchCompetenceOfId],
      userName,
      dirty: 1,
    });

    // const skipPageReload = payloadSkipPageReload || !!mapId;
    // if (!skipPageReload) {
    //   yield put(alertAction.actionLoading({message: 'course-done'}));
    // }

    const passedIds = yield select(getPassedIds);

    if (
      isCurrentActiveMap
      && isPassed
    ) {
      let i = -1;

      let updateMapComponentState = false;
      let updateMapDotts = true;

      const newMapCourses = produce(mapCourses, draft => {
        const dotts = draft.data.tracks[0].dotts;
        const targetIndex = dotts.findIndex(dott => getCompetenceId(dott) === refetchCompetenceOfId);

        if (targetIndex === -1 || dotts[targetIndex].real_status === 'DONE') {
          updateMapDotts = false;

          return;
        }
        dotts[targetIndex].status = 'DONE';
        dotts[targetIndex].real_status = 'DONE';

        i = targetIndex + 1;

        while (i < dotts.length) {
          if (dotts[i].real_status === 'LOCKED') {
            dotts[i].status = 'OPEN';
            dotts[i].real_status = 'OPEN';

            break;
          }
          if (dotts[i].real_status === 'OPEN') {
            dotts[i].status = 'OPEN';

            break;
          }
          if (dotts[i].real_status === 'DONE') {
            dotts[i].status = 'DONE';
          }
          i++;
        }
        updateMapComponentState = i < dotts.length;
      });

      if (updateMapDotts) {
        yield put(mapCoursesActions.fetchMapCoursesSucceeded({
          data: newMapCourses.data,
          id: mapId,
          skipComponentUpdate: true,
        }));
      }
      if (updateMapComponentState) {
        yield put(componentsSetMap({
          animationTargetIndex: Math.min(i, mapCourses.data.tracks[0].dotts.length - 1),
          skipAnimation: false,
        }));
      }
    }

    const passedIds_new = yield select(getPassedIds);

    if (
      !disableStatusUpdateCheck
    && (passedIds.length !== passedIds_new.length || section === 'roles')
    ) {
    /*
     * check if we have a status update, if we do "reload" page.
     *
     * */
      yield put(PA.profileFetchCompetenceLevel());// updateCompetenceLevel();

      if (section === 'roles') {
        const selectedRoleId = yield select(getSelectedRoleId);

        yield put(fetchRole(selectedRoleId));
      } else {
        yield fetchPersonCompetencesAPI();
      }
    }

    yield updateProgress();

    if (window.location.href.includes('/atlas/') || isCurrentActiveMap) {
      const isAllDone = yield select(getIsAllMapDotsCompleted);

      if (isAllDone) {
        const badge = yield select(getPropertiesForCurrLangAndTrackBadge);

        if (badge) {
          yield put(selectMapCourse({id: 'badge'}));
        }
      }
    }
    yield put(CA.coursesCloseCourse());
    yield put(alertAction.actionClear());
  } catch (error) {
    console.error(error);
  }
}

function* editPerson(action) {
  const {
    person: {
      profile_image_dataURL,
      firstname,
      lastname,
      email,
      mobile,
      roles,
    } = {},
    onSuccess,
    onError,
  } = action?.payload || {};

  try {
  /*
   * TRIGGER SO WE UPDATE THE PERSON CARD AS WELL.
   * */
    yield put(PA.profileEditPerson.request());
    const userName = yield select(selectProfileUserName);
    const params = encodeURI(stringifyUrlParams(
      {
        profile_image_dataURL,
        firstname,
        lastname,
        mobile,
        email,
        roles: roles.map(role => role.role_id),
      },
      'person_data',
      true,
    ));

    yield call(ProfileAxios.request, {
      method: 'PUT',
      url: `${backendUrl}/api/persons/${userName}?${params}`,
      withCredentials: true,
    });
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.your-profile-updated-success'),
        color: 'green',
      },
    }));
    if (onSuccess) onSuccess();
    yield call(fetchPerson, {});
    yield put(PA.profileEditPerson.success());
  } catch (error) {
    if (onError) onError();
    yield put(notificationsAdd({
      notification: {
        text: i18n('person.your-profile-updated-failure'),
        color: 'red',
      },
    }));
    console.error(error);
    yield put(PA.profileEditPerson.failure(error));
  }
}

function* changeProfilePicture(action) {
  const {person_id, file} = action.payload;

  if (!file || person_id) return;

  yield put(PA.profileChangeProfilePicture.request());

  try {
    const formData = new FormData();

    formData.append('profile_image', file);
    const {data} = yield retry(() => ProfileAxios.request({
      method: 'POST',
      url: `${backendUrl}/persons/save_profile_image/${person_id}`,
      data: formData,
      withCredentials: true,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
    }));

    yield put(PA.profileChangeProfilePicture.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileChangeProfilePicture.failure({error}));
  }
}

function* deleteProfilePicture(action) {
  const {person_id, file_id} = action.payload || {};

  if (!file_id || !person_id) return;

  yield put(PA.profileDeleteProfilePicture.request());

  try {
    const {data} = yield retry(() => ProfileAxios.request({
      method: 'POST',
      url: `${backendUrl}/deletemanagefile/person/${person_id}/${file_id}`,
      withCredentials: true,
    }));

    yield put(PA.profileDeleteProfilePicture.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileDeleteProfilePicture.failure({error}));
  }
}

function cheatAPI(action) {
  return ProfileAxios
    .request({
      method: 'get',
      url: `${backendUrl}/templates/set-comp?username=${action.userName}&cid=${action.id}`,
    })
    .then(response => response.data);
}

function* cheatCompetence(payload) {
  try {
    const userName = yield select(selectProfileUserName);

    yield call(cheatAPI, {
      id: payload.payload.id,
      userName,
    });
    yield delay(2000);
    yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/competencelevel`,
      withCredentials: true,
    })
      .then(({data: {competencelevel: [profile]}}) => profile));
    yield call(updateCompetences, {cid: payload.payload.id});
  } catch (error) {
    console.error(error);
  }
}

function* fetchCv(action) {
  const {cvId} = action.payload;

  yield put(PA.profileFetchCv.request());
  try {
    const cv = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/cvs/${cvId}`,
      withCredentials: true,
    }));

    yield put(PA.profileFetchCv.success({cv: cv.data.cv[0]}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCv.failure({error}));
  }
}

function* fetchCvs(action) {
  yield put(PA.profileFetchCvs.request());
  try {
    const cvs = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/api/cvs`,
      withCredentials: true,
    }));

    yield put(PA.profileFetchCvs.success({cvs: cvs.data.cv}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchCvs.failure({error}));
  }
}

function* addCv(action) {
  const {data} = action.payload;

  yield put(PA.profileAddCv.request());

  try {
    const cv = yield retry(() => ProfileAxios.request({
      method: 'POST',
      data,
      url: `${backendUrl}/api/cvs`,
      withCredentials: true,
    }));

    yield put(PA.profileAddCv.success({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());

    if (action?.payload?.onSuccessCallback) {
      action.payload.onSuccessCallback(cv.data.cv[0]);
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileAddCv.failure({error}));
  }
}

function* editCv(action) {
  const {data, cvId} = action.payload;

  yield put(PA.profileEditCv.request());

  try {
    const cv = yield retry(() => ProfileAxios.request({
      method: 'PUT',
      data,
      url: `${backendUrl}/api/cvs/${cvId}`,
      withCredentials: true,
    }));

    yield put(PA.profileEditCv.success({cv: cv.data.cv[0]}));
    yield put(PA.profileFetchCvs());

    if (action?.payload?.onSuccessCallback) {
      action.payload.onSuccessCallback();
    }
  } catch (error) {
    console.error(error);
    yield put(PA.profileEditCv.failure({error}));
  }
}

function* removeCv(action) {
  const {cvId, onSuccessCallback, onFailureCallback} = action.payload;

  yield put(PA.profileRemoveCv.request());

  try {
    yield retry(() => ProfileAxios.request({
      method: 'DELETE',
      url: `${backendUrl}/api/cvs/${cvId}`,
      withCredentials: true,
    }));

    yield put(PA.profileRemoveCv.success({removedId: cvId}));
    yield put(PA.profileFetchCvs());

    yield put(notificationsAdd({
      notification: {
        text: i18n('cv.delete-success'),
        color: 'green',
      },
    }));
  } catch (error) {
    console.error(error);
    yield put(notificationsAdd({
      notification: {
        text: i18n('cv.cv-delete-failure'),
        color: 'red',
      },
    }));

    yield put(PA.profileRemoveCv.failure({error}));
  }
}

function* fetchTasks(payload) {
  // const profile = yield call(waitForProfile, true);
  const personId = yield call(waitForProfileId);

  yield put(PA.profileFetchMyTasks.request());

  try {
    const {data} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/persons/pendingchecklists/${personId}`,
      withCredentials: true,
    }));

    console.log('my data', data);
    yield put(PA.profileFetchMyTasks.success({competences: data.competences}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchMyTasks.failure({error}));
  }
}

function* fetchReport(payload) {
  const personId = yield call(waitForProfileId);

  yield put(PA.profileFetchReport.request());

  try {
    const {data} = yield retry(() => ProfileAxios.request({
      method: 'GET',
      url: `${backendUrl}/reports/personfullcompetencereportjson/${personId}`,
      params: {fields: 'id,passed,date,certificate_url,competence_title,competence(competence_type,files)'},
      withCredentials: true,
    }));

    yield put(PA.profileFetchReport.success({data}));
  } catch (error) {
    console.error(error);
    yield put(PA.profileFetchReport.failure());
  }
}

const forceRefreshSelfSignListener = takeLatest([
  `${PA.profileCancelSelfSign.success}`,
  `${PA.profileCancelSelfSign.failure}`,
  `${PA.profileCreateSelfSign.success}`,
  `${PA.profileCreateSelfSign.failure}`,
], forceRefreshSelfSign);

const exportObj = [
  forceRefreshSelfSignListener,
  takeEvery([
    `${PA.profileUpdatePassedCompetences}`,
    `${CA.coursesCourseFinished}`,
  ], updateCompetences),
  takeLeading(routerMyEducationMainViewDidMount().type, fetchPassedCompetencesFull),
  takeLatest(`${PA.profileAutoFetchSelfSign}`, autoFetchSelfSign),
  takeEvery(`${PA.profileUpdateActiveOrgId}`, updateActiveOrgIdSaga),
  takeLatest(`${PA.profileFetchPersonEvents}`, fetchProfileEvents),
  takeLatest(`${PA.profileFetchExpiring}`, fetchExpiringCompetences),
  takeLatest(`${PA.profileEditPassword}`, editPassword),
  takeLatest(`${PA.profileFetchPersonFull}`, fetchFullPerson),
  takeLatest(`${PA.profileFetchPersonSummary}`, fetchPersonsSummary),
  takeLatest(`${PA.profileFetchRequirements}`, fetchMissingCompetencesAPI),
  takeLatest(`${PA.profileFetchPersonCompetences}`, fetchPersonCompetencesAPI),
  takeLatest(`${PA.profileSelfSign}`, fetchSelfSign),
  takeLatest(`${PA.profileFetchPassedCompetences}`, fetchPassedCompetences),
  takeEvery(`${PA.profileUpdateOneCompetence}`, updateOneCompetence),
  takeEvery(`${PA.profileFetchCompetencesChildren}`, fetchCompetencesChildrenSaga),
  takeLatest(`${PA.profileEditPerson}`, editPerson),
  takeLatest(`${PA.profileChangeProfilePicture}`, changeProfilePicture),
  takeLatest(`${PA.profileChangeProfilePicture.success}`, fetchPerson),
  takeLatest(`${PA.profileDeleteProfilePicture}`, deleteProfilePicture),
  takeLatest(`${PA.profileDeleteProfilePicture.success}`, fetchPerson),
  takeLatest(`${PA.profileFetchPerson}`, fetchPerson),
  takeLatest(`${PA.profileCheatCompetence}`, cheatCompetence),
  takeLatest(`${PA.profileFetchCv}`, fetchCv),
  takeLatest(`${PA.profileCreateSelfSign}`, createSelfSign),
  takeLatest(`${PA.profileFetchCvs}`, fetchCvs),
  takeLatest(`${PA.profileEditCv}`, editCv),
  takeLatest(`${PA.profileFetchReport}`, fetchReport),
  takeLatest(`${PA.profileFetchMyTasks}`, fetchTasks),
  takeLatest(`${PA.profileAddCv}`, addCv),
  takeLatest(`${PA.profileRemoveCv}`, removeCv),
  takeLatest(`${PA.profileCancelSelfSign}`, cancelMySelfSign),
  takeLatest(`${PA.profileFetchCompetenceLevel}`, fetchProfileCompetenceLevelSaga),
];

export default exportObj;
