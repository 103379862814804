import React from 'react';
import {ClearCacheProvider} from 'react-clear-cache';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {ENABLE_REDUX_TESTING, version} from '@config';
import * as Sentry from '@sentry/react';
import {configureStore} from '@store';
import {defaultThemeGlobalCSSVars, styleInjectThemeCSSVars} from '@utils/themes.utils';
import {ModalsProvider} from './features/modals/context/modals-context';
import App from './app';
import {env} from './env';
import {ThemeProvider} from './providers';
import * as serviceWorker from './serviceWorker';
import './index.scss';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://86b43514fafa44f1830181ed78f2d228@o1035034.ingest.sentry.io/6003562',
    integrations: [new Sentry.BrowserTracing({tracePropagationTargets: [/^\/apiv2\//]})],
    release: `waymaker@${version}.${process.env.REACT_APP_GIT_SHA}`,
    tracesSampleRate: 0.1,
  });
}

const store = configureStore();

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production' && ENABLE_REDUX_TESTING) {
  window.store = store;
}

const root = ReactDOM.createRoot(document.querySelector('#root'));

styleInjectThemeCSSVars(defaultThemeGlobalCSSVars);

root.render(<Provider store={store}>
  <ThemeProvider>
    <ClearCacheProvider duration={300_000}>
      <Router basename={process?.env?.REACT_APP_MOUNT_PATH || env.REACT_APP_MOUNT_PATH}>
        <ModalsProvider>
          <App />
        </ModalsProvider>
      </Router>
    </ClearCacheProvider>
  </ThemeProvider>
            </Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
