import * as React from 'react';
import {useTheme} from '@emotion/react';
import {faChevronRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ActionButtonStyled, IconWrapper, Separator} from './buttonStyles';

const rightChevronProps = {
  icon: faChevronRight,
  css: {
    marginLeft: '0.75em',
    fontSize: '0.8em',
    alignSelf: 'center',
  },
};

/** *
 * props:
 * variant: controlls the shape and look-and-feel of the button
 * theme: adjusts the colors for a given variant
 */

export const ActionButton = React.forwardRef(({
  children,
  withRightChrevron,
  disabled,
  withPhosphorIcon,
  placeholderMode, // hide text and icons
  variant = 'default',
  fontSize,
  className,
  ...props
}, ref) => {
  const theme = useTheme();
  const PhosphorIconComponent = withPhosphorIcon?.icon;

  const phosphorIconProps = React.useMemo(() => ({
    weight: withPhosphorIcon?.weight || 'fill',
    color: theme.buttons[variant || 'default'].iconColor || (['default', 'defaultTheme', 'cancel', 'cancelTheme'].includes(variant) ? 'black' : 'white'),
    fontSize: withPhosphorIcon?.fontSize || '20px',
  }), [theme, variant, withPhosphorIcon]);

  return (
    <ActionButtonStyled
      className={`action-button ${variant || 'default' } ${className || ''}`}
      ref={ref}
      theme={theme}
      variant={variant || 'default'}
      disabled={disabled}
      fontSize={fontSize}
      hasRightIcon={withRightChrevron && !withRightChrevron.noSeparatorLine || withPhosphorIcon?.position === 'right' || withPhosphorIcon && !withPhosphorIcon.position}
      hasLeftIcon={withPhosphorIcon?.position === 'left'}
      {...props}
    >
      {withPhosphorIcon && withPhosphorIcon.position === 'left'
        && !placeholderMode && (
          <IconWrapper position="left">
            <Separator />
            <PhosphorIconComponent {...phosphorIconProps}/>
          </IconWrapper>
      )}
      {!placeholderMode && children}

      {withPhosphorIcon && (withPhosphorIcon.position === 'right' || !withPhosphorIcon.position)
        && (
          <IconWrapper position="right">
            <Separator />
            <PhosphorIconComponent {...phosphorIconProps}/>
          </IconWrapper>
        )}
      {placeholderMode && (
        <span
          aria-hidden="true"
          style={{color: 'transparent'}}
        >
          {/* display some invisible text to take up space */}
          Test1234567
        </span>
      )}
      {withRightChrevron && !placeholderMode && !withRightChrevron.noSeparatorLine && (
        <IconWrapper position="right">
          <Separator />
          <FontAwesomeIcon
            {...rightChevronProps}
          />
        </IconWrapper>
      )}
      {withRightChrevron && !placeholderMode && withRightChrevron.noSeparatorLine && (
        <FontAwesomeIcon
          {...rightChevronProps}
        />
      )}
    </ActionButtonStyled>
  );
});
